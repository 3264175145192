const SocialIcon = () => {

  return <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.5479 18.0967C11.7655 18.1523 11.9857 18.1801 12.2084 18.1801C12.7861 18.1801 13.2957 18.0196 13.7373 17.6986C14.1788 17.3776 14.5013 16.9542 14.7046 16.4285C14.8776 16.6819 15.1052 16.8769 15.3874 17.0136C15.6697 17.1502 15.9635 17.2185 16.2689 17.2185C16.8391 17.2185 17.3196 17.0199 17.7106 16.6228C18.1016 16.2257 18.2971 15.7482 18.2971 15.1904C18.2971 14.6949 18.1321 14.2505 17.8021 13.8572C17.4721 13.4638 17.0546 13.2483 16.5497 13.2105C16.7596 12.9646 16.9225 12.6958 17.0383 12.404C17.1542 12.1123 17.2121 11.8043 17.2121 11.4801C17.2121 10.6225 16.9067 9.95831 16.2959 9.48744C15.6851 9.01657 14.9816 8.78113 14.1855 8.78113C13.5892 8.78113 12.9346 8.88088 12.2214 9.08039C11.5083 9.2799 10.7007 9.52384 9.79871 9.81222L10.0937 10.8958C10.9872 10.5979 11.7713 10.3578 12.4459 10.1753C13.1205 9.9928 13.7107 9.90155 14.2167 9.90155C14.6942 9.90155 15.1284 10.0287 15.5194 10.2831C15.9104 10.5374 16.1058 10.9364 16.1058 11.4801C16.1058 12.2063 15.8262 12.6658 15.2669 12.8587C14.7076 13.0516 14.1462 13.2019 13.5827 13.3097L13.3175 13.7508C13.4527 14.1441 13.5659 14.4752 13.6572 14.744C13.7484 15.0127 13.794 15.2632 13.794 15.4953C13.794 15.9226 13.643 16.2914 13.3409 16.6015C13.0388 16.9117 12.6698 17.0668 12.2339 17.0668C12.1117 17.0668 11.9895 17.0527 11.8673 17.0246C11.7451 16.9964 11.6229 16.9495 11.5006 16.8838C11.4174 17.0327 11.3249 17.1954 11.2231 17.3722C11.1213 17.5489 11.0195 17.7024 10.9178 17.8326C11.1202 17.9531 11.3302 18.0411 11.5479 18.0967Z" fill="#B668AE"/>
    <path d="M7.13721 11.8028C7.57452 12.2155 8.05934 12.6596 8.59166 13.1352L9.38022 12.3467C8.88098 11.9042 8.43102 11.4924 8.03036 11.1113C7.6297 10.7303 7.28885 10.3708 7.00778 10.0327C6.72672 9.69471 6.50983 9.37432 6.35713 9.07158C6.20443 8.76882 6.12808 8.47532 6.12808 8.1911C6.12808 7.7446 6.27459 7.37484 6.5676 7.08183C6.86061 6.78881 7.23037 6.64231 7.67687 6.64231C8.09668 6.64231 8.43471 6.75979 8.69095 6.99475C8.94718 7.22972 9.29514 7.60391 9.73482 8.11733H10.2468C10.6742 7.62376 11.016 7.25453 11.2722 7.00964C11.5285 6.76475 11.8798 6.64231 12.3263 6.64231C12.658 6.64231 12.9482 6.73213 13.1969 6.91178C13.4455 7.09143 13.6247 7.32262 13.7344 7.60533C13.9112 7.58547 14.105 7.57553 14.3157 7.57553C14.5264 7.57553 14.716 7.58925 14.8846 7.61667C14.7579 7.02005 14.4612 6.52413 13.9946 6.12891C13.528 5.73368 12.9709 5.53606 12.3232 5.53606C12.2315 5.53606 12.1494 5.53961 12.0771 5.5467C12.0048 5.55379 11.9322 5.56443 11.8594 5.57862C11.9133 5.46043 11.9549 5.34295 11.9842 5.22618C12.0135 5.10941 12.0282 4.98341 12.0282 4.8482C12.0282 4.28483 11.8308 3.80597 11.436 3.41161C11.0413 3.01725 10.5623 2.82007 9.9992 2.82007C9.43606 2.82007 8.95739 3.01725 8.56319 3.41161C8.16899 3.80597 7.97189 4.28483 7.97189 4.8482C7.97189 4.98173 7.98654 5.10919 8.01585 5.23058C8.04516 5.35198 8.08677 5.47177 8.14067 5.58997C8.06785 5.57579 7.99528 5.56326 7.92296 5.55238C7.85062 5.5415 7.76859 5.53606 7.67687 5.53606C6.92978 5.53606 6.30051 5.7918 5.78905 6.30327C5.27758 6.81474 5.02185 7.44401 5.02185 8.1911C5.02185 8.61469 5.10684 9.02292 5.27681 9.41579C5.44678 9.80865 5.69002 10.2015 6.00652 10.5944C6.32301 10.9872 6.69991 11.39 7.13721 11.8028Z" fill="#e3cee1"/>
    <circle cx="10" cy="4.85767" r="1" fill="white"/>
    <path d="M6.25581 17.695C6.69264 18.0137 7.19377 18.173 7.75919 18.173C8.46929 18.173 9.04843 17.9612 9.4966 17.5376C9.94477 17.114 10.3062 16.5961 10.5809 15.9839C10.8555 15.3716 11.0681 14.7256 11.2184 14.0458C11.3687 13.3659 11.4968 12.766 11.6027 12.246L10.5192 11.951C10.4483 12.2866 10.357 12.7421 10.2455 13.3175C10.1339 13.8929 9.97505 14.4573 9.76893 15.0109C9.56279 15.5645 9.29898 16.0444 8.9775 16.4505C8.65602 16.8566 8.24756 17.0596 7.75211 17.0596C7.32154 17.0596 6.95695 16.9043 6.65833 16.5937C6.35971 16.2831 6.2104 15.9207 6.2104 15.5066C6.2104 15.2806 6.25602 15.0305 6.34726 14.7563C6.43851 14.4821 6.55173 14.1484 6.68694 13.755L6.42883 13.3139C5.87758 13.2062 5.31924 13.057 4.75382 12.8665C4.18839 12.676 3.90568 12.2176 3.90568 11.4914C3.90568 11.2557 3.95958 11.0303 4.06738 10.8154C4.17517 10.6004 4.32929 10.4159 4.52974 10.2618C4.43236 10.1199 4.3433 9.95508 4.26256 9.76716C4.18183 9.57924 4.11772 9.40279 4.07022 9.23779C3.66271 9.4893 3.34596 9.8121 3.11997 10.2062C2.894 10.6003 2.78101 11.0226 2.78101 11.473C2.78101 11.7992 2.84011 12.1112 2.95829 12.4091C3.07649 12.7069 3.24055 12.974 3.45045 13.2104C2.94554 13.2435 2.52809 13.4591 2.19811 13.8571C1.86812 14.2552 1.70312 14.6996 1.70312 15.1903C1.70312 15.7537 1.89861 16.2326 2.28959 16.6269C2.68056 17.0213 3.16112 17.2185 3.73126 17.2185C4.03668 17.2185 4.3305 17.1502 4.61273 17.0135C4.89497 16.8769 5.1226 16.6819 5.29563 16.4285C5.49891 16.9542 5.81897 17.3764 6.25581 17.695Z" fill="#c897c3"/>
    <circle cx="3.73474" cy="15.1896" r="1" fill="white"/>
    <circle cx="16.2701" cy="15.1982" r="1" fill="white"/>
  </svg>;
}

export default SocialIcon;
