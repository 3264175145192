
const PeopleIcon = () => {

  return <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.9958 14.8711H4.09839C4.09834 13.7912 4.09839 12.7504 9.20516 12.345C13.1015 12.345 14.053 14.0291 13.9958 14.8711Z" fill="#e3cee1"/>
    <circle cx="8.99341" cy="6.8269" r="1.6709" fill="#e3cee1"/>
    <path d="M1.49841 16.0897V14.2372C1.49841 13.8077 1.60926 13.4244 1.83095 13.0873C2.05264 12.7503 2.34869 12.488 2.71912 12.3006C3.51108 11.9132 4.30743 11.6146 5.10816 11.4047C5.90891 11.1947 6.78899 11.0898 7.74839 11.0898C8.7078 11.0898 9.58786 11.1947 10.3886 11.4047C11.1894 11.6146 11.9857 11.9132 12.7777 12.3006C13.1481 12.488 13.4441 12.7503 13.6658 13.0873C13.8875 13.4244 13.9984 13.8077 13.9984 14.2372V16.0897H1.49841ZM7.74839 9.74355C6.94632 9.74355 6.2597 9.45797 5.68852 8.8868C5.11734 8.31562 4.83175 7.62899 4.83175 6.82692C4.83175 6.02484 5.11734 5.33822 5.68852 4.76705C6.2597 4.19587 6.94632 3.91028 7.74839 3.91028C8.55046 3.91028 9.23709 4.19587 9.80827 4.76705C10.3794 5.33822 10.665 6.02484 10.665 6.82692C10.665 7.62899 10.3794 8.31562 9.80827 8.8868C9.23709 9.45797 8.55046 9.74355 7.74839 9.74355ZM2.74839 14.8397H12.7484V14.2372C12.7484 14.063 12.7049 13.9081 12.6178 13.7724C12.5307 13.6367 12.3926 13.5181 12.2035 13.4166C11.5176 13.063 10.8114 12.7951 10.0849 12.6129C9.35843 12.4308 8.57959 12.3397 7.74839 12.3397C6.9172 12.3397 6.13835 12.4308 5.41185 12.6129C4.68536 12.7951 3.97917 13.063 3.29327 13.4166C3.10417 13.5181 2.96608 13.6367 2.879 13.7724C2.79193 13.9081 2.74839 14.063 2.74839 14.2372V14.8397ZM7.74839 8.49359C8.20673 8.49359 8.59909 8.3304 8.92548 8.00401C9.25186 7.67762 9.41506 7.28526 9.41506 6.82692C9.41506 6.36859 9.25186 5.97623 8.92548 5.64984C8.59909 5.32345 8.20673 5.16026 7.74839 5.16026C7.29006 5.16026 6.8977 5.32345 6.57131 5.64984C6.24492 5.97623 6.08173 6.36859 6.08173 6.82692C6.08173 7.28526 6.24492 7.67762 6.57131 8.00401C6.8977 8.3304 7.29006 8.49359 7.74839 8.49359Z" fill="#B668AE"/>
    <path d="M15.6649 14.1346V16.0897H18.5014V14.1346C18.5014 13.7656 18.3842 13.4252 18.1497 13.1135C17.9152 12.8017 17.5826 12.531 17.1521 12.3013C16.6959 12.0631 16.2167 11.8566 15.7146 11.6819C15.2125 11.5073 14.682 11.3783 14.1233 11.2949C14.6153 11.6498 14.9952 12.0751 15.2631 12.5707C15.531 13.0663 15.6649 13.5876 15.6649 14.1346Z" fill="#c897c3"/>
    <path d="M14.0871 8.8868C14.6583 8.31562 14.9439 7.62899 14.9439 6.82692C14.9439 6.02484 14.6583 5.33822 14.0871 4.76705C13.5159 4.19587 12.8293 3.91028 12.0272 3.91028C11.9076 3.91028 11.7879 3.91669 11.6683 3.92951C11.5486 3.94233 11.429 3.9701 11.3093 4.01284C11.6309 4.41455 11.8817 4.85418 12.0617 5.33174C12.2417 5.80931 12.3317 6.30737 12.3317 6.82592C12.3317 7.34449 12.2435 7.84454 12.067 8.32607C11.8905 8.8076 11.638 9.24591 11.3093 9.64101C11.4033 9.66665 11.523 9.69015 11.6683 9.71151C11.8136 9.73287 11.9332 9.74355 12.0272 9.74355C12.8293 9.74355 13.5159 9.45797 14.0871 8.8868Z" fill="#c897c3"/>
  </svg>;
}

export default PeopleIcon;
