import React, { useState} from "react";
import styled from "styled-components";
import { useRecoilState } from "recoil";
import { showMediaModalAtom } from "../../atoms/profileAtom";
import { Link } from "react-router-dom";

const ModalWrapper = styled.div`
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  //background-color: #fff;

  .overlay {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: .8;
    background-color: rgba(21, 21, 21, 1);
  }

  .inner {
    background-color: #fff;
    position: absolute;
    display: block;
    z-index: 9999;
    max-width: 80%;
    max-height: 92vh;
    overflow: auto;
    width: auto;
    height: auto;
    padding: 20px;
    border-radius: 20px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    
    .tech-info {
      p {
        margin-bottom: 4px;
      }
    }
  }

  .remove-item {
    display: block;
    margin-left: 10px;
    position: relative;
    width: 14px;
    height: 14px;
    opacity: 1;

    &:hover {
      opacity: .7;
    }

    &:after, &:before {
      display: block;
      content: '';
      width: 18px;
      height: 1px;
      background: rgba(16, 24, 40, 1);
      z-index: -1;
    }

    &:after {
      transform: translate(-1px, 5px) rotate(45deg);
    }

    &:before {
      transform: translate(-1px, 6px) rotate(-45deg);
    }
  }

  .image-wrapper {
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      max-width: 100%;
      height: auto;
      object-fit: contain;
      max-height: 70vh;
    }
  }

  .header-modal {
    padding: 15px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    
    img {
      max-width: 160px;
      max-height: 50px;
      //height: auto;
      //width: 100%;
    }
    
    .title {
      text-align: right;
      p {
        color: rgba(101,106,107,1);
        padding-bottom: 0;
        margin-bottom: 0;
      }
    }
  }
`

const ModalImage = ( { imageObject, logo } ) => {
  const [ showModal, setShowModal ] = useRecoilState( showMediaModalAtom );
  const [ techInfo, setTechInfo] = useState(false);
  const baseLogoURL = process.env.REACT_APP_LOGOS_BASE_URL_600;
  const signature = process.env.REACT_APP_LOGOS_SIGNATURE;
  // console.log( imageObject );

  function createTagsHTML(tags) {
    return Object.entries(tags).map(([tagName, tagValue]) => `<p><b>${tagName}: </b>${tagValue}</p>`).join('');
  }

  // const allTagsHTML = imageObject.map(image => createTagsHTML(image.tags)).join('');

  return <ModalWrapper>
    <div className="overlay" onClick={() => setShowModal( false )}></div>
    <div className="inner">
      <div className="d-flex justify-content-end">
        <Link
          to="#"
          className="remove-item"
          onClick={( e ) => setShowModal( false )} />
      </div>
      <div className="header-modal">
        { (logo !== undefined && logo.logo600FileName !== null && logo.logo600FileName !== undefined) ? <img
          src={baseLogoURL + logo.logo600FileName + signature}
          alt={logo.name}
        /> : <div></div>}
        <div className="title text-right">
          <h4 className="fw-400">{imageObject.tags.metric === 'product' ? imageObject.tags.product : (imageObject.tags.metric || 'Metric Name')}</h4>
          <p>{imageObject.tags.source} - {imageObject.tags.date}</p>
        </div>
      </div>
      <div className="image-wrapper position-relativer">
        <img
          src={imageObject.uri}
          alt={imageObject.name}
        />
        <Link
          to="#"
          onClick={()=>setTechInfo(!!!techInfo)}
          className='position-absolute fs-14px'
          style={{
            top: "auto",
            bottom: "-2px",
            left: '20px',
            color: "grey"
          }}
        >
          <i className="fa fa-info-circle"></i>
        </Link>
      </div>
        {techInfo && <div className="tech-info">
          <div className="p-2"></div>
          <p><b>name:</b> {imageObject.name}</p>
          <div dangerouslySetInnerHTML={{__html: createTagsHTML(imageObject.tags)}} />
          <p><b>uri:</b> {imageObject.uri}</p>
        </div>}
    </div>
  </ModalWrapper>;
}

export default ModalImage;
