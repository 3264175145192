import React from 'react';

const HomeIconMain = ({color, size}) => {
  let _color = color ?? 'currentColor';

  return <svg width="28" height="24" viewBox="0 0 28 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.6322 16.673V15.7884V12.4807H7.43227V14.9038H20.0328V12.4807H21.8329V15.7884V16.673H20.9328H6.53224H5.6322Z" fill="#C897C3"/>
    <path d="M5.2326 9.75L6.7326 2.25H24.2326L25.7326 9.75H5.2326Z" fill="#E3CEE1"/>
    <path d="M2.93211 1.63462V0.75C2.72412 0.750123 2.23254 0.75 2.23254 0.75C2.23254 0.75 2.09084 1.26077 2.05007 1.46123L2.93211 1.63462ZM24.533 1.63462L25.415 1.46123C25.3742 1.26077 25.2325 0.75 25.2325 0.75C25.2325 0.75 24.741 0.750123 24.533 0.75V1.63462ZM26.333 10.4808V11.3654H27.2063L27.2151 10.3074L26.333 10.4808ZM1.13204 10.4808L0.25 10.3074V11.3654H1.13204V10.4808ZM2.93211 2.51923H24.533V0.75H2.93211V2.51923ZM23.6509 1.808L25.451 10.6542L27.2151 10.3074L25.415 1.46123L23.6509 1.808ZM26.333 9.59615H1.13204V11.3654H26.333V9.59615ZM2.01407 10.6542L3.81414 1.808L2.05007 1.46123L0.25 10.3074L2.01407 10.6542Z" fill="#B668AE"/>
    <path d="M25.433 23.7499H2.0321V22.8653V21.9807V12.4807H3.83218V21.9807H23.633V12.4807H25.433V21.9807V22.8653V23.7499Z" fill="#B668AE"/>
  </svg>
    ;
}

export default HomeIconMain;
