import React from 'react';

const TableIcon = ({color, size}) => {
	let _size = 21;
	let fillColor = "#B668AE";
	if ( color ) {
		fillColor = color;
	}
	if (size) _size = size;

	return <svg width={_size} height={_size} viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M20.9935 0H0V21H21L20.9935 0ZM0.997974 19.9763V1.02375H19.9922L19.9988 19.9763H0.997974Z" fill={fillColor}/>
		<path d="M20.5799 13.8599H0.419922V13.4399H20.5411" fill={fillColor}/>
		<path d="M20.4186 7.13997L0.419922 7.10766V6.71997L20.5799 6.75228" fill={fillColor}/>
		<path d="M7.98047 20.8358V0.840088H8.40047V21.0001" fill={fillColor}/>
		<path d="M1.67973 16.8L6.71973 16.8L6.71973 17.22L1.67973 17.22" fill={fillColor}/>
		<path d="M1.67973 10.0801L6.71973 10.0801L6.71973 10.5001L1.67973 10.5001" fill={fillColor}/>
		<path d="M1.67973 3.78003L6.71973 3.78003L6.71973 4.20003L1.67973 4.20003" fill={fillColor}/>
		<path d="M12.1797 20.1436V0L12.5997 0.147584V20.16" fill={fillColor}/>
		<path d="M16.3799 20.9837V0.840088L16.7999 0.987672V21.0001" fill={fillColor}/>
	</svg>

}

export default TableIcon;
