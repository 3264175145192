
const CustomizedLabel = (compMetric, position, type) => {
  // console.log(compMetric);
  let _name;
  let _position = `${position}`;
  let _angle;
  compMetric.map(metric=> {
    if (metric.ts_chart_type === type && position === 'left') {
      _name = metric.metric_name;
      _angle = -90;
    } else if (metric.ts_chart_type === type && position === 'right') {
      _name = metric.metric_name;
      _angle = 90;
    }
  })
  return { value: _name, angle: _angle, position: _position };
}

export default CustomizedLabel;
