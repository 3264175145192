import React from "react";

const CommonErrorComponent = ( { error, admin } ) => {

  return <>
    {!admin && <div>Something went wrong</div>}
    {admin && <h5>{error}</h5>}
  </>
}

export default CommonErrorComponent;
