
export function sortArrayByLocationName ( arr, order ) {
  console.log( order )
  return arr.sort( ( a, b ) => {
    // console.log(a.tags.locationname)
    const nameA = a.tags.locationname ? a.tags.locationname.toUpperCase() : 'z'; // Convert to uppercase to ensure case-insensitive comparison
    const nameB = b.tags.locationname ? b.tags.locationname.toUpperCase() : 'z';

    let comparison = 0;
    if ( nameA > nameB ) {
      comparison = 1;
    } else if ( nameA < nameB ) {
      comparison = -1;
    }

    return order === 'desc' ? comparison * -1 : comparison;
  } );
}

export function sortArrayByProduct ( arr, order ) {
  return arr.sort( ( a, b ) => {
    const nameA = a.tags.product ? a.tags.product.toUpperCase() : 'z'; // Convert to uppercase to ensure case-insensitive comparison
    const nameB = b.tags.product ? b.tags.product.toUpperCase() : 'z';

    let comparison = 0;
    if ( nameA > nameB ) {
      comparison = 1;
    } else if ( nameA < nameB ) {
      comparison = -1;
    }

    return order === 'desc' ? comparison * -1 : comparison;
  } );
}

export function sortArrayByMetric ( arr, order ) {
  return arr.sort( ( a, b ) => {
    const nameA = a.tags.metric ? a.tags.metric.toUpperCase() : 'z'; // Convert to uppercase to ensure case-insensitive comparison
    const nameB = b.tags.metric ? b.tags.metric.toUpperCase() : 'z';

    let comparison = 0;
    if ( nameA > nameB ) {
      comparison = 1;
    } else if ( nameA < nameB ) {
      comparison = -1;
    }

    return order === 'desc' ? comparison * -1 : comparison;
  } );
}

export function sortDates ( arr, order ) {
  return arr.sort( ( a, b ) => {
    const dateA = new Date( a.tags.date ) || '2010-01-01';
    const dateB = new Date( b.tags.date ) || '2010-01-01';

    let comparison = 0;
    if ( dateA > dateB ) {
      comparison = 1;
    } else if ( dateA < dateB ) {
      comparison = -1;
    }

    return order === 'desc' ? comparison * -1 : comparison;
  } );
}

export function sortedList  ( data ) {
  let _data = data;
  _data.sort( function ( a, b ) {
    if ( a.name.toLowerCase() > b.name.toLowerCase() ) {
      return 1;
    }
    if ( a.name.toLowerCase() < b.name.toLowerCase() ) {
      return -1;
    }
    return 0;
  } )

  return _data;
}

const sortedListByValue = ( data ) => {
  let _data = data;
  _data.sort( function ( a, b ) {
    if ( a.value.toLowerCase() > b.value.toLowerCase() ) {
      return 1;
    }
    if ( a.value.toLowerCase() < b.value.toLowerCase() ) {
      return -1;
    }
    return 0;
  } )
  // console.log( _data )

  return _data;
}

export const metricOptions = ( metric, array ) => {
  // const _metricOptions = [{value: 'test', label: 'label'}];
  let _metricOptions = [];
  array.metrics.map( el => {
      if ( el.category === metric ) {
        _metricOptions.push( { value: el.name, label: el.name } )
      }
    }
  )
  _metricOptions = sortedListByValue(_metricOptions);
  return _metricOptions
}

export function isDateValid ( dateStr ) {
  return !isNaN( new Date( dateStr ) );
}

export function findIndexByValue ( array, value ) {
  for ( let i = 0; i < array.length; i++ ) {
    if ( array[ i ].name === value ) {
      return i;
    }
  }
  return -1; // Return -1 if the value is not found
}
function lightenColor(color, percent) {
  // Check if the color is in hexadecimal format
  if (color[0] === '#') {
    // Convert hexadecimal to RGB
    const r = parseInt(color.slice(1, 3), 16);
    const g = parseInt(color.slice(3, 5), 16);
    const b = parseInt(color.slice(5, 7), 16);

    // Increase RGB values to lighten the color
    const newR = Math.min(255, r + 255 * percent);
    const newG = Math.min(255, g + 255 * percent);
    const newB = Math.min(255, b + 255 * percent);

    // Convert back to hexadecimal
    return `#${Math.round(newR).toString(16)}${Math.round(newG).toString(16)}${Math.round(newB).toString(16)}`;
  } else {
    // Parse RGB color
    const rgbMatch = color.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/);
    if (rgbMatch) {
      const r = parseInt(rgbMatch[1]);
      const g = parseInt(rgbMatch[2]);
      const b = parseInt(rgbMatch[3]);

      // Increase RGB values to lighten the color
      const newR = Math.min(255, r + 255 * percent);
      const newG = Math.min(255, g + 255 * percent);
      const newB = Math.min(255, b + 255 * percent);

      // Return the new RGB color
      return `rgb(${newR}, ${newG}, ${newB})`;
    } else {
      // If the color format is not recognized, return the original color
      return color;
    }
  }
}

function isVeryDark(color) {
  // Check if the color is in hexadecimal format
  if (color[0] === '#') {
    // Convert hexadecimal to RGB
    const r = parseInt(color.slice(1, 3), 16);
    const g = parseInt(color.slice(3, 5), 16);
    const b = parseInt(color.slice(5, 7), 16);

    // Calculate brightness using the formula: (r * 299 + g * 587 + b * 114) / 1000
    const brightness = (r * 299 + g * 587 + b * 114) / 1000;

    // Return true if the brightness is below a threshold, indicating a very dark color
    return brightness < 50; // You can adjust this threshold as needed
  } else {
    // Parse RGB color
    const rgbMatch = color.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/);
    if (rgbMatch) {
      const r = parseInt(rgbMatch[1]);
      const g = parseInt(rgbMatch[2]);
      const b = parseInt(rgbMatch[3]);

      // Calculate brightness using the formula: (r * 299 + g * 587 + b * 114) / 1000
      const brightness = (r * 299 + g * 587 + b * 114) / 1000;

      // Return true if the brightness is below a threshold, indicating a very dark color
      return brightness < 50; // You can adjust this threshold as needed
    } else {
      // If the color format is not recognized, return false
      return false;
    }
  }
}

export function lightenVeryDarkColor(color, percent) {
  if (isVeryDark(color)) {
    // Lighten the color if it's very dark
    return lightenColor(color, percent);
  } else {
    // Return the original color if it's not very dark
    return color;
  }
}

// Example usage:
// const darkColor = '#3a3a3a';
// const lightenedColor = lightenVeryDarkColor(darkColor, 0.5); // Lighten by 50% if the color is very dark
// console.log(lightenedColor); // Output: "#9a9a9a"

