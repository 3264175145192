
const PetIcon = ({size}) => {
  let _size = size === 'L' ? 2 : 1;

  return <svg width={_size * 24} height={_size * 24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.7847 3.45654L18.9478 8.15044L17.9591 12.1535L14.6133 15.3593H10.282L6.37024 9.4886L7.38499 7.85376L12.5714 6.83902L15.7847 3.45654Z" fill="#e3cee1"/>
    <path d="M13.9846 20.0719C13.9846 18.229 13.9846 15.3527 13.9846 15.3527L16.5327 12.795C17.0353 12.2925 17.4247 11.7236 17.701 11.0883C17.9772 10.453 18.1154 9.78092 18.1154 9.07193C18.1154 8.37322 17.9763 7.70783 17.6981 7.07578C17.4199 6.44372 17.0346 5.8764 16.5423 5.37383L15.7346 4.54113L12.4538 7.82191H8.70386L7.37883 9.14693L6.30963 8.10273L8.09041 6.32196H11.8404L15.7346 2.42773L17.6115 4.29503C18.2525 4.93605 18.7452 5.66457 19.0894 6.48058C19.4336 7.29662 19.6089 8.1604 19.6154 9.07193C19.6089 9.98347 19.4336 10.8473 19.0894 11.6633C18.7452 12.4793 18.2525 13.2078 17.6115 13.8488L15.4846 15.9662V21.5719H13.9846C13.9846 21.2664 13.9846 20.0719 13.9846 20.0719Z" fill="#B668AE"/>
    <path d="M5.23462 16.0239V21.572H12.8723V20.072H6.73459V16.0239H5.23462Z" fill="#c897c3"/>
    <path d="M9.90002 17.0318L4.88272 12.0241C4.71862 11.86 4.59458 11.669 4.51062 11.4511C4.42663 11.2331 4.38464 11.0068 4.38464 10.7722C4.38464 10.5376 4.42663 10.3155 4.51062 10.1059C4.59458 9.89628 4.71862 9.70943 4.88272 9.54533L6.80964 7.60303L9.73654 10.4953C10.184 10.9428 10.5272 11.4546 10.7663 12.0309C11.0054 12.6072 11.125 13.2126 11.125 13.8472C11.125 14.437 11.0224 15.0039 10.8173 15.5482C10.6122 16.0924 10.3064 16.5869 9.90002 17.0318Z" fill="#B668AE"/>
  </svg>;
}

export default PetIcon;
