import React, { useContext, useEffect } from "react";
import { AppSettings } from "../../config/app-settings";
import {ContainerHelpPage} from './FAQPage';
import CustomCompIcon from "../../components/shared/CustomCompIcon";

const MetricsInfo = () => {
  const context = useContext( AppSettings );

  useEffect(
    () => {
      context.handleSetAppHeaderNone( false );
      context.handleSetAppSidebarNone( false );
      context.handleSetAppContentClass( '' );
      context.handleSetAppTitle( 'Data & Metrics info' );
      context.handleSetAppIcon( <CustomCompIcon /> );
    },
    []
  );

  return <ContainerHelpPage>
    <h2 className="color-primary py-2">Info-metrics</h2>
    <div className="col-12">
      <p>Info-metrics is an interdisciplinary approach to scientific modeling, inference and efficient information processing. It is the science of modeling, reasoning, and drawing inferences under conditions of noisy and limited information. From the point of view of the sciences, this framework is at the intersection of information theory, statistical methods of inference, applied mathematics, computer science, econometrics, complexity theory, decision analysis, modeling, and the philosophy of science.</p>

      <p>Info-metrics provides a constrained optimization framework to tackle under-determined or ill-posed problems – problems where there is not sufficient information for finding a unique solution. Such problems are very common across all sciences: available information is incomplete, limited, noisy and uncertain. Info-metrics is useful for modelling, information processing, theory building, and inference problems across the scientific spectrum. The info-metrics framework can also be used to test hypotheses about competing theories or causal mechanisms.</p>
    </div>
  </ContainerHelpPage>
}

export default MetricsInfo;
