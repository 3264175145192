
const MinimizeLegendIcon = () => {

  return <svg width="23" height="14" viewBox="0 0 23 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.7451 6.82613C13.5498 7.0214 13.5498 7.33798 13.7451 7.53324L16.9271 10.7152C17.1223 10.9105 17.4389 10.9105 17.6342 10.7152C17.8294 10.52 17.8294 10.2034 17.6342 10.0081L14.8057 7.17969L17.6342 4.35126C17.8294 4.156 17.8294 3.83942 17.6342 3.64415C17.4389 3.44889 17.1223 3.44889 16.9271 3.64415L13.7451 6.82613ZM22.0004 7.67969C22.2765 7.67969 22.5004 7.45583 22.5004 7.17969C22.5004 6.90354 22.2765 6.67969 22.0004 6.67969L22.0004 7.67969ZM14.0986 7.67969L22.0004 7.67969L22.0004 6.67969L14.0986 6.67969L14.0986 7.67969Z" fill="#26252A"/>
    <path d="M9.25541 7.53324C9.45067 7.33798 9.45067 7.0214 9.25541 6.82613L6.07343 3.64415C5.87817 3.44889 5.56158 3.44889 5.36632 3.64415C5.17106 3.83942 5.17106 4.156 5.36632 4.35126L8.19475 7.17969L5.36632 10.0081C5.17106 10.2034 5.17106 10.52 5.36632 10.7152C5.56158 10.9105 5.87817 10.9105 6.07343 10.7152L9.25541 7.53324ZM1.00011 6.67969C0.723965 6.67969 0.500107 6.90355 0.500107 7.17969C0.500107 7.45583 0.723965 7.67969 1.00011 7.67969L1.00011 6.67969ZM8.90186 6.67969L1.00011 6.67969L1.00011 7.67969L8.90186 7.67969L8.90186 6.67969Z" fill="#26252A"/>
    <path d="M11.4531 1V13.3529" stroke="#26252A" stroke-linecap="round"/>
  </svg>;
}

export default MinimizeLegendIcon;
