import React from 'react';

const CompaniesIcon = ({color, size}) => {
  const _size = size || '16';
  let _color = color ?? 'currentColor';

  return <svg width="18" height={_size} viewBox="0 0 18 16" fill={_color} xmlns="http://www.w3.org/2000/svg">
    <path d="M1.21157 1.74995V0.25H16.7885V1.74995H1.21157ZM1.25004 15.75V9.74995H0.134644V8.25L1.21157 3.25H16.7885L17.8654 8.25V9.74995H16.75V15.75H15.25V9.74995H10.75V15.75H1.25004ZM2.74999 14.25H9.25004V9.74995H2.74999V14.25ZM1.66537 8.25H16.3347L15.5712 4.74995H2.42884L1.66537 8.25Z" fill={_color} />
  </svg>;
}

export default CompaniesIcon;
