
const ExperientialIcon = ({size}) => {
  let _size = size === 'L' ? 2 : 1;

  return <svg width={_size * 24} height={_size * 24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.4375 3.48193V21.4937L13.5787 18.7328H17.2113V17.7421V13.5091H20.0933L19.7631 12.2182L19.1327 9.4562L18.1119 6.7843L16.0104 4.74285L12.4375 3.48193Z" fill="#c897c3"/>
    <path d="M11.4346 2.5C9.07565 2.5 7.06925 3.32532 5.41542 4.97595C3.96281 6.42575 3.14812 8.14877 2.97137 10.145H4.47686C4.64487 8.56049 5.31413 7.18875 6.48462 6.02977C7.85129 4.67657 9.50129 3.99997 11.4346 3.99997C13.0385 3.99997 14.4686 4.47338 15.725 5.42018C16.9814 6.36696 17.8013 7.60189 18.1846 9.12498L19.1346 13H16.4346V18H12.4346V21.5H13.9346V19.5H17.9346V14.5H21.0653L19.6288 8.7596C19.1711 6.90833 18.1766 5.40225 16.6452 4.24135C15.1138 3.08045 13.3769 2.5 11.4346 2.5Z" fill="#B668AE"/>
    <path d="M14.3721 14.0826C13.6291 14.7557 12.7352 15.0922 11.6904 15.0922C10.4724 15.0922 9.44166 14.623 8.59808 13.6846C7.75449 12.7461 7.3327 11.6108 7.3327 10.2788C7.3327 9.59291 7.46475 8.93715 7.72885 8.31151C7.99295 7.68588 8.36924 7.13396 8.8577 6.65576L9.93653 7.72496C9.59036 8.07111 9.32401 8.46663 9.13748 8.91151C8.95094 9.35638 8.85768 9.82048 8.85768 10.3038C8.85768 11.2397 9.13652 12.0243 9.6942 12.6577C10.2519 13.291 10.9256 13.6077 11.7154 13.6077C12.3705 13.6077 12.9154 13.4157 13.35 13.0317C13.7846 12.6477 14.0019 12.1878 14.0019 11.6519C14.0019 11.2109 13.8776 10.8464 13.6288 10.5586C13.3801 10.2708 13.0763 10.1269 12.7173 10.1269C12.4198 10.1269 12.1849 10.2035 12.0125 10.3567C11.84 10.5099 11.7538 10.691 11.7538 10.9C11.7538 10.9923 11.7743 11.0763 11.8154 11.1519C11.8564 11.2275 11.9179 11.2948 12 11.3538L10.9019 12.4134C10.6942 12.189 10.5343 11.9461 10.4221 11.6846C10.3099 11.423 10.2539 11.1532 10.2539 10.875C10.2539 10.2378 10.4942 9.69966 10.975 9.26056C11.4558 8.82146 12.0365 8.60191 12.7173 8.60191C13.4942 8.60191 14.15 8.89582 14.6846 9.48364C15.2192 10.0715 15.4865 10.7859 15.4865 11.6269C15.4865 12.591 15.115 13.4096 14.3721 14.0826Z" fill="#B668AE"/>
    <path d="M5.93463 17.4691V21.4998H7.43461V16.7998L6.78461 16.1998C6.03461 15.4998 5.45544 14.7014 5.04711 13.8046C4.63877 12.9079 4.43461 11.9595 4.43461 10.9595H2.93468L2.93463 10.9902C2.93463 12.203 3.19713 13.3694 3.72213 14.4892C4.24713 15.6091 4.98463 16.6024 5.93463 17.4691Z" fill="#c897c3"/>
  </svg>;
}

export default ExperientialIcon;
