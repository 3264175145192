import React, { useState } from "react";
import SweetAlert from "sweetalert-react";
import { Link } from "react-router-dom";
import { APIHeaders } from "./helpers";
import axios from "axios";
import ClearCacheIcon from "./ClearCacheIcon";

const ClearCacheComponent = ( { show } ) => {
  const [ confirmClear, setConfirmClear ] = useState( show );
  const baseURL = process.env.REACT_APP_BASE_URL;

  const clearCache = () => {
    const config = {
      method: 'get',
      url: `${baseURL}/clear_cash`,
      headers: APIHeaders,
      withCredentials: true,
      credentials: "include",
    };
    axios( config )
      .then( ( response ) => {
        console.log(response)
        setConfirmClear( false )
      } )
  }

  return <>
    <Link to="#" className="menu-link" onClick={() => setConfirmClear( true )}>
      <div className="menu-icon">
        <ClearCacheIcon />
      </div>
      {localStorage.getItem( 'appSidebarMinify' ) !== 'true' &&
        <div className="menu-text">Clear Cache</div>}
    </Link>
    <SweetAlert
      show={confirmClear}
      title="Are you sure that you want to clear the Server Cache?"
      text="This process will take some time. This is a testing/QA feature, and is only visible to Admin users. Please do not run the function unless you've been instructed to do so."
      type="error"
      confirmButtonText="Delete"
      showCancelButton={true}
      onCancel={() => setConfirmClear( false )}
      confirmButtonColor="rgb(255, 91, 87)"
      onConfirm={() => clearCache()}
    />
  </>
}

export default ClearCacheComponent;
