import { APIHeaders, kitcut } from "../../components/shared/helpers";
import axios from "axios";
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import moment from "moment";
import { useRecoilState } from "recoil";
import {
  addCompaniesListAtom,
  messagesLoadedAtom,
  questionsListLoadedAtom,
  showCompanyAddMessageModalAtom,
  showCompEditMessageModalIDAtom,
} from "../../atoms/profileAtom";

const OrderedButtonStyle = styled.span`
  cursor: pointer;
  opacity: ${props => props.opacity};
`;

const OrderableColumnStyle = styled.th`
  cursor: pointer;
  background-color: #fff;
  transition: all 0.3s;
  vertical-align: top;
  min-width: 50px;
  padding: 5px !important;

  &:hover {
    background-color: #eeeeee;
  }
`;

const sortingList = ( list ) => {
  let _data = list;
  _data.sort( function ( a, b ) {
    if ( a.id < b.id ) {
      return 1;
    }
    if ( a.id > b.id ) {
      return -1;
    }
    return 0;
  } )
  return _data;
}

const TicketStatus = ({status}) => {
  let _color = '#ffc2c2';
  let _text = 'New';

  if (status === 'in_process') {
    _color = '#e1e2e1';
    _text = 'In progress';
  } else if (status === 'processed') {
    _color = '#c8ebb0';
    _text = 'Closed';
  }

  return <span style={{'backgroundColor': _color, "padding": "4px 8px"}}>{_text}</span>
}

const CompaniesRequestsPanel = () => {
  const [ addCompaniesList, setAddCompaniesList ] = useRecoilState( addCompaniesListAtom );
  const [ questionsListLoaded, setQuestionsListLoaded ] = useRecoilState( questionsListLoadedAtom );
  const [ orderByASC, setOrderByASC ] = useState( false );
  const [ isOrderedList, setIsOrderedList ] = useState( false );
  const [ param, setParam ] = useState( {} );
  const [ orderByColumnName, setOrderByColumnName ] = useState( '' );
  const [ filteredList, setFilteredList] = useState([]);
  const [ orderByBool, setOrderByBool ] = useState( false );
  const [ orderByInteger, setOrderByInteger ] = useState( false );
  const [ orderByObject, setOrderByObject ] = useState( false );
  const [ showCompanyEditMessageModal, setShowCompanyEditMessageModal ] = useRecoilState( showCompanyAddMessageModalAtom );
  const [ showCompEditMessageModalID, setShowCompEditMessageModalID ] = useRecoilState( showCompEditMessageModalIDAtom );
  const [ loadedMessages, setLoadedMessages ] = useRecoilState( messagesLoadedAtom );
  const baseURL = process.env.REACT_APP_BASE_URL;

  const OrderableColumn = ( { title, name, asc, bool, integer, object, width } ) => {

    return <OrderableColumnStyle
      style={{ minWidth: width + "px" }}
      onClick={() => handleOrder( name, asc, bool, integer, object )}
    >{title}
      <OrderedButton
        opacity={name === orderByColumnName ? 1 : 0}
        asc={orderByASC}
      />
    </OrderableColumnStyle>
  }

  const OrderedButton = ( { asc, opacity } ) => {

    return <OrderedButtonStyle opacity={opacity}>
      {asc ?
        <i className="mx-2 fa fa-arrow-down"></i> :
        <i className="mx-2 fa fa-arrow-up"></i>
      }
    </OrderedButtonStyle>
  }

  const handleOrder = ( name, asc, bool, integer, object ) => {
    setOrderByBool( bool );
    setOrderByInteger( integer );
    setOrderByObject( object );
    if ( !isOrderedList ) {
      setIsOrderedList( true );
      setParam( { per_page: 1000 } );
    }

    if ( orderByColumnName !== name ) {
      setOrderByColumnName( name );
    } else {
      setOrderByASC( !asc )
    }
  }

  useEffect( () => {
    if ( !questionsListLoaded  || !loadedMessages) {
      loadQuestions();
    }
  }, [ questionsListLoaded, loadedMessages ] )

  const loadQuestions = () => {
    setQuestionsListLoaded( false );
    const config = {
      method: 'get',
      url: `${baseURL}/ask_us_requests`,
      headers: APIHeaders,
      withCredentials: true,
      credentials: "include",
    };
    axios( config )
      .then( ( resp ) => {
        let _filteredList = resp.data.data.filter(el=>el.request_type === 'Add Company')
        setAddCompaniesList( sortingList( _filteredList ) );
      } ).finally( () => {
      setQuestionsListLoaded( true );
    } )
  }

  if ( !questionsListLoaded ) return <div className="d-flex justify-content-center align-items-center text-center h-500px">
    <div className="spinner-border m-5" role="status">
      <span className="sr-only">Loading...</span>
    </div>
  </div>;

  return <div className="py-3" style={{
    backgroundColor: "white"
  }}>
    <table className="table mb-0 align-middle">
      <thead>
      <tr>
        <OrderableColumn title="ID" name="id" asc={orderByASC} integer />
        <OrderableColumn title="Name" name="full_name" asc={orderByASC} width="100" />
        <OrderableColumn title="Email" name="email" asc={orderByASC} width="100" />
        <OrderableColumn title="Status" name="status" asc={orderByASC} width="100" />
        <OrderableColumn title="Created" name="created_at" asc={orderByASC} width="80" />
        <OrderableColumn title="Updated " name="updated_at" asc={orderByASC} width="80" />
        <OrderableColumn title="Company" name="selected_questions" asc={orderByASC} />
        <OrderableColumn title="Message" name="details" asc={orderByASC} integer width="90" />
        <OrderableColumn title="Notes" name="admin_notes" asc={orderByASC} />
      </tr>
      </thead>
      <tbody>
      {addCompaniesList && addCompaniesList.length ?
        <>
          {addCompaniesList.map( ( row, i ) => {

            return <tr key={i}
                       className="cursor-pointer"
                       onClick={() => {
                         setShowCompanyEditMessageModal( true );
                         setShowCompEditMessageModalID( row.id );
                       }}
            >
              <td>{row.id}</td>
              <td>{row.user.full_name}</td>
              <td>{row.user.email}</td>
              <td><TicketStatus status={row.status} /></td>
              <td>{row.created_at && moment( row.created_at ).format( 'MM-DD-yyyy' )}</td>
              <td>{row.updated_at && moment( row.updated_at ).format( 'MM-DD-yyyy' )}</td>
              <td>{row.options?.company ? row.options?.company.name : ''}</td>
              <td title={row.details}>{row.details ? kitcut( row.details, 30 ) : ''}</td>
              <td title={row.admin_notes}>{row.admin_notes !== null ? kitcut( row.admin_notes, 30 ) : ''}</td>
            </tr>
          } )}
        </> : <></>
      }
      </tbody>
    </table>
  </div>
}

export default CompaniesRequestsPanel;
