import React, { useParams } from "react-router-dom";
import { APIHeaders } from "../../components/shared/helpers";
import axios from "axios";
import { useRecoilState } from "recoil";
import { customCompAtom, customCompIDAtom } from "../../atoms/profileAtom";
import { useEffect, useState } from "react";
import ScatterPlot from "../Chart/ScatterPlot";
import CombineChart from "../Chart/CombineChart";
import TableChart from "../Chart/TableChart";
import StoreListTable from "./StoreListTable";
import InvestmentTable from "./InvestmentTable";
import MarketTrackerMap from "../market-tracker/MarketTrackerMap";
import MarketTrackerTimeline from "../market-tracker/MarketTrackerTimeline";

const CustomCompID = () => {
  let { id } = useParams();
  const [ compID, setCompID ] = useRecoilState( customCompIDAtom );
  const [ customComp, setCustomComp ] = useRecoilState( customCompAtom );
  const [ customCompType, setCustomCompType ] = useState( '' );
  const [ isLoadedComp, setIsLoadedComp ] = useState( false );
  const [ wrongID, setWrongID ] = useState( false );
  const baseURL = process.env.REACT_APP_BASE_URL;

  useEffect( () => {
    if ( compID !== id ) {
      setCompID( id )
    }
  }, [] )

  useEffect( () => {
    if ( +compID === +compID ) {
      getCustomComp();
    }
  }, [] )

  const getCustomComp = () => {
    setIsLoadedComp( false );
    // let metrics = '?include_data=true';
    const data = new FormData();
    const config = {
      method: 'get',
      url: `${baseURL}/custom_comps/${id}`,
      headers: APIHeaders,
      withCredentials: true,
      credentials: "include",
      data: data,
    };
    axios( config )
      .then( ( resp ) => {
        setCustomComp( resp.data.data );
        setCustomCompType( resp.data.data.type );
        setIsLoadedComp( true );
        // console.log(resp.data.data)
      } ).catch( ( error ) => {
      if ( error.response.status === 404 ) {
        setIsLoadedComp( true );
        setWrongID( true );
      }
      // console.log( error.response.status )
    } )
  }

  if ( !isLoadedComp ) {
    return <div className="d-flex justify-content-center align-items-center text-center min-vh-100">
      <div className="spinner-border m-5" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  }

  if ( +compID !== +compID || wrongID) {
    return <h3>A Custom Comp with this ID ({id}) does not exist.</h3>
  }

  switch ( customCompType ) {
    case "CustomComps::ScatterPlot":
      return <ScatterPlot />;
    case "CustomComps::TimeSeries":
      return <CombineChart />;
    case "CustomComps::Table":
      return <TableChart />;
    case "CustomComps::CompanyList":
      return <InvestmentTable />;
    case "CustomComps::SectorList":
      return <InvestmentTable />;
    case "CustomComps::InvestorList":
      return <InvestmentTable />;
    case "CustomComps::StoreList":
      return <StoreListTable />;
    case "CustomComps::MarketTimeline":
      return <MarketTrackerTimeline />;
    case "CustomComps::MarketTrackerMap":
      return <MarketTrackerMap />;
  }
}

export default CustomCompID;
