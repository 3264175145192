import React, { useContext, useEffect, useState } from "react";
import { AppSettings } from "../config/app-settings";
import { APIHeaders, toasterNotify } from "../components/shared/helpers";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Logo from "../components/shared/Logo";

const ResetPassword = () => {
	const baseURL = process.env.REACT_APP_BASE_URL;
	const context = useContext( AppSettings );
	const [ password, setPassword ] = useState( '' );
	const [ passwordConfirmation, setPasswordConfirmation ] = useState( '' );
	const [ resetPasswordToken, setResetPasswordToken ] = useState( '' );
	const [ messageAPIReset, setMessageAPIReset ] = useState( '' );
	const [ disableButton, setDisableButton ] = useState( true );
	const [ errorPassword, setErrorPassword ] = useState( false );
	const [ isErrorReset, setIsErrorReset ] = useState( false );
	const [ showMessageReset, setShowMessageReset ] = useState( false );
	const [ isSamePassword, setIsSamePassword ] = useState( false );
	const navigate = useNavigate();


	useEffect( () => {
		context.handleSetAppHeaderNone( true );
		context.handleSetAppSidebarNone( true );
		context.handleSetAppContentClass( 'p-0' );
		const queryString = window.location.search;
		const urlParams = new URLSearchParams( queryString );
		setResetPasswordToken( urlParams.get( 'reset_password_token' ) ?? '' )

	}, [] );

  useEffect(()=>{
    if(!resetPasswordToken.length) {
      setShowMessageReset( true );
      setIsErrorReset( true );
      setMessageAPIReset(  'Token is not valid.');
    } else {
      setShowMessageReset( false );
      setIsErrorReset( false );
      setMessageAPIReset(  '');
    }
  }, [resetPasswordToken])

	const onPasswordChange = ( e ) => {
		setPassword( e.target.value );
		setDisableButton( false );
    setMessageAPIReset(  '');
    setIsErrorReset(false);
    setShowMessageReset( false );
		// console.log( errorPassword );
		(e.target.value.length <= 5) ? setErrorPassword( 'is-invalid' ) : setErrorPassword( 'is-valid' );
		e.target.value.length === 0 && setErrorPassword( '' ) && setIsSamePassword( '' );
	}

	const onPasswordConfirmationChange = ( e ) => {
		setPasswordConfirmation( e.target.value )
		password === e.target.value ? setIsSamePassword( 'is-valid' ) : setIsSamePassword( 'is-invalid' );
	}

	const savePassword = (e) => {
		const data = new FormData();
		data.append( 'user[reset_password_token]', resetPasswordToken );
		data.append( "user[password]", password );
		data.append( "user[password_confirmation]", passwordConfirmation );
		const config = {
			method: 'put',
			url: `${baseURL}/password`,
			headers: APIHeaders,
			withCredentials: true,
			credentials: "include",
			data: data,
		};
		axios( config )
			.then( ( resp ) => {
				console.log( resp );
        setShowMessageReset( true );
        setIsErrorReset( false );
        setMessageAPIReset('Password was changed')
        setTimeout(() => {
          navigate( '/user/login', { replace: true } );
        }, 3000)
			} )
			.catch( ( error ) => {
        // console.log( error )
				if ( error?.response?.status === 500 ) {
          setShowMessageReset( true );
          setIsErrorReset( true );
					setMessageAPIReset( 'Something went wrong.' )
				} else {
          // console.log(error?.response.data.errors);
					setShowMessageReset( true );
					setIsErrorReset( true );
					setMessageAPIReset( error?.response.data.errors.password ? 'Password '+ error?.response.data.errors.password : 'Something went wrong.')
				}
			} )
	}

	useEffect( () => {
		password.length === 0 && setIsSamePassword( '' );
	}, [ password ] )

	return (
		<div className="login login-v1">
			<div className="login-container">
				<div className="login-header">
					<div className="brand">
						<div className="d-flex align-items-center">
							<Logo mode="dark"/> <span className="p-2"></span><b>4Wall IQ</b>
						</div>
					</div>
				</div>
				<div className="login-body">
					<div className="login-content fs-13px">
						<div>
							<div className="form-floating mb-20px">
								<input
									type="password"
									className={`form-control fs-13px h-45px ${errorPassword}`}
									name="password"
									placeholder="New Password"
                  disabled={!resetPasswordToken.length}
									value={password}
									onChange={onPasswordChange}
								/>
								<label htmlFor="password" className="d-flex align-items-center py-0">New Password</label>
							</div>
							<div className="form-floating mb-20px">
								<input
									type="password"
									className={resetPasswordToken.length < 0 ? `disabled form-control fs-13px h-45px ${isSamePassword}` : `form-control fs-13px h-45px ${isSamePassword}`}
                  disabled={!resetPasswordToken.length}
									name="passwordConfirmation"
									placeholder="Password Confirmation"
									value={passwordConfirmation}
									onChange={onPasswordConfirmationChange}
								/>
								<label htmlFor="password" className="d-flex align-items-center py-0">Password Confirmation</label>
							</div>
							{showMessageReset &&
								<div className={isErrorReset ? 'alert alert-danger' : 'alert alert-success'} role="alert">
									{messageAPIReset}
								</div>
							}
							<div className="login-buttons">
								<button
									onClick={savePassword}
									className={disableButton ? "btn h-45px btn-primary d-block w-100 btn-lg disabled" : "btn h-45px btn-primary d-block w-100 btn-lg"}
								>Save
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default ResetPassword;
