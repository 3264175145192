import React, { Link, useParams } from "react-router-dom";
import { APIHeaders } from "../../components/shared/helpers";
import axios from "axios";
import { useEffect, useContext, useState } from "react";
import { AppSettings } from "../../config/app-settings";
import { useRecoilState, useRecoilValue } from "recoil";
import { filteredCompaniesAtom, locationsListAtom, searchCompanyArrayAtom } from "../../atoms/profileAtom";
import GoogleMaps from "./GoogleMaps";
import CustomCompIcon from "../../components/shared/CustomCompIcon";

const MapByCompanyId = () => {
  const { id } = useParams();
  const baseURL = process.env.REACT_APP_BASE_URL;
  const context = useContext( AppSettings );
  const [ companyID, setCompanyID] = useState('');
  const [ linkToCompany, setLinkCompany ] = useState('');
  const [ companyName, setCompanyName ] = useState( '' );
  const [ searchCompanyArray, setSearchCompanyArray ] = useRecoilState( searchCompanyArrayAtom );
  const [ filteredCompanies, setFilteredCompanies] = useRecoilState( filteredCompaniesAtom );
  const [ locationList, setLocationList ] = useRecoilState( locationsListAtom );
  const [ loadedCompanyDetails, setLoadedCompanyDetails] = useState(false);

  useEffect(
    () => {
      context.handleSetAppHeaderNone( false );
      context.handleSetAppSidebarNone( false );
      context.handleSetAppContentClass( 'mh-100vh-88' );
      context.handleSetAppTitle( 'Company Locations' );
      context.handleSetAppIcon( <CustomCompIcon /> );
      setSearchCompanyArray([]);
      setLoadedCompanyDetails(false);
      setCompanyName('');
    },
    []
  );

  useEffect( () => {
    // console.log('id: ' + id, 'companyID: '+ companyID);
    if ( id !== companyID ) {
      setCompanyID( id );
      setLoadedCompanyDetails(false);
      setLinkCompany('/companies/'+id);
      setSearchCompanyArray([]);
      setLocationList([]);
      setFilteredCompanies([]);
      setCompanyName('');
    }
  }, [ id ] )

  useEffect( () => {
    if ( !loadedCompanyDetails && companyID) {
      getCompaniesDetails();
    }
  }, [ companyID,loadedCompanyDetails ] )

  const getCompaniesDetails = () => {
    let params = `&ids[]=${companyID}`;

    const config = {
      method: 'get',
      url: `${baseURL}/companies?${params}`,
      headers: APIHeaders,
      withCredentials: true,
      credentials: "include",
    };
    axios( config )
      .then( ( resp ) => {
        setSearchCompanyArray([{ id: companyID, name: resp.data.data[0].name }])
        setCompanyName(resp.data.data[0].name);
        setLoadedCompanyDetails(true);
      } )
  }

  // console.log(loadedCompanyDetails, companyName, searchCompanyArray);
  // console.log(searchCompanyArray.length)

  if (!loadedCompanyDetails) return <div className="d-flex justify-content-center align-items-center text-center loader-map">
    <div className="spinner-border m-5" role="status">
      <span className="sr-only">Loading...</span>
    </div>
  </div>;

  return <div>
    <Link
      to={linkToCompany}
      className="fs-16px btn w-150px text-light bg-green2"
      >Back</Link>
    {id === companyID && loadedCompanyDetails && searchCompanyArray.length && <GoogleMaps />}
</div>
}

export default MapByCompanyId;
