import React, { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, useNavigate, useLocation, useRoutes } from "react-router-dom";
import AppRoute from './config/app-route.jsx';
import { RecoilRoot, useRecoilState, } from 'recoil';
import {  sessionAtom } from "./atoms/profileAtom";
import { AuthContextProvider } from "./components/shared/AuthContext";

// bootstrap
import 'bootstrap';

// css
import '@fortawesome/fontawesome-free/css/all.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import './index.css';
import './scss/react.scss';

const container = document.getElementById( 'root' );
const root = createRoot( container );

function App () {
	const [ sessionID, setSessionID ] = useRecoilState( sessionAtom );
	const location = useLocation();
	const navigate = useNavigate();

	useEffect( () => {
		if ( !sessionID && (location.pathname === '/invitation_accept' || location.pathname === '/reset_password') ) {
			setSessionID( localStorage.getItem( 'id' ) ?? false );
		} else if ( !sessionID && !localStorage.getItem( 'id' ) ) {
			navigate( '/user/login', { replace: true } );
		}

	}, [ sessionID ] )

	return useRoutes( AppRoute );
}

root.render(
	<RecoilRoot>
		<BrowserRouter>
			<AuthContextProvider>
				<App />
			</AuthContextProvider>
		</BrowserRouter>
	</RecoilRoot>
);
