import React from 'react';
import { useRecoilState } from "recoil";
import { sessionAtom } from "../../../atoms/profileAtom";
import axios from "axios";
import { Link } from "react-router-dom";
import styled from 'styled-components';

const UserIcon = styled.div`
	width: 40px;
	height: 40px;
	background: linear-gradient(183.88deg, #E3CEE1 -5.59%, #7D3975 100.45%);
	border-radius: 50%;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 16px;
	font-weight: 400;
	text-transform: uppercase;
`;

const DropdownProfile = () => {
	const [ sessionID, setSessionID ] = useRecoilState( sessionAtom );
	const baseURL = process.env.REACT_APP_BASE_URL;
	const first_name = localStorage.getItem("first_name") ?? '';
	const last_name = localStorage.getItem("last_name") ?? '';
	const username = (first_name.charAt(0) ?? '') + (last_name.charAt(0) ?? '');
	const full_name = localStorage.getItem("full_name") ?? '';
	const email = localStorage.getItem("email") ?? '';

	const handleLogout = (e) => {
		e.preventDefault();
		console.log(sessionID);
		const config = {
			method: 'delete',
			url: `${baseURL}/sign_out`,
			headers: {
				'id': sessionID,
			},
		};

		axios( config ).then((resp)=>{
			console.log(resp)
		}).finally(()=>{
			window.location.replace('/user/login')
		});
	}

	return (
		<div className="navbar-item navbar-user dropdown">
			<a href="#/" className="navbar-link dropdown-toggle d-flex align-items-center" data-bs-toggle="dropdown">
				<UserIcon>{username}</UserIcon>
			</a>
			<div className="dropdown-menu dropdown-menu-end me-1">
				<a href="#/" className="dropdown-item disabled full-name">{full_name}</a>
				<a href="#/" className="dropdown-item disabled email">{email}</a>
				<div className="dropdown-divider"></div>
				<Link to="#" className="dropdown-item logout" onClick={(e)=>handleLogout(e)}>Log Out</Link>
			</div>
		</div>
	);
};

export default DropdownProfile;
