
const HomeIcon = ({size}) => {
  let _size = size === 'L' ? 2 : 1;

  return <svg width={_size * 24} height={_size * 24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.6936 18.4113V5.69287L13.6072 10.3898V18.4113H8.6936Z" fill="#e3cee1"/>
    <path d="M3.19241 19.3895V10.101L8.69236 4.60107L14.1923 10.101V19.3895H3.19241ZM4.69236 17.8895H7.80776V15.2356H9.57699V17.8895H12.6924V10.7145L8.69236 6.71447L4.69236 10.7145V17.8895ZM7.80776 12.7741V11.0049H9.57699V12.7741H7.80776Z" fill="#B668AE"/>
    <path d="M19.3077 7.98182V19.399H20.8076V7.35875L18.0595 4.60107H15.9269L19.3077 7.98182Z" fill="#c897c3"/>
    <path d="M16 9.35295V19.399H17.5V8.7299L13.3711 4.60107H11.2481L16 9.35295Z" fill="#c897c3"/>
  </svg>;
}

export default HomeIcon;
