import React from "react";
import styled from "styled-components";
import { colorPatternDefault } from "../../components/shared/Colors";
import { useRecoilState } from "recoil";
import { minimizeLocationLegendAtom, showCitiesIDCompanyAtom } from "../../atoms/profileAtom";
import CompaniesIcon from "../../components/shared/CompaniesIcon";

const CompanyEntryStyle = styled.div`
  width: ${props => props.minimize ? 'auto' : '190px'};
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  padding: 8px;
  //border-top: 1px solid #F2F2F2;
  //border-right: 1px solid #F2F2F2;
  //border-left: 1px solid #F2F2F2;
  //background-color: #fcfcfc;
  position: relative;
`;

export const CompanyColor = styled.div`
  width: 8px;
  height: 28px;
  display: inline-block;
  border-radius: 8px;
  background-color: ${props => props.color};
  margin-right: 5px;
`;

export const LogoWrapper = styled.div`
  height: 40px;
  overflow: hidden;
  display: flex;
  align-items: center;

  img {
    max-width: ${props => props.minimize ? '45px' : '150px'} ;
    max-height: 30px;
  }
`;

const TitleCompany = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
`;

const ToggleButtonStyle = styled.div`
  width: 20px;
  height: 20px;
  cursor: pointer;
  display: flex;
  //align-items: center;
  //position: relative;
  //left: auto;
  //right: 6px;
  //z-index: 10;

  &:before,
  &:after {
    width: 8px;
    height: 1px;
    background-color: #111;
    content: '';
    position: absolute;
    transition: all .2s;
  }

  &:before {
    transform: ${props => props.index === props.show ? 'translate(4px, 10px) rotate(40deg)' : 'translate(10px, 6px) rotate(220deg)' };
  }

  &:after {
    transform: ${props => props.index === props.show ?  'translate(10px, 10px) rotate(-40deg)' : 'translate(10px, 11px) rotate(-400deg)' };
  }
`;

const CompanyEntry = ( { company, count, index } ) => {
  const [ show, setShow ] = useRecoilState( showCitiesIDCompanyAtom );
  const [ minimizeLocationLegend, setMinimizeLocationLegend ] = useRecoilState( minimizeLocationLegendAtom );
  const baseLogoURL = process.env.REACT_APP_LOGOS_BASE_URL;
  const signature = process.env.REACT_APP_LOGOS_SIGNATURE;
  let _color;
  if ( company.company_color === undefined || company.company_color === null ) {
    _color = colorPatternDefault[ index ];
  } else {
    _color = company.company_color;
  }
  let _logo = company.company_logo ?
    <LogoWrapper minimize={minimizeLocationLegend}>
      <img
        src={baseLogoURL + company.company_logo + signature}
        alt={company.company_name}
        title={company.company_name}
      />
    </LogoWrapper> : '';

  return <CompanyEntryStyle
    minimize={minimizeLocationLegend}
  >
    <div>
      <TitleCompany>
        <CompanyColor color={_color} />
        {_logo ? _logo :
          company.company_name}
      </TitleCompany>
      {!minimizeLocationLegend ? <div className="d-flex justify-content-between">
        <div>{count} {count === 1 ? 'Location' : 'Locations'}</div>
        {count > 0 && !minimizeLocationLegend && <ToggleButtonStyle
          index={index}
          show={show}
          onClick={() => setShow( index === show ? '' : index )}
        />}
      </div> : <div className="d-flex justify-content-between mt-2">
        <span style={{
          "color": "#b668ae"
        }}>
          <CompaniesIcon/>
        </span>{ count}
      </div> }
    </div>
  </CompanyEntryStyle>;
}

export default CompanyEntry;
