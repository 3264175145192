
const ClearCacheIcon = ({color}) => {
  let _color = color ?? 'currentColor';

  return <svg width="16" height="18" viewBox="0 0 16 18" fill={_color} xmlns="http://www.w3.org/2000/svg">
    <path d="M1.5 17.5V3.00005H0.5V1.50008H4.99997V0.615479H11V1.50008H15.5V3.00005H14.5V17.5H1.5ZM2.99997 16.0001H13V3.00005H2.99997V16.0001ZM5.40385 14.0001H6.90382V5.00005H5.40385V14.0001ZM9.09612 14.0001H10.5961V5.00005H9.09612V14.0001Z" fill={_color} />
  </svg>;
}

export default ClearCacheIcon;
