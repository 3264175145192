const ProductIcon = () => {

  return <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.87622 14.8008V6.92847L10.5935 9.16666L13.7965 10.1314L11.732 12.6205L11.539 15.7656L8.87622 14.8008Z" fill="#e3cee1"/>
    <path d="M6.2573 15.2337L8.8823 13.6504L11.5073 15.2545L10.8198 12.2545L13.1323 10.2545L10.0906 9.98369L8.8823 7.15035L7.67396 9.96285L4.6323 10.2337L6.9448 12.2545L6.2573 15.2337ZM4.3615 17.8474L5.5554 12.7064L1.56665 9.24973L6.82944 8.79302L8.8823 3.94531L10.9352 8.79302L16.1979 9.24973L12.2092 12.7064L13.4031 17.8474L8.8823 15.1199L4.3615 17.8474Z" fill="#B668AE"/>
    <path d="M16.5826 10.5317L13.5521 13.1359L14.4704 17.0766L16.0425 18.0317L15.0393 13.6311L18.4335 10.692L16.5826 10.5317Z" fill="#c897c3"/>
    <path d="M10.9352 5.66L11.7781 7.63756L13.2653 7.77698L11.6451 3.94531L10.9352 5.66Z" fill="#c897c3"/>
  </svg>;
}

export default ProductIcon;
