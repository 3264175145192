import React, { useEffect, useState } from 'react';
import axios from "axios";
import { APIHeaders, capitalizeFirstLetter, toasterNotify, validateEmail } from "../shared/helpers";
import 'react-notifications-component/dist/theme.css';
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { showEditUserModalAtom, updateUserListAtom } from "../../atoms/profileAtom";
import { Modal } from "react-bootstrap";

const EditUserModal = ( { user } ) => {
  const baseURL = process.env.REACT_APP_BASE_URL;
  const [ email, setEmail ] = useState( '' );
  const [ phone, setPhone ] = useState( '' );
  const [ userID, setUserID ] = useState( '' );
  const [ firstName, setFirstName ] = useState( '' );
  const [ lastName, setLastName ] = useState( '' );
  const [ organization, setOrganization ] = useState( '' );
  const [ role, setRole ] = useState( '' );
  const [ activeUser, setActiveUser ] = useState( false );
  const setUpdateUserList = useSetRecoilState( updateUserListAtom );
  const [ showEditUserModal, setEditCreateUserModal ] = useRecoilState( showEditUserModalAtom );
  const [ emailError, setEmailError ] = useState( false );
  const [ phoneError, setPhoneError ] = useState( false );

  const onEmailChange = ( e ) => {
    setEmail( e.target.value );
    (e.target.value.length > 0 && validateEmail( e.target.value )) ? setEmailError( false ) : setEmailError( true );
  }

  const onPhoneChange = ( e ) => {
    setPhone( e.target.value );
    setPhoneError( false );
  }

  const onFirstNameChange = ( e ) => {
    setFirstName( e.target.value );
  }

  const onLastNameChange = ( e ) => {
    setLastName( e.target.value );
  }

  const handleActivateUser = ( e ) => {
    setActiveUser( !activeUser );
  }

  const handleSaveUser = () => {
    prevalidateForm();
    const data = new FormData();
    data.append( 'user[email]', email );
    data.append( 'user[first_name]', firstName );
    data.append( 'user[last_name]', lastName );
    data.append( 'user[activated]', activeUser );
    data.append( 'user[phone]', phone );
    // data.append( 'user[role]', role.toLowerCase() );
    const config = {
      method: 'put',
      url: `${baseURL}/users/${userID}`,
      headers: APIHeaders,
      withCredentials: true,
      credentials: "include",
      data: data,
    };
    if ( !emailError && !phoneError && phone.length !== 0) {
      axios( config )
        .then( ( response ) => {
          toasterNotify( 'User data saved', '', 'success' );
          setUpdateUserList( updateUserList => !updateUserList );
          handleClose();
        } )
        .catch( ( error ) => {
          if ( error.response.status === 500 ) {
            toasterNotify( 'Error', 'Something went wrong.', 'danger' )
          } else {
            for ( const [ title, message ] of Object.entries( error.response.data ) ) {
              toasterNotify( title?.toUpperCase(), message, 'danger' );
            }
          }
          // console.log( error.response.data )
        } )
    }

  }

  const prevalidateForm = () => {
    phone.length > 0 ? setPhoneError( false ) : setPhoneError( true );
  }

  const handleClose = () => {
    setUserID( '' );
    setEmail( ''  );
    setPhone( ''  );
    setFirstName( '' );
    setLastName( '' );
    setRole( '' );
    setActiveUser( false );
    setEmailError( false );
    setPhoneError( false );
    setOrganization(  '' );
    setEditCreateUserModal( false )
  }

  useEffect( () => {
    setUserID( user[ 'id' ] );
    setEmail( user[ 'email' ] );
    setPhone( user[ 'phone' ] );
    setFirstName( user[ 'first_name' ] );
    setLastName( user[ 'last_name' ] );
    setRole( user[ 'role' ] );
    setActiveUser( user[ 'activated' ] );
    setOrganization( user[ 'organization' ] || '' );
  }, [ user ] )

  return (
    <Modal show={showEditUserModal} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Edit User</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <div className="row">
            <div className="col-12">
              <div className="form-floating mb-20px">
                <input
                  type="email"
                  className={emailError ? "form-control fs-13px h-45px  is-invalid" : "form-control fs-13px h-45px"}
                  name="email"
                  placeholder="Email Address"
                  autoComplete="on"
                  value={email}
                  onChange={onEmailChange}
                />
                <label htmlFor="emailAddress" className="d-flex h-45px align-items-center py-0">Email Address</label>
                {emailError && <div className="invalid-feedback">
                  Please enter correct e-email.
                </div>}
              </div>
            </div>
            <div className="col-12">
              <div className="form-floating mb-20px">
                <input
                  type="text"
                  className={phoneError ? 'form-control fs-13px h-45px is-invalid' : 'form-control fs-13px h-45px'}
                  name="phone"
                  placeholder="Phone Number"
                  autoComplete="on"
                  value={phone}
                  onChange={onPhoneChange}
                />
                <label htmlFor="newPhone" className="d-flex h-45px align-items-center py-0">Phone Number</label>
                {phoneError && <div className="invalid-feedback">
                  Please enter correct Phone Number.
                </div>}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-12">
              <div className="form-floating mb-20px">
                <input
                  type="text"
                  className="form-control fs-13px h-45px"
                  name="first_name"
                  placeholder="First Name"
                  value={firstName}
                  onChange={onFirstNameChange}
                />
                <label htmlFor="first_name" className="d-flex align-items-center py-0">First Name</label>
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="form-floating mb-20px">
                <input
                  type="text"
                  className="form-control fs-13px h-45px"
                  name="last_name"
                  placeholder="Last Name"
                  value={lastName}
                  onChange={onLastNameChange}
                />
                <label htmlFor="last_name" className="d-flex align-items-center py-0">Last Name</label>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-12">
              <div className="form-floating mb-20px">
                <input
                  type="text"
                  disabled={true}
                  name="organization"
                  className="form-control fs-13px h-45px disabled"
                  value={organization ? organization.name : ''}
                />
                <label htmlFor="organization" className="d-flex align-items-center py-0">Organization</label>
              </div>
            </div>
            <div className="col-md-6 col-12">
                <div className="form-floating col-md-12">
                  <input
                    type="text"
                    disabled={true}
                    name="role"
                    className="form-control fs-13px h-45px disabled"
                    value={role ? capitalizeFirstLetter(role) : ''}
                  />
                  <label htmlFor="organization" className="d-flex align-items-center py-0">Role</label>
                </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-12">
              <div className="form-check form-switch mt-2 mb-20px">
                <label className="form-check-label col-form-label" htmlFor="flexSwitchCheckDefault">Activate User</label>
                <input
                  className="form-check-input mt-2"
                  checked={activeUser}
                  type="checkbox"
                  onChange={handleActivateUser}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-white" onClick={handleClose}>Close</button>
        <button className="btn btn-success" onClick={handleSaveUser}>Save</button>
      </Modal.Footer>
    </Modal>
  );
}

export default EditUserModal;
