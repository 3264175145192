import React, { useEffect, useState, useContext, useMemo } from "react";
import { AppSettings } from "../../config/app-settings";
import { useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import {
  customCompAtom,
  locationsListAtom,
  locationsLoadedListAtom,
  needUpdateCompAtom,
  searchCompanyArrayAtom,
  trackerIDAtom
} from "../../atoms/profileAtom";
import { APIHeaders } from "../../components/shared/helpers";
import axios from "axios";
import moment from "moment";
import { LogoName, TableChartWrapper } from "../Chart/TableChart";
import TitleCustomComp from "../../components/shared/TitleCustomComp";
import { remapResourceIDs } from "./MarketTrackerMap";
import CustomCompIcon from "../../components/shared/CustomCompIcon";
import TitleTagCustomComp from "../../components/shared/TitleTagCustomComp";

const remapMarketTrackerTimeLine = ( data ) => {
  let _data = [];

  data.map( loc => {
    if ( loc.openingDate ) {
      let _times = 1;
      let _timesText = '';
      let _year = moment( loc.openingDate ).year();
      let _month = moment( loc.openingDate ).format( 'MMM' );
      let _monthNumber = moment( loc.openingDate ).format( 'MM' );
      _data.map( entry => {
        if ( entry.city === loc.city && entry.year === _year ) {
          _data = _data.filter( el => el.city !== entry.city && entry.year === _year ) ?? _data;
          _times = entry.times + 1;

        }
      } )
      _timesText = _times > 1 ? " (" + _times + ")" : "";
      let _entry = {
        company_id: loc.company.id,
        company_name: loc.company.name,
        year: _year,
        month: +_monthNumber,
        city: loc.city,
        times: _times,
        entry_data: _month + " / " + loc.city + _timesText,
      };
      _data.push( _entry )
      // console.log( _entry );
    }
  } )

  return _data;
}

const MarketTrackerTimeline = ( { preview } ) => {
  const [ customComp, setCustomComp ] = useRecoilState( customCompAtom );
  const [ trackerID, setTrackerID ] = useRecoilState( trackerIDAtom );
  const [ loadedList, setLoadedList ] = useRecoilState( locationsLoadedListAtom );
  const [ searchCompanyArray, setSearchCompanyArray ] = useRecoilState( searchCompanyArrayAtom );
  const [ needUpdateComp, setNeedUpdateComp ] = useRecoilState( needUpdateCompAtom );
  const [ loadedLocationList, setLoadedLocationList ] = useState( false );
  const [ locationList, setLocationList ] = useRecoilState( locationsListAtom );
  const [ isLoadedComp, setIsLoadedComp ] = useState( false );
  const [ compName, setCompName ] = useState( '' );
  const [ remappedMarketTrackerData, setRemappedMarketTrackerData ] = useState( [] );
  const [ yearsList, setYearsList ] = useState( [] );
  const [ resourceIDs, setResourceIDs ] = useState( [] );
  const context = useContext( AppSettings );
  const [ companiesList, setCompaniesList ] = useState( [] );
  const [ showLogo, setShowLogo ] = useState( true );
  const [ noLocations, setNoLocations ] = useState( true );
  const baseURL = process.env.REACT_APP_BASE_URL;
  const { id } = useParams();

  useEffect(
    () => {
      context.handleSetAppHeaderNone( false );
      context.handleSetAppSidebarNone( false );
      context.handleSetAppContentClass( 'mh-100vh-88' );
      context.handleSetAppTitle( 'Market Tracker' );
      context.handleSetAppIcon( <CustomCompIcon /> );
      setResourceIDs([]);
      setRemappedMarketTrackerData( [] )
      setLocationList( [] );
      setCompaniesList( [] );
      setIsLoadedComp( false );
      setLoadedLocationList( false );
      setYearsList( [] );
    },
    []
  );

  useEffect( () => {
    // if ( id !== trackerID || preview ) {
    if ( id !== trackerID && !preview) {
      setSearchCompanyArray([]);
      setIsLoadedComp( false );
      setRemappedMarketTrackerData( [] )
      setLocationList( [] );
      setLoadedList( false );
      setCompaniesList( [] );
      setLoadedLocationList( false );
      setYearsList( [] );
      setCustomComp([]);
      setCompName('');
      setTrackerID( id );
    }
  }, [ id, trackerID ] )


  const getCustomComp = () => {
    setIsLoadedComp( false );
    const data = new FormData();
    const config = {
      method: 'get',
      url: `${baseURL}/custom_comps/${id}`,
      headers: APIHeaders,
      withCredentials: true,
      credentials: "include",
      data: data,
    };
    axios( config )
      .then( ( resp ) => {
        setCustomComp( resp.data.data );
        setCompName(resp.data.data.name);
        // setCompMetrics( resp.data.data.custom_comp_metrics );
        setTimeout(() => {

          setIsLoadedComp( true );
        }, 500);
        console.log( resp.data.data )
      } )
  };
  // console.log(customComp.resource_ids[0])
  // console.log(resourceIDs[0])

  const getCompanyList = () => {
    let params = '';
    resourceIDs.map( el => {
      return params = params + '&ids[]=' + el;
    } )
    const config = {
      method: 'get',
      url: `${baseURL}/companies?${params}`,
      headers: APIHeaders,
      withCredentials: true,
      credentials: "include",
    };
    if ( params ) {
      axios( config )
        .then( ( resp ) => {
          setCompaniesList( resp.data.data );
          setIsLoadedComp( true );
        } )
    }
  }

  const getLocationsList = () => {
    setLoadedLocationList( false );
    let params = '&includeMetrics=true';
    resourceIDs.map( el => {
      return params = params + '&companyIds[]=' + el;
    } )

    const config = {
      method: 'get',
      url: `${baseURL}/locations?${params}`,
      headers: APIHeaders,
      withCredentials: true,
      credentials: "include",
    };
    axios( config )
      .then( ( response ) => {
        // console.log( response.data.data )
        setLocationList( response.data.data );
        setLoadedLocationList( true );
      } )
      .catch( ( error ) => {
        console.log( error );
      } ).finally( () => {
      setLoadedLocationList( true );
    } )
  }

  useEffect( () => {
    context.handleSetAppContentClass( 'mh-100vh-88' );
    setLoadedList( false );
  }, [ id ] )


  useEffect( () => {
    // console.log('resourceIDs', resourceIDs)
    if ( resourceIDs && resourceIDs.length >0 ) {
      console.log('getLocationsList')
      getLocationsList();
    }
  }, [ companiesList, resourceIDs ] );

  useEffect( () => {
    if ( !preview && customComp.resource_ids !== undefined && customComp.resource_ids.length > 0 ) {
      setSearchCompanyArray( remapResourceIDs( customComp.resource_ids ) );
    }
    setRemappedMarketTrackerData( remapMarketTrackerTimeLine( locationList ) );
  }, [ locationList, trackerID, resourceIDs, customComp ] );

  useEffect( () => {
    if ( resourceIDs ) {
      // console.log('getCompanyList')
      getCompanyList();
    }
  }, [ resourceIDs ] )

  useEffect( () => {
    let _years = [];
    remappedMarketTrackerData.map( location => {
      if ( !_years.includes( location.year ) ) {
        _years.push( location.year )
      }
    } )
    _years.sort( function ( a, b ) {
      if ( a > b ) {
        return 1;
      }
      if ( a < b ) {
        return -1;
      }
      return 0;
    } )
    setYearsList( _years );

    _years.length === 0 ? setNoLocations( true ) : setNoLocations( false );

    setTimeout(() => {
      setLoadedList(true);
    }, 1000);

  }, [ remappedMarketTrackerData ] )

  useEffect( () => {
    let _resourceIDs = [];
    if ( preview || searchCompanyArray.length !== resourceIDs.length || searchCompanyArray.length && searchCompanyArray[0]?.id !== resourceIDs[0] ) {
      searchCompanyArray.map( comp => _resourceIDs.push( comp.id ) )
      setResourceIDs( _resourceIDs );
    }
  }, [ searchCompanyArray, trackerID ] )

  useEffect( () => {
    if ( !isLoadedComp && !preview ) {
      getCustomComp();
    }
  }, [ isLoadedComp, id, needUpdateComp ] )

  useEffect( () => {
    if (customComp.length > 0) {
      setCompName( customComp.name );
      setResourceIDs( customComp.resource_ids )
    }
  }, [ customComp, isLoadedComp ] ) //|| id !== trackerID && customComp.length === 0

  if ( !isLoadedComp || !yearsList || !remappedMarketTrackerData ) {
    return <div className="d-flex justify-content-center align-items-center text-center min-vh-100">
      <div className="spinner-border m-5" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  }
  // console.log(remappedMarketTrackerData, loadedLocationList) // id === trackerID && +id === customComp.id

  return <div className={preview ? 'p-4' : ''}>
    {(isLoadedComp && yearsList && remappedMarketTrackerData && loadedLocationList && customComp) && <>
      <TitleTagCustomComp
        compName={compName}
        id={id}
        marketTracker
        isPopular={customComp.default_comp}
        ownerID={customComp.user_id}
        taggings={customComp.taggings}
        preview={preview}
        showLogo={() => setShowLogo( !showLogo )}
        isShowLogo={showLogo}
        noLocations={!noLocations}
      />
      <TitleCustomComp
        compName={compName}
        id={id}
        marketTracker
        isPopular={customComp.default_comp}
        ownerID={customComp.user_id}
        taggings={customComp.taggings}
        preview={preview}
        showLogo={() => setShowLogo( !showLogo )}
        isShowLogo={showLogo}
        noLocations={!noLocations}
      />
      {!noLocations ? <TableChartWrapper className={preview ? ' py-2' : ''}>
          <table className="table table-hover table-bordered">
            <thead>
            <tr>
              <th scope="col">Company</th>
              {yearsList.map( ( el, i ) => {
                return <th scope="col" key={`company-${el}-${i}`}>{el}</th>
              } )}
            </tr>
            </thead>
            <tbody>
            {resourceIDs && resourceIDs.map( ids => {

              return companiesList.map( ( comp, index ) => {
                if ( comp.id === +ids ) {
                  return <tr key={`tr-${index}`} className="text-center" style={{ verticalAlign: "middle" }}>
                    <LogoName comp={comp} showLogo={showLogo} />
                    {yearsList.map( ( year ) => {
                      let _entries;
                      _entries = remappedMarketTrackerData.filter( entry => (year === entry.year && comp.id === entry.company_id) );
                      _entries.sort( function ( a, b ) {
                        if ( a.month > b.month ) {
                          return 1;
                        }
                        if ( a.month < b.month ) {
                          return -1;
                        }
                        return 0;
                      } )
                      // console.log(_entries)
                      if ( _entries.length === 0 ) return <td><p>—</p></td>;

                      return <td key={"td-" + year + comp.id}>
                        {_entries.map( ent => {
                          return <p key={"p" + ent.entry_data}>{ent.entry_data}</p>
                        } )}
                      </td>;

                    } )}
                  </tr>
                }
              } )
            } )}
            </tbody>
          </table>
        </TableChartWrapper> :
        <div>
          <h4>No Entry Date information available for locations</h4>
        </div>}

    </>}
  </div>;
}

export default MarketTrackerTimeline;

