import Rating4WallIcon from "../../components/shared/Rating4WallIcon";
import styled from "styled-components";

const Rating4WallStyle = styled.div`
  margin-top: 10px;
  font-size: 14px;
  display: flex;
  align-items: center;
  
  svg {
    margin-right: 6px;
  }
`;

const RatingMeter = ( { value } ) => {
  let _color = "#dddddd";
  if ( +value < 30 ) {
    _color = 'rgb(216 177 177)';
  } else if ( +value < 60 ) {
    _color = 'rgb(216 208 177)';
  } else if ( +value < 100 ) {
    _color = '#e3cee1';
  }

  return <div style={{
    backgroundColor: _color,
    display: "inline-block",
    marginLeft: "12px",
    borderRadius: "6px",
    padding: "0 10px",
    lineHeight: "30px",
    height: "30px"
  }}>{value}/100</div>
}

const Rating4Wall = ( { value } ) => {

  return <Rating4WallStyle>
    <Rating4WallIcon /> 4-Wall IQ Rating: {value ? <RatingMeter value={value} /> : 'Coming Soon'}
  </Rating4WallStyle>;
}

export default Rating4Wall;
