import React, { useEffect, useState } from 'react';
import axios from "axios";
import { toasterNotify, userRoles, capitalizeFirstLetter, APIHeaders, validateEmail } from "../shared/helpers";
import 'react-notifications-component/dist/theme.css';
import { useRecoilState, useSetRecoilState } from "recoil";
import {
  organizationListAtom,
  showCreateUserModalAtom,
  updateUserListAtom,
} from "../../atoms/profileAtom";
import { Modal } from "react-bootstrap";
import Select from "react-select";

const stylesSelect = {
  control: (styles, { isDisabled }) => ({
    backgroundColor: isDisabled ? 'var(--app-component-disabled-bg)' : 'var(--app-component-bg)',
    color: 'var(--app-component-color)',
    border: '1px solid var(--app-component-border-color)',
    borderRadius: '10px',
    display: 'flex',
    height: '45px',
  }),
  indicatorSeparator: styles => ({
    backgroundColor: 'transparent'
  }),
  input: styles => ({
    color: 'var(--app-component-color)',
    fontWeight: '600',
    gridArea: '1/1/2/3',
    flex: '1 1 auto',
    display: 'flex',
    margin: '2px',
    gridTemplateColumns: '0 min-content',
    boxSizing: 'content-box',
    paddingTop: '2px',
    paddingBottom: '2px',
    visibility: 'visible'
  }),
  singleValue: styles => ({
    color: 'var(--app-component-color)',
    fontWeight: '600',
    gridArea: '1/1/2/3',
    marginLeft: '2px',
    marginRight: '2px',
    width: "140px",
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    boxSizing: 'border-box'
  }),
  placeholder: styles => ({
    color: 'rgba(var(--app-component-color-rgb), .5)',
    fontWeight: '600',
    width: "140px",
    gridArea: '1/1/2/3'
  }),
  menu: styles => ({
    backgroundColor: 'var(--app-component-dropdown-bg)',
    position: 'absolute',
    top: '100%',
    borderRadius: '10px',
    margin: '8px 0',
    zIndex: '1',
    boxSizing: 'border-box',
    width: '100%'
  }),
  option: ( styles, { data, isDisabled, isFocused, isSelected } ) => {
    return {
      backgroundColor: isFocused ? 'var(--app-component-dropdown-hover-bg)' : '',
      color: 'var(--app-component-color)',
      cursor: isDisabled ? 'not-allowed' : 'default',
      padding: '8px 12px'
    };
  }
};

const CreateUserModal = () => {
	const baseURL = process.env.REACT_APP_BASE_URL;
	const [ newEmail, setNewEmail ] = useState( '' );
	const [ newPhone, setNewPhone ] = useState( '' );
	const [ emailError, setEmailError ] = useState( false );
	const [ phoneError, setPhoneError ] = useState( false );
	const [ newPassword, setNewPassword ] = useState( '' );
	const [ isFull, setIsFull ] = useState( false );
	const [ disableButton, setDisableButton ] = useState( true );
	const [ passwordConfirmation, setPasswordConfirmation ] = useState( '' );
	const [ isSamePassword, setIsSamePassword ] = useState( '' )
	const [ errorPassword, setErrorPassword ] = useState( false )
	const [ firstName, setFirstName ] = useState( '' );
	const [ firstNameError, setFirstNameError ] = useState( false );
	const [ lastName, setLastName ] = useState( '' );
	const [ lastNameError, setLastNameError ] = useState( false );
	const [ role, setRole ] = useState( '' );
	const [ roleError, setRoleError ] = useState( false );
	const [ activeUser, setActiveUser ] = useState( false );
	const [ companyType, setCompanyType ] = useState( '' );
	const [ organization, setOrganization ] = useState( '' );
	const [ organizationError, setOrganizationError ] = useState( false );
	const setUpdateUserList = useSetRecoilState( updateUserListAtom );
	const [ showCreateUserModal, setShowCreateUserModal ] = useRecoilState( showCreateUserModalAtom );
  const [ organizationList, setOrganizationList ] = useRecoilState( organizationListAtom );
  const [ organizationListSelector, setOrganizationListSelector ] = useState( [] );
  const [ organizationLabel, setOrganizationLabel ] = useState( '' );

  const mapOrganizationList = ( data ) => {
    let _data = [];
    data.map(el=> {
      _data.push({value: el.id, label: `${el.name} (${el.company_type})`})
    });
    return _data;
  }

  useEffect( () => {
    setOrganizationListSelector( mapOrganizationList( organizationList ) )
  }, [ organizationList ] )

	const onEmailChange = ( e ) => {
		setNewEmail( e.target.value );
		setDisableButton( false );
    (e.target.value.length > 0 && validateEmail( e.target.value )) ? setEmailError( false ) : setEmailError( true );
	}

	const onPhoneChange = ( e ) => {
		setPhoneError( false );
		setDisableButton( false );
		setNewPhone( e.target.value );
	}

	const onFirstNameChange = ( e ) => {
		setFirstNameError( false );
		setDisableButton( false );
		setFirstName( e.target.value );
	}

	const onLastNameChange = ( e ) => {
		setLastNameError( false );
		setLastName( e.target.value );
	}

	const handleSelectRole = ( e ) => {
		setRoleError( false );
		setRole( e.target.value );
    if ( e.target.value === 'admin' ) {
      setOrganization('')
      setOrganizationLabel('')
    }
	}

  const handleSelectOrganization = (e) => {
    setOrganizationError( false );
    setRole('');
    setOrganizationLabel(e.text);
    setOrganization(e.value);
    organizationList.map(el=> {
      if (el.id.toString() === e.value.toString() ) {
        setCompanyType( el.company_type )
      }
    })
  }

	const onPasswordChange = ( e ) => {
		setNewPassword( e.target.value );
		(e.target.value.length <= 5) ? setErrorPassword( 'is-invalid' ) : setErrorPassword( 'is-valid' );
		e.target.value.length === 0 && setErrorPassword( '' ) && setIsSamePassword( '' );
	}

	const handleOnChangeStatus = () => {
		setActiveUser( !activeUser )
	}

	const onPasswordConfirmationChange = ( e ) => {
		setPasswordConfirmation( e.target.value )
		newPassword !== e.target.value && newPassword.length > 0 ? setIsSamePassword( 'is-invalid' ) : setIsSamePassword( 'is-valid' );
	}

	const preValidateForm = () => {
		firstName.length > 0 ? setFirstNameError( false ) : setFirstNameError( true );
		lastName.length > 0 ? setLastNameError( false ) : setLastNameError( true );
		role.length > 0 ? setRoleError( false ) : setRoleError( true );
		newPhone.length > 0 ? setPhoneError( false ) : setPhoneError( true );
    // newPassword.length <= 5 ? setErrorPassword( 'is-invalid' ) : setErrorPassword( 'is-valid' );
    newPassword.length > 0 && passwordConfirmation.length <= 5 && setIsSamePassword( 'is-invalid' );
    if (newPassword.length === 0 && passwordConfirmation.length)  {
      setIsSamePassword( '' );
      setErrorPassword(false);
    }
		(role !== 'admin' && organization === '') ? setOrganizationError( true ) : setOrganizationError( false );
	}

	const reInitFields = () => {
		setNewEmail( '' );
		setNewPassword( '' );
		setPasswordConfirmation( '' );
		setIsSamePassword( '' );
		setErrorPassword( '' );
		setFirstName( '' );
		setLastName( '' );
		setRole( '' );
    setCompanyType('');
		setOrganization( '' );
		setActiveUser( false );
		setRoleError( false );
    setPhoneError( false );
		setFirstNameError( false );
		setLastNameError( false );
		setEmailError( false );
		setOrganizationError( false );
	}

	useEffect( () => {
		(firstName.length > 0 &&
			lastName.length > 0 &&
			newEmail.length > 0 &&
			role.length > 0 &&
			(role !== 'admin' && organization && !emailError)) ? setIsFull( true ) : setIsFull( false );
	}, [ firstName, lastName, newEmail, role ] )

	const handleCreateUser = ( e ) => {
		preValidateForm();
		const data = new FormData();
		data.append( 'user[email]', newEmail );
		data.append( 'user[phone]', newPhone );
		data.append( 'user[first_name]', firstName );
		data.append( 'user[last_name]', lastName );
		if ( activeUser ) {
			data.append( 'user[activated]', true );
		}
		if ( newPassword ) {
			data.append( "user[password]", newPassword );
			data.append( "user[password_confirmation]", passwordConfirmation );
		}
		data.append( "user[role]", role.toLowerCase() );
		if ( role !== 'admin' ) {
			data.append( "user[organization_id]", organization );
		}
		const config = {
			method: 'post',
			url: `${baseURL}/users`,
			headers: APIHeaders,
			withCredentials: true,
			credentials: "include",
			data: data,
		};

		if ( (isFull || role === 'admin') && !emailError && newEmail.length ) {
			axios( config )
				.then( ( response ) => {
					toasterNotify( 'User created', '', 'success' );
					setUpdateUserList( updateUserList => !updateUserList );
					setShowCreateUserModal( false );
					reInitFields();
				} )
				.catch( ( error ) => {
					if ( error.response.status === 500 ) {
						toasterNotify( 'Error', 'Something went wrong.', 'danger' )
					} else {
						for ( const [ title, message ] of Object.entries( error.response.data.error ) ) {
							toasterNotify( title?.toUpperCase(), message, 'danger' );
						}
					}
					console.log( error.response.data )
				} )
		}
	}

	useEffect( () => {
		newPassword.length === 0 && setIsSamePassword( '' );
	}, [ newPassword ] )

	const handleClose = () => {
		setShowCreateUserModal( false );
		reInitFields();
	}

	return (
		<Modal show={showCreateUserModal} onHide={handleClose}>
			<Modal.Header closeButton>
				<Modal.Title>Create User</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<form>
					<div className="row mb-12px">
						<div className="col-md-6 col-12">
							<div className="form-floating mb-20px">
								<input
									type="text"
									className={firstNameError ? 'form-control fs-13px h-45px is-invalid' : 'form-control fs-13px h-45px '}
									name="first_name"
									placeholder="First Name"
									value={firstName}
									onChange={onFirstNameChange}
								/>
								<label htmlFor="first_name" className="d-flex h-45px fs-13px align-items-center">First Name</label>
								{firstNameError && <div className="invalid-feedback">
									Please enter First Name.
								</div>}
							</div>
						</div>
						<div className="col-md-6 col-12">
							<div className="form-floating mb-20px">
								<input
									type="text"
									className={lastNameError ? 'form-control fs-13px h-45px is-invalid' : 'form-control fs-13px h-45px '}
									name="last_name"
									placeholder="Last Name"
									value={lastName}
									onChange={onLastNameChange}
								/>
								<label htmlFor="last_name" className="d-flex h-45px align-items-center py-0">Last Name</label>
								{lastNameError && <div className="invalid-feedback">
									Please enter Last Name.
								</div>}
							</div>
						</div>
					</div>
					<div className="row mb-12px">
						<div className="col-md-6 col-12">
							<div className="form-floating mb-20px">
								<input
									type="text"
									className={emailError ? 'form-control fs-13px h-45px is-invalid' : 'form-control fs-13px h-45px'}
									name="newEmail"
									placeholder="Email Address"
									value={newEmail}
									onChange={onEmailChange}
								/>
								<label htmlFor="newEmail" className="d-flex h-45px align-items-center py-0">Email Address</label>
								{emailError && <div className="invalid-feedback">
									Please enter correct e-email.
								</div>}
							</div>
						</div>
						<div className="col-md-6 col-12">
							<div className="form-floating mb-20px">
								<input
									type="text"
									className={phoneError ? 'form-control fs-13px h-45px is-invalid' : 'form-control fs-13px h-45px'}
									name="newPhone"
									placeholder="Phone Number"
									value={newPhone}
									onChange={onPhoneChange}
								/>
								<label htmlFor="newPhone" className="d-flex h-45px align-items-center py-0">Phone Number</label>
								{phoneError && <div className="invalid-feedback">
									Please enter correct Phone Number.
								</div>}
							</div>
						</div>
					</div>
					<div className="row mb-12px">
						<div className="col-md-6 col-12">
							<div className="form-floating mb-20px">
								<input
									type="password"
									className={`form-control fs-13px h-45px ${errorPassword}`}
									name="newPassword"
									placeholder="Password"
									value={newPassword}
									onChange={onPasswordChange}
								/>
								<label htmlFor="newPassword" className="d-flex h-45px align-items-center py-0">Password</label>
                {errorPassword === 'is-invalid' && newPassword.length <= 5 && <div className="invalid-feedback">
                  Password must contain minimum 6 characters.
                </div>}
							</div>
						</div>
						<div className="col-md-6 col-12">
							<div className="form-floating mb-20px">
								<input
									type="password"
									className={`form-control fs-13px h-45px ${isSamePassword}`}
									name="password_confirmation"
									placeholder="Password Confirmation"
									value={passwordConfirmation}
									onChange={onPasswordConfirmationChange}
								/>
								<label htmlFor="password_confirmation" className="d-flex h-45px align-items-center py-0">Password Confirmation</label>
                {isSamePassword === 'is-invalid' && <div className="invalid-feedback">
                  Password and Confirmation password do not match.
                </div>}
							</div>
						</div>
					</div>
					<div className="row mb-15px">
						<div className="col-md-6 col-12">
              <div className="form-floating">
                <Select
                  className={organizationError ? "h-45px is-invalid" : "h-45px"}
                  isDisabled={role === 'admin'}
                  isSearchable={true}
                  options={organizationListSelector}
                  value={organizationLabel}
                  onChange={handleSelectOrganization}
                  styles={stylesSelect}
                  placeholder="Select Organization"
                />
                {organizationError && <div className="invalid-feedback">
                  Please select an Organization.
                </div>}
              </div>
						</div>
						<div className="col-md-6 col-12">
							<select
								className={roleError ? "form-select h-45px is-invalid" : "form-select h-45px"}
								value={role}
								onChange={handleSelectRole}
								placeholder="Select role"
							>
								<option disabled="disabled" value="">Select role</option>
								{userRoles.map( ( el ) => {
                  if ( (companyType === 'firm' && el !== 'operator') ||
                    companyType === 'company' && (el === 'operator' || el === 'admin') ||
                    companyType === '') {
                    return <option
                      value={el}
                      key={el}>
                      {capitalizeFirstLetter( el )}
                    </option>
                  }

								} )}
							</select>
							{roleError && <div className="invalid-feedback">
								Please select a role.
							</div>}
						</div>
					</div>
					<div className="row">
						<div className="col-md-6 col-12">
							<div className="form-check form-switch ">
								<label
									className="form-check-label col-form-label"
									htmlFor="flexSwitchCheckDefault"
								>
									Activate User
								</label>
								<input
									className="form-check-input mt-2"
									checked={activeUser}
									type="checkbox"
									onChange={handleOnChangeStatus}
									id="flexSwitchCheckDefault"
								/>
							</div>
						</div>
					</div>
				</form>
			</Modal.Body>
			<Modal.Footer>
				<button
					className="btn btn-white"
					onClick={handleClose}
				>
					Close
				</button>
				<button
					type="submit"
					className={disableButton ? 'btn btn-success disabled' : 'btn btn-success'}
					onClick={handleCreateUser}
				>
					Create
				</button>
			</Modal.Footer>
		</Modal>
	)

}

export default CreateUserModal;
