import React from 'react';

const TimeSeriesIcon = ({color, size}) => {
	let _size = 21;
	let fillColor = "#B668AE";
	if ( color ) {
		fillColor = color;
	}
	if (size) _size = size;

	return <svg width={_size} height={_size} viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M16.3799 18.8885V4.62012L18.4799 4.72466V18.9001" fill={fillColor}/>
		<path d="M13.4399 18.8929V10.0801L15.5399 10.1446V18.9001" fill={fillColor}/>
		<path d="M10.5 18.896V13.8601L12.6 13.897V18.9001" fill={fillColor}/>
		<path d="M20.5044 21H0.49894C0.223229 21 3.57422e-05 20.7769 3.57422e-05 20.5012V0.49875C-0.00324653 0.223125 0.219947 0 0.49894 0C0.77465 0 0.997846 0.223125 0.997846 0.49875V19.4742C0.997846 19.7498 1.22104 19.973 1.49675 19.973H20.5011C20.7768 19.973 21 20.1961 21 20.4717V20.4947C21 20.7703 20.7768 20.9934 20.5011 20.9934L20.5044 21Z" fill={fillColor}/>
		<path d="M2.74929 18.825L5.56141 11.7178C5.68544 11.4068 5.99405 11.3206 6.2046 11.5379L7.58903 12.9729C7.80823 13.1977 8.13127 13.0965 8.24375 12.7631L10.0291 7.49537C10.1502 7.1357 10.5165 7.05328 10.7242 7.33802L12.1144 9.22629C12.299 9.47731 12.6162 9.44734 12.772 9.15885L16.3052 2.54614C16.4148 2.34008 16.4032 2.05908 16.2763 1.87176C16.0946 1.59826 15.7629 1.62073 15.6072 1.91671L12.6884 7.37548C12.5355 7.66397 12.2182 7.69394 12.0307 7.44292L10.5021 5.36731C10.2916 5.08257 9.92814 5.165 9.80701 5.52467L7.96687 10.9535C7.85438 11.2832 7.53135 11.3881 7.31215 11.1633L5.97098 9.77329C5.76043 9.55599 5.45181 9.64216 5.32779 9.95313L2.11188 18.0832C2.09169 18.1357 2.09745 18.2031 2.12918 18.2443L2.57047 18.855C2.62238 18.9262 2.71179 18.9112 2.74641 18.8213L2.74929 18.825Z" fill={fillColor}/>
	</svg>

}

export default TimeSeriesIcon;
