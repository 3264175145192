import SectorIcon from "../components/shared/SectorIcon";
import { Link, useNavigate } from "react-router-dom";
import React, { useState } from "react";
import { checkIsInSubSector, CompanyItem, CompanyItemPublicTag } from "./Companies";
import LoadingImage from "../components/shared/LoadingImage";
import { useRecoilState } from "recoil";
import { showSingleCompanyAtom, singleCompanyIDAtom } from "../atoms/profileAtom";

const CompaniesCategory = (
  {
    sector,
    countCompaniesInSector,
    showLogos,
    filterBrand,
    companiesList,
    isPublic,
    subSectorsFilter,
  }
) => {
  const baseLogoURL = process.env.REACT_APP_LOGOS_BASE_URL;
  const signature = process.env.REACT_APP_LOGOS_SIGNATURE;
  const navigate = useNavigate();
  const [ collapseStatus, setCollapseStatus ] = useState( false )
  const [ openSingleCompany, setOpenSingleCompany ] = useRecoilState( showSingleCompanyAtom );
  const [ singleCompanyID, setSingleCompanyID ] = useRecoilState( singleCompanyIDAtom );

  const handleCollapseState = ( el ) => {
    setCollapseStatus( !collapseStatus );
  }

  return <div
    key={sector}
    className='col-12 mb-4'>
    <div
      className='fs-20px d-flex my-4'
    >
      <SectorIcon
        sector={sector}
      /><span
      // className="cursor-pointer"
      // onClick={() => handleCollapseState( sector )}
    >{sector} {countCompaniesInSector > 1 && !filterBrand ? `(${countCompaniesInSector})`  : <></>}</span>
    </div>
    <div
      key={sector}
      className={collapseStatus ? "hide-icon" : "d-flex flex-wrap mb-2"}
      style={{
        marginLeft: showLogos ? '-1%' : '0',
        marginRight: showLogos ? '-1%' : '0',
        height: collapseStatus ? '0%' : '100%',
        paddingBottom: collapseStatus ? "5px" : "30px",
        transition: "height 0.3s",
        overflow: "hidden"
      }}
    >
      {companiesList && companiesList.map( ( el, i ) => {
        // console.log(sector, collapseStateCategory.includes(sector))
        if ( el.sectorCategory?.name === sector &&
          (el.name === filterBrand || filterBrand === 'All Brands' || filterBrand === '') &&
          (isPublic !== '' ? el.isPublic === isPublic : true) && checkIsInSubSector( el, subSectorsFilter )
        ) {
//src, width, size, variant
          let logo = el.logoFileName ? <Link
              // to={'/companies/' + el.id}
            to="#"
              onClick={()=>{
                setOpenSingleCompany( true );
                setSingleCompanyID(el.id)
              }}
              className="logo-wrapper"><LoadingImage
              src={baseLogoURL + el.logoFileName + signature}
              width="auto"
              size="auto"
            />
          </Link> :
            <Link
              // to={'/companies/' + el.id}
              to="#"
              onClick={()=>{
                setOpenSingleCompany( true );
                setSingleCompanyID(el.id)
              }}
            ><div className="fs-14px">{el.name}</div>
            </Link>;
          return <CompanyItem
            key={el.name + "-" + i}
            showLogo={showLogos}
            onClick={showLogos ? () => {setOpenSingleCompany( true );
              setSingleCompanyID(el.id)} : () => {}}
          >
            {showLogos ? logo :
              <Link
                // to={'/companies/' + el.id}
                to="#"
                onClick={()=>{
                  setOpenSingleCompany( true );
                  setSingleCompanyID(el.id)
                }}
              >{el.name}</Link>}
            {el.isPublic && <CompanyItemPublicTag
              showLogo={showLogos}
            >Public</CompanyItemPublicTag>}
          </CompanyItem>
        }
      } )}
    </div>
    <hr />
  </div>
}

export default CompaniesCategory;
