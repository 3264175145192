import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from 'styled-components';
import NewCompSetStep1 from "./NewCompSetStep1";
import NewCompSetStep2 from "./NewCompSetStep2";
import NewCompSetStep3 from "./NewCompSetStep3";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  chosenMetricsAtom, chosenTagsAtom,
  companiesLimitAtom,
  CSMetricsAttributesAtom,
  customCompAtom, inProgressNewCustomCompAtom, inProgressNewCustomCompDialogAtom, inProgressNewCustomCompTypeAtom, linkToMenuItemAtom,
  metricLimitAtom,
  metricListAtom,
  metricSameStartAtom,
  newCustomCompNameAtom,
  newCustomCompTypeAtom,
  nextStepTitleAtom,
  searchCompanyArrayAtom,
  sectorsLimitAtom,
  selectedSectorsAtom,
  stepOfCreationCompsAtom, tagsOptionsAtom,
  timeframeSelectedAtom,
  timeframeSelectedLabelAtom
} from "../../atoms/profileAtom";
import TimeSeriesIcon from "../../components/shared/TimeSeriesIcon";
import ScatterPlotIcon from "../../components/shared/ScatterPlotIcon";
import ListIcon from "../../components/shared/ListIcon";
import TableIcon from "../../components/shared/TableIcon";
import MinusIcon from "../../components/shared/MinusIcon";
import { APIHeadersRAW, kitcut } from "../../components/shared/helpers";
import axios from "axios";
import CombineChart from "../Chart/CombineChart";
import { OverlayTrigger, Popover } from "react-bootstrap";
import Select from "react-select";
import moment from "moment";
import DatePicker from "react-datepicker";
import ScatterPlot from "../Chart/ScatterPlot";
import TableChart from "../Chart/TableChart";
import SubSectorsList from "./SubSectorsList";
import InvestorDealList from "./InvestorDealList";
import InvestmentTable from "./InvestmentTable";
import StoreListTable from "./StoreListTable";
import { useContext } from "react";
import { AppSettings } from "../../config/app-settings";
import MinusIconNoBorder from "../../components/shared/MinusIconNoBorder";
import { PublicTagStyle } from "../../components/modals/SelectCompanyBySector";
import MyComps from "../../components/shared/Icons/MyComps";
import CustomCompIcon from "../../components/shared/CustomCompIcon";

const CompSetContainer = styled.div`
  display: flex;
  margin-left: -30px;
  margin-right: -30px;
  margin-bottom: -20px;
  height: calc(100vh - 168px);
  padding-left: 15px;
  justify-content: space-between;
`;

const WizardContainer = styled.div`
  margin-right: -30px;
  margin-left: -30px;
  height: 60px;
  width: calc(100% + 60px);
  padding-left: 15px;
  border-bottom: 1px solid #EBEBEB;
  display: flex;
  justify-content: space-between;
`;

const Sidebar = styled.div`
  width: 450px;
  background-color: #F8F8F8;
  border-top: 7px solid var(--bs-primary);
  border-left: 1px solid #828282;
`;

const BarLineOptions = styled.div`
  display: flex;
  align-items: center;

  .form-check {
    &:nth-last-child(1) {
      margin-right: 0;
    }
  }
`;

const renderTooltip = ( text ) => (
  <Popover id="popover-contained" style={{ color: "black", background: "white" }}>
    {text}
  </Popover>
);

// const CompSetContainerStyle = { maxWidth: '1335px', width: '100%', overflow: "auto" };
// const CompSetContainerStyleFirstStep = { maxWidth: '1335px', width: '100%' };

const getContainerStyle = ( step ) => {
  switch ( step ) {
    case 1:
      return { maxWidth: '1335px', width: '100%' };
    case 4:
      return { width: "100%", padding: "0 20px 20px 20px" };
    default:
      return { maxWidth: '1335px', width: '100%', overflow: "auto" }
  }
}

export const stylesSelect = {
  control: styles => ({
    backgroundColor: 'var(--app-component-bg)',
    color: 'var(--app-component-color)',
    border: '1px solid var(--app-component-border-color)',
    borderRadius: '10px',
    display: 'flex',
    zIndex: "777",
  }),
  indicatorSeparator: styles => ({
    backgroundColor: 'transparent'
  }),
  input: styles => ({
    color: 'var(--app-component-color)',
    fontWeight: '600',
    gridArea: '1/1/2/3',
    flex: '1 1 auto',
    display: 'flex',
    margin: '2px',
    gridTemplateColumns: '0 min-content',
    boxSizing: 'content-box',
    paddingTop: '2px',
    paddingBottom: '2px',
    visibility: 'visible'
  }),
  singleValue: styles => ({
    color: 'var(--app-component-color)',
    gridArea: '1/1/2/3',
    marginLeft: '2px',
    marginRight: '2px',
    width: "140px",
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    boxSizing: 'border-box'
  }),
  placeholder: styles => ({
    color: 'rgba(var(--app-component-color-rgb), .5)',
    fontWeight: '600',
    width: "140px",
    gridArea: '1/1/2/3'
  }),
  menu: styles => ({
    backgroundColor: 'var(--app-component-dropdown-bg)',
    position: 'relative',
    top: '100%',
    borderRadius: '10px',
    margin: '8px 0',
    zIndex: '8888',
    boxSizing: 'border-box',
    width: '100%'
  }),
  option: ( styles, { data, isDisabled, isFocused, isSelected } ) => {
    return {
      backgroundColor: isFocused ? 'var(--app-component-dropdown-hover-bg)' : '',
      color: 'var(--app-component-color)',
      cursor: isDisabled ? 'not-allowed' : 'default',
      padding: '8px 12px'
    };
  }
};

export const InProgress = () => {

  return <div className="text-center">
    <div className="spinner-border spinner-border-sm" role="status">
      <span className="sr-only">Loading...</span>
    </div>
  </div>
}

const timeframeOption = [
  { value: 120, label: "All Years" },
  { value: 12, label: "Last Year" },
  { value: 24, label: "Last 2 Years" },
  { value: 36, label: "Last 3 Years" },
  { value: "Custom", label: "Custom" },
]

const NewCompSet = () => {
  const [ stepOfCreationComps, setStepOfCreationComps ] = useRecoilState( stepOfCreationCompsAtom );
  const [ searchCompanyArray, setSearchCompanyArray ] = useRecoilState( searchCompanyArrayAtom );
  const companiesLimit = useRecoilValue( companiesLimitAtom );
  const [ compName, setCompName ] = useRecoilState( newCustomCompNameAtom );
  const [ compType, setCompType ] = useRecoilState( newCustomCompTypeAtom );
  const [ classStep2, setClassStep2 ] = useState( 'nav-link disabled' );
  const [ classStep3, setClassStep3 ] = useState( 'nav-link disabled' );
  const [ wizardClass, setWizardClass ] = useState( 'nav-wizards-container col-9' );
  const [ nextStepTitle, setNextStepTitle ] = useRecoilState( nextStepTitleAtom );
  const [ chosenMetrics, setChosenMetrics ] = useRecoilState( chosenMetricsAtom );
  const metricLimit = useRecoilValue( metricLimitAtom );
  const sectorsLimit = useRecoilValue( sectorsLimitAtom );
  const metricList = useRecoilValue( metricListAtom );
  const [ selectedSectors, setSelectedSectors ] = useRecoilState( selectedSectorsAtom );
  const [ metricSameStart, setMetricSameStart ] = useRecoilState( metricSameStartAtom );
  const [ CSMetricsAttributes, setCSMetricsAttributes ] = useRecoilState( CSMetricsAttributesAtom );
  const [ filteredMetrics, setFilteredMetrics ] = useState( [] );
  const [ sidebarTitle, setSidebarTitle ] = useState( '' );
  const [ successComp, setSuccessComp ] = useState( false );
  const [ customComp, setCustomComp ] = useRecoilState( customCompAtom );
  const [ brandsOptionsTimeframe, setBrandsOptionsTimeframe ] = useState( timeframeOption );
  const [ timeframeSelected, setTimeframeSelected ] = useRecoilState( timeframeSelectedAtom );
  const [ timeframeSelectedLabel, setTimeframeSelectedLabel ] = useRecoilState( timeframeSelectedLabelAtom );
  const [ inProgressNewCustomComp, setInProgressNewCustomComp ] = useRecoilState( inProgressNewCustomCompAtom );
  const [ inProgressNewCustomCompType, setInProgressNewCustomCompType] = useRecoilState( inProgressNewCustomCompTypeAtom );
  const [ linkToMenuItem, setLinkToMenuItem] = useRecoilState( linkToMenuItemAtom );
  const [ showModal, setShowModal] = useRecoilState( inProgressNewCustomCompDialogAtom );
  const [ timeframeStartDate, setTimeframeStartDate ] = useState( '' );
  const [ timeframeEndDate, setTimeframeEndDate ] = useState( '' );
  const [ timeframeCustomStartDate, setTimeframeCustomStartDate ] = useState( '' );
  const [ timeframeCustomEndDate, setTimeframeCustomEndDate ] = useState( '' );
  const [ isAllSameStartOption, setIsAllSameStartOption ] = useState( false );
  const [ validCustomTimeframe, setValidCustomTimeframe ] = useState( true );
  const chosenTags = useRecoilValue( chosenTagsAtom );
  const [ inProgress, setInProgress ] = useState( false );
  const baseURL = process.env.REACT_APP_BASE_URL;
  const navigate = useNavigate();
  const context = useContext( AppSettings );

  const IconStar = ()=> {
    return <i className='fa-solid fa-folder-plus fs-16px mt-1' title='media'></i>
  }

  useEffect(
    () => {
      context.handleSetAppHeaderNone( false );
      context.handleSetAppSidebarNone( false );
      context.handleSetAppContentClass( '' );
      context.handleSetAppTitle( 'New Custom Comp' );
      context.handleSetAppIcon( <IconStar /> );
      setInProgressNewCustomComp(true);
      setInProgressNewCustomCompType('Custom Comp');
      setCompType( '' );
      setStepOfCreationComps( 1 );
      setSearchCompanyArray( [] );
      setChosenMetrics( [] );
      setCSMetricsAttributes( [] );
      setSelectedSectors( [] );
      setTimeframeSelectedLabel( '' );

      if (!compName) {
        let newName = '';
        const currentDate = new Date();
        // Format the date as "8/22/2023"
        const formattedDate = moment(currentDate).format('M/D/YYYY');
        let firstName = localStorage.getItem( 'first_name' );
        let _firstNameArray = firstName.split('');
        let lastName = localStorage.getItem( 'last_name' );
        newName = _firstNameArray[0] + lastName + " - " + formattedDate;
        setCompName(newName);
      }
    },
    []
  );

  const getTSType = ( id ) => {
    let _type;
    CSMetricsAttributes.map( el => {
      if ( el.metric_id === id ) {
        _type = el.ts_chart_type
      }
    } )

    return _type;
  }

  const handleRemoveCompany = ( e ) => {
    let _filtered = searchCompanyArray.filter( item => item.id !== e );
    setSearchCompanyArray( _filtered )
  }

  const handleRemoveSector = ( e ) => {
    let _filtered = selectedSectors.filter( item => item.id !== e );
    setSelectedSectors( _filtered )
  }

  const handleRemoveMetric = ( e ) => {
    let _filtered = chosenMetrics.filter( item => +item !== e );
    let _filteredAtributeMetrics = CSMetricsAttributes.filter( item => item.metric_id !== e)
    setChosenMetrics( _filtered );
    setCSMetricsAttributes( _filteredAtributeMetrics );
  }

  const handlerRadioChange = ( e ) => {
    let _newMetric;
    _newMetric = CSMetricsAttributes.map( el => {
      if ( +el.metric_id === +e.target.id ) {
        let _el = { ...el };
        _el.ts_chart_type = e.target.value;
        return _el;
      }
      return el;
    } )
    setCSMetricsAttributes( _newMetric );
  }

  const handlerSameStartChange = ( e ) => {
    setMetricSameStart( e.target.value === '0' );
  }

  const handleDateSelectStartDate = ( e ) => {
    setTimeframeCustomStartDate( e );
  }

  const handleDateSelectEndDate = ( e ) => {
    // console.log(_endDate)
    setTimeframeCustomEndDate( e );
  }

  useEffect( () => {
    if (timeframeCustomEndDate) {
      let _endDate = moment( timeframeCustomEndDate ).format( 'MM/DD/Y' );
      setTimeframeEndDate( _endDate );
    }

  }, [ timeframeCustomEndDate ] )

  useEffect( () => {
    if (timeframeCustomStartDate) {
      let _startDate = moment( timeframeCustomStartDate ).format( 'MM/DD/Y' );
      setTimeframeStartDate( _startDate );
    }

  }, [ timeframeCustomStartDate ] )

  useEffect( () => {
    if ( timeframeSelected === 'Custom' && (timeframeCustomStartDate === '' || timeframeCustomEndDate === '') ) {
      setValidCustomTimeframe( false );
    } else {
      setValidCustomTimeframe( true );
    }

  }, [ timeframeCustomStartDate, timeframeCustomEndDate, timeframeSelected ] )

  const handleSaveCustomComps = ( e, action ) => {
    e.preventDefault();
    let _existingTags = [];
    let _newTags = [];
    chosenTags.map( tag => {
      if ( tag.id !== undefined ) {
        _existingTags.push( { "tag_id": tag.id } )
      } else {
        _newTags.push( { "label": tag.label } )
      }
    } );

    // console.log( "existing ", _existingTags )
    // console.log( "new ", _newTags )

    setInProgress( true );
    let _url = `${baseURL}/custom_comps/new`;
    if ( action === 'save' ) {
      _url = `${baseURL}/custom_comps`;
    }

    let _compType = '';
    if ( compType === 'Table' ) {
      _compType = 'CustomComps::Table';
    } else if ( compType === 'Time Series' ) {
      _compType = 'CustomComps::TimeSeries';
    } else if ( compType === 'Scatter Plot' ) {
      _compType = 'CustomComps::ScatterPlot';
    } else if ( compType === 'Company Fundraising History' ) {
      _compType = 'CustomComps::CompanyList';
    } else if ( compType === 'Sector Investment List' ) {
      _compType = 'CustomComps::SectorList';
    } else if ( compType === 'Store List' ) {
      _compType = 'CustomComps::StoreList';
    } else if ( compType === 'Firm Retail Investments' ) {
      _compType = 'CustomComps::InvestorList';
    }

    let metricData = {};
    metricData[ 'custom_comp' ] = {
      "name": compName,
      "type": _compType,
      "start_date": !metricSameStart ? timeframeStartDate : '',
      "end_date": !metricSameStart ? timeframeEndDate : '',
      "taggings_attributes": _existingTags,
      "tags": _newTags,
      "resource_ids": compType !== 'Sector Investment List' ? searchCompanyArray.map( el => el.id ) : selectedSectors.map( el => el.id ),
      "custom_comp_metrics_attributes": compType !== 'Sector Investment List' ? CSMetricsAttributes.map( el => {
        return {
          metric_id: el.metric_id,
          ts_chart_type: el.ts_chart_type,
          same_start: metricSameStart

        };
      } ) : [],
      // } ) : [{metric_id: 1}],
    }
    // same_start: metricSameStart

    const config = {
      method: 'post',
      url: _url,
      headers: APIHeadersRAW,
      withCredentials: true,
      credentials: "include",
      data: metricData,
    };
    axios( config ).then( resp => {
      console.log( resp );
      if ( resp.data ) {
        setCustomComp( resp.data.data );
        setSuccessComp( true );
        setStepOfCreationComps( 4 );
      }
      if ( action === 'save' ) {
        // toasterNotify( 'Custom Comp was created', '', 'success' );
        navigate( '/custom-comps', { replace: false } );
        setInProgressNewCustomComp(false);
      }
      setInProgress( false );
    } ).catch( error => {
      console.log( error );
      setInProgress( false );
    } )
  }

  const handleChangeTimeframeFilter = ( e ) => {
    let _endDate = moment( new Date() ).format( 'MM/DD/Y' );
    let _startDate = moment().add( -e.value, 'month' ).format( 'MM/DD/Y' );
    setTimeframeSelected( e.value );
    setTimeframeSelectedLabel( e );
    setTimeframeStartDate( _startDate );
    setTimeframeEndDate( _endDate );
  }

  useEffect( () => {
    if ( compType === 'Sector Investment List' || compType === 'Firm Retail Investments' || compType === 'Store List' || compType === 'Company Fundraising History' ) {
      setWizardClass( 'nav-wizards-container col-9' )
    }
    if ( (compType === 'Table' || compType === 'Time Series' || compType === 'Scatter Plot') && stepOfCreationComps < 3 ) {
      setWizardClass( 'nav-wizards-container col-9' )
    }
    if ( (compType === 'Table' || compType === 'Time Series' || compType === 'Scatter Plot') && stepOfCreationComps > 3 ) {
      setWizardClass( 'nav-wizards-container col-11' )
    }
    if ( (compType === 'Table' || compType === 'Time Series' || compType === 'Scatter Plot') && stepOfCreationComps === 2 ) {
      setSidebarTitle( 'Companies' )
    }
    if ( (compType === 'Company Fundraising History') && stepOfCreationComps === 2 ) {
      setSidebarTitle( 'Company' )
    }
    if ( (compType === 'Table' || compType === 'Time Series' || compType === 'Scatter Plot') && stepOfCreationComps === 3 ) {
      setSidebarTitle( 'Metrics' )
    }
    if ( compType === 'Sector Investment List' && stepOfCreationComps === 2 ) {
      setSidebarTitle( 'Sectors' )
    }
    if ( compType === 'Firm Retail Investments' && stepOfCreationComps === 2 ) {
      setSidebarTitle( 'Firm' )
    }
    if ( compType === 'Store List' && stepOfCreationComps === 2 ) {
      setSidebarTitle( 'Store' )
    }
    if ( stepOfCreationComps === 3 && (compType === 'Table' || compType === 'Time Series' || compType === 'Scatter Plot') ) {
      setNextStepTitle( 'Generate Chart' )
    }
    if ( stepOfCreationComps === 2 && (compType === 'Table' || compType === 'Time Series' || compType === 'Scatter Plot') ) {
      setNextStepTitle( 'Next' )
    }
  }, [ compType, stepOfCreationComps ] )

  useEffect( () => {
    if ( stepOfCreationComps > 2 ) {
      setClassStep2( 'nav-link completed' )
    } else if ( stepOfCreationComps === 2 ) {
      setClassStep2( 'nav-link active' )
    } else if ( stepOfCreationComps === 1 ) {
      setClassStep2( 'nav-link disabled' )
    }
    if ( stepOfCreationComps < 3 ) {
      setClassStep3( 'nav-link disabled' )
    } else if ( stepOfCreationComps === 4 ) {
      setClassStep3( 'nav-link completed' )
    } else {
      setClassStep3( 'nav-link active' )
    }
  }, [ stepOfCreationComps ] )

  useEffect( () => {
    if ( metricList && chosenMetrics ) {
      let _temp = [];
      metricList.forEach( metric => {
        chosenMetrics.map( choose => {
          if ( +metric.id === +choose ) {
            _temp = [ ..._temp, metric ];
          }
        } )
      } )

      setFilteredMetrics( _temp );

      let _isAllSameStartOption = [];
      chosenMetrics.forEach( choose => {
        metricList.map( metric => {
          if ( +metric.id === +choose ) {
            _isAllSameStartOption.push( metric.same_start );
          }
        } )
      } )
      setIsAllSameStartOption( _isAllSameStartOption.every( v => v === true ) );
    }
  }, [ metricList, chosenMetrics ] )

  useEffect( () => {
    if ( !isAllSameStartOption ) {
      setMetricSameStart( false );
    }

  }, [ isAllSameStartOption ] )

  return <>
    <WizardContainer>
      <div className={wizardClass}>
        <nav className="nav nav-wizards-2 mb-20px">
          <div className="nav-item col">
            <Link
              to="#"
              className={'nav-link completed'}
              onClick={( e ) => {
                e.preventDefault();
                setShowModal( true );
                setLinkToMenuItem( '/custom-comps/' );
              }}>
              <div className="nav-text">
                <MyComps height="24" />
              </div>
            </Link>
          </div>
          <div className="nav-item col">
            <Link to=""
                  className={stepOfCreationComps === 1 ? 'nav-link active' : 'nav-link completed'}
                  onClick={() => setStepOfCreationComps( 1 )}>
              <div className="nav-text">1. Name & Type</div>
            </Link>
          </div>
          {compType === '' && <>
            <div className="nav-item col">
              <Link to=""
                    className={'nav-link disabled'}
              >
                <div className="nav-text">2. Pick Companies/Firms</div>
              </Link>
            </div>
            <div className="nav-item col">
              <Link to=""
                    className={'nav-link disabled'}
              >
                <div className="nav-text">3. Choose Metrics</div>
              </Link>
            </div>
          </>}
          {compType && <>
            {compType === 'Sector Investment List' &&
              <div className="nav-item col">
                <Link to="" className={(stepOfCreationComps < 2) ? 'nav-link disabled' : 'nav-link active'}>
                  <div className="nav-text">2. Choose Sector(s)</div>
                </Link>
              </div>
            }
            {compType === 'Company Fundraising History' &&
              <div className="nav-item col">
                <Link to="#" className={(stepOfCreationComps < 2) ? 'nav-link disabled' : 'nav-link active'}>
                  <div className="nav-text">2. Choose a Company</div>
                </Link>
              </div>
            }
            {compType === 'Firm Retail Investments' &&
              <div className="nav-item col">
                <Link to="#" className={(stepOfCreationComps < 2) ? 'nav-link disabled' : 'nav-link active'}>
                  <div className="nav-text">2. Choose Firm(s)</div>
                </Link>
              </div>
            }
            {compType === 'Store List' &&
              <div className="nav-item col">
                <Link to="#" className={(stepOfCreationComps < 2) ? 'nav-link disabled' : 'nav-link active'}>
                  <div className="nav-text">2. Choose a Store</div>
                </Link>
              </div>
            }
            {(compType === 'Table' || compType === 'Time Series' || compType === 'Scatter Plot') &&
              <>
                <div className="nav-item col">
                  <Link to="#" className={classStep2} onClick={() => setStepOfCreationComps( 2 )}>
                    <div className="nav-text">2. Pick Companies</div>
                  </Link>
                </div>
                <div className="nav-item col">
                  <Link
                    to="#"
                    onClick={() => setStepOfCreationComps( 3 )}
                    className={classStep3}>
                    <div className="nav-text">3. Choose Metrics</div>
                  </Link>
                </div>
                {stepOfCreationComps > 3 && <div className="nav-item col">
                  <Link to="#" className={'nav-link completed'}>
                    <div className="nav-text">{kitcut( compName, 20 )}</div>
                  </Link>
                </div>}
              </>
            }
          </>
          }
        </nav>
      </div>
      {stepOfCreationComps === 4 && <div className="col-1">
        <Link to="#" className="fs-16px w-75px h-40px btn bg-green2 btn-outline-light" onClick={( e ) => handleSaveCustomComps( e, 'save' )}>Save</Link>
      </div>}
    </WizardContainer>
    <CompSetContainer>
      <div style={getContainerStyle(stepOfCreationComps)}>
        {/*<div style={stepOfCreationComps !== 4 ? CompSetContainerStyle : { width: "100%", padding: "0 20px 20px 20px" }}>*/}
        {stepOfCreationComps === 1 && <NewCompSetStep1 />}
        {stepOfCreationComps === 2 &&
          (compType === 'Table' || compType === 'Time Series' || compType === 'Scatter Plot' || compType === "Company Fundraising History") &&
          <NewCompSetStep2 />}
        {stepOfCreationComps === 3 &&
          (compType === 'Table' || compType === 'Time Series' || compType === 'Scatter Plot') &&
          <NewCompSetStep3 />}
        {stepOfCreationComps === 2 && compType === 'Firm Retail Investments' && <InvestorDealList />}
        {stepOfCreationComps === 4 && (compType === 'Company Fundraising History' || compType === 'Sector Investment List' || compType === 'Firm Retail Investments') &&
          <InvestmentTable preview={true} />}
        {stepOfCreationComps === 2 && compType === 'Store List' && <NewCompSetStep2 />}
        {stepOfCreationComps === 2 && compType === 'Sector Investment List' && <SubSectorsList />}
        {stepOfCreationComps === 4 && compType === 'Time Series' &&
          <CombineChart preview={true} />}
        {stepOfCreationComps === 4 && compType === 'Scatter Plot' &&
          <ScatterPlot preview={true} />}
        {stepOfCreationComps === 4 && compType === 'Table' &&
          <TableChart preview={true} />}
        {stepOfCreationComps === 4 && compType === 'Store List' &&
          <StoreListTable preview={true} />}
      </div>
      {(stepOfCreationComps > 1 && stepOfCreationComps < 4) &&
        <Sidebar
          className={(stepOfCreationComps < 3 && compType === 'Sector Investment List') ? "right-sidebar col-3 px-3 py-4 sectors" : "right-sidebar col-3 px-3 py-4"}
        >
          <div className="d-flex flex-column justify-content-between h-100">
            {/*<div style={{ "overflowX": "auto", "height": "100%" }}>*/}
            <div style={{ "overflowX": "hidden", "height": "100%" }}>
              <div className="color-purple d-flex align-items-center my-2">
                {compType === 'Scatter Plot' && <ScatterPlotIcon size='25' />}
                {compType === 'Time Series' && <TimeSeriesIcon size='25' />}
                {(compType === 'Sector Investment List' ||
                    compType === "Company Fundraising History" ||
                    compType === 'Firm Retail Investments' ||
                    compType === 'Store List') &&
                  <ListIcon size="25" />}
                {compType === 'Table' && <TableIcon size='25' />}
                <span className="mx-2 fs-15px">{compType}</span>
              </div>
              <h3>{compName} <span className="color-purple">{sidebarTitle}</span></h3>

              {(stepOfCreationComps === 2 && compType === 'Firm Retail Investments') && <>

                {/*<p className="py-2 fs-14px">Search or browse to add <strong>a firm</strong> to your list</p>*/}
                <p className="py-2 fs-14px">Search or browse to add <strong>{companiesLimit > 1 ? `up to ${companiesLimit} companies` : `a company`}</strong> to your list
                </p>
                {searchCompanyArray && searchCompanyArray.map( el => {
                  return <div
                    className="lh-18 d-flex align-items-center mb-1 fs-14px"
                    key={el.name}>
                  <span className="cursor-pointer" onClick={() => handleRemoveCompany( el.id )}>
                    <MinusIcon />
                  </span>
                    <span className="mx-2">{el.name}{el.isPublic && <PublicTagStyle>public</PublicTagStyle>}</span>
                  </div>
                } )}
                {searchCompanyArray.length ? <div className="py-3">
                  <Link
                    to="#"
                    className="btn btn-outline-black px-2"
                    onClick={() => setSearchCompanyArray( [] )}
                  ><MinusIconNoBorder /> Clear All</Link>
                </div> : <></>}
              </>}

              {(stepOfCreationComps < 3 && compType !== 'Sector Investment List' && compType !== 'Firm Retail Investments') && <>

                <p className="py-2 fs-14px">Search or browse to add <strong>{companiesLimit > 1 ? `up to ${companiesLimit} companies` : `a company`}</strong> to your list
                </p>
                {searchCompanyArray && searchCompanyArray.map( el => {
                  return <div
                    className="lh-18 d-flex align-items-center mb-1 fs-14px"
                    key={el.name}>
                  <span className="cursor-pointer" onClick={() => handleRemoveCompany( el.id )}>
                    <MinusIcon />
                  </span>
                    <span className="mx-2">{el.name}{el.isPublic && <PublicTagStyle>public</PublicTagStyle>}</span>
                  </div>
                } )}
                {searchCompanyArray.length && companiesLimit > 1 ? <div className="py-3">
                  <Link
                    to="#"
                    className="btn btn-outline-black px-2"
                    onClick={() => setSearchCompanyArray( [] )}
                  ><MinusIconNoBorder /> Clear All</Link>
                </div> : <></>}
              </>}

              {(stepOfCreationComps < 3 && compType === 'Sector Investment List') && <>
                <p className="py-2 fs-14px">Search or browse to add <strong>{sectorsLimit > 1 ? `up to ${sectorsLimit} sectors` : `a sector`}</strong> to your list
                </p>
                {selectedSectors && selectedSectors.map( el => {
                  return <div
                    className="lh-18 d-flex align-items-center mb-1 fs-14px"
                    key={el.name}>
                  <span className="cursor-pointer" onClick={() => handleRemoveSector( el.id )}>
                    <MinusIcon />
                  </span>
                    <span className="mx-2">{el.name}</span>
                  </div>
                } )}
                {selectedSectors.length && sectorsLimit > 1 ? <div className="py-3">
                  <Link
                    to="#"
                    className="btn btn-outline-black px-2"
                    onClick={() => setSelectedSectors( [] )}
                  ><MinusIconNoBorder /> Clear All</Link>
                </div> : <></>}
              </>}

              {stepOfCreationComps > 2 && <>
                <p className="py-2 fs-14px">Add <strong>up to {metricLimit} metrics</strong> to your list </p>
                {filteredMetrics && filteredMetrics.length > 0 && compType === "Time Series" &&
                  <div className="d-flex justify-content-end mb-2">
                    <div className="mx-1">
                      <span className="m-3">Line</span>
                      <span className="m-1">Bar</span>
                    </div>
                  </div>}

                <div className="h-150px fs-14px">
                  {filteredMetrics && filteredMetrics.map( metric => {
                    return <div className="lh-18 d-flex mb-2 justify-content-between"
                                key={metric.name}>
                      <div className="d-flex align-items-center">
                        <span className="cursor-pointer" onClick={() => handleRemoveMetric( metric.id )}>
                        <MinusIcon />
                      </span>
                        <span className="mx-2">{`${metric.section}: ${metric.name}`}</span>
                      </div>
                      {compType === "Time Series" && <BarLineOptions>
                        <div className="form-check form-check-inline">
                          <input className="form-check-input"
                                 type="radio"
                                 disabled={metric.ts_chart_type !== 'both'}
                                 checked={getTSType( metric.id ) === 'line'}
                                 onChange={handlerRadioChange}
                                 value="line"
                                 id={`${metric.id}`}
                                 name={`radio-${metric.id}`}
                          />
                        </div>
                        <div className="form-check form-check-inline">
                          <input className="form-check-input"
                                 type="radio"
                                 disabled={metric.ts_chart_type !== 'both'}
                                 checked={getTSType( metric.id ) === 'bar'}
                                 onChange={handlerRadioChange}
                                 value="bar"
                                 id={`${metric.id}`}
                                 name={`radio-${metric.id}`}
                          />
                        </div>
                      </BarLineOptions>}
                    </div>
                  } )
                  }
                  {filteredMetrics.length ? <div className="py-3">
                    <Link
                      to="#"
                      className="btn btn-outline-black px-2"
                      onClick={() => {
                        setFilteredMetrics([]);
                        setChosenMetrics( [] );
                        setCSMetricsAttributes([])
                      }}
                    ><MinusIconNoBorder /> Clear All</Link>
                  </div> : <></>}
                </div>
              </>}

              {stepOfCreationComps === 3 && compType === 'Time Series' && chosenMetrics.length > 0 && <>
                <div className="row h-200px fs-14px">
                  <div className="col-5">
                    <h5 className="mx-4px">Line type</h5>
                    <div className="form-check my-2 mx-4px">
                      <input className="form-check-input"
                             type="radio"
                             value='1'
                             checked={!metricSameStart}
                             id="same_start_false"
                             onChange={handlerSameStartChange}
                             name="same_start_false"
                      />

                      <label htmlFor="same_start_false">Chronological <OverlayTrigger
                        placement="right"
                        delay={{ show: 400, hide: 400 }}
                        overlay={renderTooltip( "Look at the metrics progression starting in whatever calendar year you'd like to start from, e.g. 2015, 2018, 2021, etc." )}
                      ><i className="mx-1 fa fa-circle-info fs-11px"></i></OverlayTrigger></label>

                    </div>
                    <div className="form-check my-2 mx-4px">
                      <input className={!isAllSameStartOption ? "form-check-input muted" : "form-check-input"}
                             type="radio"
                             value='0'
                             checked={metricSameStart}
                             disabled={!isAllSameStartOption}
                             id="same_start_true"
                             onChange={handlerSameStartChange}
                             name="same_start_true"
                      />
                      <label htmlFor="same_start_true" className={!isAllSameStartOption ? 'color-secondary opacity-50' : ''}>Same Start <OverlayTrigger
                        placement="right"
                        delay={{ show: 400, hide: 400 }}
                        overlay={renderTooltip( "Look at the metrics progression starting from each brand's first year in business, e.g. if Brand A opened its first location in 2017 and Brand B opened its first location in 2021, this sets both clocks to zero so you can see how the metrics evolved  at the same point in their lifespans, or X-Axis increments are Y1 (first year in business), Y2 (second year in business), etc." )}
                      ><i className="mx-1 fa fa-circle-info fs-11px"></i>
                      </OverlayTrigger></label>

                    </div>

                  </div>
                  <div className="col-7">
                    <h5 className={metricSameStart ? 'color-secondary opacity-50' : ''}>Timeframe</h5>
                    <Select
                      className={metricSameStart ? 'opacity-50' : ''}
                      isSearchable={false}
                      isDisabled={metricSameStart}
                      options={brandsOptionsTimeframe}
                      value={timeframeSelectedLabel}
                      onChange={handleChangeTimeframeFilter}
                      styles={stylesSelect}
                    />
                    {timeframeSelected === 'Custom' && !metricSameStart &&
                      <>
                        <DatePicker
                          showMonthYearPicker
                          className="form-control h-40px my-1"
                          // value={timeframeStartDate && moment( timeframeStartDate, "MM/DD/Y" )}
                          selected={timeframeCustomStartDate ? new Date( timeframeCustomStartDate ) : ''} //moment("12-25-1995", "MM-DD-YYYY");
                          placeholderText="Start Date"
                          format="MM/DD/Y"
                          onChange={handleDateSelectStartDate}
                        />
                        <DatePicker
                          showMonthYearPicker
                          className="form-control h-40px my-1"
                          // value={timeframeEndDate && moment( timeframeEndDate, "MM/DD/Y" )}
                          selected={timeframeCustomEndDate ? new Date( timeframeCustomEndDate ) : ''} //moment("12-25-1995", "MM-DD-YYYY");
                          placeholderText="End Date"
                          format="MM/DD/Y"
                          onChange={handleDateSelectEndDate}
                        />
                      </>
                    }
                  </div>
                </div>
              </>}
            </div>
            <div>


              <div className="d-flex justify-content-end m-2">
                <Link to=""
                      className="fs-16px btn w-100px color-green2 btn-outline-green2 text-uppercase"
                      onClick={() => setStepOfCreationComps( stepOfCreationComps => stepOfCreationComps - 1 )}
                >Back</Link>
                <div className="w-20px"></div>

                {stepOfCreationComps !== 3 && (compType === 'Table' || compType === 'Time Series' || compType === 'Scatter Plot') ?
                  <Link to=""
                        className={(searchCompanyArray.length && !inProgress ) ? "fs-16px btn w-200px text-light bg-green2 text-uppercase" : "fs-16px btn w-200px text-light bg-green2 text-uppercase disabled"}
                        onClick={() => setStepOfCreationComps( stepOfCreationComps => stepOfCreationComps + 1 )}
                  >{inProgress ? <InProgress /> : nextStepTitle}</Link> : null}

                {(stepOfCreationComps === 3 && (compType === 'Table' || compType === 'Time Series' || compType === 'Scatter Plot')) ||
                (stepOfCreationComps === 2 && (compType === 'Company Fundraising History' || compType === 'Sector Investment List' || compType === 'Firm Retail Investments' || compType === 'Store List')) ?
                  <Link
                    to="#"
                    disable=""
                    className={(CSMetricsAttributes.length > 0 && !inProgress && validCustomTimeframe) ||
                    (searchCompanyArray.length &&
                      (compType === 'Company Fundraising History' || compType === 'Firm Retail Investments' || compType === 'Store List')) ||
                    (selectedSectors.length && compType === 'Sector Investment List') ?
                      "fs-16px btn w-200px text-light bg-green2 text-uppercase" :
                      "fs-16px btn w-200px text-light bg-green2 text-uppercase disabled"}
                    onClick={( e ) => handleSaveCustomComps( e, 'preview' )}>{inProgress ? <InProgress /> : nextStepTitle}</Link> : null}
              </div>
            </div>
          </div>
        </Sidebar>}
    </CompSetContainer>
  </>;
}

export default NewCompSet;
