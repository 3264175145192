

const Rating4WallIcon = () =>{

  return <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.09275 0.58751V1.03495H1.86327H0.633789L0.650309 2.81463C0.663609 4.50807 0.666829 4.61419 0.736549 4.92891C0.905529 5.69107 1.17069 6.20151 1.63465 6.67205C2.07537 7.11613 2.47969 7.32823 3.08617 7.43099C3.23205 7.45423 3.32473 7.48727 3.32473 7.51051C3.32473 7.59339 3.64953 8.21303 3.81193 8.44179C4.27589 9.09125 4.99171 9.62157 5.76059 9.88015L6.05879 9.97955L6.06873 10.5926L6.07531 11.209L5.98585 11.2289C4.64367 11.5338 3.71239 12.1701 3.29491 13.0682C3.18557 13.3002 3.09275 13.6217 3.09275 13.7675V13.8604H7.01989H10.947L10.9271 13.751C10.8741 13.4296 10.8277 13.2871 10.6952 13.0153C10.261 12.1305 9.35635 11.524 8.02411 11.2291L7.93129 11.2092V10.5993V9.98963L8.20639 9.90017C9.26353 9.56207 10.1384 8.77667 10.5991 7.75271C10.7218 7.48097 10.7349 7.46445 10.8476 7.44457C11.3315 7.36827 11.7258 7.21259 12.0936 6.95079C12.7266 6.50013 13.1939 5.62191 13.3298 4.63099C13.3529 4.47853 13.3662 3.70965 13.3662 2.70557V1.03537H12.1566H10.947V0.587929V0.14007H7.01989H3.09275V0.58751ZM3.09275 4.21967V6.20809L3.01981 6.18821C2.74149 6.10197 2.37021 5.78389 2.18807 5.47575C2.05885 5.25371 1.90975 4.76651 1.86999 4.44843C1.85011 4.29597 1.83359 3.73261 1.83359 3.19907V2.22803H2.46331H3.09303V4.21967H3.09275ZM12.1666 3.41103C12.1533 4.51129 12.1467 4.61727 12.0805 4.85919C11.9147 5.45573 11.5734 5.92627 11.1691 6.11527C11.0663 6.16497 10.9768 6.20473 10.9669 6.20473C10.957 6.20473 10.947 5.30999 10.947 4.21631V2.22789H11.5634H12.1765L12.1666 3.41103Z" fill="#B668AE"/>
  </svg>;
}

export default Rating4WallIcon;
