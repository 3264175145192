import React from "react";
import styled from "styled-components";
import { numberWithCommas } from "../../components/shared/helpers";
import { useRecoilState, useRecoilValue } from "recoil";
import { collapsedLegendStatisticAtom, minimizeLocationLegendAtom } from "../../atoms/profileAtom";
import MinimizeLegendIcon from "../../components/shared/MinimizeLegend";
import { CompanyColor, LogoWrapper } from "./CompanyEntry";


const CustomTooltipMapStyle = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: space-between;
  flex-wrap: wrap;
  border-top: 1px solid rgba(231, 231, 231, 1);
  margin-top: 10px;
  padding-top: 10px;

  .tooltip-metric {
    //width: calc(50% - 40px);
    width: auto;
    border-right: 1px solid rgba(231, 231, 231, 1);
    //min-width: 130px;
    padding-right: 10px;
    padding-bottom: 10px;
    //margin-top: 10px;
    //min-width: 138px;
    display: flex;
    justify-content: space-between;
    min-width: 239px;

     &:nth-of-type(even) {
      border-right: none;
    }
    &:nth-of-type(3), &:nth-of-type(4) {
      padding-bottom: 0;
    }
  }

  .tooltip-key {
    font-size: 14px;
    //margin-top: 10px;
    padding: 0;
    //min-width: 130px;
    text-align: left;
    //border-bottom: 1px solid #333;
  }

  .tooltip-value {
    //margin-top: 10px;
    padding: 0;
    font-size: 15px;
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
  }

  meter {
    height: 20px;
  }

`;

const CustomTooltipWrapper = styled.div`
  position: absolute;
  top: auto;
  bottom: 10px;
  padding: 10px 16px;
  background-color: #fff;
  //border: 1px solid #F2F2F2;
  box-shadow: 0 0 8px -0.5px rgba(0, 0, 0, 0.14);
  border-radius: 20px;
  z-index: 100;
  left: ${props => props.minimizeLocationLegend ? (window.innerHeight < 900 ? "120px" : "12px") : "240px"};
  //transform: translateX(-50%);
  width: 540px;

  .address-field {
    padding: 4px;
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #8f8c8c
  }
`;

const CustomTooltipMap = ( { show, data, address, openingStatus, companyName, logoUrl, color } ) => {
  const baseLogoURL = process.env.REACT_APP_LOGOS_BASE_URL;
  const signature = process.env.REACT_APP_LOGOS_SIGNATURE;
  const [ collapsedLegendStatistic, setCollapsedLegendStatistic ] = useRecoilState( collapsedLegendStatisticAtom );
  const  minimizeLocationLegend  = useRecoilValue( minimizeLocationLegendAtom );
  const handleCollapseLegend = () => {
    setCollapsedLegendStatistic(!collapsedLegendStatistic);
  }

  if ( data.length === 0 || collapsedLegendStatistic ) {
    return <></>;
  }
  // let _nationalAVGReviews = data['vs. National Avg. '];
  let _nationalAVGReviews = data['Brand National Avg. '];

  const Logo = () => {

    return  logoUrl ?
      <LogoWrapper>
        <img
          src={baseLogoURL + logoUrl + signature}
          alt={companyName}
          title={companyName}
        />
      </LogoWrapper> : <h4 className="m-0 h-40px d-flex align-items-center">{companyName}</h4>;
  }

  return <CustomTooltipWrapper
    minimizeLocationLegend={minimizeLocationLegend}
  >
    <div className="d-flex justify-content-end">
      <div
        className="cursor-pointer"
        onClick={()=>handleCollapseLegend()}
      ><MinimizeLegendIcon />
      </div>
    </div>
    <div className="d-flex justify-content-between align-items-center">
      <div className="d-flex align-items-center">
        <CompanyColor color={color} />
        <Logo />
      </div>
      <div>
        {address}
      </div>
    </div>
    {/*{window.innerHeight} */}

    {/*{openingStatus !== "OPEN" ? <div className="address-field">Opening Status: {openingStatus !== undefined ? openingStatus.replace(/_/g, " ") : 'Open'}</div> : <></>}*/}
    {/*<div className="address-field">{companyName}</div>*/}
    {/*<div className="address-field">{address}</div>*/}
    <CustomTooltipMapStyle>
      {Object.entries( data ).map( ( [ key, value ] ) => {

        return <div
          className="tooltip-metric"
          key={key + value}
        >
          <div className="tooltip-key">{key}</div>

          {((key === 'Rating' || key === 'Brand National Avg.') && value !== 0 && !isNaN(value) && value > 0) &&
            <div className="tooltip-value">
              <div className="w-40px">{numberWithCommas(value.toFixed(1))}</div>
              <div className="mx-1">
                <meter
                  value={value}
                  min="0"
                  max="5"
                  low="2"
                  optimum="4.2"
                  high="4"
                ></meter>
              </div>
            </div>}

          {((key === 'Reviews' || key === 'Brand National Avg. ') && value !== 0 && !isNaN(value) && value > 0) &&
            <div className="tooltip-value">
              <div className="w-40px">{numberWithCommas(value.toFixed(0))}</div>
              <div className="mx-1">
                <meter
                  value={value}
                  low={key === 'Reviews' ? (_nationalAVGReviews / 100) * 30 : ''}
                  optimum={key === 'Reviews' ? (_nationalAVGReviews / 100) * 80 : ''}
                  high={key === 'Reviews' ? (_nationalAVGReviews / 100) * 60 : ''}
                  min="0"
                  max={_nationalAVGReviews}
                ></meter>
              </div>
            </div>}
          { (value === 0 || value < 0 || value === null || value === undefined || isNaN(value)) &&
            <div className="tooltip-value">
              <div>{'—'}</div>
              {/*<div><meter/></div>*/}
            </div>}
        </div>;
      } )}
    </CustomTooltipMapStyle>
  </CustomTooltipWrapper>
}

export default CustomTooltipMap;
