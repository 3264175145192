
const BeautyIcon = ({size}) => {
  let _size = size === 'L' ? 2 : 1;

  return <svg width={_size * 10} height={_size * 15} viewBox="0 0 10 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.500031 14.7499V5.41343L4.00003 0.75H5.99998L9.49998 5.41343V14.7499H0.500031ZM2.00001 13.2499H8.00001V10.7192L5.00001 7.71918L2.00001 10.7192V13.2499ZM2.00001 8.60575L4.25003 6.35575V2.9134L2.00001 5.92495V8.60575ZM8.00001 8.60575V5.92495L5.74998 2.9134V6.35575L8.00001 8.60575Z" fill="#B668AE"/>
  </svg>;
}

export default BeautyIcon;
