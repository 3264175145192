import React from 'react';

const Logo = ( { mode, height } ) => {
  let fillColor = "#fff";
  let _height = height ? height : 30;
  if ( mode === 'dark' ) {
    fillColor = "#c897c3"
  }

  return <svg width="81" height="41" viewBox="0 0 81 41" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M79.2807 35.6413H24.09V33.601H51.0951V6.52858H24.0997V4.48828H79.2807V35.6413ZM77.2404 33.5786V6.54783H53.1964V33.5786H77.2404Z" fill="url(#paint0_linear_2053_170276)"/>
    <path d="M18.1969 4.48145H21.2189V25.9976H24.8343V28.0604H21.2381V35.6345H19.015V28.0732H2.4648C2.4648 27.3867 2.45838 26.7258 2.47122 26.0682C2.47122 25.9816 2.56425 25.8917 2.6252 25.8083C7.76444 18.7667 12.9037 11.7219 18.0429 4.68034C18.0878 4.61939 18.136 4.55844 18.1969 4.48145ZM18.9829 25.9912V6.84896C14.3088 13.2586 9.68284 19.5976 5.01838 25.9912H18.9829Z" fill="url(#paint1_linear_2053_170276)"/>
    <path d="M31.1797 27.8813C29.6367 22.6939 28.0936 17.513 26.5474 12.3128H28.9085C30.0569 16.2009 31.2022 20.089 32.3218 23.8745C33.4831 20.105 34.6797 16.2169 35.8795 12.316H38.2951C39.4821 16.2041 40.6755 20.1115 41.8336 23.9098C42.9628 20.1243 44.1273 16.2137 45.2918 12.3096H47.6048C46.0361 17.5066 44.4738 22.6811 42.9082 27.8717H40.7621C39.5559 23.9322 38.3432 19.9799 37.1338 16.0245C37.1049 16.0212 37.0761 16.018 37.0472 16.0116C35.8217 19.9639 34.5963 23.9194 33.3676 27.8781H31.1829L31.1797 27.8813Z" fill="url(#paint2_linear_2053_170276)"/>
    <path d="M70.7666 25.7893C71.296 26.3892 71.8253 26.9923 72.3739 27.6179C71.8734 28.0702 71.3954 28.5065 70.8885 28.962C70.3271 28.3333 69.7786 27.7173 69.2877 27.1687C68.4087 27.4607 67.61 27.8264 66.7695 27.99C62.352 28.8401 58.573 26.2962 57.8415 21.7472C57.5528 19.9571 57.6876 18.1991 58.3805 16.5117C59.606 13.5283 62.4643 11.8248 65.6851 12.0269C69.6374 12.2771 72.361 15.1611 72.6882 19.2032C72.8519 21.2275 72.5214 23.1555 71.4018 24.8975C71.2093 25.199 70.988 25.4781 70.7666 25.7893ZM69.2075 24.0441C69.3551 23.8067 69.4963 23.6207 69.5957 23.4186C70.6127 21.3141 70.6896 19.1615 69.7176 17.0442C68.7745 14.9943 67.0582 14.1314 64.8286 14.2148C62.8845 14.2886 61.2581 15.4819 60.5331 17.4035C59.9588 18.9209 59.9203 20.48 60.3117 22.0455C60.9758 24.689 63.0097 26.1582 65.7269 25.9657C66.4037 25.9176 67.0518 25.738 67.6773 25.3594C66.9972 24.5895 66.3428 23.8452 65.6755 23.0914C66.1888 22.6294 66.6668 22.1963 67.164 21.7504C67.8538 22.5203 68.5082 23.255 69.2108 24.0441H69.2075Z" fill="url(#paint3_linear_2053_170276)"/>
    <defs>
      <linearGradient id="paint0_linear_2053_170276" x1="24.09" y1="4.48828" x2="26.4031" y2="39.1682" gradientUnits="userSpaceOnUse">
        <stop stop-color="#CC8DC5"/>
        <stop offset="1" stop-color="#94428B"/>
      </linearGradient>
      <linearGradient id="paint1_linear_2053_170276" x1="2.4632" y1="4.48145" x2="8.04406" y2="38.3973" gradientUnits="userSpaceOnUse">
        <stop stop-color="#CC8DC5"/>
        <stop offset="1" stop-color="#94428B"/>
      </linearGradient>
      <linearGradient id="paint2_linear_2053_170276" x1="26.5474" y1="12.3096" x2="28.0573" y2="29.5894" gradientUnits="userSpaceOnUse">
        <stop stop-color="#CC8DC5"/>
        <stop offset="1" stop-color="#94428B"/>
      </linearGradient>
      <linearGradient id="paint3_linear_2053_170276" x1="57.6989" y1="12.0107" x2="60.181" y2="30.6342" gradientUnits="userSpaceOnUse">
        <stop stop-color="#CC8DC5"/>
        <stop offset="1" stop-color="#94428B"/>
      </linearGradient>
    </defs>
  </svg>;
}

export default Logo;
