import React from 'react';

const ScatterPlotIcon = ({color, size}) => {
	let _size = 19;
	let fillColor = "#B668AE";
	if ( color ) {
		fillColor = color;
	}
	if (size) _size = size;

	return <svg width={_size} height={_size} viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M18.5516 19H0.451388C0.201935 19 0 18.7981 0 18.5488V0.45125C0 0.201875 0.201935 0 0.451388 0C0.700841 0 0.902784 0.201875 0.902784 0.45125V17.6195C0.902784 17.8689 1.10472 18.0708 1.35417 18.0708H18.5486C18.7981 18.0708 19 18.2727 19 18.522V18.5428C19 18.7922 18.7981 18.9941 18.5486 18.9941L18.5516 19Z" fill={fillColor}/>
		<path d="M6.43246 10.4262C7.08686 10.4262 7.61736 9.89586 7.61736 9.24166C7.61736 8.58746 7.08686 8.05713 6.43246 8.05713C5.77805 8.05713 5.24756 8.58746 5.24756 9.24166C5.24756 9.89586 5.77805 10.4262 6.43246 10.4262Z" fill={fillColor}/>
		<path d="M5.702 6.90544C6.3564 6.90544 6.8869 6.37511 6.8869 5.72091C6.8869 5.06671 6.3564 4.53638 5.702 4.53638C5.04759 4.53638 4.51709 5.06671 4.51709 5.72091C4.51709 6.37511 5.04759 6.90544 5.702 6.90544Z" fill={fillColor}/>
		<path d="M10.1204 6.90544C10.7748 6.90544 11.3054 6.37511 11.3054 5.72091C11.3054 5.06671 10.7748 4.53638 10.1204 4.53638C9.46604 4.53638 8.93555 5.06671 8.93555 5.72091C8.93555 6.37511 9.46604 6.90544 10.1204 6.90544Z" fill={fillColor}/>
		<path d="M9.19418 3.3842C9.84858 3.3842 10.3791 2.85387 10.3791 2.19967C10.3791 1.54547 9.84858 1.01514 9.19418 1.01514C8.53977 1.01514 8.00928 1.54547 8.00928 2.19967C8.00928 2.85387 8.53977 3.3842 9.19418 3.3842Z" fill={fillColor}/>
		<path d="M13.5506 3.10832C14.205 3.10832 14.7355 2.57799 14.7355 1.92379C14.7355 1.26959 14.205 0.739258 13.5506 0.739258C12.8962 0.739258 12.3657 1.26959 12.3657 1.92379C12.3657 2.57799 12.8962 3.10832 13.5506 3.10832Z" fill={fillColor}/>
		<path d="M3.13022 12.8547C3.78462 12.8547 4.31512 12.3243 4.31512 11.6701C4.31512 11.0159 3.78462 10.4856 3.13022 10.4856C2.47582 10.4856 1.94531 11.0159 1.94531 11.6701C1.94531 12.3243 2.47582 12.8547 3.13022 12.8547Z" fill={fillColor}/>
		<path d="M14.3553 13.9203C15.0097 13.9203 15.5402 13.39 15.5402 12.7358C15.5402 12.0816 15.0097 11.5513 14.3553 11.5513C13.7009 11.5513 13.1704 12.0816 13.1704 12.7358C13.1704 13.39 13.7009 13.9203 14.3553 13.9203Z" fill={fillColor}/>
		<path d="M17.0965 6.90544C17.7509 6.90544 18.2814 6.37511 18.2814 5.72091C18.2814 5.06671 17.7509 4.53638 17.0965 4.53638C16.4421 4.53638 15.9116 5.06671 15.9116 5.72091C15.9116 6.37511 16.4421 6.90544 17.0965 6.90544Z" fill={fillColor}/>
		<path d="M14.3553 10.1205C15.0097 10.1205 15.5402 9.5902 15.5402 8.936C15.5402 8.2818 15.0097 7.75146 14.3553 7.75146C13.7009 7.75146 13.1704 8.2818 13.1704 8.936C13.1704 9.5902 13.7009 10.1205 14.3553 10.1205Z" fill={fillColor}/>
		<path d="M10.9461 12.3915C11.6005 12.3915 12.131 11.8612 12.131 11.207C12.131 10.5528 11.6005 10.0225 10.9461 10.0225C10.2917 10.0225 9.76123 10.5528 9.76123 11.207C9.76123 11.8612 10.2917 12.3915 10.9461 12.3915Z" fill={fillColor}/>
		<path d="M10.6849 15.9272C11.3393 15.9272 11.8698 15.3968 11.8698 14.7426C11.8698 14.0884 11.3393 13.5581 10.6849 13.5581C10.0305 13.5581 9.5 14.0884 9.5 14.7426C9.5 15.3968 10.0305 15.9272 10.6849 15.9272Z" fill={fillColor}/>
		<path d="M6.43246 17.0971C7.08686 17.0971 7.61736 16.5668 7.61736 15.9126C7.61736 15.2584 7.08686 14.728 6.43246 14.728C5.77805 14.728 5.24756 15.2584 5.24756 15.9126C5.24756 16.5668 5.77805 17.0971 6.43246 17.0971Z" fill={fillColor}/>
	</svg>


}

export default ScatterPlotIcon;
