import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  companiesLimitAtom,
  companiesListAtom, roleAtom,
  searchCompanyArrayAtom,
  sectorsListAtom,
  selectedSectorAtom, showNewCompanyModalAtom,
  showSelectCompaniesBySectorAtom,
} from "../../atoms/profileAtom";
import { APIHeaders } from "../../components/shared/helpers";
import axios from "axios";
import SelectCompanyBySector, { PublicTagStyle } from "../../components/modals/SelectCompanyBySector";
import CompaniesIcon from "../../components/shared/CompaniesIcon";
import { sortArrayWithPrefixFirst } from "../custom-comps/NewCompSetStep2";
import SectorIcon from "../../components/shared/SectorIcon";

const NewCompSetStep2 = () => {
  const [ searchCompany, setSearchCompany ] = useState( '' );
  const [ searchCompanyArray, setSearchCompanyArray ] = useRecoilState( searchCompanyArrayAtom );
  const [ companiesList, setCompanies ] = useRecoilState( companiesListAtom );
  const companiesLimit = useRecoilValue( companiesLimitAtom );
  const [ sectorsList, setSectorsListAtom ] = useRecoilState( sectorsListAtom );
  const [ showSelectCompaniesBySector, setShowSelectCompaniesBySector ] = useRecoilState( showSelectCompaniesBySectorAtom );
  const [ showNewCompanyModal, setShowNewCompanyModal ] = useRecoilState( showNewCompanyModalAtom );
  const [ selectedSector, setSelectedSector ] = useRecoilState( selectedSectorAtom );
  const [ loadedList, setLoadedList ] = useState( false );
  const [ loadedSectors, setLoadedSectors ] = useState( false );
  const [ found, setFound ] = useState( [] );
  const baseURL = process.env.REACT_APP_BASE_URL;
  const role = useRecoilValue( roleAtom );
  const isAdmin = role === 'admin';
  const navigate = useNavigate();

  const getCompaniesList = () => {
    const config = {
      method: 'get',
      url: `${baseURL}/companies`,
      headers: APIHeaders,
      withCredentials: true,
      credentials: "include",
    };
    axios( config )
      .then( ( response ) => {
        // console.log( response.data.data )
        setCompanies( response.data.data );
      } )
      .catch( ( error ) => {
        console.log( error.response.status );
        if ( error.response.status === 401 ) {
          navigate( '/user/login', {replace: true} )
        }
      } ).finally( () => {
      setLoadedList( true );
    } )
  }

  const getSectorsList = () => {
    const config = {
      method: 'get',
      url: `${baseURL}/sectors`,
      headers: APIHeaders,
      withCredentials: true,
      credentials: "include",
    };
    axios( config )
      .then( ( response ) => {
        // console.log( response.data.data )
        setSectorsListAtom( response.data.data );
      } )
      .catch( ( error ) => {
        console.log( error.response.status );
        if ( error.response.status === 401 ) {
          navigate( '/user/login', {replace: true} )
        }
      } ).finally( () => {
      setLoadedSectors( true );
    } )
  }

  useEffect( () => {
    if ( searchCompanyArray.length > companiesLimit ) {
      setSearchCompanyArray( [] );
    }
  }, [] )

  useEffect( () => {
    if ( !companiesList.length && !loadedList ) {
      getCompaniesList()
    }

    if ( !sectorsList.length && !loadedSectors ) {
      getSectorsList()
    }

  }, [] )

  const handleSearchCompany = ( e ) => {
    setSearchCompany( e.target.value );
    let _found = [];
    companiesList.map( ( comp ) => {
      if ( comp.name.toUpperCase().indexOf( e.target.value.toUpperCase() ) >= 0 ) {
        _found.push( comp )
      }
    } );
    let _sortedFound = sortArrayWithPrefixFirst(_found, e.target.value.toUpperCase())
    setFound( _sortedFound );
  }

  const handleCheckOption = ( e ) => {

    searchCompanyArray?.map( el => {
      if ( searchCompanyArray.map( el => el.id ).includes( e.target.value ) ) {
        let _filtered = searchCompanyArray.filter( item => item.id !== e.target.value );
        setSearchCompanyArray( _filtered )
      } else {
        let _temp = searchCompanyArray.concat( { id: e.target.value, name: e.target.name, isPublic: e.target.dataset.ispublic === 'true' } );
        setSearchCompanyArray( _temp );
      }
    } );

    if ( searchCompanyArray.length === 0 ) setSearchCompanyArray( [ { id: e.target.value, name: e.target.name, isPublic: e.target.dataset.ispublic === 'true' } ] )
    // console.log( searchCompanyArray )
  }

  const handleShowModal = ( e ) => {
    setSelectedSector( e.target.id );
    setShowSelectCompaniesBySector( true );
  }

  return <div className="p-4">
    <div className="d-flex justify-content-between">
      <h3 className="color-purple py-1">Search All Companies</h3>
      {!isAdmin && <div><span className="fs-16px mx-3">Don't see a company?</span><Link
        to="#"
        onClick={()=>setShowNewCompanyModal(true)}
        className="btn btn-primary fs-16px"
      ><i className="mx-2 fa fa-circle-plus"></i> Request New Company</Link></div>}
    </div>
    <p className="fs-16px">Add companies to your comp set by searching for brands below</p>
    <div className="col-12 col-md-4 py-1 h-200px w-350px fs-14px">
      {(loadedList || companiesList.length) ? <div className="position-relative">
          <input
            type="text"
            value={searchCompany}
            disabled={searchCompanyArray.length >= companiesLimit}
            onChange={handleSearchCompany}
            className="form-control"
            placeholder="e.g. Warby Parker, Shake Shack, Barry's Bootcamp" />
          {searchCompany && <i onClick={() => setSearchCompany( '' )} className="fa fa-times-circle clear-field-button"></i>}
        </div> :
        <div className="d-flex justify-content-center align-items-center text-center">
          <div className="spinner-border m-5" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>}
      {searchCompany && found && <div
        className={found.length > 3 ?
          "m-1 p-3 position-relative overflow-auto border-radius-1 h-150px" :
          "m-1 p-3 position-relative overflow-auto border-radius-1"}
        style={{
          backgroundColor: "#fff",
          borderRadius: "3px",
          border: "1px solid var(--app-component-border-color)"
        }}>
        {found.map( ( el, index ) => {
          if ( index < 300 && !searchCompanyArray.map( comp => comp.name ).includes( el.name )) return <div className="form-check mb-2" key={index}>
            <input
              className="form-check-input"
              type="checkbox"
              checked={searchCompanyArray.map( comp => comp.name ).includes( el.name )}
              disabled={searchCompanyArray.length >= companiesLimit && !searchCompanyArray.map( comp => comp.name ).includes( el.name )}
              onChange={handleCheckOption}
              id={`checkbox-${el.id}`}
              name={el.name}
              data-ispublic={el.isPublic.toString()}
              value={el.id} />
            <label
              className="form-check-label"
              htmlFor={`checkbox-${el.id}`}>{el.name}{el.isPublic && <PublicTagStyle>Public</PublicTagStyle>}
            </label>
          </div>
        } )}
        {(found.length === 0 && searchCompany.length) &&
          <div className="color-secondary">no results</div>}
      </div>}
    </div>
    <h3 className="fw-500 py-1">Browse Companies by Sector</h3>
    <p className="fs-16px">Add companies to your comp set by searching through the sector lists below</p>
    <div className="col-12 py-2 h-200px">
      {(loadedList || sectorsList.length && companiesList.length) ? <div className="col-12 w-100">
          {sectorsList.map( ( sector ) => {

            return <div key={sector.name} className="mb-3 fs-20px d-flex">
              <SectorIcon
                sector={sector.name}
              />
              <Link
                to=""
                className="text-decoration-none text-link"
                id={sector.id}
                onClick={handleShowModal}
              >{sector.name} ({sector.companies.length})
              </Link>
            </div>
          } )}
        </div> :
        <div className="d-flex col-md-4 justify-content-center align-items-center text-center">
          <div className="spinner-border m-5" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>}
    </div>
    <SelectCompanyBySector />
  </div>
}

export default NewCompSetStep2;
