import SearchIconBig from "./Icons/SearchIconBig";
import React from "react";

const NoResults = ({searchBox}) => {

  return <div className="d-flex justify-content-center align-items-center text-center mh-100vh-300">
    <div className="d-flex flex-column">
      <div className="mb-3 text-center">
        <SearchIconBig />
      </div>
      {searchBox ? <>
        <p className="fs-20px">Sorry we couldn’t find any matches for “{searchBox}”</p>
        <p className="fs-16px">Please try searching with another term</p>
      </> : <>
        <p className="fs-20px">Sorry we couldn’t find any matches</p>
        <p className="fs-16px">Please try another filter</p>
      </>}
    </div>
  </div>
}

export default NoResults;
