import React, { useState, useEffect } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  companiesLimitAtom,
  companiesListInvestorAtom, newModalTitleAtom, roleAtom,
  searchCompanyArrayAtom,
  sectorsInvestListAtom,
  selectedInvestSectorAtom, showNewCompanyModalAtom,
  showSelectCompaniesBySectorAtom
} from "../../atoms/profileAtom";
import { APIHeaders } from "../../components/shared/helpers";
import axios from "axios";
import { Link } from "react-router-dom";
import SelectCompanyByInvestor from "../../components/modals/SelectCompanyByInvestor";
import { ReactNotifications } from "react-notifications-component";
import { PublicTagStyle } from "../../components/modals/SelectCompanyBySector";
import MoneyIcon from "../../components/shared/Icons/MoneyIcon";

const InvestorDealList = () => {
  const [ companiesListInvestor, setCompaniesListInvestorAtom ] = useRecoilState( companiesListInvestorAtom );
  const [ loadedInvestList, setLoadedInvestList ] = useState( false );
  const [ loadedInvestSectors, setLoadedInvestSectors ] = useState( false );
  const [ sectorsInvestList, setSectorsInvestList ] = useRecoilState( sectorsInvestListAtom );
  const [ searchCompany, setSearchCompany ] = useState( '' );
  const [ searchCompanyArray, setSearchCompanyArray ] = useRecoilState( searchCompanyArrayAtom );
  const [ selectedSector, setSelectedSector ] = useRecoilState( selectedInvestSectorAtom );
  const [ showNewCompanyModal, setShowNewCompanyModal ] = useRecoilState( showNewCompanyModalAtom );
  const [ newModalTitle, setNewModalTitle] = useRecoilState( newModalTitleAtom );
  const [ found, setFound ] = useState( [] );
  const companiesLimit = useRecoilValue( companiesLimitAtom );
  const [ showSelectCompaniesBySector, setShowSelectCompaniesBySector ] = useRecoilState( showSelectCompaniesBySectorAtom );
  const baseURL = process.env.REACT_APP_BASE_URL;
  const role = useRecoilValue( roleAtom );
  const isAdmin = role === 'admin';

  const handleShowModal = ( e ) => {
    setSelectedSector( e.target.id );
    setShowSelectCompaniesBySector( true );
  }

  useEffect( () => {
    if ( searchCompanyArray.length > companiesLimit ) {
      setSearchCompanyArray( [] );
    }
  }, [ ] )

  const handleSearchCompany = ( e ) => {
    setSearchCompany( e.target.value );
    let _found = [];
    companiesListInvestor.map( ( comp ) => {
      if ( comp.name.toUpperCase().indexOf( e.target.value.toUpperCase() ) >= 0 ) {
        _found.push( comp )
      }
    } );
    setFound( _found );
  }

  const getCompaniesListInvestor = () => {
    const config = {
      method: 'get',
      url: `${baseURL}/investors`,
      headers: APIHeaders,
      withCredentials: true,
      credentials: "include",
    };
    axios( config )
      .then( ( response ) => {
        // console.log( response.data.data )
        setCompaniesListInvestorAtom( response.data.data );
      } )
      .catch( ( error ) => {
        console.log( error.response.status );
      } ).finally( () => {
      setLoadedInvestList( true );
    } )
  }

  const getSectorsList = () => {
    const config = {
      method: 'get',
      url: `${baseURL}/investor_types`,
      headers: APIHeaders,
      withCredentials: true,
      credentials: "include",
    };
    axios( config )
      .then( ( response ) => {
        // console.log( response.data.data )
        setSectorsInvestList( response.data.data );
      } )
      .catch( ( error ) => {
        console.log( error.response.status );
      } ).finally( () => {
      setLoadedInvestSectors( true );
    } )
  }

  const handleCheckOption = ( e ) => {

    searchCompanyArray?.map( el => {
      if ( searchCompanyArray.map( el => el.id ).includes( e.target.value ) ) {
        let _filtered = searchCompanyArray.filter( item => item.id !== e.target.value );
        setSearchCompanyArray( _filtered )
      } else {
        let _temp = searchCompanyArray.concat( { id: e.target.value, name: e.target.name, isPublic:e.target.isPublic } );
        setSearchCompanyArray( _temp );
      }
    } );

    if ( searchCompanyArray.length === 0 ) setSearchCompanyArray( [ { id: e.target.value, name: e.target.name, isPublic:e.target.isPublic } ] )
    // console.log( searchCompanyArray )
  }

  useEffect( () => {
    if ( !companiesListInvestor.length && !loadedInvestList ) {
      getCompaniesListInvestor()
    }

    if ( !sectorsInvestList.length && !loadedInvestSectors ) {
      getSectorsList()
    }

  }, [] )

  return <div className="p-4">
    <h3 className="color-purple mb-3 py-2">Choose Investment Firm</h3>
    <div className="d-flex justify-content-between">
      <h5>Add an investment firm to your list</h5>
      {!isAdmin && <div><span className="fs-16px mx-3">Don't see a firm?</span><Link
        to="#"
        onClick={()=> {
          setShowNewCompanyModal(true);
          setNewModalTitle('Firm');
        }}
        className="btn btn-primary fs-16px"
      ><i className="mx-2 fa fa-circle-plus"></i> Request New Firm</Link></div>}
    </div>
    <div className="col-12 col-md-4 py-1 h-200px w-300px fs-14px">
      {(loadedInvestList || companiesListInvestor.length) ? <div className="position-relative">
          <input
            type="text"
            value={searchCompany}
            disabled={searchCompanyArray.length >= companiesLimit}
            onChange={handleSearchCompany}
            className="form-control"
            placeholder="Enter Company Name" />
          {searchCompany && <i onClick={() => setSearchCompany( '' )} className="fa fa-times-circle clear-field-button"></i>}
        </div> :
        <div className="d-flex justify-content-center align-items-center text-center">
          <div className="spinner-border m-5" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>}
      {searchCompany && found && <div
        className={found.length > 3 ?
          "m-1 p-3 position-relative overflow-auto border-radius-1 h-150px" :
          "m-1 p-3 position-relative overflow-auto border-radius-1"}
        style={{
          backgroundColor: "#fff",
          borderRadius: "3px",
          border: "1px solid var(--app-component-border-color)"
        }}>
        {found.map( ( el, index ) => {
          if ( index < 300 && !searchCompanyArray.map( comp => comp.name ).includes( el.name )) return <div className="form-check mb-2" key={index}>
            <input
              className="form-check-input"
              type="checkbox"
              checked={searchCompanyArray.map( comp => comp.name ).includes( el.name )}
              disabled={searchCompanyArray.length >= companiesLimit && !searchCompanyArray.map( comp => comp.name ).includes( el.name )}
              onChange={handleCheckOption}
              id={`checkbox-${el.id}`}
              name={el.name}
              value={el.id} />
            <label
              className="form-check-label"
              htmlFor={`checkbox-${el.id}`}>{el.name}{el.isPublic && <PublicTagStyle>public</PublicTagStyle>}
            </label>
          </div>
        } )}
        {(found.length === 0 && searchCompany.length) &&
          <div className="color-secondary">no results</div>}
      </div>}
    </div>

    <h3 className="color-purple mb-3 py-2">Browse Firms by Type</h3>
    <h5>Add an investment firm to your list from the categories below</h5>
    <div className="col-12 py-2 h-200px">
      {(loadedInvestList || sectorsInvestList.length > 0 && companiesListInvestor.length > 0) ? <div className="col-12 w-100">
          {sectorsInvestList.map( ( sector ) => {

            if ( sector.investors.length ) {
            return <div key={sector.name} className="mb-3 fs-20px">
              <MoneyIcon />
              <Link
                to=""
                className="text-decoration-none text-link mx-1"
                // style={{color: "#42663A"}}
                id={sector.id}
                onClick={handleShowModal}
              > {sector.name} ({sector.investors.length})
              </Link>
            </div>
          }
          } )}
        </div> :
        <div className="d-flex col-md-4 justify-content-center align-items-center text-center">
          <div className="spinner-border m-5" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>}
    </div>
    <SelectCompanyByInvestor />
    <ReactNotifications />
  </div>
}

export default InvestorDealList;
