import React from 'react';

const CustomCompIcon = ({color, size}) => {
  let _color = color ?? 'currentColor';

  return <svg width="21" height="19" viewBox="0 0 21 19" fill={_color} xmlns="http://www.w3.org/2000/svg">
      <path d="M5.39997 18.6538L4.34615 17.6L10.9 11.0308L14.4 14.5308L19.575 9.35577L20.6442 10.425L14.4 16.6538L10.9 13.1538L5.39997 18.6538ZM2.3077 17.5H0.5V0.5H17.5V6.99997H1.99998V16H2.3077V17.5ZM1.99998 5.5H16V1.99997H1.99998V5.5Z" fill={_color} />
    </svg>;
}

export default CustomCompIcon;
