export const tilesContent = [
  {
    title: 'Store Rollout & Evaluation',
    icon: 'PHN2ZyB3aWR0aD0iMzAiIGhlaWdodD0iMzAiIHZpZXdCb3g9IjAgMCAzMCAzMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTYuODk5NjYgMTkuNDIzVjE4LjUzODRWMTUuMjMwN0g4LjY5OTczVjE3LjY1MzhIMjEuMzAwMlYxNS4yMzA3SDIzLjEwMDNWMTguNTM4NFYxOS40MjNIMjIuMjAwM0g3Ljc5OTY5SDYuODk5NjZaIiBmaWxsPSIjNDc4RjgzIi8+CjxwYXRoIGQ9Ik02LjUgMTIuNUw4IDVIMjUuNUwyNyAxMi41SDYuNVoiIGZpbGw9IiNCMUQ4RDMiLz4KPHBhdGggZD0iTTQuMTk5NTYgNC4zODQ2MlYzLjVDMy45OTE1NyAzLjUwMDEyIDMuNSAzLjUgMy41IDMuNUMzLjUgMy41IDMuMzU4MjkgNC4wMTA3NyAzLjMxNzUzIDQuMjExMjNMNC4xOTk1NiA0LjM4NDYyWk0yNS44MDA0IDQuMzg0NjJMMjYuNjgyNSA0LjIxMTIzQzI2LjY0MTcgNC4wMTA3NyAyNi41IDMuNSAyNi41IDMuNUMyNi41IDMuNSAyNi4wMDg0IDMuNTAwMTIgMjUuODAwNCAzLjVWNC4zODQ2MlpNMjcuNjAwNSAxMy4yMzA4VjE0LjExNTRIMjguNDczOEwyOC40ODI1IDEzLjA1NzRMMjcuNjAwNSAxMy4yMzA4Wk0yLjM5OTQ5IDEzLjIzMDhMMS41MTc0NiAxMy4wNTc0VjE0LjExNTRIMi4zOTk0OVYxMy4yMzA4Wk00LjE5OTU2IDUuMjY5MjNIMjUuODAwNFYzLjVINC4xOTk1NlY1LjI2OTIzWk0yNC45MTg0IDQuNTU4TDI2LjcxODUgMTMuNDA0MkwyOC40ODI1IDEzLjA1NzRMMjYuNjgyNSA0LjIxMTIzTDI0LjkxODQgNC41NThaTTI3LjYwMDUgMTIuMzQ2MkgyLjM5OTQ5VjE0LjExNTRIMjcuNjAwNVYxMi4zNDYyWk0zLjI4MTUzIDEzLjQwNDJMNS4wODE2IDQuNTU4TDMuMzE3NTMgNC4yMTEyM0wxLjUxNzQ2IDEzLjA1NzRMMy4yODE1MyAxMy40MDQyWiIgZmlsbD0iIzQ3OEY4MyIvPgo8cGF0aCBvcGFjaXR5PSIwLjUiIGQ9Ik0yNi43MDA1IDI2LjQ5OTlIMy4yOTk1NlYyNS42MTUzVjI0LjczMDdWMTUuMjMwN0g1LjA5OTYzVjI0LjczMDdIMjQuOTAwNFYxNS4yMzA3SDI2LjcwMDVWMjQuNzMwN1YyNS42MTUzVjI2LjQ5OTlaIiBmaWxsPSIjNjZCMzlDIi8+Cjwvc3ZnPgo=',
    color: '#a0e0d8',
    features: [ ],
    links: [
      {
        url: '/market-tracker/new-market-tracker',
        label: 'Help me pick new sites',
        content: "<p>Here you can </p><p>1) <u>via the <strong>Market Performance Map</strong> function</u>, look at any trade area to understand the co-tenancy of that area and view your competitor locations (and understand how those stores are performing) to assess whether this is the right trade area for your brand </p><p>and</p><p>2) <u>via the <strong>Market Entry Timeline</strong> function</u>, track your and your peers’ market entry opening cadence & sequencing to decide when and in what order you want to enter new markets</p><p><strong>Enter the brands of interest and see the Map or Timeline. And once you save it to your Market Trackers Library, it will automatically update for new openings and market entry events so you can monitor how that trade area evolves over time.</strong></p>",
        title: 'Welcome to Market Tracker!'
      },
      {
        url: '/market-tracker/new-market-tracker',
        label: 'Where are my competitors?',
        content: "<p>Here you can </p><p>1) <u>via the <strong>Market Tracker</strong> function, map your locations and your competitor locations</u> (and understand how stores are performing)</p><p>and</p><p>2) <u>via the <strong>Market Entry Timeline</strong> function, track your and your peers’ market entry opening cadence & sequencing</u></p><p><strong>Enter the brands of interest and see the Map or Timeline. And once you save it to your Market Trackers Library, it will automatically update for new openings and market entry events.</strong> </p>",
        title: 'Welcome to Market Tracker!'
      },
      {
        url: '/market-tracker/new-market-tracker',
        label: 'Tell me where my competitors are',
        content: "<p>Here you can </p><p>1) <u>via the <strong>Market Performance Map</strong> function</u>, observe any trade area to understand the co-tenancy of that area and view your competitor locations (and understand how those stores are performing) to assess whether this is the right trade area for your brand</p><p>and</p><p>2) <u>via the <strong>Market Entry Timeline</strong> function</u>, track your and your peers’ market entry opening cadence & sequencing to decide when and in what order you want to enter new markets</p><p><strong>Enter the brands of interest and see the Map or Timeline. And once you save it to your Market Trackers Library, it will automatically update for new openings and market entry events so you can monitor how that trade area evolves over time.</strong></p>",
        title: 'Welcome to Market Tracker!'
      },
      {
        url: '/market-tracker/new-market-tracker',
        label: 'Help me decide what market to go to next',
        content: "<p>Here you can </p><p>1) <u>via the <strong>Market Entry Timeline</strong> function</u>, track your and your peers’ market entry opening cadence & sequencing to decide when and in what order you want to enter new markets</p><p>and</p><p>2) <u>via the <strong>Market Performance Map</strong> function</u>, observe any trade area to understand the co-tenancy of that area and view your competitor locations (and understand how those stores are performing) to assess whether this is the right trade area for your brand</p><p><strong>Enter the brands of interest and see the Map or Timeline. And once you save it to your Market Trackers Library, it will automatically update for new openings and market entry events so you can monitor how that trade area evolves over time.</strong></p>",
        title: 'Welcome to Market Tracker!'
      },

      {
        url: '/new-comp-set',
        label: 'How are my competitor stores rated compared to mine?',
        content: "<p>Here you can create charts, tables, scatter plots, and lists with metrics about brands, stores, and investors.</p><p>To understand competitor store performance for the company/companies of interest, create one of the following depending on your goals:</p><ul><li> a <u>Comps Table</u> to see store performance (ratings, review count per store) for desired brands in columns</li><li>a <u>Time Series</u> chart to see a brand’s store rating and review count per store over time</li><li>a <u>Scatter Plot</u> to see 2 unit economics metrics (X, Y style) for multiple brands, e.g. AUV vs. store rating, total funding vs. review count per store</li></ul><p><strong>Once you create the chart and save it to your Custom Comps Library, it will automatically update for the latest company performance.</strong></p>",
        title: "Welcome to Custom Comps!"
      },
      {
        url: '/market-tracker/new-market-tracker',
        label: 'How fast did my competitors grow?',
        content: "<p>Here you can </p><p>1) <u>via the <strong>Market Entry Timeline</strong> function, track your and your peers’ market entry opening cadence & sequencing</u></p><p>and</p><p>2) <u>via the <strong>Market Tracker</strong> function, map your locations and your competitor locations</u> (and understand how stores are performing)</p><p><strong>Enter the brands of interest and see the Map or Timeline. And once you save it to your Market Trackers Library, it will automatically update for new openings and market entry events.</strong></p>",
        title: 'Welcome to Market Tracker!'
      },
      {
        url: '/new-comp-set',
        label: 'How are competitors stores performing?',
        content: "<p>Here you can create charts, tables, scatter plots, and lists with metrics about brands, stores, and investors.</p><p>To understand your competitor performance, create one of the following depending on your goals:</p><ul><li> a <u>Comps Table</u> to see aggregate rating and review count of a brand’s stores</li><li>a <u>Time Series</u> chart to see a brand’s store rating and/or review count evolution over time</li><li>a <u>Scatter Plot</u> to see store rating or review count and another metric (X,Y style)</li><li>a <u>Store List</u> to see the individual ratings and review counts of each store of a single brand</li></ul><p><strong>Once you create the chart or list and save it to your Custom Comps Library, it will automatically update for the latest ratings, reviews, and store openings.</strong></p>",
        title: "Welcome to Custom Comps!"
      },
      {
        url: '/market-tracker/new-market-tracker',
        label: 'Should I sign this lease?',
        content: "<p>Here you can <u>via the <strong>Market Tracker</strong> function, map a trade area and understand how stores in that trade area are performing. View density of desired brands, their ratings and review counts – and versus national averages for those - to determine whether the trade area has the demand and co-tenancy profile for your potential new location.</u></p><p><strong>Enter the brands of interest and see the Map. And once you save it to your Market Trackers Library, it will automatically update for new openings, so you can monitor how that trade area evolves over time.</strong></p>",
        title: 'Welcome to Market Tracker!'
      },

    ]
  },
  {
    title: 'Company Diligence',
    icon: 'PHN2ZyB3aWR0aD0iMzAiIGhlaWdodD0iMzAiIHZpZXdCb3g9IjAgMCAzMCAzMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik05LjI1IDEzLjA4MDFMMTIuMzA1NSAxMC4wMjQ1TDE3LjY3MTggMTUuMzkwOEwyNi43MjczIDYuMzM1MjZWNS4wMjE0N0wyNi43NSA1VjI2LjVIOS4yNVYxMy4wODAxWiIgZmlsbD0iI0IxRDhEMyIvPgo8cGF0aCBkPSJNMy4yNSAyNi40OTk5VjI0LjI5NDFMNS4yNjIyOCAyMi4yODE4VjI2LjQ5OTlIMy4yNVpNOC42MTYyNiAyNi40OTk5VjE4LjkyNzlMMTAuNjI4NSAxNi45MTU2VjI2LjQ5OTlIOC42MTYyNlpNMTMuOTgyNSAyNi40OTk5VjE2LjkxNTZMMTUuOTk0OCAxOC45NjE0VjI2LjQ5OTlIMTMuOTgyNVpNMTkuMzQ4OCAyNi40OTk5VjE4Ljk2MTRMMjEuMzYxMSAxNi45NDkxVjI2LjQ5OTlIMTkuMzQ4OFpNMjQuNzE1MSAyNi40OTk5VjEzLjU2MTZMMjYuNzI3MyAxMS41NDkzVjI2LjQ5OTlIMjQuNzE1MVoiIGZpbGw9IiM2NkIzOUMiLz4KPHBhdGggb3BhY2l0eT0iMC41IiBkPSJNMy4yNSAxNi4yNDQ4VjE5LjA4MDFMMTIuMzA1NSAxMC4wMjQ1TDE3LjY3MTggMTUuMzkwOEwyNi43MjczIDYuMzM1MjZWMy41TDE3LjY3MTggMTIuNTU1NUwxMi4zMDU1IDcuMTg5MjdMMy4yNSAxNi4yNDQ4WiIgZmlsbD0iIzY2QjM5QyIvPgo8L3N2Zz4K',
    color: '#e3cee1',
    features: [  ],
    links: [

      {
        url: '/new-comp-set',
        label: 'What are good unit economics?',
        content: "<p>Here you can create charts, tables, scatter plots, and lists with metrics about brands, stores, and investors.</p><p>To understand unit economics for the company/companies of interest, create one of the following depending on your goals:</p><ul><li>a <u>Comps Table</u> to see unit economics composition for desired brands in columns (AUV, COGS % Sales, Buildout Costs, etc.)</li><li>a <u>Time Series</u> chart to see a brand’s unit economics evolution over time</li><li>a <u>Scatter Plot</u> to see 2 unit economics metrics (X, Y style) for multiple brands, e.g. AUV vs. Buildout, 4-wall EBITDA Margin vs. AUV, etc</li></ul><p><strong>Once you create the chart and save it to your Custom Comps Library, it will automatically update for the latest company performance.</strong></p>",
        title: 'Welcome to Custom Comps!'
      },
      {
        url: '/new-comp-set',
        label: 'What I should put in my Board Deck?',
        content: "<p>Here you can create charts, tables, scatter plots, and lists with metrics about brands, stores, and investors.</p><p>To put together a comprehensive Board Deck, see here some examples of what you can create:</p><ul><li>a <u>Comps Table</u> to see unit economics composition for desired brands in columns (AUV, COGS % Sales, Buildout Costs, etc.)</li><li>a <u>Time Series</u> chart to see a brand’s unit economics evolution over time</li><li>a <u>Scatter Plot</u> to see 2 unit economics metrics (X, Y style) for multiple brands, e.g. AUV vs. Buildout, 4-wall EBITDA Margin vs. AUV, etc</li><li>Go to <a href='/market-tracker'>Market Tracker</a> to understand the footprint of your company and competitive set of interest to generate a map for the deck</li></ul><p><strong>Once you create the chart and save it to your Custom Comps Library, it will automatically update for the latest company performance so you can seamless update your Board materials with no effort.</strong></p>",
        title: 'Welcome to Custom Comps!'
      },
      {
        url: '/market-tracker/new-market-tracker',
        label: 'What is this company’s store footprint?',
        content: "<p>Here you can</p><p>1) <u>via the <strong>Market Tracker</strong> function, map your locations and your competitor locations</u> (and understand how stores are performing)</p><p>and</p><p>2) <u>via the <strong>Market Entry Timeline</strong> function, track your and your peers’ market entry opening cadence & sequencing</u></p><p><strong>Enter the brands of interest and see the Map or Timeline. And once you save it to your Market Trackers Library, it will automatically update for new openings and market entry events. </strong></p>",
        title: "Welcome to Market Tracker!"
      },
      {
        url: '/new-comp-set',
        label: 'Please provide me with 4-wall unit economic benchmarks',
        content: "<p>Here you can create charts, tables, scatter plots, and lists with metrics about brands, stores, and investors.</p><p>To benchmark unit economic performance, see here some examples of what you can create:</p><ul><li>a <u>Comps Table</u> to see unit economics composition for desired brands in columns (AUV, COGS % Sales, Buildout Costs, etc.)</li><li>a <u>Time Series</u> chart to see a brand’s unit economics evolution over time</li><li>a <u>Scatter Plot</u> to see 2 unit economics metrics (X, Y style) for multiple brands, e.g. AUV vs. Buildout, 4-wall EBITDA Margin vs. AUV, etc</li><li>Go to <a href='/market-tracker'>Market Tracker</a> to understand the footprint of the company and competitive set of interest</li></ul><p><strong>Once you create the chart and save it to your Custom Comps Library, it will automatically update for the latest company performance.</strong></p>",
        title: 'Welcome to Custom Comps!'
      },
      {
        url: '/new-comp-set',
        label: 'Help me assess an investment opportunity',
        content: "<p>Here you can create charts, tables, scatter plots, and lists with metrics about brands, stores, and investors.</p><p>To assess a potential investment into a retail company, see here some examples of what you can create and do:</p><ul><li>a <u>Comps Table</u> to see unit economics composition for desired brands in columns (AUV, COGS % Sales, Buildout Costs, etc.)</li><li>a <u>Time Series</u> chart to see a brand’s unit economics evolution over time</li><li>a <u>Scatter Plot</u> to see 2 unit economics metrics (X, Y style) for multiple brands, e.g. AUV vs. Buildout, 4-wall EBITDA Margin vs. AUV, etc</li><li>Go to <a href='/market-tracker'>Market Tracker</a>to understand the footprint of the company and competitive set of interest</li></ul><p><strong>Once you create the chart and save it to your Custom Comps Library, it will automatically update for the latest company performance.</strong></p>",
        title: 'Welcome to Custom Comps!'
      },
      {
        url: '/new-comp-set',
        label: 'Help me put together my investment memo',
        content: "<p>Here you can create charts, tables, scatter plots, and lists with metrics about brands, stores, and investors.</p><p>To put together a comprehensive investment memo, see here some examples of what you can create:</p><ul><li>a <u>Comps Table</u> to see unit economics composition for desired brands in columns (AUV, COGS % Sales, Buildout Costs, etc.)</li><li>a <u>Time Series</u> chart to see a brand’s unit economics evolution over time</li><li>a <u>Scatter Plot</u> to see 2 unit economics metrics (X, Y style) for multiple brands, e.g. AUV vs. Buildout, 4-wall EBITDA Margin vs. AUV, etc</li><li>Go to <a href='/market-tracker'>Market Tracker</a> to understand the footprint of the company and competitive set of interest</li></ul><p><strong>Once you create the chart and save it to your Custom Comps Library, it will automatically update for the latest company performance so you can update investor memos with no effort.</strong></p>",
        title: 'Welcome to Custom Comps!'
      },

      {
        url: '/new-comp-set',
        label: 'Are company employees satisfied?',
        content: "<p>Here you can create charts, tables, scatter plots, and lists with metrics about brands, stores, and investors.</p><p>To understand employee satisfaction at the company/companies of interest, create one of the following depending on your goals:</p><ul><li>a <u>Comps Table</u> to see employee satisfaction and/or CEO approval for desired brands in columns</li><li>a <u>Scatter Plot</u> to see 2 metrics (X, Y style) for multiple brands, e.g. Glassdoor ratings vs. Store Count</li></ul><p><strong>Once you create the chart and save it to your Custom Comps Library, it will automatically update for the latest employee sentiment data.</strong></p>",
        title: 'Welcome to Custom Comps!'
      },
      {
        url: '/new-comp-set',
        label: 'How strong is this company’s brand?',
        content: "<p>Here you can create charts, tables, scatter plots, and lists with metrics about brands, stores, and investors.</p><p>To understand brand resonance for the company/companies of interest, create one of the following depending on your goals:</p><ul><li>a <u>Comps Table</u> to see social following in columns (Instagram followers, TikTok followers etc.)</li><li>a <u>Scatter Plot</u> to see up to 2 social metrics (X, Y style) for multiple brands, e.g. AUV vs. IG following, IG following vs. TikTok following, etc.</li></ul><p><strong>Once you create the chart and save it to your Custom Comps Library, it will automatically update for the latest social following data.</strong></p>",
        title: 'Welcome to Custom Comps!'
      },
      {
        url: '/new-comp-set',
        label: 'How fast did this brand open stores?',
        content: "<p>Here you can create charts, tables, scatter plots, and lists with metrics about brands, stores, and investors.</p><p>To understand the store count growth for the company/companies of interest, create one of the following:</p><ul><li>a <u>Time Series Chart</u> to see opening cadence over time</li><li>a <u>Store List</u> to see all stores for a given brand and its opening dates</li></ul><p><strong>Once you create the chart and save it to your Custom Comps Library, it will automatically update for the latest store openings.</strong></p>",
        title: 'Welcome to Custom Comps!'
      },
    ]
  },
  {
    title: 'Fundraising & Investors',
    icon: 'PHN2ZyB3aWR0aD0iMzAiIGhlaWdodD0iMzAiIHZpZXdCb3g9IjAgMCAzMCAzMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTIuOTk5ODYgNEgxOS45OTk5VjI2SDIuOTk5ODZWNFoiIGZpbGw9IiNCMUQ4RDMiLz4KPHBhdGggZD0iTTIuMjM0MDEgMjYuNVYzLjVIMjUuMjM0VjYuNjI3MjlIMjMuMjA0NlY1LjUyOTM4SDQuMjYzMzlWMjQuNDcwNkgyMy4yMDQ2VjIzLjM3MjdIMjUuMjM0VjI2LjVIMi4yMzQwMVoiIGZpbGw9IiM2NkIzOUMiLz4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xNC4yMzY2IDguOTExODdIMjcuNzY2VjIxLjA4ODNIMTQuMjM2NlY4LjkxMTg3Wk0yMy4yMzM5IDE3LjAwMDFDMjQuMzM4NSAxNy4wMDAxIDI1LjIzMzkgMTYuMTA0NyAyNS4yMzM5IDE1LjAwMDFDMjUuMjMzOSAxMy44OTU1IDI0LjMzODUgMTMuMDAwMSAyMy4yMzM5IDEzLjAwMDFDMjIuMTI5MyAxMy4wMDAxIDIxLjIzMzkgMTMuODk1NSAyMS4yMzM5IDE1LjAwMDFDMjEuMjMzOSAxNi4xMDQ3IDIyLjEyOTMgMTcuMDAwMSAyMy4yMzM5IDE3LjAwMDFaIiBmaWxsPSIjNjZCMzlDIi8+Cjwvc3ZnPgo=',
    color: '#e3cee1',
    features: [ ],
    links: [
      {
        url: '/new-comp-set',
        label: 'What firms are investing in retail?',
        content: "<p>Here you can create charts, tables, scatter plots, and lists with metrics about brands, stores, and investors.</p><p>To see the funding activity in retail, create a <u>Firm Retail Investments List</u> to see what firms are investing in retail companies</p><p><strong>Once you create the list and save it to your Custom Comps Library, it will automatically update for the latest retail investments of the firms of interest.</strong></p>",
        title: "Welcome to Custom Comps!"
      },
      {
        url: '/new-comp-set',
        label: 'Tell me how to think about valuation',
        content: "<p>Here you can create charts, tables, scatter plots, and lists with metrics about brands, stores, and investors.</p><p>To understand how retail companies are valued, create one of the following: </p><ul><li>a <u>Company Fundraising History List </u> to see the funding history of a single company & the valuation of each financing</li><li>a <u>Sector Investment List</u> to see the funding rounds in the subsector(s) of interest</li><li> a <u>Comps Table</u>, <u>Time Series Chart</u>, or <u>Scatter plot</u> to see how public retail companies are valued</li></ul><p><strong>Once you create the list and save it to your Custom Comps Library, it will automatically update for the latest funding events.</strong></p>",
        title: "Welcome to Custom Comps!"
      },
      {
        url: '/new-comp-set',
        label: 'What are the latest retail valuations?',
        content: "<p>Here you can create charts, tables, scatter plots, and lists with metrics about brands, stores, and investors.</p><p>To understand valuation for the company/companies of interest, create one of the following:</p><ul><li>Create a <u>Comps Table</u> to see valuation info for public companies (Market Cap, EV/Sales, etc.) and private companies</li><li>Create a <u>Company Fundraising History List</u> to see valuations at various financing's for a single private company (where available)</li><li>Create a <u>Sector Investment List</u> to see valuations for all private companies in a given subsector(s) (where available)</li></ul><p><strong>Once you create the list and save it to your Custom Comps Library, it will automatically update for the latest funding events in the desired retail subsector(s).</strong></p>",
        title: "Welcome to Custom Comps!"
      },
      {
        url: '/new-comp-set',
        label: 'Tell me who is investing in fast growth retail',
        content: "<p>Here you can create charts, tables, scatter plots, and lists with metrics about brands, stores, and investors.</p><p>To understand the fundraising history for the company of interest, create one of the following:</p><ul><li>Create a <u>Company Fundraising History List</u> to see the funding history of a single company</li><li>Create a <u>Sector Investment List</u> to see the funding rounds in the subsector(s) of interest</li></ul><p><strong>Once you create the list and save it to your Custom Comps Library, it will automatically update for the latest funding events.</strong></p>",
        title: "Welcome to Custom Comps!"
      },
      {
        url: '/new-comp-set',
        label: 'How much capital did my competitors raise?',
        content: "<p>Here you can create charts, tables, scatter plots, and lists with metrics about brands, stores, and investors.</p><p>To understand the fundraising history for the company of interest, create one of the following: </p><ul><li>Create a <u>Company Fundraising History List </u> to see the funding history of a single company</li><li> Create a <u>Sector Investment List</u> to see the funding rounds in the subsector(s) of interest</li></ul><p><strong>Once you create the list and save it to your Custom Comps Library, it will automatically update for the latest funding events.</strong></p>",
        title: "Welcome to Custom Comps!"
      },

      {
        url: '/new-comp-set',
        label: 'What is the latest M&A activity in the category',
        content: "<p>Here you can create charts, tables, scatter plots, and lists with metrics about brands, stores, and investors.</p><p>To understand M&A activity in retail, create one of the following: </p><ul><li> Create a <u>Company Fundraising History List </u> to see the funding history and acquisition history of a single company</li><li> Create a <u>Sector Investment List</u> to see the funding rounds in the subsector(s) of interest</li></ul><p><strong>Once you create the list and save it to your Custom Comps Library, it will automatically update for the latest funding events.</strong></p>",
        title: "Welcome to Custom Comps!"
      },
      {
        url: '/new-comp-set',
        label: 'Tell me how much money a company raised',
        content: "<p>Here you can create charts, tables, scatter plots, and lists with metrics about brands, stores, and investors.</p><p>To understand the fundraising history for the company of interest, create one of the following:  </p><ul><li> Create a <u>Company Fundraising History List </u> to see the funding history and acquisition history of a single company</li><li> Create a <u>Sector Investment List</u>  to see the funding rounds in the subsector(s) of interest</li></ul><p><strong>Once you create the list and save it to your Custom Comps Library, it will automatically update for the latest funding events.</strong></p>",
        title: "Welcome to Custom Comps!"
      },
      {
        url: '/new-comp-set',
        label: 'What are the latest retail funding rounds?',
        content: "<p>Here you can create charts, tables, scatter plots, and lists with metrics about brands, stores, and investors.</p><p>To understand valuation for the company/companies of interest, create one of the following:</p><ul><li>Create a <u>Company Fundraising History List</u> to see the funding history of a single company</li><li>Create a <u>Sector Investment List</u> to see the funding rounds in the subsector(s) of interest</li></ul><p><strong>Once you create the list and save it to your Custom Comps Library, it will automatically update for the latest funding events.</strong></p>",
        title: "Welcome to Custom Comps!"
      },
    ]
  },
];
