
const GlassDoorIcon = () => {

  return <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.33656 17.5H10.6635C11.3079 17.5 11.9259 17.244 12.3815 16.7884C12.8371 16.3328 13.0931 15.7148 13.0931 15.0704V4.98375H10.6635V15.0704H0.906982C0.906982 15.7148 1.16296 16.3328 1.61859 16.7884C2.07422 17.244 2.69219 17.5 3.33656 17.5Z"/>
    <path d="M10.6635 0.5H3.33656C2.69219 0.5 2.07422 0.755973 1.61859 1.21161C1.16296 1.66724 0.906982 2.28522 0.906982 2.92958V13.0163H3.33656V2.92958H13.0931C13.0931 2.28522 12.8371 1.66724 12.3815 1.21161C11.9259 0.755973 11.3079 0.5 10.6635 0.5Z"/>
  </svg>;
}

export default GlassDoorIcon;
