import styled from "styled-components";
import { useRecoilState } from "recoil";
import { modalContentTipsAtom, modalTipsLinkAtom, showModalTipsAtom, tipsModalTitle } from '../../atoms/profileAtom';
import { Link } from "react-router-dom";

const ModalWrapper = styled.div`
  position: fixed;
  width: 90%;
  max-width: 680px;
  //height: 70%;
  min-height: 150px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1411;
  background-color: #F9F9F9;
  border: 1px solid #bebebe;
  border-radius: 8px;
  padding: 0 32px 32px 32px;
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background-color: #101D1B;
  opacity: .8;
  z-index: 1111;
  overflow: hidden;
`;

const ModalContent = styled.div`

  .heading {
    border-bottom: 1px solid #EBEBEB;
    position: relative;
    text-align: center;
    color: #1E1E1E;

    h2 {
      font-size: 24px;
      font-weight: 400;
      line-height: 70px;
      margin-bottom: 0;
    }

    .close-button {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: auto;
      right: 0;

      i {
        font-size: 20px;
      }
    }
  }

  .content {
    margin-top: 24px;
    color: #656A6B;
    font-size: 16px;

    p {
      font-size: 16px;
      font-weight: 300;
    }

    strong {
      //font-size: 18px;
      font-weight: 400;
    }

    ul {
      list-style: decimal;

      li {
        font-size: 16px;
        font-weight: 300;
        margin-bottom: 5px;
      }
    }
  }

`;

const TipsModal = () => {
  const [ title, setTitle ] = useRecoilState( tipsModalTitle );
  const [ showModalTips, setShowModalTips ] = useRecoilState( showModalTipsAtom );
  const [ modalContent, setModalContent ] = useRecoilState( modalContentTipsAtom );
  const [ modalTipsLink, setModalTipsLink ] = useRecoilState( modalTipsLinkAtom );

  if ( !showModalTips ) return <></>;

  return <div>
    <ModalOverlay />
    <ModalWrapper>
      <ModalContent>
        <div className="heading">
          <h2>{title}</h2>
          <Link to="#"
                onClick={() => setShowModalTips( false )}
                className='close-button typical-gray'
          >
            <i className="fa fa-times"></i>
          </Link>
        </div>
        <div
          className="content"
          dangerouslySetInnerHTML={{ __html: modalContent }}
        >
        </div>
        <div className="d-flex justify-content-end">
          <Link
            to="#"
            className="fs-16px btn btn-outline-secondary"
            onClick={() => setShowModalTips( false )}
          >Back to Home</Link>
          <div className="w-25px"></div>
          <Link
            to={modalTipsLink}
            onClick={() => setShowModalTips( false )}
            className="fs-16px btn btn-primary"
          >Try It Here</Link>
        </div>
      </ModalContent>
    </ModalWrapper>
  </div>
}

export default TipsModal;
