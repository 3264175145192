import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useRecoilState } from "recoil";
import {
	companiesBySectorListAtom,
	companiesLimitAtom,
	companiesListAtom,
	searchCompanyArrayAtom,
	selectedSectorAtom,
	showSelectCompaniesBySectorAtom
} from "../../atoms/profileAtom";
import { APIHeaders } from "../shared/helpers";
import axios from "axios";
import styled from "styled-components";

export const PublicTagStyle = styled.span`
  color: var(--bs-secondary);
  margin-left: 5px;
  font-size: 11px;
  border: 1px solid #B668AE;
  border-radius: 5px;
  padding: 0 3px;
  background: #fff;
`;

const SelectCompanyBySector = () => {
	const [ showSelectCompaniesBySector, setShowSelectCompaniesBySector ] = useRecoilState( showSelectCompaniesBySectorAtom );
	const [ selectedSector, setSelectedSector ] = useRecoilState( selectedSectorAtom );
	const [ companiesList, setCompaniesListAtom ] = useRecoilState( companiesListAtom );
	const [ companiesLimit, setCompaniesLimit ] = useRecoilState( companiesLimitAtom );
	const [ searchCompanyArray, setSearchCompanyArray ] = useRecoilState( searchCompanyArrayAtom );
	const [ sectorsList, setSectorList ] = useRecoilState( companiesBySectorListAtom );
	const [ sectorName, setSectorName ] = useState( '' );
  const [ sortedFilteredCompanyList, setSortedFilteredCompanyList ] = useState([]);
	const baseURL = process.env.REACT_APP_BASE_URL;

	const getSectorList = () => {
		const config = {
			method: 'get',
			url: `${baseURL}/sectors`,
			headers: APIHeaders,
			withCredentials: true,
			credentials: "include",
		};
		axios( config )
			.then( ( response ) => {
				// console.log( response.data.data )
				setSectorList( response.data.data );
			} )
			.catch( ( error ) => {
				console.log( error.response.status );
			} ).finally( () => {
		} )
	}

	const handleCheckOption = ( e ) => {

		searchCompanyArray?.map( el => {
			if ( searchCompanyArray.map( el => el.id ).includes( e.target.value ) ) {
				let _filtered = searchCompanyArray.filter( item => item.id !== e.target.value );
				setSearchCompanyArray( _filtered )
			} else {
				let _temp = searchCompanyArray.concat( { id: e.target.value, name: e.target.name, isPublic: e.target.dataset.ispublic === 'true'} );

				setSearchCompanyArray( _temp );
			}
		} );

		if ( searchCompanyArray.length === 0 ) setSearchCompanyArray( [ { id: e.target.value, name: e.target.name, isPublic: e.target.dataset.ispublic === 'true' } ] )
	}

	useEffect( () => {
		if ( !sectorsList.length ) {
			getSectorList();
		}

		companiesList.map( ( comp ) => {
			if ( comp.sectorCategory !== null ) {
				if ( comp.sectorCategory.id === +selectedSector ) {
					setSectorName( comp.sectorCategory.name );
				}
			}
		} )

	}, [ selectedSector ] )

  useEffect( () => {
    let _sortedFilteredCompanyList = [];
    sectorsList.map( ( sect ) => sect )
      .filter( ( sect ) => sect.name === sectorName ).map( comp => {
      comp.companies.map( el => {
        _sortedFilteredCompanyList.push( el )
      } );
    } )
    _sortedFilteredCompanyList.sort( function ( a, b ) {
      if ( a.name.toLowerCase() > b.name.toLowerCase() ) {
        return 1;
      }
      if ( a.name.toLowerCase() < b.name.toLowerCase() ) {
        return -1;
      }
      return 0;
    } )

    setSortedFilteredCompanyList(_sortedFilteredCompanyList)

  }, [ sectorName ] )


	return (
		<Modal
      show={showSelectCompaniesBySector}
      onHide={() => setShowSelectCompaniesBySector( false )}
      centered
    >
			<Modal.Header closeButton>
				<Modal.Title>{sectorName}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="fs-14px" style={{columns: '2'}}>
					{sortedFilteredCompanyList.map( ( el, i ) => {
							return <div className="form-check mb-2 w-200px" key={"select-" + el.name + i}>
								<input
									className="form-check-input"
									type="checkbox"
									checked={searchCompanyArray.map( comp => comp.name ).includes( el.name )}
									disabled={searchCompanyArray.length >= companiesLimit && !searchCompanyArray.map( comp => comp.name ).includes( el.name )}
									onChange={handleCheckOption}
									id={`select-checkbox-${el.id}`}
									name={el.name}
                  data-ispublic={el.isPublic.toString()}
									value={el.id} />
								<label
									className="form-check-label mx-2"
                  htmlFor={`select-checkbox-${el.id}`}>{el.name} {el.isPublic && <PublicTagStyle>public</PublicTagStyle>  }
								</label>
                {/*{el.isPublic && <span className="bg-light px-2 py-1 rounded">Public</span>}*/}
							</div>
						  })}
				</div>
			</Modal.Body>
			<Modal.Footer>
				<button type="submit" className="btn btn-primary" onClick={() => setShowSelectCompaniesBySector( false )}>Select</button>
			</Modal.Footer>
		</Modal>
	)
}

export default SelectCompanyBySector;
