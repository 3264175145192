import React, { useContext, useEffect, useState } from "react";
import { AppSettings } from "../config/app-settings";
import styled from "styled-components";
import Select from "react-select";
import { APIHeaders } from "../components/shared/helpers";
import axios from "axios";
import {
  companiesListAtom,
  roleAtom,
  showNewCompanyModalAtom,
  limitForTableViewAtom,
  newModalTitleAtom,
  showSingleCompanyAtom,
  singleCompanyIDAtom,
} from "../atoms/profileAtom";
import { useRecoilState, useRecoilValue } from "recoil";
import { Link, useNavigate } from "react-router-dom";
import NoResults from "../components/shared/NoResults";
import { ReactNotifications } from "react-notifications-component";
import CompaniesTableView from "./CompaniesTableView";
import { OverlayTrigger, Popover } from "react-bootstrap";
import CompaniesCategory from "./CompaniesCategory";
import SectorIcon from "../components/shared/SectorIcon";
import {
  colorVariantsCompany,
  colorVariantsShort,
  CompaniesBySectorContainer,
  SearchedCompanyList,
  SearchedItem
} from "./StyledComponent/mediaPages";
import CompanyProfile from "./Company/CompanyProfile";
import CompaniesIcon from "../components/shared/CompaniesIcon";

const LogosToggle = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

export const CompanyItemPublicTag = styled.span`
  position: ${props => props.showLogo ? 'absolute' : 'initial'};
  top: auto;
  color: var(--bs-secondary);
  margin-left: ${props => props.showLogo ? '0' : '7px'};
  left: auto;
  right: 7px;
  bottom: 7px;
  font-size: 12px;
  border: 1px solid #B668AE;
  border-radius: 5px;
  padding: 0 3px;
  background: #fff;
`;

export const CompanyItem = styled.div`
  font-size: 16px;
  width: ${props => props.showLogo ? '18%' : '23%'};
  //width: 23%;
  position: relative;
  cursor: ${props => props.showLogo ? 'pointer' : 'default'};
  display: flex;
  align-items: center;
  justify-content: ${props => props.showLogo ? 'center' : 'flex-start'};
  min-width: ${props => props.showLogo ? '180px' : 'auto'};
  min-height: ${props => props.showLogo ? '62px' : 'auto'};
  margin-bottom: 15px;
  border: ${props => props.showLogo ? '1px solid #E2E2E2' : 'none'};
  margin-left: ${props => props.showLogo ? '1%' : '0'};
  margin-right: 1%;
  border-radius: 5px;
  background-color: ${props => props.showLogo ? '#F9F9F9' : 'transparent'};

  .logo-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80px;
    width: 140px;
  }

  a {
    color: #1E1E1E;
    position: relative;
    padding-left: ${props => props.showLogo ? '0' : '15px'};

    &:hover {
      text-decoration: none;
    }

    &:before {
      position: absolute;
        //display: ${props => props.showLogo ? 'none' : ''}
      content: '';
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      width: ${props => props.showLogo ? '' : '8px'};
      height: 8px;
      background-color: #e3cee1;
      border-radius: 50%;
    }
  }

  img {
    max-width: 100px;
    max-height: 60px;
  }
`;

export const stylesSelect = {
  control: ( styles, { isDisabled, isFocused, isHovered } ) => ({
    backgroundColor: isDisabled ? 'var(--app-component-border-color)' : 'var(--app-component-bg)',
    color: 'var(--app-component-color)',
    border: isFocused ? '1px solid #b787b3' : '1px solid var(--app-component-border-color)',
    borderRadius: '10px',
    display: 'flex',
    // width: '200px',
    height: "100%",
    transition: 'all 0.3s',
    boxShadow: isFocused ? "0 0 0 0.25rem rgba(159, 95, 153, 0.25)" : "0 0 0 0.25rem rgba(159, 95, 153, 0)",
    "&:hover": {
      borderColor: isFocused ? "#b787b3" : "#6c757d",
    }
  }),
  indicatorSeparator: styles => ({
    backgroundColor: 'transparent'
  }),
  input: styles => ({
    color: 'var(--app-component-color)',
    fontWeight: '400',
    gridArea: '1/1/2/3',
    flex: '1 1 auto',
    display: 'flex',
    margin: '2px',
    gridTemplateColumns: '0 min-content',
    boxSizing: 'content-box',
    paddingLeft: "2px",
    paddingTop: '2px',
    paddingBottom: '2px',
    visibility: 'visible'
  }),
  singleValue: styles => ({
    color: 'var(--app-component-color)',
    gridArea: '1/1/2/3',
    marginLeft: '2px',
    marginRight: '2px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    boxSizing: 'border-box'
  }),
  placeholder: styles => ({
    color: 'rgba(var(--app-component-color-rgb), .5)',
    fontWeight: '400',
    gridArea: '1/1/2/3',
    marginLeft: "2px"
  }),
  menu: styles => ({
    // backgroundColor: 'var(--app-component-dropdown-bg)',
    backgroundColor: '#F9F9F9',
    boxShadow: '0px 6px 22px rgba(0, 0, 0, 0.15)',
    position: 'absolute',
    top: '100%',
    borderRadius: '8px',
    margin: '8px 0',
    zIndex: '1',
    fontSize: '16px',
    fontWeight: '300',
    boxSizing: 'border-box',
    width: '100%'
  }),
  option: ( styles, { data, isDisabled, isFocused, isSelected } ) => {
    return {
      backgroundColor: isFocused ? 'var(--app-component-dropdown-hover-bg)' : '',
      color: 'var(--app-component-color)',
      cursor: isDisabled ? 'not-allowed' : 'default',
      padding: '8px 10px'
    };
  }
};

export const stylesSelectWide = {
  control: ( styles, { isDisabled, isFocused } ) => ({
    backgroundColor: isDisabled ? 'var(--app-component-border-color)' : 'var(--app-component-bg)',
    color: 'var(--app-component-color)',
    border: isFocused ? '1px solid #b787b3' : '1px solid var(--app-component-border-color)',
    borderRadius: '10px',
    display: 'flex',
    boxShadow: isFocused ? "0 0 0 0.25rem rgba(159, 95, 153, 0.25)" : "0 0 0 0.25rem rgba(159, 95, 153, 0)",
    "&:hover": {
      borderColor: isFocused ? "#b787b3" : "#6c757d",
    }
  }),
  indicatorSeparator: styles => ({
    backgroundColor: 'transparent'
  }),
  input: styles => ({
    color: 'var(--app-component-color)',
    fontWeight: '400',
    gridArea: '1/1/2/3',
    flex: '1 1 auto',
    display: 'flex',
    margin: '2px',
    // height: "100%",
    gridTemplateColumns: '0 min-content',
    boxSizing: 'content-box',
    paddingLeft: "2px",
    // paddingTop: '2px',
    // paddingBottom: '2px',
    visibility: 'visible'
  }),
  singleValue: styles => ({
    color: 'var(--app-component-color)',
    gridArea: '1/1/2/3',
    marginLeft: '2px',
    marginRight: '2px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    boxSizing: 'border-box'
  }),
  placeholder: styles => ({
    color: 'rgba(var(--app-component-color-rgb), .5)',
    fontWeight: '400',
    gridArea: '1/1/2/3',
    marginLeft: "2px"
  }),
  menu: styles => ({
    // backgroundColor: 'var(--app-component-dropdown-bg)',
    backgroundColor: '#F9F9F9',
    boxShadow: '0px 6px 22px rgba(0, 0, 0, 0.15)',
    position: 'absolute',
    top: '100%',
    borderRadius: '8px',
    margin: '8px 0',
    zIndex: '1',
    fontSize: '16px',
    fontWeight: '300',
    boxSizing: 'border-box',
    width: '100%'
  }),
  option: ( styles, { data, isDisabled, isFocused, isSelected } ) => {
    return {
      backgroundColor: isFocused ? 'var(--app-component-dropdown-hover-bg)' : '',
      color: 'var(--app-component-color)',
      cursor: isDisabled ? 'not-allowed' : 'default',
      padding: '8px 10px'
    };
  }
};

const InputBox = styled.div`
  position: relative;

  .chevron-icon {
    position: absolute;
    top: 50%;
    left: auto;
    right: 10px;
    color: hsl(0, 0%, 80%);
    transform: translateY(-50%);

    &:hover {
      svg {
        fill: hsl(0, 0%, 50%);
      }
    }

    svg {
      fill: hsl(0, 0%, 80%);
    }
  }

  input::placeholder {
    font-size: 13px;
    color: rgb(143, 146, 148);
  }
`;

const SearchBox = styled.div`
  position: relative;
  margin-bottom: 8px;

  .search-icon {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
  }

  input {
    padding-left: 30px;
  }

  .clear-field {
    cursor: pointer;
    position: absolute;
    top: 50%;
    left: auto;
    right: 10px;
    transform: translateY(-50%);
  }
`;

export const WrapperComponent = styled.div`
  background-color: white;
  border-radius: 20px;
  padding: 22px 30px 8px 30px;
  border: 1px solid rgba(234, 236, 240, 0.75);
  box-shadow: 0 0 8px -0.5px rgba(0, 0, 0, 0.14);

  h4 {
    font-weight: 400;
  }
`;

const SubSectorsDropdownItemStyle = styled.div`
  padding-top: 2px;
  padding-bottom: 2px;
  transition: all .2s;
  background-color: transparent;
  cursor: pointer;

  &:hover {
    background-color: #EFF1F3;
  }
`;

const CompanySectorTiles = styled.div`
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
  //margin-left: -14px;
  //margin-right: -14px;
  margin-left: calc(-.5 * var(--bs-gutter-x) - 7px);
  margin-right: calc(-.5 * var(--bs-gutter-x) - 7px);
  margin-top: 16px;

  .title {
    font-size: 16px;
    display: flex;
    align-items: center;
    font-weight: 300;

    h4 {
      font-weight: inherit;
      margin: 0;
    }
  }

  .icon {
    width: 32px;
    margin-right: 10px;
  }

  .cat-link {
    cursor: pointer;
    position: absolute;
    top: auto;
    left: auto;
    bottom: 24px;
    right: 14px;
    color: black;
  }
`;

const SectorTile = styled.div`
  display: flex;
  flex-direction: column;
  //width: 23%;
  border-radius: 20px;
  background-color: rgba(246, 242, 235, 1);
  padding: 16px;
  min-width: 230px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.13);
  margin-bottom: 14px;
  height: calc(100% - 14px);
  //margin-right: 7px;
  //margin-left: 7px;
  position: relative;
  transition: all .2s;

  @media screen and (max-width: 1500px) {
    width: 31.3%;
  }

  &:hover {
    box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.15);
  }
`;

const CompanyInTile = styled.div`
  width: 41px;
  height: 41px;
  margin-right: 10px;
  border-radius: 10px;
  box-shadow: 0 0 3px 0.5px rgba(0, 0, 0, 0.2);
  transition: all .2s;

  &:hover {
    box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.25);
  }

  a {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
`;

const sortedList = ( data ) => {
  let _data = data;
  _data.sort( function ( a, b ) {
    if ( a.name.toLowerCase() > b.name.toLowerCase() ) {
      return 1;
    }
    if ( a.name.toLowerCase() < b.name.toLowerCase() ) {
      return -1;
    }
    return 0;
  } )

  // console.log( _data )

  return _data;
}

const sortByTotalStoresOpenDescending = ( a, b ) => {
  const aValue = a.totalStoresOpen !== null ? a.totalStoresOpen : Number.MIN_SAFE_INTEGER;
  const bValue = b.totalStoresOpen !== null ? b.totalStoresOpen : Number.MIN_SAFE_INTEGER;

  return bValue - aValue;
};

const publicOptions = [
  { value: true, label: 'Public' },
  { value: false, label: 'Private' },
]

const locationsCountOptions = [
  { value: 5, label: 'Less than 5 locations' },
  { value: 10, label: 'Less than 10 locations' },
  { value: 20, label: '10-20 locations' },
  { value: 50, label: '21-50 locations' },
  { value: 199, label: '51-199 locations' },
  { value: 99999, label: '200+ locations' }
]

export const checkIsInSubSector = ( el, subSectorFilter ) => {
  if ( subSectorFilter.length > 0 && el.sectors.length > 0 ) {

    return el.sectors.some( element1 => subSectorFilter.find( element2 => element2 === element1.name ) )
  } else return subSectorFilter.length <= 0;
}

const renderTooltip = ( text ) => (
  <Popover id="popover-contained-bottom" style={{ color: "black", background: "white" }}>
    {text}
  </Popover>
);

const Companies = () => {
  const [ brandsOptions, setBrandsOptions ] = useState( [ { value: "!All Brands", label: "All Brands" } ] );
  const [ filterBrandText, setFilterBrandText ] = useState( [ '' ] );
  const [ companyList, setCompanyList ] = useRecoilState( companiesListAtom );
  const [ companiesList, setCompaniesList ] = useState( [] );
  const [ companiesListFilteredByStores, setCompaniesListFilteredByStores ] = useState( [] );
  const [ sectors, setSectors ] = useState( [ { value: 'All Sectors', label: 'All Sectors' } ] );
  const [ filterSector, setFilterSector ] = useState( '' );
  const [ filterSectorText, setFilterSectorText ] = useState( '' );
  const [ filterBrand, setFilterBrand ] = useState( '' );
  const [ showLogos, setShowLogo ] = useState( true );
  const [ filteredSectors, setFilteredSectors ] = useState( sectors.map( el => el.value ) );
  const [ loadedCompanies, setLoadedCompanies ] = useState( false );
  const [ openFilterPanel, setOpenFilterPanel ] = useState( true );
  const [ searchBox, setSearchBox ] = useState( '' );
  const [ isPublic, setIsPublic ] = useState( '' );
  const [ publicOptionText, setPublicOptionText ] = useState( '' );
  const [ locationsCountFilter, setLocationsCountFilter ] = useState( '' );
  const [ locationsCountFilterText, setLocationsCountFilterText ] = useState( '' );
  const [ subSectorsListLoad, setSubSectorsListLoad ] = useState( false );
  // const [ found, setFound ] = useState( [] );
  const [ updateList, setUpdateList ] = useState( false );
  const [ subSectorsOpenDropdown, setSubSectorsOpenDropdown ] = useState( false );
  const [ companySectorTiles, setCompanySectorTiles ] = useState( true );
  const [ subSectorsList, setSubSectorsList ] = useState( [] );
  const [ subSectorsAPIList, setSubSectorsAPIList ] = useState( [] );
  const [ subSectorsFilter, setSubSectorsFilter ] = useState( [] );
  const [ countFilteredList, setCountFilteredList ] = useState( '' );
  const [ countFilteredListIDs, setCountFilteredListIDs ] = useState( [] );
  const [ viewTable, setViewTable ] = useState( false );
  const limitForTableView = useRecoilValue( limitForTableViewAtom );
  const [ showNewCompanyModal, setShowNewCompanyModal ] = useRecoilState( showNewCompanyModalAtom );
  const [ newModalTitle, setNewModalTitle ] = useRecoilState( newModalTitleAtom );
  const [ isNoResult, setIsNoResult ] = useState( false );
  const [ openSingleCompany, setOpenSingleCompany ] = useRecoilState( showSingleCompanyAtom );
  const [ singleCompanyID, setSingleCompanyID ] = useRecoilState( singleCompanyIDAtom );
  const [ , updateState ] = React.useState();
  const forceUpdate = React.useCallback( () => updateState( {} ), [] );
  const role = useRecoilValue( roleAtom );
  const isAdmin = role === 'admin';
  const context = useContext( AppSettings );
  const baseURL = process.env.REACT_APP_BASE_URL;
  const baseLogoURL = process.env.REACT_APP_LOGOS_BASE_URL;
  const signature = process.env.REACT_APP_LOGOS_SIGNATURE;
  const navigate = useNavigate();

  useEffect(
    () => {
      context.handleSetAppHeaderNone( false );
      context.handleSetAppSidebarNone( false );
      context.handleSetAppContentClass( 'mh-100vh-88' );
      context.handleSetAppTitle( 'Companies ' );
      context.handleSetAppIcon( <CompaniesIcon /> );
      setSingleCompanyID('');
      setOpenSingleCompany(false);
    },
    []
  );

  const handleChangeBrandFilter = ( e ) => {
    setFilterBrand( e.label );
    setFilterBrandText( e.text );
    if ( e.label === 'All Brands' ) {
      setFilterSector( '' );
      setFilterBrand( '' );
    }
  }

  useEffect( () => {
    let countCompaniesInSector = 0;

    filteredSectors.map( ( sector ) => {
      countCompaniesInSector += companiesList.filter( x => x.sectorCategory?.name === sector &&
        (isPublic !== '' ? x.isPublic === isPublic : true) &&
        checkIsInSubSector( x, subSectorsFilter ) ).length;
    } )
    countCompaniesInSector === 0 ? setIsNoResult( true ) : setIsNoResult( false );
  }, [ filteredSectors, companiesList, isPublic, searchBox, subSectorsFilter ] )

  const handleChangeSectorFilter = ( e ) => {
    setFilterSector( e.label )
    setFilterSectorText( e.text )
  }

  const handleChangeSearchBox = ( e ) => {
    setSearchBox( e.target.value );
    handleClearAllFilter();
  }

  const handleSubsectorDropdown = () => {
    setSubSectorsOpenDropdown( !!!subSectorsOpenDropdown );
  }

  const handleIsPublicChange = ( e ) => {
    if ( e !== null ) {
      setIsPublic( e.value );
      setPublicOptionText( e.text )
    } else {
      setIsPublic( '' );
    }
  }

  const handleChangeSubSectorFilter = ( e ) => {
    let _subSectorsFilter = subSectorsFilter;
    const index = _subSectorsFilter.indexOf( e );

    if ( index !== -1 ) {
      _subSectorsFilter.splice( index, 1 );
    } else {
      _subSectorsFilter.push( e );
    }
    setSubSectorsFilter( _subSectorsFilter );
    setUpdateList( !!!updateList );
  }

  const handleLocationsCountChange = ( e ) => {
    setLocationsCountFilter( e.label );
    setLocationsCountFilterText( e.text );
    setLocationsCountFilter( e.value )
  }

  useEffect( () => {
    if ( searchBox ) {
      let _searchedCompanies = companyList;
      let _found = [];
      _searchedCompanies.map( ( comp ) => {
        if ( comp.name.toUpperCase().indexOf( searchBox.toUpperCase() ) >= 0 ) {
          _found.push( comp )
        }
      } );
      setCompaniesList( _found );

    } else {
      setCompaniesList( companyList );
    }
  }, [ searchBox ] )

  useEffect( () => {
    companyList.map( company => {
      if ( company.name === filterBrand && company.sectorCategory ) {
        setFilterSector( company.sectorCategory.name );
      } else if ( company.name === filterBrand && company.sectorCategory === null ) {
        setFilterSector( 'Other' );
      }
    } )
  }, [ filterBrand ] )

  const getCompaniesList = () => {
    setLoadedCompanies( false )
    const config = {
      method: 'get',
      url: `${baseURL}/companies?includeSocialMetrics=true&all=true`,
      headers: APIHeaders,
      withCredentials: true,
      credentials: "include",
    };
    axios( config )
      .then( ( response ) => {
        // console.log( response.data.data )
        setCompanyList( sortedList( response.data.data ) );
      } )
      .catch( ( error ) => {
        // console.log( error );
        if ( error.response.status === 401 ) {
          navigate( '/user/login', true )
        }
      } ).finally( () => {
      setLoadedCompanies( true );
    } )
  }

  useEffect( () => {
    if ( companyList.length > 0 ) {
      const sortedData = [ ...companyList ].sort( sortByTotalStoresOpenDescending );
      setCompaniesListFilteredByStores( sortedData );
    }
  }, [ companyList ] )

  useEffect( () => {
    setSubSectorsList( subSectorsAPIList )
  }, [ subSectorsAPIList ] )

  useEffect( () => {
  }, [ subSectorsFilter ] )

  const handleClearAllFilter = () => {
    setFilterBrand( '' );
    setFilterBrandText( '' );
    setFilterSectorText( '' );
    setFilterSector( '' );
    setIsPublic( '' );
    setPublicOptionText( '' );
    setLocationsCountFilterText( '' );
    setLocationsCountFilter( '' );
    setSubSectorsFilter( [] );
  }

  const getSubSectorsList = () => {
    setSubSectorsListLoad( false )
    const config = {
      method: 'get',
      url: `${baseURL}/sub_sectors`,
      headers: APIHeaders,
      withCredentials: true,
      credentials: "include",
    };
    axios( config )
      .then( ( response ) => {
        // console.log( response.data.data );
        let _subSectorList = [];
        let _sortedSubSector = sortedList( response.data.data );
        _sortedSubSector.map( sector => {
          if ( sector.companies.length > 0 ) {
            _subSectorList.push( { value: sector.name, label: sector.name } )
          }
        } )
        // console.log(_subSectorList)
        // setSubSectorsList( _subSectorList );
        setSubSectorsAPIList( _subSectorList );
      } )
      .catch( ( error ) => {
        console.log( error );
      } ).finally( () => {
      setSubSectorsListLoad( true );
    } )
  }

  useEffect( () => {
    getCompaniesList();
    getSubSectorsList();
  }, [] )

  useEffect( () => {
    if ( brandsOptions.length ) {
      let _sortedOptions = brandsOptions.sort( function ( a, b ) {
        if ( a.value.toLowerCase() > b.value.toLowerCase() ) {
          return 1;
        }
        if ( a.value.toLowerCase() < b.value.toLowerCase() ) {
          return -1;
        }
        return 0;
      } );

      setBrandsOptions( _sortedOptions );
    }

  }, [ brandsOptions ] )

  useEffect( () => {
    if ( sectors.length === 1 ) {
      let _sortedOptions = sectors.sort( function ( a, b ) {
        if ( a.label.toLowerCase() > b.label.toLowerCase() ) {
          return 1;
        }
        if ( a.label.toLowerCase() < b.label.toLowerCase() ) {
          return -1;
        }
        return 0;
      } );

      setSectors( _sortedOptions );
    }

  }, [ sectors ] )

  useEffect( () => {
    if ( brandsOptions.length === 1 ) {
      companyList.map( ( el ) => {
        if ( !brandsOptions.includes( el.value ) ) {
          setBrandsOptions( brandsOptions => [ ...brandsOptions, { value: el.name, label: el.name } ] )
        }
      } )
    }

    let _sectors = [];

    companyList.map( ( el ) => {
      if ( el.sectorCategory !== null ) {
        sectors.map( ( item ) => {
          if ( item.value !== el.sectorCategory?.id && !_sectors.map( ell => ell.value ).includes( el.sectorCategory.id ) && !sectors.map( ell => ell.value ).includes( el.sectorCategory.id ) ) {
            _sectors.push( { value: el.sectorCategory.id, label: el.sectorCategory.name } )
          }
        } )
      }
    } )

    setSectors( sectors.concat( _sectors ) )
    setCompaniesList( companyList )
    // console.log( sectors );

  }, [ companyList ] );

  useEffect( () => {
    if ( companyList && locationsCountFilter ) {
      let _searchedCompanies = [];
      let minLocationsCountFilter = 0;
      if ( locationsCountFilter === 20 ) {
        minLocationsCountFilter = 11;
      } else if ( locationsCountFilter === 50 ) {
        minLocationsCountFilter = 21;
      } else if ( locationsCountFilter === 199 ) {
        minLocationsCountFilter = 51;
      } else if ( locationsCountFilter === 99999 ) {
        minLocationsCountFilter = 200;
      }
      _searchedCompanies = companyList.filter( company =>
        company.socialMetrics.length > 0 && +company.socialMetrics[ 0 ]?.total_stores_open <= locationsCountFilter
        && +company.socialMetrics[ 0 ]?.total_stores_open >= minLocationsCountFilter
      )

      setCompaniesList( [ ...new Set( _searchedCompanies ) ] )
    }


  }, [ locationsCountFilter, filterSector ] )

  useEffect( () => {
    if ( locationsCountFilter === '' ) {

      setCompaniesList( companyList )
    }

  }, [ locationsCountFilter ] )

  useEffect( () => {
    if ( filterSector !== 'All Sectors' && filterSector !== '' ) {
      setFilteredSectors( sectors.map( sec => sec.label ).filter( ( el ) => el === filterSector ) )
    } else {
      setFilteredSectors( sectors.map( el => el.label ) );
    }
  }, [ filterSector, sectors ] );

  useEffect( () => {
    let _count = companiesList.length;
    let _filteredList = companiesList;

    if ( companiesList && companiesList.length > 0 ) {
      if ( filterSector !== 'All Sectors' && filterSector !== '' ) {
        _filteredList = companiesList.filter( x => x.sectorCategory?.name === filterSector && (isPublic !== '' ? x.isPublic === isPublic : true) && checkIsInSubSector( x, subSectorsFilter ) );
      } else {
        _filteredList = companiesList.filter( x => (isPublic !== '' ? x.isPublic === isPublic : true) && checkIsInSubSector( x, subSectorsFilter ) )
      }
      _count = _filteredList.length;
    }

    setCountFilteredList( _count );
    if ( _count > 0 ) {
      setCountFilteredListIDs( _filteredList.map( el => el.id ) );
    }

  }, [ locationsCountFilter, filterSector, companiesList, subSectorsFilter, isPublic, updateList ] )

  const handleViewList = () => {
    setViewTable( !viewTable );
  }

  const handleClickSector = ( catId ) => {
    setFilterSector( catId );
    setFilterSectorText( catId );
    setCompanySectorTiles( false );
  }

  const handleBackToSectorTiles = () => {
    setCompanySectorTiles( !!!companySectorTiles );
    setFilterSectorText( '' );
    setFilterSector( '' );
  }

  const SubSectorsDropdownItem = ( { sector } ) => {
    const [ inList, setInList ] = useState( subSectorsFilter.map( el => el ).includes( sector ) );
    const [ clicked, setClicked ] = useState( false );

    useEffect( () => {
      setInList( subSectorsFilter.map( el => el ).includes( sector ) );
    }, [ clicked ] )

    if ( !inList ) return <SubSectorsDropdownItemStyle
      className="px-3 py-2 fs-16px color-neutral-black"
      key={'subSectorsItem-' + sector}
      onClick={() => {
        handleChangeSubSectorFilter( sector );
        setClicked( !!!clicked );
        setInList( false );
        forceUpdate();
      }}
    >
      <div
      >
        {sector}
      </div>
    </SubSectorsDropdownItemStyle>
  }

  const SubSectorsDropdown = () => {

    return <CompaniesBySectorContainer
      className={subSectorsList.length > 10 ?
        "position-absolute overflow-auto h-350px w-100" :
        "position-absolute overflow-auto w-100"}
      style={{
        backgroundColor: "#fff",
        zIndex: "11",
        top: "48px",
        padding: "0",
        border: "1px solid var(--app-component-border-color)"
      }}>
      <div
        className="overlay position-fixed w-100 h-100 top-0"
        style={{
          left: "0",
          zIndex: "-1"
        }}
        onClick={() => setSubSectorsOpenDropdown( false )}></div>
      {subSectorsList.map( ( comp, index ) => {

        return <SubSectorsDropdownItem sector={comp.value} />;
      } )}
    </CompaniesBySectorContainer>;
  }

  const handleRemoveMetric = ( metric ) => {
    let _prevMetricsFilter = subSectorsFilter;
    _prevMetricsFilter = _prevMetricsFilter.filter( item => item !== metric )
    setSubSectorsFilter( _prevMetricsFilter );
  }

  const SearchedMetricsList = ( { subSectorsFilter } ) => {

    useEffect( () => {
    }, [ subSectorsFilter ] )

    return subSectorsFilter.length > 0 && <SearchedCompanyList>
      {subSectorsFilter.map( metric => <SearchedItem
        key={"SearchedMetric-" + metric}
        style={{
          backgroundColor: colorVariantsShort[ subSectorsFilter.indexOf( metric ) ]
        }}
      >
        <span>{metric}</span>
        <Link
          to="#"
          className="remove-item"
          onClick={( s ) => handleRemoveMetric( metric )} />
      </SearchedItem> )}
    </SearchedCompanyList>
  }

  useEffect( () => {
    if ( countFilteredList > limitForTableView ) {
      setViewTable( false );
    }
  }, [ countFilteredList ] )

  if ( !loadedCompanies ) {
    return <div className="d-flex justify-content-center align-items-center text-center mh-100vh-300">
      <div className="spinner-border m-5" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  }

  if ( openSingleCompany ) return <CompanyProfile />;

  if ( !openSingleCompany ) return <div className="companies-page">
    <WrapperComponent>
      <div className="d-flex align-items-center justify-content-between row mb-3">
        <h4>Search Companies</h4>
        <div className="d-flex align-items-center justify-content-between my-2">
          <div className="col-6 d-flex align-items-center">
            <div className="input-group d-flex align-items-center w-100">
              <SearchBox className="form-input w-100">
                <span className="search-icon"><i className="fa fa-search"></i></span>
                <input
                  className="form-control fs-16px fw-300"
                  type="text"
                  value={searchBox}
                  placeholder="Search"
                  onChange={handleChangeSearchBox}
                />
                {searchBox && <span
                  className="clear-field"
                  onClick={() => setSearchBox( '' )}
                >
                <i className="fa fa-times"></i>
              </span>}
              </SearchBox>
            </div>
          </div>
          {isAdmin && <div><span className="fs-16px mx-3">Don't see a company?</span><Link
            to="#"
            onClick={() => {
              setShowNewCompanyModal( true );
              setNewModalTitle( 'Company' );
            }
            }
            className="text-link cursor-pointer fs-16px"
          > Request New Company</Link></div>}
        </div>
        <div className="col-6 d-flex align-items-center justify-content-end">
          {!isAdmin && <><span className="fs-14px mx-3">
              Don't see a company?</span><Link
            to="#"
            onClick={() => setShowNewCompanyModal( true )}
            className="btn btn-primary fs-14px"
          ><i className="mx-2 fa fa-circle-plus"></i> Request New Company</Link></>}
        </div>
      </div>
      <div className="row mb-4">
        <div className="fs-14px fw-300 d-flex align-items-center mb-1 gray-inactive">Filter by:</div>
        <div
          className="cursor-pointer fs-16px w-auto"
          onClick={() => setOpenFilterPanel( !!!openFilterPanel )}
        >Brand, Store Count, Sector, Public/Private
          <i
            className={openFilterPanel ? "fa fa-chevron-down mx-2" : "fa fa-chevron-right mx-2"}
          /></div>
      </div>
      <div className="row">
        {searchBox === '' ? <div className="d-flex flex-wrap">

          {openFilterPanel && <><div className="col-12 d-flex flex-wrap align-items-center">

            <div className="col position-relative mb-3">
              <Select
                options={brandsOptions}
                value={filterBrandText}
                isDisabled={viewTable}
                onChange={handleChangeBrandFilter}
                styles={stylesSelect}
                placeholder="Brand"
              />
              {filterBrand &&
                <i onClick={() => {
                  if ( !viewTable ) {
                    handleClearAllFilter();
                  }
                }} className={viewTable ? "fa fa-times-circle clear-field-button cursor-default" : "fa fa-times-circle clear-field-button"} style={{ right: "-45px" }}></i>}
            </div>
            <div className="w-20px"></div>
            <div className="col position-relative mb-3">
              <Select
                placeholder={'Location Count'}
                name="Location Count"
                options={locationsCountOptions}
                value={locationsCountFilterText}
                styles={stylesSelect}
                isDisabled={viewTable || filterBrand}
                onChange={handleLocationsCountChange}
                className={viewTable ? "basic-multi-select w-100 disabled" : "basic-multi-select w-100"}
                classNamePrefix="select"
              />
              {locationsCountFilter && <span
                className={viewTable ? "clear-field cursor-default" : "clear-field"}
                onClick={() => {
                  if ( !viewTable ) {
                    setLocationsCountFilter( '' );
                    setLocationsCountFilterText( '' );
                  }
                }
                }
              >
                <i className="fa fa-times-circle"></i>
              </span>}
            </div>
            <div className="w-20px"></div>
            <div className="col position-relative d-flex mb-3">
              <Select
                placeholder={'Public/Private'}
                name="public"
                options={publicOptions}
                value={publicOptionText}
                styles={stylesSelect}
                isDisabled={viewTable || filterBrand}
                onChange={handleIsPublicChange}
                className={viewTable ? "basic-multi-select w-100 disabled" : "basic-multi-select w-100"}
                classNamePrefix="select"
              />
              {isPublic !== '' && <span
                className={viewTable ? "clear-field cursor-default" : "clear-field"}
                onClick={() => {
                  if ( !viewTable ) {
                    setIsPublic( '' );
                    setPublicOptionText( '' );
                  }
                }
                }
              >
                <i className="fa fa-times-circle"></i>
              </span>}
            </div>
            <div className="w-20px"></div>
            <div className="col position-relative d-flex mb-3">
              <Select
                options={sectors}
                value={filterSectorText}
                isDisabled={viewTable || filterBrand}
                onChange={handleChangeSectorFilter}
                styles={stylesSelect}
                className={viewTable ? "basic-multi-select w-100 disabled" : "basic-multi-select w-100"}
                placeholder="Sector"
              />
              {(filterSectorText !== 'All Sectors' && filterSectorText !== '') && <span
                className={viewTable ? "clear-field cursor-default" : "clear-field"}
                onClick={() => {
                  if ( !viewTable ) {
                    setFilterSector( '' )
                    setFilterSectorText( '' )
                  }
                }
                }
              >
                <i className="fa fa-times-circle"></i>
              </span>}
            </div>
            <div className="w-20px"></div>
            <div className="col position-relative mb-3">
              <div className="input-group d-flex align-items-center w-100">
                <InputBox className="form-input disabled w-100">

                  <input
                    className={(viewTable || filterBrand) ? "disabled form-control fs-16px fw-300" : "form-control fs-16px fw-300"}
                    type="text"
                    value={searchBox}
                    style={{
                      backgroundColor: (viewTable || filterBrand) ? 'var(--app-component-border-color)' : 'var(--app-component-bg)',
                      borderColor: (viewTable || filterBrand) ? 'var(--app-component-border-color)' : 'var(--app-component-border-color)'
                    }}
                    disabled={viewTable || filterBrand}
                    onClick={handleSubsectorDropdown}
                    placeholder="Subsectors"
                    onChange={handleChangeSearchBox}
                  />
                  <span
                    className="chevron-icon"
                    onClick={handleSubsectorDropdown}
                  ><svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false"><path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path></svg></span>
                </InputBox>

              </div>
              {subSectorsOpenDropdown && <SubSectorsDropdown></SubSectorsDropdown>}
            </div>
          </div>
            <div className="w-100 d-flex justify-content-end">
              <div className="mb-3">
                <Link
                  to="#"
                  className={filterBrand ||
                  locationsCountFilter ||
                  isPublic !== '' ||
                  filterSector ||
                  subSectorsFilter.length > 0 ? "btn btn-outline-green2 color-green2" : "btn btn-outline-green2 color-green2 disabled"}
                  onClick={() => {
                    handleClearAllFilter();
                    setSearchBox( '' )
                  }}
                  style={{ "padding": "9px 15px" }}
                >Clear All</Link>
              </div></div>
          </>}

        </div> : <></>}
      </div>
    </WrapperComponent>
    <div className="h-20px"></div>

    {companySectorTiles ? <>
        <WrapperComponent className="mt-2">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <h4 className="p-0 m-0">Browse Companies by Sector:</h4>
            <Link
              to="#"
              className="text-link fs-16px"
              onClick={() => setCompanySectorTiles( !!!companySectorTiles )}
            >View all</Link>
          </div>

          <SearchedMetricsList subSectorsFilter={subSectorsFilter} />
          <CompanySectorTiles className="row">
            {filteredSectors.map( ( sector ) => {
              let countCompaniesInSector = companiesList.filter( x => x.sectorCategory?.name === sector &&
                (isPublic !== '' ? x.isPublic === isPublic : true) &&
                checkIsInSubSector( x, subSectorsFilter ) ).length;
              let i = 0;
              let subSectorList = [];
              if ( sector !== "All Sectors" && countCompaniesInSector ) return <div className="col-xxl-3 col-xl-3">
                <SectorTile
                  className="mx-1"
                  key={'company-by-sector-' + sector}
                >
                  <div className="title">
                    <div className="icon">
                      <SectorIcon
                        sector={sector}
                        size="L"
                      />
                    </div>
                    <div>
                      <h4
                        className="cursor-pointer"
                        onClick={() => handleClickSector( sector )}
                      >{sector} {countCompaniesInSector > 1 ? `(${countCompaniesInSector})` : ''}</h4>
                    </div>
                  </div>

                  <div className="d-flex flex-column-reverse my-1 h-100 justify-content-between">
                    <div className="d-flex w-100">{companiesListFilteredByStores.length > 0 && companiesListFilteredByStores.map( ( el ) => {
                      // console.log(sector, collapseStateCategory.includes(sector))

                      if ( el.sectorCategory?.name === sector &&
                        (el.name === filterBrand || filterBrand === 'All Brands' || filterBrand === '') &&
                        (isPublic !== '' ? el.isPublic === isPublic : true) && checkIsInSubSector( el, subSectorsFilter )
                      ) {
                        i++;

                        if ( subSectorList.length < 4 ) {
                          el.sectors.map( sector => !subSectorList.includes( sector.name ) && subSectorList.push( sector.name ) )

                        }
                        if ( i < 4 ) return <CompanyInTile
                          key={'companyInTile' + el.name}
                          className="d-flex"
                        >
                          <Link
                            // to={'/companies/' + el.id}
                            to="#"
                            onClick={() => {
                              setOpenSingleCompany( true );
                              setSingleCompanyID( el.id )
                            }}
                          >{(el.logoFileName !== undefined && el.logoFileName !== null) ? <img
                            src={baseLogoURL + el.logoFileName + signature}
                            alt={el.name}
                          /> : <span className="fs-10px">{el.name}</span>}</Link>
                        </CompanyInTile>
                      }
                    } )
                    }</div>
                    <div
                      className="my-2 fs-14px"
                    >{subSectorList.map( ( el, i ) => subSectorList.length - 1 > i ? `${el}, ` : el )}</div>
                  </div>
                  <div
                    className="cat-link"
                    onClick={() => handleClickSector( sector )}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                      <path d="M8.99992 0.333374L7.47242 1.86087L13.5174 7.91671H0.333252V10.0834H13.5174L7.47242 16.1392L8.99992 17.6667L17.6666 9.00004L8.99992 0.333374Z" fill="black" />
                    </svg>
                  </div>
                </SectorTile>
              </div>

            } )}
          </CompanySectorTiles>
        </WrapperComponent>
      </> :
      <>{!viewTable || countFilteredList > 14 ? <WrapperComponent className="mt-2 position-relative">
        <div className="my-2 fs-16px d-flex justify-content-between">
          <div>
            <i className="fa fa-arrow-left color-purple "></i>
            <Link to="#"
                  className="text-link mx-2"
                  onClick={handleBackToSectorTiles}
            >Company Sector Tiles</Link>
          </div>
          <LogosToggle>
            <div>
              <OverlayTrigger
                placement="bottom"
                delay={{ show: 400, hide: 400 }}
                overlay={renderTooltip( 'The detailed list view is available for not more than ' + limitForTableView + ' companies at a time.' )}
              >
                <Link
                  to="#"
                  onClick={countFilteredList !== 0 && countFilteredList < limitForTableView && !filterBrand ? handleViewList : null}
                  className={countFilteredList !== 0 && countFilteredList < limitForTableView && !filterBrand ? "text-link" : "text-link disabled"}
                >Company Metric Summary</Link>
              </OverlayTrigger>

            </div>
            <div className="form-check-reverse form-switch mx-3">
              <input
                className="form-check-input fs-18px"
                type="checkbox"
                id="flexSwitchCheckDefault"
                defaultChecked={showLogos}
                value={showLogos}
                onChange={() => setShowLogo( !showLogos )}
              />
              <label
                className="form-check-label fz-12px lh-20"
                htmlFor="flexSwitchCheckDefault">View Logos?</label>
            </div>
          </LogosToggle>
        </div>
        {searchBox !== '' && filterSector === '' && !companySectorTiles &&
          <div className="row my-2">
            <div className="fs-18px fw-300 h-35px">Search results: ({companiesList.filter( x => x.sectorCategory !== null ).length})</div>
          </div>
        }
        <SearchedMetricsList subSectorsFilter={subSectorsFilter} />
        {filteredSectors.map( ( sector ) => {
          let countCompaniesInSector = companiesList.filter( x => x.sectorCategory?.name === sector &&
            (isPublic !== '' ? x.isPublic === isPublic : true) &&
            checkIsInSubSector( x, subSectorsFilter ) ).length;

          if ( sector !== "All Sectors" && countCompaniesInSector ) return <CompaniesCategory
            key={sector}
            sector={sector}
            countCompaniesInSector={countCompaniesInSector}
            showLogos={showLogos}
            filterBrand={filterBrand}
            companiesList={companiesList}
            isPublic={isPublic}
            subSectorsFilter={subSectorsFilter}
          />

        } )}

      </WrapperComponent> : <WrapperComponent className="mt-2">
        <div className="my-2 fs-16px d-flex justify-content-between">
          <div>
            <i className="fa fa-arrow-left color-purple "></i>
            <Link to="#"
                  className="text-link mx-2"
                  onClick={handleBackToSectorTiles}
            >Company Sector Tiles</Link>
          </div>
          <LogosToggle>
            <div>
              <OverlayTrigger
                placement="bottom"
                delay={{ show: 400, hide: 400 }}
                overlay={renderTooltip( 'The detailed list view is available for not more than ' + limitForTableView + ' companies at a time.' )}
              >
                <Link
                  to="#"
                  onClick={countFilteredList !== 0 && countFilteredList < limitForTableView && !filterBrand ? handleViewList : null}
                  className={countFilteredList !== 0 && countFilteredList < limitForTableView && !filterBrand ? "text-link" : "text-link disabled"}
                >Company Metric Summary</Link>
              </OverlayTrigger>

            </div>
            <div className="form-check-reverse form-switch mx-3">
              <input
                className="form-check-input fs-18px"
                type="checkbox"
                id="flexSwitchCheckDefault"
                defaultChecked={showLogos}
                value={showLogos}
                onChange={() => setShowLogo( !showLogos )}
              />
              <label
                className="form-check-label fz-12px lh-20"
                htmlFor="flexSwitchCheckDefault">View Logos?</label>
            </div>
          </LogosToggle>
        </div>
        <div className="h-30px"></div>
        <CompaniesTableView
          ids={countFilteredListIDs}
          showLogos={showLogos}
        />
      </WrapperComponent>}</>}
    {isNoResult || companiesList.length === 0 ? <NoResults /> : <></>}
    <ReactNotifications />
  </div>
}

export default Companies;
