export const colorPatternDefault = [
  '#225688',
  '#d80132',
  '#ff9a00',
  '#4d87bf',
  '#005450',
  '#594db1',
  '#016a7a',
  '#52284f',
  '#c4dacc',
  '#5e7872',
  '#212a2e',
  '#65415c',
  '#e3cdb3',
  '#eff0f2',
  '#ee562b',
];

