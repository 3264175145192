import HomeIcon from "../components/shared/HomeIcon";
import CompaniesIcon from "../components/shared/CompaniesIcon";
import CustomCompIcon from "../components/shared/CustomCompIcon";
import MarkerTrackerIcon from "../components/shared/MarkerTrackerIcon";
import UsersIcon from "../components/shared/UsersIcon";
import OrganizationsIcon from "../components/shared/OrganizationsIcon";
import AskUsIcon from "../components/shared/AskUsIcon";
import BadgeNewMessage from "../pages/AskUsAnything/BadgeNewMessage";


const Menu = [
	{ path: '/home', title: 'Home', svgIcon: <HomeIcon /> },
	{ path: '/companies', title: 'Companies', svgIcon: <CompaniesIcon /> },
	{ path: '/custom-comps/', title: 'My Comps Library', svgIcon: <CustomCompIcon /> },
	{
		path: '/new-comp-set',
		title: 'New Custom Comp',
		icon: "fa-solid fa-folder-plus"
	},
	// {
	// 	path: "/custom-comps/", title: "Custom Comps", svgIcon: <CustomCompIcon />,
	// 	children: [
	// 		{path: '/new-comp-set',
	// 			title: 'New Custom Comp',
	// 			icon: "fa-solid fa-folder-plus"}
	// 	]
	// },
	{ path: '/popular-custom-comps', title: 'Popular Custom Comps', icon: 'fa fa-star' },
	{ path: '/market-tracker', title: 'Market Tracker', svgIcon: <MarkerTrackerIcon /> },
	// { path: '/ask-us', title: 'Ask Us Anything', svgIcon: <AskUsIcon /> },

	{ path: '/user/dashboard', title: 'Users', svgIcon: <UsersIcon /> },
	{ path: '/organizations', title: 'Organizations', svgIcon: <OrganizationsIcon /> },
	{ path: '/media-library', title: 'Media Library', icon: 'fa-solid fa-photo-film' },
	{ path: '/market-insights', title: 'Market Insights', icon: 'fa fa-image' },
	{ path: '/support', title: 'Support', svgIcon: <AskUsIcon />, badge: <BadgeNewMessage /> },

]

export default Menu;
