import React from 'react';

const MinusIconNoBorder = ( { border, color, size } ) => {
  let _size = 16;
  let fillColor = "#F2545B";
  let stroke = "#828282";
  if ( color ) {
    fillColor = color;
  }
  if ( border ) {
    stroke = border;
  }
  if ( size ) _size = size;

  return <svg width={_size} height={_size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    {/*<rect x="15.5" y="15.5" width="15" height="15" rx="4.5" transform="rotate(180 15.5 15.5)" fill="white" stroke={stroke} />*/}
    <path opacity="0.5" d="M6.94825 7L2.99975 7L2.99975 9L6.94825 9L9.05126 9.00009L12.9998 9L12.9998 7L9.05126 7L6.94825 7Z" fill={fillColor} />
  </svg>
}

export default MinusIconNoBorder;
