
const SuccessIcon = () => {

  return <svg width="85" height="88" viewBox="0 0 85 88" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_134_4189" mask-type="alpha" maskUnits="userSpaceOnUse" x="-1" y="7" width="81" height="81">
      <ellipse cx="39.6526" cy="47.5" rx="40" ry="40" fill="url(#paint0_linear_134_4189)"/>
    </mask>
    <g mask="url(#mask0_134_4189)">
      <circle opacity="0.8" cx="40" cy="48" r="40" fill="url(#paint1_linear_134_4189)"/>
    </g>
    <path d="M38.7865 64.5482L16 41.7618L23.9753 33.7865L38.7865 48.5977L46.6619 56.6392L38.7865 64.5482Z" fill="url(#paint2_linear_134_4189)"/>
    <path d="M76.3841 11L38.7865 48.5977L30.9258 56.6875L38.7865 64.5482L84.3594 18.9753L76.3841 11Z" fill="#66B39C"/>
    <defs>
      <linearGradient id="paint0_linear_134_4189" x1="2.65258" y1="-2.5" x2="-3.46433" y2="87.6759" gradientUnits="userSpaceOnUse">
        <stop stopColor="#e3cee1"/>
        <stop offset="1" stopColor="#66B39C"/>
      </linearGradient>
      <linearGradient id="paint1_linear_134_4189" x1="3" y1="-2" x2="-3.11691" y2="88.1759" gradientUnits="userSpaceOnUse">
        <stop stopColor="#e3cee1"/>
        <stop offset="1" stopColor="#66B39C"/>
      </linearGradient>
      <linearGradient id="paint2_linear_134_4189" x1="16" y1="33.7865" x2="20.0212" y2="67.7064" gradientUnits="userSpaceOnUse">
        <stop stopColor="#66B39C"/>
        <stop offset="1" stopColor="#B668AE"/>
      </linearGradient>
    </defs>
  </svg>
}

export default SuccessIcon;
