import { Store } from "react-notifications-component";

export const toasterNotify = ( title, message, type) => {
	Store.addNotification({
		title: title,
		message: message,
		type: type,
		insert: "bottom",
		container: "bottom-center",
		dismiss: {
			duration: 5000,
			onScreen: true
		}
	});
}

export const userRoles = ['operator', 'investor', 'advisor', 'broker', 'developer', 'admin'];

export const capitalizeFirstLetter = (string) => {
	return string.charAt(0).toUpperCase() + string.slice(1);
}

export const APIHeaders = {
	'Accept': 'application/json',
	'Content-Type': 'multipart/form-data',
	'Access-Control-Allow-Origin': process.env.REACT_APP_FRONT_URL,
	'Access-Control-Allow-Credentials': 'true',
	'Access-Control-Allow-Methods': "GET,POST,PUT,DELETE,PATCH,HEAD,OPTIONS",
	'Access-Control-Allow-Headers': 'Origin, X-Api-Key, X-Requested-With, Content-Type, Accept, Authorization'
};

export const APIHeadersRAW = {
	'Accept': 'application/json',
  'Content-Type': 'application/json',
	'Access-Control-Allow-Origin': process.env.REACT_APP_FRONT_URL,
	'Access-Control-Allow-Credentials': 'true',
	'Access-Control-Allow-Methods': "GET,POST,PUT,DELETE,PATCH,HEAD,OPTIONS",
	'Access-Control-Allow-Headers': 'Origin, X-Api-Key, X-Requested-With, Content-Type, Accept, Authorization'
};

export const validateEmail = (email) => {
	return email.match(
		/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
	);
};

export function kitcut( text, limit) {
	text = text.trim();
	if( text.length <= limit) return text;
	text = text.slice( 0, limit);
	const lastSpace = text.lastIndexOf(" ");
	if( lastSpace > 0) {
		text = text.substr(0, lastSpace);
	}
	return text + "...";
}

export const generateRandomPastelColor = () => {
  // Generate random RGB values
  let r = Math.floor(Math.random() * 156) + 100; // Random red value between 100 and 255
  let g = Math.floor(Math.random() * 156) + 100; // Random green value between 100 and 255
  let b = Math.floor(Math.random() * 156) + 100; // Random blue value between 100 and 255

  // Convert RGB to hexadecimal format
  return `#${r.toString( 16 )}${g.toString( 16 )}${b.toString( 16 )}`;
};

export const generateRandomColor = () => {
  // Generate random RGB values
  let r = Math.floor(Math.random() * 256); // Random red value between 0 and 255
  let g = Math.floor(Math.random() * 256); // Random green value between 0 and 255
  let b = Math.floor(Math.random() * 256); // Random blue value between 0 and 255

  // Adjust RGB values to make the color lighter
  const increaseFactor = 0.5; // Factor to increase brightness (adjust as needed)
  r = Math.min(255, Math.floor(r + (255 - r) * increaseFactor));
  g = Math.min(255, Math.floor(g + (255 - g) * increaseFactor));
  b = Math.min(255, Math.floor(b + (255 - b) * increaseFactor));

  // Convert RGB to hexadecimal format
  return `#${r.toString( 16 )}${g.toString( 16 )}${b.toString( 16 )}`;
};

export const TO_NAME = 1;
export const TO_ABBREVIATED = 2;
export const states = [
  ['Alabama', 'AL'],
  ['Alaska', 'AK'],
  ['American Samoa', 'AS'],
  ['Arizona', 'AZ'],
  ['Arkansas', 'AR'],
  // ['Armed Forces Americas', 'AA'],
  // ['Armed Forces Europe', 'AE'],
  // ['Armed Forces Pacific', 'AP'],
  ['California', 'CA'],
  ['Colorado', 'CO'],
  ['Connecticut', 'CT'],
  ['Delaware', 'DE'],
  ['District of Columbia', 'DC'],
  ['Florida', 'FL'],
  ['Georgia', 'GA'],
  ['Guam', 'GU'],
  ['Hawaii', 'HI'],
  ['Idaho', 'ID'],
  ['Illinois', 'IL'],
  ['Indiana', 'IN'],
  ['Iowa', 'IA'],
  ['Kansas', 'KS'],
  ['Kentucky', 'KY'],
  ['Louisiana', 'LA'],
  ['Maine', 'ME'],
  ['Marshall Islands', 'MH'],
  ['Maryland', 'MD'],
  ['Massachusetts', 'MA'],
  ['Michigan', 'MI'],
  ['Minnesota', 'MN'],
  ['Mississippi', 'MS'],
  ['Missouri', 'MO'],
  ['Montana', 'MT'],
  ['Nebraska', 'NE'],
  ['Nevada', 'NV'],
  ['New Hampshire', 'NH'],
  ['New Jersey', 'NJ'],
  ['New Mexico', 'NM'],
  ['New York', 'NY'],
  ['North Carolina', 'NC'],
  ['North Dakota', 'ND'],
  ['Northern Mariana Islands', 'NP'],
  ['Ohio', 'OH'],
  ['Oklahoma', 'OK'],
  ['Oregon', 'OR'],
  ['Pennsylvania', 'PA'],
  ['Puerto Rico', 'PR'],
  ['Rhode Island', 'RI'],
  ['South Carolina', 'SC'],
  ['South Dakota', 'SD'],
  ['Tennessee', 'TN'],
  ['Texas', 'TX'],
  ['US Virgin Islands', 'VI'],
  ['Utah', 'UT'],
  ['Vermont', 'VT'],
  ['Virginia', 'VA'],
  ['Washington', 'WA'],
  ['West Virginia', 'WV'],
  ['Wisconsin', 'WI'],
  ['Wyoming', 'WY'],
];

export const centersStates = {
  'Alabama': { lat: 32.7043351, lng: -85.924844, zoom: 7},
  'Alaska': { lat: 64.0620979, lng: -159.9018638, zoom: 5},
  'Arizona': { lat: 34.1388004, lng: -112.2954041, zoom: 7},
  'Arkansas': { lat: 34.868568, lng: -92.3955608, zoom: 8},
  'California': { lat: 37.4290247, lng: -118.4351573, zoom: 6},
  'Colorado': { lat: 38.908098, lng: -105.0799739, zoom: 7},
  'Connecticut': { lat: 41.5960157, lng: -72.6202921, zoom: 9},
  'Delaware': { lat: 39.1417789, lng: -75.2933921, zoom: 9},
  'District of Columbia': { lat: 38.8937336, lng: -77.0846157, zoom: 12},
  'Florida': { lat: 28.4883746, lng: -84.1630378, zoom: 6},
  'Georgia': { lat: 32.9167739, lng: -82.2356521, zoom: 7},
  'Guam': { lat: 38.7372578, lng: -98.0473973, zoom: 4},
  'Hawaii': { lat: 20.775173, lng: -158.0604407, zoom: 7},
  'Idaho': { lat: 45.730805, lng: -114.36319, zoom: 6},
  'Illinois': { lat: 40.0212848, lng: -88.554191, zoom: 7},
  'Indiana': { lat: 39.8884196, lng: -85.7399458, zoom: 7},
  'Iowa': { lat: 42.128687, lng: -92.952012, zoom: 7},
  'Kansas': { lat: 38.4859847, lng: -98.2450396, zoom: 7},
  'Kentucky': { lat: 37.7228528, lng: -85.3856224, zoom: 7},
  'Louisiana': { lat: 30.9933379, lng: -90.8867789, zoom: 7},
  'Maine': { lat: 45.2284618, lng: -68.8655293, zoom: 7},
  'Marshall Islands': { lat: 9.5606458, lng: 161.7320732, zoom: 6},
  'Maryland': { lat: 39.124082, lng: -77.37477, zoom: 8},
  'Massachusetts': { lat: 42.3895097, lng: -71.8432428, zoom: 8},
  'Michigan': { lat: 44.8721656, lng: -87.0556643, zoom: 6},
  'Minnesota': { lat: 46.541037, lng: -94.066024, zoom: 7},
  'Mississippi': { lat: 32.7269522, lng: -88.4833696, zoom: 7},
  'Missouri': { lat: 38.4641746, lng: -91.9775502, zoom: 7},
  'Montana': { lat: 46.6954373, lng: -111.4304407, zoom: 7},
  'Nebraska': { lat: 41.4872586, lng: -99.8473899, zoom: 7},
  'Nevada': { lat: 38.9584475, lng: -115.8507507, zoom: 7},
  'New Hampshire': { lat: 44.059095, lng: -70.8469526, zoom: 8},
  'New Jersey': { lat: 40.1726517, lng: -74.3402625, zoom: 8},
  'New Mexico': { lat: 34.2276105, lng: -105.2811006, zoom: 7},
  'New York': { lat: 40.6971494, lng: -74.2598617, zoom: 10},
  'North Carolina': { lat: 35.6423974, lng: -80.0058654, zoom: 7},
  'North Dakota': { lat: 47.5424461, lng: -100.1058431, zoom: 7},
  'Northern Mariana Islands': { lat: 17.3024501, lng: 143.242427, zoom: 7},
  'Ohio': { lat: 40.6758475, lng: -81.2977208, zoom: 7},
  'Oklahoma': { lat: 41.1115487, lng: -78.5774381, zoom: 8},
  'Oregon': { lat: 44.3387515, lng: -120.1763778, zoom: 6},
  'Pennsylvania': { lat: 41.1115487, lng: -78.7257535, zoom: 8},
  'Puerto Rico': { lat: 18.2076699, lng: -67.1463354, zoom: 9},
  'Rhode Island': { lat: 41.6826905, lng: -71.1388445, zoom: 9},
  'South Carolina': { lat: 33.8341648, lng: -80.9875655, zoom: 8},
  'South Dakota': { lat: 44.3087987, lng: -100.0619276, zoom: 7},
  'Tennessee': { lat: 35.8185858, lng: -85.9696262, zoom: 7},
  "Texas": { lat: 31.000000, lng: -100.000000, zoom: 6 },
  'US Virgin Islands': { lat: 18.065972, lng: -65.2991423, zoom: 9},
  'Utah': { lat: 39.4527629, lng: -110.4611906, zoom: 7},
  'Vermont': { lat: 43.8385456, lng: -71.9959585, zoom: 8},
  'Virginia': { lat: 37.8607468, lng: -79.1371743, zoom: 7},
  "Washington": { lat: 47.2729926, lng: -120.8822769, zoom: 7},
  'West Virginia': { lat: 38.9148094, lng: -81.3027691, zoom: 8},
  'Wisconsin': { lat: 44.5274314, lng: -89.274349, zoom: 7},
  'Wyoming': { lat: 42.978425, lng: -107.3364444, zoom: 7},
}

export function convertRegion(input, to) {
  // using function: var stateName = convertRegion("TX", TO_NAME);
  // using function: var stateAbbr = convertRegion("Texas", TO_ABBREVIATED);

  // So happy that Canada and the US have distinct abbreviations
  const provinces = [
    ['Alberta', 'AB'],
    ['British Columbia', 'BC'],
    ['Manitoba', 'MB'],
    ['New Brunswick', 'NB'],
    ['Newfoundland', 'NF'],
    ['Northwest Territory', 'NT'],
    ['Nova Scotia', 'NS'],
    ['Nunavut', 'NU'],
    ['Ontario', 'ON'],
    ['Prince Edward Island', 'PE'],
    ['Quebec', 'QC'],
    ['Saskatchewan', 'SK'],
    ['Yukon', 'YT'],
  ];

  const regions = states.concat(provinces);

  let i; // Reusable loop variable
  if (to === TO_ABBREVIATED) {
    input = input.replace(/\w\S*/g, function (txt) { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); });
    for (i = 0; i < regions.length; i++) {
      if (regions[i][0] === input) {
        return (regions[i][1]);
      }
    }
  } else if (to === TO_NAME) {
    input = input.toUpperCase();
    for (i = 0; i < regions.length; i++) {
      if (regions[i][1] === input) {
        return (regions[i][0]);
      }
    }
  }
}

export const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const abbrNum = (number, decPlaces) => {
  decPlaces = Math.pow(10,decPlaces);

  let abbrev = [ "K", "M", "B", "T" ];

  for (let i=abbrev.length-1; i>=0; i--) {

    let size = Math.pow(10,(i+1)*3);

    if(size <= number) {
      number = Math.round(number*decPlaces/size)/decPlaces;

      if((number === 1000) && (i < abbrev.length - 1)) {
        number = 1;
        i++;
      }

      number += abbrev[i];

      break;
    }
  }

  return number;
}


