
const ExerciseIcon = ({size}) => {
  let _size = size === 'L' ? 2 : 1;

  return <svg width={_size * 24} height={_size * 24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.5281 14.9964L8.88348 12.1682L12.6301 8.38481L9.98542 5.48301L12.6301 3.13208L21.2253 11.4702L18.2133 14.4822L15.1646 11.4702L11.5281 14.9964Z" fill="#c897c3"/>
    <path d="M18.3538 13.2981L20.0673 11.5846L12.4154 3.9327L10.7019 5.64617L18.3538 13.2981ZM11.5654 20.0865L13.2885 18.3481L5.65192 10.7115L3.91345 12.4346L11.5654 20.0865ZM11.3577 14.3039L14.3 11.3769L12.6231 9.7L9.69613 12.6423L11.3577 14.3039ZM15.4269 18.3577L11.5654 22.2L1.80005 12.4346L5.64232 8.57312L8.62692 11.5731L11.5693 8.6308L8.57887 5.64617L12.425 1.80005L22.1999 11.575L18.3538 15.4211L15.3692 12.4307L12.4269 15.3731L15.4269 18.3577Z" fill="#B668AE"/>
    <path d="M16.5211 4.20183L19.7981 7.47876L20.8707 8.55135L21.9461 7.50376L16.4711 2.02881L15.4236 3.10425L16.5211 4.20183Z" fill="#c897c3"/>
    <path d="M7.49423 19.8078L4.19231 16.5058L3.13079 15.4443L2.08466 16.4905L7.50961 21.9154L8.55574 20.8693L7.49423 19.8078Z" fill="#c897c3"/>
  </svg>;
}

export default ExerciseIcon;
