import React from "react";
import CompanyEntry from "./CompanyEntry";
import CitiesList from "./CitiesList";
import { useRecoilState, useRecoilValue } from "recoil";
import { showCitiesIDCompanyAtom, minimizeLocationLegendAtom } from "../../atoms/profileAtom";
import { convertRegion, TO_ABBREVIATED } from "../../components/shared/helpers";
import MinimizeLegendIcon from "../../components/shared/MinimizeLegend";
import MaximazeLegendIcon from "../../components/shared/MaximazeLegendIcon";
import { Link } from "react-router-dom";

const MinimizeLegend = () => {
  const [ minimizeLocationLegend, setMinimizeLocationLegend ] = useRecoilState( minimizeLocationLegendAtom );

  const handleLocationCollapse = () => {
    console.log(minimizeLocationLegend)
    setMinimizeLocationLegend( !minimizeLocationLegend )
  }

  return <div className={ minimizeLocationLegend ? "d-flex justify-content-center" : "d-flex justify-content-end"}>
    <Link
      to="#"
      className="w-20px mx-1"
      onClick={handleLocationCollapse}
    >
      {minimizeLocationLegend ? <MaximazeLegendIcon /> : <MinimizeLegendIcon />}
    </Link>
  </div>
}

const CompanyDetails = ( { locations, companiesIDs, state } ) => {
  const show = useRecoilValue( showCitiesIDCompanyAtom );
  const [ minimizeLocationLegend, setMinimizeLocationLegend ] = useRecoilState( minimizeLocationLegendAtom );

  if ( companiesIDs.length > 0 ) {
    return <div className="mx-2 my-1" style={{ width: minimizeLocationLegend ? '71px': '190px' }}>
      <MinimizeLegend />
      {companiesIDs.map( ( company_id, i ) => {
        let _cities = [];
        let _openingDates = [];
        let _count = state ?
          locations.filter( loc => +loc.company_id === +company_id.id && loc.state === convertRegion( state, TO_ABBREVIATED ) ).length :
          locations.filter( loc => +loc.company_id === +company_id.id ).length;

        locations.forEach( loc => {
          // console.log(loc);

          if ( state ) {
            if ( +loc.company_id === +company_id.id && loc.state === convertRegion( state, TO_ABBREVIATED ) ) {
              _cities.push( loc.city );

              if ( loc.openingDate ) {
                _openingDates.push( {
                  "city": loc.city,
                  "openingDate": loc.openingDate,
                  "isOpeningDateComplete": loc.isOpeningDateComplete
                } )
              }
            }
          } else {
            if ( +loc.company_id === +company_id.id ) {
              _cities.push( loc.city );

              if ( loc.openingDate ) {
                _openingDates.push( {
                  "city": loc.city,
                  "openingDate": loc.openingDate,
                  "isOpeningDateComplete": loc.isOpeningDateComplete
                } )
              }
            }
          }
        } )
        let comp = locations.find( loc => +loc.company_id === +company_id.id || +loc.companyId === +company_id.id );

        if ( comp ) {
          return <div className="py-1" key={'div-company-entry-' + i}>
            <CompanyEntry
              key={'company-entry-' + i}
              company={comp}
              count={_count}
              index={i}
            />

            {!minimizeLocationLegend && <CitiesList
              key={'cities-list-' + i}
              citiesList={_cities}
              openingDate={_openingDates}
              show={show}
              index={i}
            />}
            { (minimizeLocationLegend && i < companiesIDs.length - 1) ? <div style={{
              width: "40px",
              height: "1px",
              marginTop: "8px",
              marginLeft: "16px",
              backgroundColor: "rgba(231, 231, 231, 1)"
            }}></div> : <></>}
          </div>;
        }
      } )}
    </div>
  }
}

export default CompanyDetails;
