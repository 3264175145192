import React from "react";

const MarkerCircle = ( { color, size, borderColor } ) => {
  let _color = color ?? "#ffffff"; //F8AB38
  let _size = size ?? 18;
  let _borderColor = borderColor ?? "#ffffff";

  return <svg width={_size} height={_size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="1" y="1" width="18" height="18" rx="9" fill={_color} stroke={_borderColor} strokeWidth="2" />
  </svg>
}

export default MarkerCircle;
