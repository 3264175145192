import React from 'react';

const HomeIcon = ({color, size}) => {
  let _color = color ?? 'currentColor';

  return <svg width="16" height="18" viewBox="0 0 16 18" fill={_color} xmlns="http://www.w3.org/2000/svg">
      <path d="M1.99997 16.0001H5.34615V10.0578H10.6538V16.0001H14V7.00013L7.99997 2.48091L1.99997 7.00013V16.0001ZM0.5 17.5001V6.25016L7.99997 0.605957L15.5 6.25016V17.5001H9.15382V11.5578H6.84613V17.5001H0.5Z" fill={_color}/>
    </svg>;
}

export default HomeIcon;
