import { useEffect, useContext } from "react";
import { AppSettings } from "../config/app-settings";
import CustomCompIcon from "../components/shared/CustomCompIcon";

const Page404 = () => {
  const context = useContext( AppSettings );
  useEffect(
    () => {
      context.handleSetAppTitle( 'Page 404' );
      context.handleSetAppIcon( '' );
    },
    []
  );

  return <div className="d-flex justify-content-center align-items-center text-center mh-100vh-300">
    <p className="fs-20px">Page not found</p>
  </div>
}

export default Page404;
