import React, { useContext, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import axios from "axios";
import { AppSettings } from '../../config/app-settings.js';
import { useRecoilState, useSetRecoilState } from "recoil";
import { roleAtom, sessionAtom } from "../../atoms/profileAtom";
import { APIHeaders, validateEmail } from "../../components/shared/helpers";
import UserDashboard from "./userDashboard";
import Logo from "../../components/shared/Logo";

const baseURL = process.env.REACT_APP_BASE_URL;

const Login = () => {
	const context = useContext( AppSettings );

	const [ email, setEmail ] = useState( '' );
	const [ phone, setPhone ] = useState( '' );
	const [ password, setPassword ] = useState( '' );
	const [ otpAttempt, setOtpAttempt ] = useState( '' );
	const [ showMessage, setShowMessage ] = useState( false );
	const [ showMessageReset, setShowMessageReset ] = useState( false );
  const [ isValidEmail, setIsValidEmail ] = useState(false);
	const [ isError, setIsError ] = useState( false );
	const [ isErrorReset, setIsErrorReset ] = useState( false );
	const [ messageAPI, setMessageAPI ] = useState( '' );
	const [ messageAPIReset, setMessageAPIReset ] = useState( '' );
	const [ stepLogin, setStepLogin ] = useState( 0 );
	const [ redirectHome, setRedirectHome ] = useState( false );
	const [ redirectAdmin, setRedirectAdmin ] = useState( false );
	const [ isLoggedIn, setIsLoggedIn ] = useState( false );
	const setSessionID = useSetRecoilState( sessionAtom );
	const [ loginFailsCount, setLoginFailsCount ] = useState( 0 );
	const [ showResetPassword, setShowResetPassword ] = useState( false );
  const [ role, setRole ] = useRecoilState( roleAtom );

	useEffect( () => {
		context.handleSetAppHeaderNone( true );
		context.handleSetAppSidebarNone( true );
		context.handleSetAppContentClass( 'p-0' );

	}, [ isLoggedIn ] );

	useEffect( () => {
		localStorage.removeItem( 'id' );
		localStorage.removeItem( 'role' );
		localStorage.removeItem( 'first_name' );
		localStorage.removeItem( 'last_name' );
		localStorage.removeItem( 'full_name' );
		localStorage.removeItem( 'activated_at' );
		localStorage.removeItem( 'activated' );
		localStorage.removeItem( 'organization' );
		localStorage.removeItem( 'invited_by' );
		localStorage.removeItem( 'month_in' );
		localStorage.removeItem( 'invite_accepted' );
		localStorage.removeItem( 'otp_required_for_login' );
		localStorage.removeItem( 'email' );
		setSessionID( '' );
	}, [] )

	useEffect( () => {
		loginFailsCount >= 5 && setShowResetPassword( true );
	}, [ loginFailsCount ] )

	const onEmailChange = ( e ) => {
		setShowMessage( false );
		setIsError( false );
		setEmail( e.target.value );
		setShowMessageReset(false);
		setMessageAPIReset('');
	}

	const onPhoneChange = ( e ) => {
		setShowMessage( false );
		setIsError( false );
		setPhone( e.target.value );
		setShowMessageReset(false);
		setMessageAPIReset('');
	}

	const onPasswordChange = ( e ) => {
		setShowMessage( false );
		setIsError( false );
		setPassword( e.target.value );
	}
	const onCodeChange = ( e ) => {
		setShowMessage( false );
		setIsError( false );
		setOtpAttempt( e.target.value );
	}

	const handleRedirect = ( e ) => {
		setIsLoggedIn( true );
		setIsError( false );
		setRedirectHome( true );
	}

  useEffect(()=>{
    setIsValidEmail(validateEmail(email));
  }, [email])

	const handleSubmit = ( event ) => {
		event.preventDefault();
		const data = new FormData();
		data.append( 'user[email]', email );
		data.append( 'user[password]', password );
		data.append( 'user[phone]', phone );
		otpAttempt && data.append( 'user[otp_attempt]', otpAttempt );
		const config = {
			method: 'post',
			url: `${baseURL}/sign_in`,
			headers: APIHeaders,
			withCredentials: true,
			credentials: "include",
			data: data,
		};

		axios( config )
			.then( function ( response ) {
				let resp = response.data.data;
				// console.log( response );
				if ( response.status === 200 && response.data.success && stepLogin === 0 ) {
					setStepLogin( 1 );
					setMessageAPI( response.data.success );
					setShowMessage( true );

				} else if ( response.status === 200 && response.data.error ) {
					setMessageAPI( response.data.error );
					setShowMessage( true );
					setIsError( true );
				} else if ( response.status === 200 && resp ) {
					for ( const [ key, value ] of Object.entries( resp ) ) {
						localStorage.setItem( key, value );
					}
					setSessionID( resp[ 'id' ] );
          setRole(resp.role);

					if ( resp.role === 'admin' ) {
						setRedirectAdmin( true );
					} else {
						handleRedirect();
					}
				}

			} )
			.catch( function ( error ) {
				console.log( error.response.status );
				setShowMessage( true );
				setMessageAPI( error.response.data.error ?? 'Something went wrong' );
				setIsError( true );
				if(error.response.status === 500 && stepLogin === 1) {
					setMessageAPI(  'Verification code is wrong!' );
				}
				if ( error.response.data.error === 'Invalid Email or password.' ) {
					setLoginFailsCount( loginFailsCount => loginFailsCount + 1 )
				}
			} );
	}
	const handleResetPassword = () => {
		const data = new FormData();
		data.append( "user[email]", email );
		data.append( "user[phone]", phone );

		const config = {
			"method": "POST",
			"url": `${baseURL}/password`,
			headers: APIHeaders,
			withCredentials: true,
			credentials: "include",
			data: data,
		};
		axios( config )
			.then( ( resp ) => {
				console.log( resp )
				setMessageAPIReset( 'Success. Please check your email for further instructions.' );
				setIsErrorReset( false );
			} ).catch( ( error ) => {
			setMessageAPIReset( 'Email not found' );
			setIsErrorReset( true );
			console.log( error )
		} ).finally( () => {
			setShowMessageReset( true );
		} )
	}

	if ( redirectAdmin ) {
		// return <UserDashboard to='/' />;
		return <Navigate to='/user/dashboard' />;
	}

	if ( redirectHome ) {
		return <Navigate to='/home' />;
	}
	return (
		<div className="login login-v1">
			<div className="login-container">
				<div className="login-header">
					<div className="brand">
						<div className="d-flex align-items-center">
							<Logo mode="dark" /><span className="p-2"></span><b>4Wall IQ</b>
						</div>
					</div>
				</div>
				<div className="login-body">
					<div className="login-content fs-13px">
						{!showResetPassword ?
							<>
								<form onSubmit={handleSubmit}>
									{stepLogin === 0 &&
										<>
											<div className="form-floating mb-20px">
												<input
													type="email"
													className="form-control fs-13px h-45px"
													name="email"
													placeholder="Email Address"
													autoComplete="on"
													value={email}
													onChange={onEmailChange}
												/>
												<label htmlFor="emailAddress" className="d-flex align-items-center py-0">Email Address</label>
											</div>
											{/*<div className="form-floating mb-20px">*/}
											{/*	<input*/}
											{/*		type="text"*/}
											{/*		className="form-control fs-13px h-45px"*/}
											{/*		name="phone"*/}
											{/*		placeholder="Phone Number"*/}
											{/*		autoComplete="off"*/}
											{/*		value={phone}*/}
											{/*		onChange={onPhoneChange}*/}
											{/*	/>*/}
											{/*	<label htmlFor="emailAddress" className="d-flex align-items-center py-0">Phone Number</label>*/}
											{/*</div>*/}
											<div className="form-floating mb-20px">
												<input
													type="password"
													className="form-control fs-13px h-45px"
													name="password"
													placeholder="Password"
													autoComplete="on"
													value={password}
													onChange={onPasswordChange}
												/>
												<label htmlFor="password" className="d-flex align-items-center py-0">Password</label>
											</div>
										</>}
									{showMessage &&
										<div className={isError ? 'alert alert-danger' : 'alert alert-success'} role="alert">
											{messageAPI}
										</div>
									}
									{stepLogin > 0 && <div className="form-floating mb-20px">
										<input
											type="text"
											className="form-control fs-13px h-45px"
											name="code2fa"
											placeholder="2FA code"
											value={otpAttempt}
											onChange={onCodeChange}
										/>
										<label htmlFor="password" className="d-flex align-items-center py-0">Enter Verification Code</label>
									</div>}

									{/*<div className="form-check mb-20px">*/}
									{/*	<input className="form-check-input" type="checkbox" value="" id="rememberMe" />*/}
									{/*	<label className="form-check-label" htmlFor="rememberMe">*/}
									{/*		Remember Me*/}
									{/*	</label>*/}
									{/*</div>*/}
									<div className="login-buttons">
										<button type="submit" className="btn h-45px btn-primary d-block w-100 btn-lg">Sign me in</button>
									</div>
								</form>
								<div className="mt-2">
                  {stepLogin !== 1 && <a
										href="#"
										className="mt-3"
										onClick={() => {
											setShowResetPassword( true );
											setMessageAPI('')
										}
										}
									>Forgot password?</a>}
								</div>
							</> :
							<>
								<div className="form-floating mb-20px">
									<input
										type="email"
										className="form-control fs-13px h-45px"
										name="email"
										placeholder="Email Address"
										autoComplete="on"
										value={email}
										onChange={onEmailChange}
									/>
									<label htmlFor="emailAddress" className="d-flex align-items-center py-0">Email Address</label>
								</div>
								{showMessageReset &&
									<div className={isErrorReset ? 'alert alert-danger' : 'alert alert-success'} role="alert">
										{messageAPIReset}
									</div>
								}
								<div className="login-buttons">
									<button
										className={ (email && validateEmail(email)) ? "btn h-45px btn-primary d-block w-100 btn-lg mb-15px" : "btn h-45px btn-primary d-block w-100 btn-lg mb-15px disabled"}
										onClick={handleResetPassword}
									>Reset password
									</button>
									<button
										className="btn h-45px btn-gray d-block w-100 btn-lg"
										onClick={() => {
											setShowResetPassword( false );
											setLoginFailsCount( 0 );
										}
										}
									>Cancel
									</button>
								</div>
							</>}
					</div>
				</div>
			</div>
		</div>
	)
}

export default Login;
