import React, { useState } from 'react';
import { Link } from "react-router-dom";
import {
  TilesWrapper,
  LinksWrapper,
  FeatureStyle,
  LinkStyle,
  TileStyle,
  TitleStyle, LinkTextStyle, DropdownLinks, SelectTips, ModalOverlay,
  // FeaturesWrapper
} from "./styled";
import { tilesContent } from "./HomePageContent";
import { useContext, useEffect } from "react";
import { AppSettings } from "../../config/app-settings";
// import CompanyDescription from "../Company/CompanyDescription";
import { useRecoilState } from "recoil";
import {
  chosenMetricsAtom,
  CSMetricsAttributesAtom,
  modalContentTipsAtom, modalTipsLinkAtom,
  newCustomCompNameAtom,
  newCustomCompTypeAtom,
  searchCompanyArrayAtom,
  selectedSectorsAtom,
  showModalTipsAtom,
  stepOfCreationCompsAtom,
  timeframeSelectedLabelAtom,
  tipsModalTitle
} from "../../atoms/profileAtom";
import HomeIcon from "../../components/shared/HomeIcon";
import HomeIconMain from "../../components/shared/HomeIconMain";
import { kitcut } from "../../components/shared/helpers";

const Feature = ( { content } ) => {

  return <FeatureStyle>{content}</FeatureStyle>
}

const TileIcon = ( { icon } ) => {

  return <div><img src={`data:image/svg+xml;base64,${icon}`} alt="" /></div>
}

const LinkItem = ( { content } ) => {
  const [ title, setTitle ] = useRecoilState( tipsModalTitle );
  const [ showModalTips, setShowModalTips ] = useRecoilState( showModalTipsAtom );
  const [ modalContent, setModalContent ] = useRecoilState( modalContentTipsAtom );
  const [ modalTipsLink, setModalTipsLink ] = useRecoilState( modalTipsLinkAtom );

  const [ compName, setCompName ] = useRecoilState( newCustomCompNameAtom );
  const [ compType, setCompType ] = useRecoilState( newCustomCompTypeAtom );
  const [ stepOfCreationComps, setStepOfCreationComps ] = useRecoilState( stepOfCreationCompsAtom );
  const [ searchCompanyArray, setSearchCompanyArray ] = useRecoilState( searchCompanyArrayAtom );
  const [ chosenMetrics, setChosenMetrics ] = useRecoilState( chosenMetricsAtom );
  const [ CSMetricsAttributes, setCSMetricsAttributes ] = useRecoilState( CSMetricsAttributesAtom );
  const [ selectedSectors, setSelectedSectors ] = useRecoilState( selectedSectorsAtom );
  const [ timeframeSelectedLabel, setTimeframeSelectedLabel ] = useRecoilState( timeframeSelectedLabelAtom );

  // console.log(content)
  const handleOpenModal = ( content, titleContent, url ) => {
    setTitle( titleContent );
    setShowModalTips( true );
    setModalTipsLink(url);
    setModalContent( content );
    setCompName( '' );
    setCompType( '' );
    setStepOfCreationComps( 1 );
    setSearchCompanyArray( [] );
    setChosenMetrics( [] );
    setCSMetricsAttributes( [] );
    setSelectedSectors( [] );
    setTimeframeSelectedLabel( '' );
  }

  return <LinkStyle>
    {content.url ? <Link
      onClick={() => handleOpenModal( content.content, content.title, content.url )}
      to="#"
    >{kitcut(content.label, 50)}</Link> : <span>{content.label}</span>}
  </LinkStyle>
}
const LinkItemText = ( { content } ) => {
  const [ title, setTitle ] = useRecoilState( tipsModalTitle );
  const [ showModalTips, setShowModalTips ] = useRecoilState( showModalTipsAtom );
  const [ modalContent, setModalContent ] = useRecoilState( modalContentTipsAtom );
  const [ modalTipsLink, setModalTipsLink ] = useRecoilState( modalTipsLinkAtom );

  const [ compName, setCompName ] = useRecoilState( newCustomCompNameAtom );
  const [ compType, setCompType ] = useRecoilState( newCustomCompTypeAtom );
  const [ stepOfCreationComps, setStepOfCreationComps ] = useRecoilState( stepOfCreationCompsAtom );
  const [ searchCompanyArray, setSearchCompanyArray ] = useRecoilState( searchCompanyArrayAtom );
  const [ chosenMetrics, setChosenMetrics ] = useRecoilState( chosenMetricsAtom );
  const [ CSMetricsAttributes, setCSMetricsAttributes ] = useRecoilState( CSMetricsAttributesAtom );
  const [ selectedSectors, setSelectedSectors ] = useRecoilState( selectedSectorsAtom );
  const [ timeframeSelectedLabel, setTimeframeSelectedLabel ] = useRecoilState( timeframeSelectedLabelAtom );

  // console.log(content)
  const handleOpenModal = ( content, titleContent, url ) => {
    setTitle( titleContent );
    setShowModalTips( true );
    setModalTipsLink(url);
    setModalContent( content );
    setCompName( '' );
    setCompType( '' );
    setStepOfCreationComps( 1 );
    setSearchCompanyArray( [] );
    setChosenMetrics( [] );
    setCSMetricsAttributes( [] );
    setSelectedSectors( [] );
    setTimeframeSelectedLabel( '' );
  }

  return <LinkTextStyle>
    {content.url ? <Link
      onClick={() => handleOpenModal( content.content, content.title, content.url )}
      to="#"
    >{kitcut(content.label, 80)}</Link> : <span>{content.label}</span>}
  </LinkTextStyle>
}

const Tile = ( { content } ) => {
  const [collapsed, setCollapsed] = useState(false);

  return <TileStyle>
    <TitleStyle><HomeIconMain />
      <div>
        <h2>{content.title}</h2>
      </div>
    </TitleStyle>
    <LinksWrapper color={content.color}>
      <div>
        {content.links.map( (link, j ) =>
          j < 3 && <LinkItem
            content={link}
          /> )}
      </div>
      <SelectTips className="w-100 position-relative">
        <input
          type="text"
          name="dropdown-tips"
          onClick={() => setCollapsed( !collapsed )}
          className="form-control"
          placeholder="Discover your prompt here" />
        <i
          className="fa fa-chevron-down clear-field-button"
          onClick={() => setCollapsed( !collapsed )}
        />
      </SelectTips>
      {collapsed && <DropdownLinks onClick={() => setCollapsed( !collapsed )}>
        {content.links.map( (link, j ) => j > 3 && <LinkItemText
          content={link}
        />)}
        <ModalOverlay onClick={() => setCollapsed( !collapsed )}/>
      </DropdownLinks>}
    </LinksWrapper>
  </TileStyle>
}

const Home = () => {
  const context = useContext( AppSettings );

  useEffect(
    () => {
      context.handleSetAppHeaderNone( false );
      context.handleSetAppSidebarNone( false );
      context.handleSetAppContentClass( '' );
      context.handleSetAppTitle( 'Home' );
      context.handleSetAppIcon( <HomeIcon /> );
    },
    []
  );

  return (
    <div>
      <h1 className="page-header fs-38px">Welcome to 4Wall IQ</h1>
      <p className="fs-22px fw-300" style={{
        color: 'rgba(100, 100, 107, 1)'
      }}>We automate research and analysis tasks, freeing up your time to focus on your business.
        How can we assist you today?</p>
      <TilesWrapper>
        {tilesContent.map( ( tile, i ) =>
          <Tile
            content={tile}
            key={'tile-' + i}
          />)}
      </TilesWrapper>
    </div>
  )
}

export default Home;
