import React, { useEffect, useState, useContext } from 'react';
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { Panel, PanelBody, PanelHeader } from "../../components/panel/panel.jsx";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
	organizationListAtom,
	roleAtom,
	showCreateOrgModalAtom,
	showEditOrgModalAtom,
	updateCompanyListAtom
} from "../../atoms/profileAtom";
import EditOrganizationModal from "../../components/modals/EditOrganizationModal";
import { ReactNotifications } from "react-notifications-component";
import { APIHeaders, capitalizeFirstLetter, kitcut, toasterNotify } from "../../components/shared/helpers";
import CreateOrganizationModal from "../../components/modals/CreateOrganizationModal";
import moment from 'moment';
import SweetAlert from "sweetalert-react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { AppSettings } from "../../config/app-settings";
import styled from "styled-components";
import OrganizationsIcon from "../../components/shared/OrganizationsIcon";

const OrderedButtonStyle = styled.span`
  cursor: pointer;
  opacity: ${props => props.opacity};
  vertical-align: top;
  min-width: 50px;
`;

const OrderableColumnStyle = styled.th`
  cursor: pointer;
  background-color: #fff;
  transition: all 0.3s;
  padding: 5px !important;
  min-width: 50px;
  
  &:hover {
    background-color: #eeeeee;
  }
`;

const orderList = (list, orderBy, asc, isOrdered, bool, integer, object, isDate) => {
  let _orgList = [...list];
  // console.log(_orgList, orderBy, object);

  if (!isDate && !bool && !integer && !object && isOrdered) {
    if (asc) {
      _orgList.sort( function ( a, b ) {
        if (a[orderBy] === '' || a[orderBy] === null) {
          return 1;
        }

        if (b[orderBy] === '' || b[orderBy] === null) {
          return -1;
        }
        if ( a[orderBy].toLowerCase() > b[orderBy].toLowerCase() ) {
          return 1;
        }
        if ( a[orderBy].toLowerCase() < b[orderBy].toLowerCase() ) {
          return -1;
        }
        return 0;
      } )
    } else {
      _orgList.sort( function ( a, b ) {
        if (a[orderBy] === '' || a[orderBy] === null) {
          return 1;
        }
        if (b[orderBy] === '' || b[orderBy] === null) {
          return -1;
        }
        if ( a[orderBy].toLowerCase() < b[orderBy].toLowerCase() ) {
          return 1;
        }
        if ( a[orderBy].toLowerCase() > b[orderBy].toLowerCase() ) {
          return -1;
        }
        return 0;
      } )
    }
  }

  if (bool) {
    if ( asc ) {
      _orgList = [...list].sort((a, b) => b[orderBy] - a[orderBy]);
    } else {
      _orgList = [...list].sort((a, b) => a[orderBy] - b[orderBy]);
    }
  }

  if (integer) {
    if (asc) {
      _orgList.sort( function ( a, b ) {
        if ( a[orderBy] > b[orderBy] ) {
          return 1;
        }
        if ( a[orderBy] < b[orderBy] ) {
          return -1;
        }
        return 0;
      } )
    } else {
      _orgList.sort( function ( a, b ) {
        if ( a[orderBy] < b[orderBy] ) {
          return 1;
        }
        if ( a[orderBy] > b[orderBy] ) {
          return -1;
        }
        return 0;
      } )
    }
  }

  if (object) {
    if (asc) {
      _orgList.sort( function ( a, b ) {
        if ( a[orderBy].name > b[orderBy].name ) {
          return 1;
        }
        if ( a[orderBy].name < b[orderBy].name ) {
          return -1;
        }
        return 0;
      } )
    } else {
      _orgList.sort( function ( a, b ) {
        if ( a[orderBy].name < b[orderBy].name ) {
          return 1;
        }
        if ( a[orderBy].name > b[orderBy].name ) {
          return -1;
        }
        return 0;
      } )
    }
  }

  if (isDate) {
    if (asc) {
      _orgList.sort( function ( a, b ) {
        if (a[orderBy] === null) {
          return 1;
        }
        if (b[orderBy] === null) {
          return -1;
        }

        if ( moment(a[orderBy]).unix() > moment(b[orderBy]).unix() ) {
          return 1;
        }
        if ( moment(a[orderBy]).unix() < moment(b[orderBy]).unix() ) {
          return -1;
        }
        return 0;
      } )
    } else {
      _orgList.sort( function ( a, b ) {
        if (a[orderBy] === null) {
          return 1;
        }

        if (b[orderBy] === null) {
          return -1;
        }
        if ( moment(a[orderBy]).unix() < moment(b[orderBy]).unix() ) {
          return 1;
        }
        if ( moment(a[orderBy]).unix() > moment(b[orderBy]).unix() ) {
          return -1;
        }
        return 0;
      } )
    }
  }

  return _orgList;
}

const renderTooltip = ( text ) => (
  <Tooltip id="button-tooltip">
    {text}
  </Tooltip>
);

const OrganizationsDashboard = () => {
	const navigate = useNavigate();
	const baseURL = process.env.REACT_APP_BASE_URL;
	const [ loadingCompaniesList, setLoadingCompaniesList ] = useState( false );
	const [ noOrganization, setNoOrganization ] = useState( false );
	const [ loadingCompStatus, setLoadingCompStatus ] = useState( false );
	const [ editOrganizationID, setEditOrganizationID ] = useState( -1 );
	const [ idOrgForRemoving, setIdOrgForRemoving ] = useState( -1 );
	const [ confirmDeleteShow, setConfirmDeleteShow ] = useState( false );
  const [ isOrderedList, setIsOrderedList ] = useState(false);
  const [ orderByColumnName, setOrderByColumnName ] = useState('');
  const [ orderByASC, setOrderByASC ] = useState(false);
  const [ orderByBool, setOrderByBool ] = useState(false);
  const [ orderByInteger, setOrderByInteger ] = useState(false);
  const [ orderByObject, setOrderByObject ] = useState(false);
  const [ orderByDate, setOrderByDate ] = useState(false);
  const [ searchField, setSearchField ] = useState('');
  const [ filteredList, setFilteredList] = useState([]);
	const updateCompanyList = useRecoilValue( updateCompanyListAtom );
	const [ organizationList, setOrganizationList ] = useRecoilState( organizationListAtom );
  const [ orderedOrganizationList, setOrderedOrganizationList ] = useState([]);
	const setShowCreateOrgModal = useSetRecoilState( showCreateOrgModalAtom );
	const setShowEditOrgModal = useSetRecoilState( showEditOrgModalAtom );
	const context = useContext( AppSettings );
	const role = useRecoilValue( roleAtom );
  const [ param, setParam ] = useState( {} );
  const [ pagination, setPagination ] = useState( {} )

	const toggleActivationCompany = ( id, status ) => {

	}

  const handleNextPage = (e) => {
    e.preventDefault();
    if ( pagination.current_page < pagination.total_pages ) {
      setParam( { page: pagination.next_page } )
      setOrganizationList( [] );
    }
  }

  const handlePrevPage = (e) => {
    e.preventDefault();
    if ( pagination.current_page > 1 ) {
      setParam( { page: pagination.prev_page } )
      setOrganizationList( [] );
    }
  }

	const showConfirmRemoveOrg = ( id ) => {
		setIdOrgForRemoving( id );
		setConfirmDeleteShow( true );
	}
  useEffect(
    () => {
      context.handleSetAppHeaderNone( false );
      context.handleSetAppSidebarNone( false );
      context.handleSetAppContentClass( 'mh-100vh-88' );
      context.handleSetAppTitle( 'Organizations' );
      context.handleSetAppIcon( <OrganizationsIcon /> );
    },
    []
  );

	useEffect( () => {
		// console.log(role)
		if ( role && role !== 'admin' ) {
			navigate( '/home', { replace: true } );
		}
	}, [ role ] )

	const deleteOrganization = () => {
		const config = {
			method: 'delete',
			url: `${baseURL}/organizations/${idOrgForRemoving}`,
			headers: APIHeaders,
			withCredentials: true,
			credentials: "include",
		};
		axios( config )
			.then( ( resp ) => {
				getOrganizationsList();
			} )
			.catch( ( error ) => {
				if ( error.response.status === 500 ) {
					toasterNotify( 'Error', 'Something went wrong.', 'danger' )
				}
				console.log( error );
			} )
		setIdOrgForRemoving( -1 );
		setConfirmDeleteShow( false );
	}

	const editOrgModal = ( id ) => {
		setShowEditOrgModal( true );
		setEditOrganizationID( id );
	}

	const getOrganizationsList = () => {
		setLoadingCompaniesList( true );
		const config = {
			method: 'get',
			url: `${baseURL}/organizations`,
			headers: APIHeaders,
			withCredentials: true,
			credentials: "include",
      params: param,
		};
		axios( config )
			.then( ( response ) => {
				// console.log( response.data.data )
				setOrganizationList( response.data.data.rows );
        setPagination( {
          current_page: response.data.data.current_page,
          prev_page: response.data.data.prev_page,
          next_page: response.data.data.next_page,
          total_count: response.data.data.total_count,
          total_pages: response.data.data.total_pages,
        } )
				setLoadingCompaniesList( false );
			} ).finally( () => {
			setLoadingCompaniesList( false );
		} ).catch( ( error ) => {
      if (error.response.status === 401) {
        navigate( '/user/login' );
      }
			setNoOrganization( true );
		} )
	}

	useEffect( () => {
		if ( !noOrganization ) {
			getOrganizationsList()
		}
	}, [ updateCompanyList, param ] )

  useEffect( () => {
    if (organizationList && organizationList.length) {
      setOrderedOrganizationList(orderList( organizationList, orderByColumnName, orderByASC, isOrderedList, orderByBool, orderByInteger, orderByObject, orderByDate ));
    }
  }, [ organizationList, orderByColumnName, orderByASC ] )

  const handleOrder = ( name, asc, bool, integer, object, isDate ) => {
    setOrderByBool(bool);
    setOrderByInteger(integer);
    setOrderByObject(object);
    setOrderByDate(isDate);
    if ( !isOrderedList ) {
      setIsOrderedList( true );
      setParam( { per_page: 1000 } );
    }

    if ( orderByColumnName !== name ) {
      setOrderByColumnName( name );
    } else {
      setOrderByASC( !asc )
    }
  }

  useEffect( () => {
    if ( searchField === '' ) {
      setFilteredList( orderedOrganizationList )
    }
  }, [ orderedOrganizationList ] )

  const handleSearchSector = ( e ) => {
    setSearchField( e.target.value );
  }

  const OrderedButton = ( {asc, opacity} ) => {

    return <OrderedButtonStyle opacity={opacity}>
      {asc ?
        <i className="mx-2 fa fa-arrow-down"></i> :
        <i className="mx-2 fa fa-arrow-up"></i>
      }
    </OrderedButtonStyle>
  }

  useEffect( () => {
    let _found = [];
    orderedOrganizationList.map( ( comp ) => {
      if ( comp.name?.toUpperCase().indexOf( searchField.toUpperCase() ) >= 0 ||
        comp.company_type?.toUpperCase().indexOf( searchField.toUpperCase() ) >= 0 ||
        comp.payment_details?.toUpperCase().indexOf( searchField.toUpperCase() ) >= 0 ||
        comp.tier?.toUpperCase().indexOf( searchField.toUpperCase() ) >= 0 ||
        comp.id.toString().indexOf( searchField ) >= 0 ||
        moment( comp.activated_date ).format( 'MM-DD-yyyy' ).indexOf(searchField) >= 0 ||
        comp.frequency?.toUpperCase().indexOf( searchField.toUpperCase() ) >= 0 ||
        comp.company_id?.toUpperCase().indexOf( searchField.toUpperCase() ) >= 0) {
        _found.push( comp )
      }
    } );
    console.log(_found);
    setFilteredList( _found );
  }, [ searchField ] )

  const OrderableColumn = ( { title, name, asc, bool, integer, object, isDate, width } ) => {

    return <OrderableColumnStyle
      onClick={() => handleOrder( name, asc, bool, integer, object, isDate, width )}
      style={{minWidth: width+"px"}}
    >{title}
      <OrderedButton
        opacity={name === orderByColumnName ? 1 : 0}
        asc={orderByASC}
      />
    </OrderableColumnStyle>
  }

	return (
		<div className="col-12">
			<Panel>
        <PanelHeader noButton={true}>
          <span className="mx-5 position-relative"><input
            type="text"
            value={searchField}
            onChange={handleSearchSector}
            className="form-control w-200px"
            style={{display: "inline-block"}}
            placeholder="Start typing to filter" />
            {searchField && <i onClick={() => setSearchField( '' )} className="fa fa-times-circle clear-field-button"></i>}
          </span>
        </PanelHeader>
				<PanelBody>
					<div className="table-responsive">
						{loadingCompaniesList ?
							<div className="d-flex justify-content-center text-center">
								<div className="spinner-border m-5" role="status">
									<span className="sr-only">Loading...</span>
								</div>
							</div> :
							<table className="table table-striped mb-0 align-middle">
								<thead>
								<tr>
                  <OrderableColumn title="ID" name="id" asc={orderByASC} integer/>
                  <OrderableColumn title="Name" name="name" asc={orderByASC} width="100"/>
                  <OrderableColumn title="Organization ID" name="company_id" asc={orderByASC} width="125"/>
                  <OrderableColumn title="Type" name="company_type" asc={orderByASC} />
                  <OrderableColumn title="Activated" name="activated" asc={orderByASC} bool width="95"/>
                  <OrderableColumn title="Activation At" name="activated_date" asc={orderByASC} isDate width="115"/>
                  <OrderableColumn title="Payment Date" name="payment_date" asc={orderByASC} isDate width="115"/>
                  <OrderableColumn title="Payment Details" name="payment_details" asc={orderByASC} width="130"/>
                  <OrderableColumn title="Payment Tier" name="tier" asc={orderByASC} width="115"/>
                  <OrderableColumn title="Payment Frequency" name="frequency" asc={orderByASC} width="150" />
									<th width="1%"></th>
								</tr>
								</thead>
								<tbody>
								{filteredList.length ?
									<>
										{filteredList.map( ( row, i ) => {
											let freq = '';
											if (row.frequency && row.frequency !== 'one_time') {
												freq = capitalizeFirstLetter(row.frequency);
											} else if(row.frequency === 'one_time') {
												freq = 'One Time';
											}

											return <tr key={row.id + "-" + i}>
												<td>{row.id}</td>
												<td>{row.name}</td>
												<td>{row.company_id}</td>
												<td>{row.company_type ?? capitalizeFirstLetter( row.company_type )}</td>
												<td>{loadingCompStatus !== row.id ?
													<div className="form-check">
														<input
															className="form-check-input"
															type="checkbox"
															value=""
															disabled={true}
															checked={row.activated}
															onChange={() => toggleActivationCompany( row.id, row.activated )}
														/>
													</div> :
													<div className="spinner-border spinner-border-sm" role="status">
														<span className="sr-only">Loading...</span>
													</div>}
												</td>
												<td>{row.activated_date && moment( row.activated_date ).format( 'MM-DD-yyyy' )}</td>
												<td>{row.payment_date && moment( row.payment_date ).format( 'MM-DD-YY' )}</td>
												<td>
													<OverlayTrigger
														placement="bottom"
														delay={{ show: 250, hide: 400 }}
														overlay={renderTooltip( row.payment_details )}
													>
														<span>{row.payment_details ? kitcut( row.payment_details, 20 ) : ''}</span>
													</OverlayTrigger>
												</td>
												<td>{row.tier && capitalizeFirstLetter( row.tier )}</td>
												<td>{freq}</td>

												<td className="with-btn" nowrap="true">
													<Link to="#"
														  className="btn btn-sm btn-outline-primary w-35px me-1"
														  onClick={() => editOrgModal( row )}
													>
														<i className="fas fa-edit"></i>
													</Link>
													<Link to=""
														className="btn btn-sm btn-outline-danger w-35px me-1"
														onClick={() => showConfirmRemoveOrg( row.id )}
													>
														<i className="fa fa-trash"></i>
													</Link>
												</td>
											</tr>
										} )}
									</>
									: <tr>
										<td colSpan="11">No result</td>
									</tr>
								}
								</tbody>
							</table>}
            <div className="d-flex mt-3 justify-content-between">
              <div>
                <Link to="#"
                      onClick={() => setShowCreateOrgModal( true )}
                      className="btn btn-sm btn-primary w-120px mt-3"
                >
                  New Organization
                </Link>
              </div>

            {pagination && pagination.total_pages > 1 && <div>
              <Link
                to="#"
                className={pagination.current_page > 1 ? "btn btn-sm btn-outline-primary mr-2" : "btn btn-sm btn-outline-primary mr-2 disabled"}
                onClick={handlePrevPage}
              >
                <i className="fa fa-arrow-left"></i>
              </Link>
              <span className="mx-2">{pagination.current_page} of {pagination.total_pages}</span>
              <Link
                to="#"
                className={pagination.current_page < pagination.total_pages ? "btn btn-sm btn-outline-primary" : "btn btn-sm btn-outline-primary disabled"}
                onClick={handleNextPage}
              >
                <i className="fa fa-arrow-right"></i>
              </Link>
            </div>}
            </div>

					</div>
				</PanelBody>
			</Panel>
			<CreateOrganizationModal />
			{editOrganizationID && <EditOrganizationModal organization={editOrganizationID} />}
			<ReactNotifications />
			<SweetAlert
				show={confirmDeleteShow}
				title="Are you sure you want to delete the Organization? The users belonging to it will be deleted as well."
				type="error"
				confirmButtonText="Delete"
				showCancelButton={true}
				onCancel={() => setConfirmDeleteShow( false )}
				confirmButtonColor="rgb(255, 91, 87)"
				onConfirm={() => deleteOrganization()}
			/>
		</div>
	)
}

export default OrganizationsDashboard;
