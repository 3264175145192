
const HealthIcon = ({size}) => {
  let _size = size === 'L' ? 2 : 1;

  return <svg width={_size * 24} height={_size *24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.9749 21.3884L19.7518 13.5962C19.9688 13.3754 20.1658 13.1433 20.3431 12.9H18.325L11.9999 19.25L5.65955 12.9H3.63721C3.819 13.1489 4.02262 13.3861 4.24807 13.6116L11.9749 21.3884Z" fill="#c897c3"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M2.93655 10.6259C2.64552 9.88556 2.5 9.11986 2.5 8.32885C2.5 6.75321 3.00385 5.40642 4.01155 4.28847C5.01923 3.17054 6.26666 2.61157 7.75383 2.61157C8.49613 2.61157 9.20221 2.76189 9.87208 3.06252C10.5419 3.36317 11.1391 3.78401 11.6634 4.32502L12 4.68082L12.3173 4.34425C12.8378 3.79041 13.4346 3.36317 14.1077 3.06252C14.7808 2.76189 15.4852 2.61157 16.2211 2.61157C17.7083 2.61157 18.9599 3.17054 19.9759 4.28847C20.9919 5.40642 21.5 6.74488 21.5 8.30385C21.5 9.10128 21.3519 9.87019 21.0557 10.6106C20.8717 11.0707 20.6341 11.5005 20.3431 11.9H18.474H17.4687H14.5942L13.2231 9.83265L11.7096 14.5615H10.3519L8.5846 11.9H3.63723C3.34866 11.5049 3.1151 11.0802 2.93655 10.6259ZM7.75385 4.11157C6.6923 4.11157 5.80128 4.52279 5.08078 5.34522C4.36026 6.16767 4 7.1539 4 8.3039C4 8.668 4.04103 9.02473 4.12308 9.3741C4.20512 9.72346 4.33013 10.0655 4.4981 10.4001H9.39038L10.7769 12.4674L12.2904 7.7539H13.6327L15.3904 10.4001H19.5019C19.6763 10.0655 19.807 9.72186 19.8942 9.36927C19.9814 9.01671 20.0218 8.66158 20.0154 8.3039C19.9885 7.1539 19.6147 6.17281 18.8942 5.36062C18.1737 4.54844 17.2827 4.14235 16.2212 4.14235C15.6917 4.14235 15.1798 4.24716 14.6856 4.45677C14.1913 4.66639 13.7628 4.97248 13.4 5.37505L12.3115 6.53852H11.6635L10.575 5.37505C10.2122 4.97248 9.78622 4.66126 9.2971 4.4414C8.808 4.22151 8.29358 4.11157 7.75385 4.11157Z" fill="#B668AE"/>
    <path d="M5.08078 5.34522C5.80128 4.52279 6.6923 4.11157 7.75385 4.11157C8.29358 4.11157 8.808 4.22151 9.2971 4.4414C9.78622 4.66126 10.2122 4.97248 10.575 5.37505L11.6635 6.53852H12.3115L13.4 5.37505C13.7628 4.97248 14.1913 4.66639 14.6856 4.45677C15.1798 4.24716 15.6917 4.14235 16.2212 4.14235C17.2827 4.14235 18.1737 4.54844 18.8942 5.36062C19.6147 6.17281 19.9885 7.1539 20.0154 8.3039C20.0218 8.66158 19.9814 9.01671 19.8942 9.36927C19.807 9.72186 19.6763 10.0655 19.5019 10.4001H15.3904L13.6327 7.7539H12.2904L10.7769 12.4674L9.39038 10.4001H4.4981C4.33013 10.0655 4.20512 9.72346 4.12308 9.3741C4.04103 9.02473 4 8.668 4 8.3039C4 7.1539 4.36026 6.16767 5.08078 5.34522Z" fill="#e3cee1"/>
  </svg>
    ;
}

export default HealthIcon;
