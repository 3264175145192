import QuestionsPanel from "./QuestionsPanel";
import SupportModal from "./SupportModal";
import { useRecoilState, useRecoilValue } from "recoil";
import { roleAtom, showCompanyAddMessageModalAtom, showEditMessageModalAtom } from "../../atoms/profileAtom";
import { ReactNotifications } from "react-notifications-component";
import React, { useEffect, useContext } from "react";
import { AppSettings } from "../../config/app-settings";
import CompaniesRequestsPanel from "./CompaniesRequestsPanel";
import SupportAddCompanyModal from "./SupportAddCompanyModal";
import { useNavigate } from "react-router-dom";
import AskUsIcon from "../../components/shared/AskUsIcon";

const SupportDashboard = () => {
  const [ showEditMessageModal, setShowEditMessageModal ] = useRecoilState(showEditMessageModalAtom);
  const [ showCompEditMessageModal, setCompShowEditMessageModal ] = useRecoilState( showCompanyAddMessageModalAtom );
  const context = useContext( AppSettings );
  const role = useRecoilValue( roleAtom );
  const isAdmin = role === 'admin';
  const navigate = useNavigate();

  useEffect(
    () => {
      context.handleSetAppHeaderNone( false );
      context.handleSetAppSidebarNone( false );
      context.handleSetAppContentClass( 'mh-100vh-88' );
      context.handleSetAppTitle( 'Support' );
      context.handleSetAppIcon( <AskUsIcon /> );

      if ( !isAdmin ) {
        navigate( '/', { replace: true } );
      }
    },
    []
  );

  return <>
    <h4 className="fw-400">Ask Us Anything requests</h4>
    <QuestionsPanel />
    <h4 className="mt-4 fw-400">Add Companies requests</h4>
    <CompaniesRequestsPanel />
    {showEditMessageModal && <SupportModal />}
    {showCompEditMessageModal && <SupportAddCompanyModal />}
    <ReactNotifications />
  </>
}

export default SupportDashboard;
