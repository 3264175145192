import { Link, useNavigate } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import { APIHeaders, APIHeadersRAW, toasterNotify } from "./helpers";
import axios from "axios";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  chosenTagsAtom,
  needUpdateCompAtom,
  roleAtom,
  tagsOptionsAtom,
  userIDAtom
} from "../../atoms/profileAtom";
import CreatableSelect from "react-select/creatable";

const TitleTagCustomComp = (
  {
    compName,
    id,
    preview,
    isPopular,
    ownerID,
    taggings,
    marketTracker,
  }
) => {
  const baseURL = process.env.REACT_APP_BASE_URL;
  const navigate = useNavigate();
  const role = useRecoilValue( roleAtom );
  const userID = useRecoilValue( userIDAtom );
  const [ needUpdateComp, setNeedUpdateComp ] = useRecoilState( needUpdateCompAtom );
  const [ tagsOptions, setTagsOptions ] = useRecoilState( tagsOptionsAtom );
  const [ chosenTags, setChosenTags ] = useRecoilState( chosenTagsAtom );
  const [ initTags, setInitTags ] = useState( taggings );
  const [ isPopularComp, setIsPopularComp ] = useState( isPopular );
  const [ tagChanged, setTagChanged ] = useState( false );
  const [ savingTag, setSavingTag ] = useState( false );
  const [ loadedTags, setLoadedTags ] = useState( false );
  const [ tagList, setTagList ] = useState( [] );
  const isAdmin = role === 'admin';

  useEffect( () => {
    if ( !loadedTags ) {
      getTagsList();
      setTagChanged( false );
      setChosenTags(  [] );
    }
  }, [id] )

  useEffect( () => {
    let _tagsList = [];
    if ( taggings && taggings.length > 0 && !_tagsList.includes( taggings ) ) {
      taggings.map( tag => {
        let _val = tag.label;
        if ( !_tagsList.some( obj => obj.label === tag.label ) ) {
          let _tag = { "value": _val, "label": _val, "id": tag.id, "tag_id": tag.tag_id };
          _tagsList.push( _tag );
        }

      } )

      setTagList( _tagsList )
    }

  }, [ tagsOptions, id ] )

  const getTagsList = () => {
    setLoadedTags( false );
    const config = {
      method: 'get',
      url: `${baseURL}/tags`,
      headers: APIHeaders,
      withCredentials: true,
      credentials: "include",
    };
    axios( config )
      .then( ( resp ) => {
        let _options = resp.data.data;
        setTagsOptions( _options );
      } ).finally( () => {
      setLoadedTags( true );
    } )
  }

  const sendRequest = () => {
    const config = {
      method: 'get',
      url: `${baseURL}/custom_comps/${id}/request_pdf_or_csv`,
      headers: APIHeaders,
      withCredentials: true,
      credentials: "include",
    };
    axios( config )
      .then( () => {
        toasterNotify( 'The requested data will be sent to your email soon.', '', 'success' )
      } )
      .catch( error => {
        let _error = error.response.data.error ?? 'Something went wrong';
        toasterNotify( 'Error', _error, 'danger' )
      } )
  }

  const handleMakePopular = () => {
    const data = new FormData();
    data.append( 'custom_comp[default_comp]', !isPopularComp );
    const config = {
      method: 'put',
      url: `${baseURL}/custom_comps/${id}`,
      headers: APIHeaders,
      withCredentials: true,
      credentials: "include",
      data: data,
    };
    axios( config )
      .then( () => {
        toasterNotify( `${marketTracker ? 'Market Tracker ': 'Custom comps'} was ${isPopularComp ? 'removed from' : 'added to'} Popular Custom Comps`, '', 'success' );
        setIsPopularComp( !isPopularComp );
        setNeedUpdateComp( !needUpdateComp );
      } )
  }

  const handleAddToMyList = () => {
    let _copyName = 'Copy of ' + compName;
    let data = {};
    data[ 'custom_comp' ] = {
      "name": _copyName,
    }
    const config = {
      method: 'post',
      url: `${baseURL}/custom_comps/${id}/clone`,
      headers: APIHeadersRAW,
      withCredentials: true,
      credentials: "include",
      data: data,
    };
    axios( config )
      .then( ( response ) => {
        toasterNotify( `Custom comps was added to your list`, '', 'success' );
        if (marketTracker) {
          navigate( '/market-tracker', { replace: true } );
        } else {
          navigate( '/custom-comps', { replace: true } );
        }
      } ).catch( error => {
      console.log( error )
    } )

  }

  const handleChangeTag = ( e ) => {
    setTagChanged( true );
    setChosenTags( e || [] );
    if ( e.length === 0 ) {
      setTagList( [] );
    }
  }

  const handleSaveTag = () => {
    let _existingTags = [];
    let _newTags = [];
    let _forDeletingTags = [];

    if ( initTags.length > 0 ) {
      initTags
        .filter( obj1 => !chosenTags.some( obj2 => obj2.id === obj1.id ) )
        .map( obj => {
          _forDeletingTags.push( { "id": obj.id } )
        } );

      chosenTags
        .filter( obj1 => !initTags.some( obj2 => obj2.id === obj1.id ) )
        .map( obj => {
          if ( obj.id !== undefined ) {
            _existingTags.push( { "tag_id": obj.id } )
          }
        } );
    } else {
      chosenTags.map( tag => {
          if ( tag.id !== undefined ) {
            _existingTags.push( { "tag_id": tag.id } );
          }
        }
      )
    }

    chosenTags.map( tag => {
      if ( tag.id === undefined ) {
        _newTags.push( { "label": tag.label } )
      }
    } );

    console.log( "existing ", _existingTags );
    console.log( "new ", _newTags );
    console.log( "delete ", _forDeletingTags );
    setSavingTag( true );

    if ( _forDeletingTags.length > 0 ) {
      _forDeletingTags.map( tag => {
        console.log( '/api/v1/taggings/', tag.id );
        const config = {
          method: 'delete',
          url: `${baseURL}/taggings/${tag.id}`,
          headers: APIHeadersRAW,
          withCredentials: true,
          credentials: "include",
        };
        axios( config ).catch( ( error ) => {console.log( error )} )
      } )
    }

    let metricData = {};
    metricData[ 'custom_comp' ] = {
      "taggings_attributes": _existingTags,
      "tags": _newTags,
    }

    const config = {
      method: 'put',
      url: `${baseURL}/custom_comps/${id}`,
      headers: APIHeadersRAW,
      withCredentials: true,
      credentials: "include",
      data: metricData,
    };
    axios( config ).then( resp => {
      console.log( resp );
    } ).catch( error => {
      console.log( error );
    } ).finally( () => {
      setSavingTag( false );
      setTagChanged( false );
    } )
  }



  return <>
      {!preview && <div className="d-flex align-items-center pb-3 justify-content-between">
        <div className="d-flex align-items-center">
          <div className="d-flex align-items-center">
            <div className="">
              {loadedTags || tagList ? <CreatableSelect
                onChange={( e ) => handleChangeTag( e )}
                classNamePrefix="select"
                defaultValue={tagList}
                isLoading={tagsOptions.length === 0 && !loadedTags}
                name="tags"
                value={chosenTags.length === 0 ? tagList : chosenTags}
                // value={chosenTags}
                isDisabled={isPopular && !isAdmin}
                isClearable={false}
                isMulti
                placeholder="Select project tag or create new"
                options={tagsOptions}
                styles={{
                  control: ( baseStyles, state ) => ({
                    ...baseStyles,
                    borderRadius: "10px",
                    borderColor: state.isFocused ? '#b787b3 !important' : 'var(--app-component-border-color) ',
                    boxShadow: state.isFocused ? '0 0 0 0.25rem rgba(159, 95, 153, 0.25) !important' : '0 0 0 0.25rem rgba(159, 95, 153, 0)'
                  }),
                }}
              /> : <></>}
            </div>
            {tagChanged && <div className="px-2">
              <Link
                to="#"
                style={{ "padding": "6px 14px" }}
                className={savingTag ? 'btn btn-primary fs-14px disabled' : 'btn btn-primary fs-14px'}
                onClick={handleSaveTag}
              >{!savingTag ? 'Save' : <div className="spinner-border spinner-border-sm" role="status">
                <span className="sr-only">Loading...</span>
              </div>}</Link>
            </div>}
            <div className="w-25px"></div>
          </div>

          {+userID !== +ownerID && !isAdmin ? <div className="w-200px">
            <Link
              to="#"
              className="btn btn-primary"
              onClick={handleAddToMyList}
            >{marketTracker ? 'Add to my Market Trackers' : 'Add to my Custom Comps'}</Link>
          </div> : <></>}

          {isAdmin && <div className="form-check-reverse form-switch">
            <input
              className="form-check-input fs-18px"
              type="checkbox"
              id="isPopular"
              checked={isPopularComp}
              onChange={handleMakePopular}
            />
            <label
              className="form-check-label fz-12px lh-20"
              htmlFor="isPopular">Popular Comp</label>
          </div>}
        </div>
        {(!isPopularComp && !isAdmin) && <Link
          to="#"
          className="fs-14px"
          onClick={() => sendRequest()}
        >Request this data as a PDF or CSV</Link>}

      </div>}

  </>
}

export default TitleTagCustomComp;
