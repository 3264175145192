
const CollapseLegendStaticticIcon = () => {

  return <svg width="25" height="21" viewBox="0 0 25 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M22.6528 7.29297H2.3502C1.38159 7.29297 0.59375 8.08129 0.59375 9.05038V14.7697C0.59375 15.7383 1.38159 16.5266 2.3502 16.5266H2.58171V19.6693C2.58171 19.677 2.58558 19.6833 2.58606 19.691C2.58751 19.7254 2.59718 19.7577 2.60636 19.7906C2.6141 19.8191 2.61845 19.8476 2.63053 19.8737C2.64261 19.8998 2.66195 19.9216 2.67886 19.9453C2.69917 19.9733 2.71705 20.0009 2.74267 20.0241C2.74847 20.0294 2.75088 20.0361 2.75668 20.0415C2.7794 20.0603 2.80647 20.07 2.8316 20.084C2.85142 20.0946 2.86882 20.1091 2.89008 20.1169C2.94663 20.1391 3.0056 20.1521 3.06457 20.1521C3.13417 20.1521 3.19942 20.1362 3.25935 20.1096C3.27482 20.1024 3.2869 20.0893 3.30189 20.0806C3.34587 20.055 3.38647 20.027 3.4203 19.9897C3.42514 19.9844 3.4319 19.9825 3.43674 19.9772L6.29374 16.5262H22.6518C23.6194 16.5262 24.4068 15.7378 24.4068 14.7692V9.05038C24.4078 8.08129 23.6204 7.29297 22.6528 7.29297ZM23.4411 14.7692C23.4411 15.2047 23.0878 15.5595 22.6528 15.5595H6.06754C6.05981 15.5595 6.05304 15.5638 6.04531 15.5638C6.01051 15.5658 5.97812 15.5754 5.94477 15.5841C5.91674 15.5919 5.88919 15.5962 5.86357 15.6083C5.83602 15.6209 5.8133 15.6407 5.78865 15.6586C5.76207 15.6779 5.73549 15.6953 5.71325 15.7195C5.70745 15.7257 5.69972 15.7282 5.6944 15.7344L3.54839 18.3266V16.0423C3.54839 15.775 3.33185 15.559 3.06505 15.559H2.3502C1.91471 15.559 1.56042 15.2047 1.56042 14.7687V9.05038C1.56042 8.61441 1.91471 8.25964 2.3502 8.25964H22.6528C23.0873 8.25964 23.4411 8.61441 23.4411 9.05038V14.7692Z" fill="black"/>
    <path d="M21.252 10.1406H3.74701C3.48021 10.1406 3.26367 10.3572 3.26367 10.624C3.26367 10.8908 3.48021 11.1073 3.74701 11.1073H21.2525C21.5198 11.1073 21.7358 10.8908 21.7358 10.624C21.7358 10.3572 21.5193 10.1406 21.252 10.1406Z" fill="black"/>
    <path d="M12.0913 12.7148H3.74701C3.48021 12.7148 3.26367 12.9309 3.26367 13.1982C3.26367 13.4655 3.48021 13.6815 3.74701 13.6815H12.0918C12.3586 13.6815 12.5752 13.4655 12.5752 13.1982C12.5752 12.9309 12.3586 12.7148 12.0913 12.7148Z" fill="black"/>
    <path d="M1.22379 5.19717C1.19285 5.37842 1.26729 5.5616 1.41615 5.66987C1.56502 5.77814 1.76174 5.79215 1.92511 5.7066L2.99522 5.144L4.06532 5.7066C4.13589 5.74382 4.21322 5.76219 4.29007 5.76219C4.39013 5.76219 4.49018 5.73125 4.57428 5.66987C4.72314 5.5616 4.79758 5.37842 4.76713 5.19717L4.56268 4.00574L5.42833 3.16232C5.5598 3.03375 5.60765 2.84187 5.55062 2.6669C5.49358 2.49193 5.3423 2.36433 5.16056 2.33775L3.96382 2.16374L3.42877 1.07962C3.26588 0.749501 2.72455 0.749501 2.56166 1.07962L2.02709 2.16374L0.830833 2.33775C0.648615 2.36385 0.497814 2.49145 0.440781 2.66642C0.383747 2.84138 0.431114 3.03327 0.563065 3.16184L1.42872 4.00526L1.22379 5.19717ZM2.41763 3.08353C2.5752 3.06082 2.71101 2.96173 2.78158 2.81915L2.99522 2.38608L3.20885 2.81915C3.27942 2.96173 3.41524 3.06082 3.5728 3.08353L4.05131 3.15314L3.70524 3.4905C3.59165 3.60167 3.53945 3.76166 3.56604 3.91826L3.64772 4.39531L3.22045 4.17008C3.14988 4.13286 3.07255 4.11449 2.9957 4.11449C2.91885 4.11449 2.84103 4.13286 2.77095 4.17008L2.34271 4.39531L2.42439 3.91826C2.45098 3.76166 2.39926 3.60167 2.28519 3.4905L1.93912 3.15314L2.41763 3.08353Z" fill="black"/>
    <path d="M7.76466 4.00574L7.56021 5.19668C7.52927 5.37793 7.60371 5.56112 7.75258 5.66939C7.83668 5.73029 7.93624 5.7617 8.03678 5.7617C8.11363 5.7617 8.19096 5.74334 8.26153 5.70612L9.33164 5.144L10.4022 5.70612C10.5646 5.79167 10.7618 5.77717 10.9112 5.66939C11.06 5.56112 11.1345 5.37793 11.1035 5.19668L10.8991 4.00574L11.7648 3.16184C11.8962 3.03327 11.9441 2.84138 11.887 2.66642C11.83 2.49145 11.6782 2.36385 11.496 2.33726L10.2998 2.16374L9.76519 1.07962C9.6023 0.749501 9.06097 0.749501 8.89808 1.07962L8.36351 2.16374L7.16725 2.33726C6.98504 2.36385 6.83375 2.49145 6.77672 2.66642C6.71968 2.84138 6.76705 3.03327 6.899 3.16184L7.76466 4.00574ZM8.75357 3.08353C8.91113 3.06082 9.04695 2.96173 9.11752 2.81915L9.33115 2.38608L9.54479 2.81915C9.61536 2.96173 9.75117 3.06082 9.90874 3.08353L10.3872 3.15314L10.0412 3.49099C9.92759 3.60167 9.87539 3.76214 9.90197 3.91874L9.98366 4.39531L9.55494 4.17056C9.48437 4.13334 9.40752 4.11498 9.33019 4.11498C9.25285 4.11498 9.176 4.13334 9.10543 4.17056L8.6772 4.39531L8.75888 3.91874C8.78547 3.76214 8.73375 3.60215 8.61968 3.49099L8.27361 3.15314L8.75357 3.08353Z" fill="black"/>
    <path d="M14.1019 4.00574L13.8974 5.19668C13.8665 5.37793 13.9409 5.56112 14.0893 5.66939C14.1734 5.73029 14.2734 5.7617 14.3735 5.7617C14.4503 5.7617 14.5272 5.74334 14.5982 5.70612L15.6684 5.144L16.7389 5.70612C16.9023 5.79167 17.0995 5.77765 17.2479 5.66939C17.3968 5.56112 17.4712 5.37793 17.4398 5.19668L17.2353 4.00574L18.101 3.16184C18.2325 3.03327 18.2798 2.84138 18.2233 2.66642C18.1662 2.49145 18.015 2.36385 17.8327 2.33726L16.636 2.16374L16.1014 1.07962C15.939 0.749501 15.3972 0.749501 15.2348 1.07962L14.7002 2.16374L13.5045 2.33726C13.3222 2.36385 13.1714 2.49145 13.1144 2.66642C13.0578 2.84138 13.1047 3.03327 13.2367 3.16184L14.1019 4.00574ZM15.0908 3.08353C15.2483 3.06082 15.3841 2.96173 15.4547 2.81915L15.6684 2.38608L15.882 2.81915C15.9526 2.96173 16.0884 3.06082 16.2459 3.08353L16.7249 3.15314L16.3784 3.49099C16.2648 3.60167 16.2126 3.76214 16.2397 3.91874L16.3213 4.39531L15.8926 4.17056C15.8221 4.13334 15.7452 4.11498 15.6679 4.11498C15.5905 4.11498 15.5137 4.13334 15.4431 4.17056L15.0149 4.39531L15.0966 3.91874C15.1236 3.76214 15.0714 3.60215 14.9578 3.49099L14.6113 3.15314L15.0908 3.08353Z" fill="black"/>
    <path d="M24.5602 2.66642C24.5031 2.49145 24.3519 2.36385 24.1696 2.33726L22.9729 2.16374L22.4383 1.07962C22.2759 0.749501 21.7341 0.749501 21.5717 1.07962L21.0371 2.16374L19.8409 2.33726C19.6587 2.36385 19.5079 2.49145 19.4503 2.66642C19.3938 2.84138 19.4407 3.03375 19.5726 3.16184L20.4388 4.00574L20.2343 5.19668C20.2034 5.37793 20.2778 5.56112 20.4262 5.66939C20.5755 5.77814 20.7727 5.79167 20.9351 5.70612L22.0053 5.144L23.0754 5.70612C23.1459 5.74334 23.2233 5.7617 23.3001 5.7617C23.4002 5.7617 23.5002 5.73077 23.5843 5.66939C23.7332 5.56112 23.8076 5.37793 23.7762 5.19668L23.5717 4.00574L24.4379 3.16184C24.5698 3.03327 24.6167 2.84138 24.5602 2.66642ZM22.7153 3.4905C22.6017 3.60167 22.5495 3.76166 22.5766 3.91826L22.6582 4.39483L22.23 4.17008C22.1594 4.13286 22.0826 4.11449 22.0053 4.11449C21.9279 4.11449 21.8511 4.13286 21.7805 4.17008L21.3523 4.39483L21.4339 3.91826C21.461 3.76166 21.4088 3.60167 21.2952 3.4905L20.9487 3.15314L21.4272 3.08354C21.5848 3.06082 21.7206 2.96173 21.7911 2.81915L22.0048 2.38608L22.2184 2.81915C22.289 2.96173 22.4248 3.06082 22.5824 3.08354L23.0609 3.15314L22.7153 3.4905Z" fill="black"/>
  </svg>;
}

export default CollapseLegendStaticticIcon;
