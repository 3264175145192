
const D2cIcon = ({size}) => {
  let _size = size === 'L' ? 2 : 1;

  return <svg width={_size * 20} height={_size * 19} viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2.00001 14.7885V17.0001H18V14.7885H2.00001ZM5.50003 4.50012V0.884766H14.5V4.50012H19.5V18.5001H0.500031V4.50012H5.50003ZM2.00001 12.2116H18V6.00009H14.5V7.80777H13V6.00009H7.00001V7.80777H5.50003V6.00009H2.00001V12.2116ZM7.00001 4.50012H10H13V2.38474H7.00001V4.50012Z" fill="#B668AE"/>
  </svg>;
}

export default D2cIcon;
