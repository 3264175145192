
const RealEstateIcon = () => {

  return <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.5 13.0938L3.03096 13.0527H2.91589V17.3808H4.70005L6.02528 17.2699L12.3159 18.8715L16.982 17.5189L17.3074 16.8695L17.4053 15.0096L11.5977 14.4844L7.5 13.0938Z" fill="#e3cee1"/>
    <path d="M12.2564 19.1506L6.1666 17.4134V18.5576H2V10.5769H7.9679L14.3878 12.9487V14.4551H18.5063V17.1954L12.2564 19.1506ZM3.24996 17.3076H4.91663V11.8269H3.24996V17.3076ZM12.2147 17.8333L17.2051 16.2836V15.7051H11.3653L8.76919 14.8461L9.157 13.6554L11.5945 14.4551H13.1378V13.8012L7.75635 11.8269H6.1666V16.1217L12.2147 17.8333Z" fill="#B668AE"/>
    <path d="M16.7756 7.13137V12.7644H18.0256V6.5144L12.4006 2.5L6.77563 6.5144V8.87821H8.02559V7.13137L12.4006 4.07046L16.7756 7.13137Z" fill="#c897c3"/>
    <path d="M13.6106 7.62017H12.8573V6.86696H13.6106V7.62017Z" fill="#c897c3"/>
    <path d="M11.9439 7.62017H11.1907V6.86696H11.9439V7.62017Z" fill="#c897c3"/>
    <path d="M13.6106 9.28683H12.8573V8.53363H13.6106V9.28683Z" fill="#c897c3"/>
    <path d="M11.9439 9.28683H11.1907V8.53363H11.9439V9.28683Z" fill="#c897c3"/>
  </svg>;
}

export default RealEstateIcon;
