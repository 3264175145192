import React, { useEffect, useState,useContext } from 'react';
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { Panel, PanelBody, PanelHeader } from "../../components/panel/panel.jsx";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  organizationListAtom,
  roleAtom,
  sessionAtom,
  showCreateUserModalAtom,
  showEditUserModalAtom,
  updateUserListAtom,
} from "../../atoms/profileAtom";
import EditUserModal from "../../components/modals/EditUserModal";
import { ReactNotifications } from "react-notifications-component";
import CreateUserModal from "../../components/modals/CreateUserModal";
import { APIHeaders, capitalizeFirstLetter, toasterNotify } from "../../components/shared/helpers";
import SweetAlert from 'sweetalert-react';
import { AppSettings } from "../../config/app-settings";
import styled from "styled-components";
import moment from "moment";
import UsersIcon from "../../components/shared/UsersIcon";

const OrderedButtonStyle = styled.span`
  cursor: pointer;
  opacity: ${props => props.opacity};
`;

const OrderableColumnStyle = styled.th`
  cursor: pointer;
  background-color: #fff;
  transition: all 0.3s;
  vertical-align: top;
  min-width: 50px;
  padding: 5px!important;
  
  &:hover {
    background-color: #eeeeee;
  }
`;

const orderList = (list, orderBy, asc, isOrdered, bool, integer, object) => {
  let _list = list;
  // console.log(_list, orderBy, object);

  if (!bool && !integer && !object && isOrdered) {
    if (asc) {
      _list.sort( function ( a, b ) {
        if (a[orderBy] === '' || a[orderBy] === null) {
          return 1;
        }
        if (b[orderBy] === '' || b[orderBy] === null) {
          return -1;
        }
        if ( a[orderBy].toLowerCase() > b[orderBy].toLowerCase() ) {
          return 1;
        }
        if ( a[orderBy].toLowerCase() < b[orderBy].toLowerCase() ) {
          return -1;
        }
        return 0;
      } )
    } else {
      _list.sort( function ( a, b ) {
        if (a[orderBy] === '' || a[orderBy] === null) {
          return 1;
        }
        if (b[orderBy] === '' || b[orderBy] === null) {
          return -1;
        }
        if ( a[orderBy].toLowerCase() < b[orderBy].toLowerCase() ) {
          return 1;
        }
        if ( a[orderBy].toLowerCase() > b[orderBy].toLowerCase() ) {
          return -1;
        }
        return 0;
      } )
    }
  }

  if (bool) {
    if ( asc ) {
      _list = [...list].sort((a, b) => b[orderBy] - a[orderBy]);
    } else {
      _list = [...list].sort((a, b) => a[orderBy] - b[orderBy]);
    }
  }

  if (integer) {
    if (asc) {
      _list.sort( function ( a, b ) {
        if (a[orderBy] === null) {
          return 1;
        }
        if (b[orderBy] === null) {
          return -1;
        }
        if ( a[orderBy] > b[orderBy] ) {
          return 1;
        }
        if ( a[orderBy] < b[orderBy] ) {
          return -1;
        }
        return 0;
      } )
    } else {
      _list.sort( function ( a, b ) {
        if (a[orderBy] === null) {
          return 1;
        }
        if (b[orderBy] === null) {
          return -1;
        }
        if ( a[orderBy] < b[orderBy] ) {
          return 1;
        }
        if ( a[orderBy] > b[orderBy] ) {
          return -1;
        }
        return 0;
      } )
    }
  }

  if (object) {
    if (asc) {
      _list.sort( function ( a, b ) {
        if (a[orderBy].name === undefined) {
          return 1;
        }
        if (b[orderBy].name === undefined) {
          return -1;
        }
        if ( a[orderBy].name > b[orderBy].name ) {
          return 1;
        }
        if ( a[orderBy].name < b[orderBy].name ) {
          return -1;
        }
        return 0;
      } )
    } else {
      _list.sort( function ( a, b ) {
        if (a[orderBy].name === undefined) {
          return 1;
        }
        if (b[orderBy].name === undefined) {
          return -1;
        }
        if ( a[orderBy].name < b[orderBy].name ) {
          return 1;
        }
        if ( a[orderBy].name > b[orderBy].name ) {
          return -1;
        }
        return 0;
      } )
    }
  }

  return _list;
}

const UserDashboard = () => {
	const navigate = useNavigate();
	const [ userList, setUserList ] = useState( {} );
	const [ orderedUserList, setOrderedUserList] = useState( {} );
	const [ editUserID, setUserID ] = useState( {} );
	const [ param, setParam ] = useState( {} );
	const [ pagination, setPagination ] = useState( {} )
	const [ loadingUserList, setLoadingUserList ] = useState( false );
	const [ noOrganization, setNoOrganization ] = useState( false );
	const [ loadingOgrList, setLoadingOrgList ] = useState( false );
	const [ loadingStatus, setLoadingStatus ] = useState( false );
	const [ loadingOTPStatus, setLoadingOTPStatus ] = useState( false );
	const [ confirmDeleteShow, setConfirmDeleteShow ] = useState( false );
	const [ updateUserList, setUpdateUserList ] = useRecoilState( updateUserListAtom );
	const setShowCreateUserModal = useSetRecoilState( showCreateUserModalAtom );
	const setEditCreateUserModal = useSetRecoilState( showEditUserModalAtom );
	const [ idUserForRemoving, setIdUserForRemoving ] = useState( -1 );
  const [ isOrderedList, setIsOrderedList ] = useState(false);
  const [ orderByColumnName, setOrderByColumnName ] = useState('');
  const [ orderByASC, setOrderByASC ] = useState(false);
  const [ orderByBool, setOrderByBool ] = useState(false);
  const [ orderByInteger, setOrderByInteger ] = useState(false);
  const [ orderByObject, setOrderByObject ] = useState(false);
  const [ searchField, setSearchField ] = useState('');
  const [ filteredList, setFilteredList] = useState([]);
	const [ organizationList, setOrganizationList ] = useRecoilState( organizationListAtom );
	const baseURL = process.env.REACT_APP_BASE_URL;
	const context = useContext( AppSettings );
	const sessionID = useRecoilValue( sessionAtom );
	const role = useRecoilValue( roleAtom );
  const paramOrganization = { 'per_page': 50 };

  useEffect(
    () => {
      context.handleSetAppHeaderNone( false );
      context.handleSetAppSidebarNone( false );
      context.handleSetAppContentClass( 'mh-100vh-88' );
      context.handleSetAppTitle( 'Users' );
      context.handleSetAppIcon( <UsersIcon /> );
    },
    []
  );

	useEffect(()=>{
		if(role && role !== 'admin' ) {
			navigate( '/home', { replace: true } );
		}
	}, [role])

  useEffect( () => {
    if (userList && userList.length) {
      setOrderedUserList(orderList( userList, orderByColumnName, orderByASC, isOrderedList, orderByBool, orderByInteger, orderByObject ));
    }
  }, [ userList, orderByColumnName, orderByASC ] )

	const getUserList = () => {
		setLoadingUserList( true );
		const config = {
			method: 'get',
			url: `${baseURL}/users`,
			headers: APIHeaders,
			withCredentials: true,
			credentials: "include",
			params: param,
		};
		axios( config )
			.then( ( response ) => {
				if ( response.data.data ) {
					setUserList( response.data.data.rows );
					setPagination( {
						current_page: response.data.data.current_page,
						prev_page: response.data.data.prev_page,
						next_page: response.data.data.next_page,
						total_count: response.data.data.total_count,
						total_pages: response.data.data.total_pages,
					} )
				}
				// console.log( response.error )
			} )
			.catch( ( error ) => {
				// console.log( error.response.status );
				if (error.response.status === 401) {
					navigate( '/user/login' );
				}
			} ).finally( () => {
			setLoadingUserList( false );
		} )
	}

	const handleNextPage = (e) => {
    e.preventDefault();
		if ( pagination.current_page < pagination.total_pages ) {
      setOrderedUserList( [] );
			setParam( { page: pagination.next_page } )
		}
	}

	const handlePrevPage = (e) => {
    e.preventDefault();
		if ( pagination.current_page > 1 ) {
      setOrderedUserList( [] );
			setParam( { page: pagination.prev_page } )
		}
	}

	const getOrganizationsList = () => {
		setLoadingOrgList( true );
		const config = {
			method: 'get',
			url: `${baseURL}/organizations`,
			headers: APIHeaders,
			withCredentials: true,
			credentials: "include",
      params: paramOrganization
		};
		axios( config )
			.then( ( response ) => {
				setOrganizationList( response.data.data.rows )
				// console.log(response.data.data.rows)
			} ).catch( (error) => {
			if(error.response.status === 500) {
				toasterNotify( 'Error', 'Something went wrong.', 'danger' )
			}
			setNoOrganization( true );
			// setOrganizationList( { name: 'Error' } )
		} ).finally( () => {
			setLoadingOrgList( false );
		} )
	}

	const toggleActivationUser = ( id, activated ) => {
		setLoadingStatus( id );
		const data = new FormData();
		data.append( 'user[activated]', !activated );
		const config = {
			method: 'put',
			url: `${baseURL}/users/${id}`,
			headers: APIHeaders,
			withCredentials: true,
			credentials: "include",
			data: data,
		};
		axios( config )
      .then( () => {
			setUpdateUserList( !updateUserList );
			setLoadingStatus( false );
		} )
      .catch(error => {
        if (error.response.data.activated) {
          toasterNotify( 'Error', error.response.data.activated, 'danger' )
        }
        // console.log(error.response.data.activated);
      })
      .finally( () => {
			setLoadingStatus( false )
		} )
	}

	const toggle2FAUser = ( id, activated ) => {
		setLoadingOTPStatus( id );
		const data = new FormData();
		data.append( 'user[otp_required_for_login]', !activated );
		const config = {
			method: 'put',
			url: `${baseURL}/users/${id}`,
			headers: APIHeaders,
			withCredentials: true,
			credentials: "include",
			data: data,
		};
		axios( config ).then( () => {
			setUpdateUserList( !updateUserList );
			setLoadingOTPStatus( false );
		} ).finally( () => {
			setLoadingOTPStatus( false )
		} )
	}

	const showConfirmRemoveUser = ( id ) => {
		setIdUserForRemoving( id );
		setConfirmDeleteShow( true );
	}

	const deleteUser = () => {
		const config = {
			method: 'delete',
			url: `${baseURL}/users/${idUserForRemoving}`,
			headers: APIHeaders,
			withCredentials: true,
			credentials: "include",
		};
		axios( config )
			.then( ( resp ) => {
				getUserList();
			} )
			.catch( ( error ) => {
				if(error.response.status === 500) {
					toasterNotify( 'Error', 'Something went wrong.', 'danger' )
				}
				console.log( error );
			} ).finally( () => {
			setIdUserForRemoving( -1 );
			setConfirmDeleteShow( false );
		} )

	}

	const sendInvite = (id) => {
		const config = {
			method: 'post',
			url: `${baseURL}/users/${id}/resend_invitation`,
			headers: APIHeaders,
			withCredentials: true,
			credentials: "include",
		};
		axios(config).then((resp)=>{
			console.log(resp)
		}).catch((error)=>{
			if(error.response.status === 500) {
				toasterNotify( 'Error', 'Something went wrong.', 'danger' )
			}
			console.log(error)
		})
	}

	useEffect( () => {
		if ( !noOrganization ) {
			getOrganizationsList()
		}
	},[] )

	useEffect( () => {
		getUserList();
	}, [ updateUserList, param ] )

  const handleOrder = ( name, asc, bool, integer, object ) => {
    setOrderByBool(bool);
    setOrderByInteger(integer);
    setOrderByObject(object);
    if ( !isOrderedList ) {
      setIsOrderedList( true );
      setParam( { per_page: 1000 } );
    }

    if ( orderByColumnName !== name ) {
      setOrderByColumnName( name );
    } else {
      setOrderByASC( !asc )
    }
  }

  useEffect( () => {
    if ( searchField === '' ) {
      setFilteredList( orderedUserList )
    }
  }, [ orderedUserList ] )

  const handleSearchSector = ( e ) => {
    setSearchField( e.target.value );
  }

  useEffect( () => {
    let _found = [];
    if (orderedUserList && orderedUserList.length) {
      orderedUserList.map( ( user ) => {
        if ( user.full_name && user.full_name.toUpperCase().indexOf( searchField.toUpperCase() ) >= 0 ||
          user.phone && user.phone.indexOf( searchField ) >= 0 ||
          user.id.toString().indexOf( searchField ) >= 0 ||
          user.role.toUpperCase().indexOf( searchField.toUpperCase() ) >= 0 ||
          user.email.toUpperCase().indexOf( searchField.toUpperCase() ) >= 0 ||
          user.organization.name?.toUpperCase().indexOf( searchField.toUpperCase() ) >= 0 ||
          moment( user.activated_at ).format( 'MM-DD-yyyy' ).indexOf(searchField) >= 0 ||
          user.invited_by.name?.toUpperCase().indexOf( searchField.toUpperCase() ) >= 0 ) {
          _found.push( user )
        }
      } );
    }
    setFilteredList( _found );
  }, [ searchField ] )

  const OrderedButton = ( {asc, opacity} ) => {

    return <OrderedButtonStyle opacity={opacity}>
      {asc ?
        <i className="mx-2 fa fa-arrow-down"></i> :
        <i className="mx-2 fa fa-arrow-up"></i>
      }
    </OrderedButtonStyle>
  }

  const OrderableColumn = ( { title, name, asc, bool, integer, object, width } ) => {

    return <OrderableColumnStyle
      style={{minWidth: width+"px"}}
      onClick={() => handleOrder( name, asc, bool, integer, object )}
    >{title}
      <OrderedButton
        opacity={name === orderByColumnName ? 1 : 0}
        asc={orderByASC}
      />
    </OrderableColumnStyle>
  }

  if (loadingOgrList) {
    return <div className="d-flex justify-content-center align-items-center text-center min-vh-100">
      <div className="spinner-border m-5" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  }

	return (
		<div className="col-12">
			<Panel>
				<PanelHeader noButton={true}>
          <span className="mx-5 position-relative"><input
            type="text"
            value={searchField}
            onChange={handleSearchSector}
            className="form-control w-200px"
            style={{display: "inline-block"}}
            placeholder="Start typing to filter" />
            {searchField && <i onClick={() => setSearchField( '' )} className="fa fa-times-circle clear-field-button"></i>}
          </span>
        </PanelHeader>
				<PanelBody>
					<div className="table-responsive">
						{loadingUserList ?
							<div className="d-flex justify-content-center text-center">
								<div className="spinner-border m-5" role="status">
									<span className="sr-only">Loading...</span>
								</div>
							</div>
							:
							<table className="table table-striped mb-0 align-middle">
								<thead>
								<tr>
                  <OrderableColumn title="ID" name="id" asc={orderByASC} integer/>
                  <OrderableColumn title="Name" name="full_name" asc={orderByASC} width="100"/>
                  <OrderableColumn title="Email Address" name="email" asc={orderByASC} />
                  <OrderableColumn title="Phone" name="phone" asc={orderByASC} />
                  <OrderableColumn title="Role" name="role" asc={orderByASC} />
                  <OrderableColumn title="Activated" name="activated" asc={orderByASC} bool width="90"/>
                  <OrderableColumn title="Activated At" name="activated_at" asc={orderByASC} date width="105"/>
                  <OrderableColumn title="2FA" name="otp_required_for_login" asc={orderByASC} bool width="60"/>
                  <OrderableColumn title="Invited by" name="invited_by" asc={orderByASC} object width="95"/>
                  <OrderableColumn title="Month in" name="month_in" asc={orderByASC} integer width="90"/>
                  <OrderableColumn title="Organization" name="organization" asc={orderByASC} object width="110"/>
									<th width="1%"></th>
								</tr>
								</thead>
								<tbody>
								{filteredList && filteredList.length ?
									<>
										{filteredList.map( ( row, i ) => {

											return <tr key={i}>
												<td>{row.id}</td>
												<td>{row.full_name}</td>
												<td>{row.email}</td>
												<td>{row.phone}</td>
												<td>{capitalizeFirstLetter( row.role )}</td>
												<td>{loadingStatus !== row.id ?
													<div className="form-check">
														<input
															className="form-check-input"
															type="checkbox"
															value=""
															checked={row.activated}
															onChange={() => toggleActivationUser( row.id, row.activated )}
														/>
													</div> :
													<div className="spinner-border spinner-border-sm" role="status">
														<span className="sr-only">Loading...</span>
													</div>}
												</td>
                        <td>{row.activated_at && moment( row.activated_at ).format( 'MM-DD-yyyy' )}</td>
												<td>{loadingOTPStatus !== row.id ?
													<div className="form-check">
														<input
															className="form-check-input"
															type="checkbox"
															value=""
															checked={row.otp_required_for_login}
															onChange={() => toggle2FAUser( row.id, row.otp_required_for_login )}
														/>
													</div> :
													<div className="spinner-border spinner-border-sm" role="status">
														<span className="sr-only">Loading...</span>
													</div>}
												</td>
												<td>{row.invited_by ? row.invited_by[ 'name' ] : ''}</td>
												<td>{row.month_in}</td>
												<td>{row.organization?.name}</td>
												<td className="with-btn text-right" nowrap="true" style={{ textAlign: 'right' }}>
													{!row.invite_accepted &&
														<Link to="#"
															  className="btn btn-sm btn-outline-primary w-105px me-1"
															  onClick={()=>sendInvite(row.id)}
														>
															Resend Invite
														</Link>}
													<Link to="#"
														  className="btn btn-sm btn-outline-primary w-35px me-1"
														  onClick={() => {
															  setUserID( row );
															  setEditCreateUserModal( true );
														  }}
													>
														<i className="fas fa-edit"></i>
													</Link>
													<Link to="#"
														  className="btn btn-sm btn-outline-danger w-35px me-1"
														  onClick={() => showConfirmRemoveUser( row.id )}
													><i className="fa fa-trash"></i></Link>
												</td>
											</tr>
										} )}
									</> : <tr>
                    <td colSpan="12">No result</td>
                  </tr>
								}
								</tbody>
							</table>}
						<div className="d-flex mt-3 justify-content-between">
							<div>
								<Link to="#"
									  onClick={() => setShowCreateUserModal( true )}
									  className="btn btn-sm btn-primary w-120px"
								>New User</Link>
							</div>
							{pagination && pagination.total_pages > 1 && <div>
								<Link
                  to="#"
									className={pagination.current_page > 1 ? "btn btn-sm btn-outline-primary mr-2" : "btn btn-sm btn-outline-primary mr-2 disabled"}
									onClick={handlePrevPage}
								>
									<i className="fa fa-arrow-left"></i>
								</Link>
								<span className="mx-2">{pagination.current_page} of {pagination.total_pages}</span>
								<Link
                  to="#"
									className={pagination.current_page < pagination.total_pages ? "btn btn-sm btn-outline-primary" : "btn btn-sm btn-outline-primary disabled"}
									onClick={handleNextPage}
								>
									<i className="fa fa-arrow-right"></i>
								</Link>
							</div>}
						</div>
					</div>
				</PanelBody>
			</Panel>
			{editUserID && <EditUserModal user={editUserID} />}
			<CreateUserModal />
			<ReactNotifications />
			<SweetAlert
				show={confirmDeleteShow}
				title="Are you sure you want to delete the user?"
				type="error"
				confirmButtonText="Delete"
				showCancelButton={true}
				onCancel={() => setConfirmDeleteShow( false )}
				confirmButtonColor="rgb(255, 91, 87)"
				onConfirm={() => deleteUser()}
			/>
		</div>
	)
};

export default UserDashboard;
