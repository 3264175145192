import React from 'react';
import { Outlet } from 'react-router-dom';
import App from './../app.jsx';

import Home from './../pages/home/home.js';
import Login from '../pages/user/login.js';
import UserDashboard from "../pages/user/userDashboard";
import OrganizationsDashboard from "../pages/organizations/OrganizationDashboard";
import ResetPassword from "../pages/ResetPassword";
import AcceptInvitation from "../pages/AcceptInvitation";
import Companies from "../pages/Companies";
import MyCompsDashboard from "../pages/custom-comps/Dashboard";
import NewCompSet from "../pages/custom-comps/new-comp-set";
import MarketTracker from "../pages/market-tracker/MarketTracker";
import GoogleMaps from "../pages/Maps/GoogleMaps";
import FAQPage from "../pages/FAQ/FAQPage";
import MetricsInfo from "../pages/FAQ/MetricsInfo";
import CombineChart from "../pages/Chart/CombineChart";
import TableChart from "../pages/Chart/TableChart";
import ScatterPlot from "../pages/Chart/ScatterPlot";
import MarketTrackerDashboard from "../pages/market-tracker/Dashboard";
import NewMarketTrackerSet from "../pages/market-tracker/new-market-tracker-set";
import MarketTrackerMap from "../pages/market-tracker/MarketTrackerMap";
import MarketTrackerTimeline from "../pages/market-tracker/MarketTrackerTimeline";
import InvestmentTable from "../pages/custom-comps/InvestmentTable";
import StoreListTable from "../pages/custom-comps/StoreListTable";
import CustomCompID from "../pages/custom-comps/CustomCompID";
import CompanyProfile from "../pages/Company/CompanyProfile";
import AskUsAnything from "../pages/AskUsAnything";
import SupportDashboard from "../pages/AskUsAnything/SupportDashboard";
import Page404 from "../pages/Page404";
import Triangle from "../pages/TestPage";
import MapByCompanyId from "../pages/Maps/mapByCompanyId";
import MediaLibrary from "../pages/MediaLibrary";
import MarketInsights from "../pages/MarketInsights";

const AppRoute = [
  {
    path: '*',
    element: <App />,
    children: [
    	{
				path: '',
				element: <Home />
			},
    	{
				path: 'home',
				element: <Home />
			},
      	{
				path: 'user/*',
				element: <Outlet />,
				children: [
					{ path: 'login', element:<Login /> },
					{ path: 'dashboard', element:<UserDashboard /> },
				]
			},
		{
			path: 'new-comp-set',
			element:<NewCompSet />
		},
		{
			path: 'custom-comps',
			element: <Outlet />,
			children: [
				{ path: '', element: <MyCompsDashboard /> },
				{
					path: 'table/:id',
					element: <TableChart />
				},
				{
					path: 'chart/:id',
					element: <CombineChart />
				},
				{
					path: 'scatter-plot/:id',
					element: <ScatterPlot />
				},
				{
					path: 'list/:id',
					element: <InvestmentTable />
				},
				{
					path: 'store-list/:id',
					element: <StoreListTable />
				},
				{
					path: ':id',
					element: <CustomCompID />
				},
			]
		},
		{
			path: 'popular-custom-comps',
			element: <MyCompsDashboard popular />
		},
		{
			path: 'organizations',
			element: <OrganizationsDashboard />,
		},
		{
			path: 'reset_password',
			element: <ResetPassword />
		},
		{
			path: 'invitation_accept',
			element: <AcceptInvitation />
		},
		{
			path: 'companies',
			element: <Outlet />,
			children: [
				{
					path: '',
					element: <Companies />
				},
				{
					path: ':id',
					element: <CompanyProfile />
				},
				{
					path: ':id/map',
					element: <MapByCompanyId />
				}
			]

		},
		{
			path: 'market-tracker',
			element: <MarketTrackerDashboard />
		},
		{
			path: 'market-tracker/map/:id',
			element: <MarketTrackerMap />
		},
		{
			path: 'market-tracker/timeline/:id',
			element: <MarketTrackerTimeline />
		},
		{
			path: 'market-tracker/new-market-tracker',
			element: <NewMarketTrackerSet />
		},
		{
			path: 'market-tracker/new-market-tracker-old',
			element: <MarketTracker />
		},
		{
			path: 'map-proto',
			element: <GoogleMaps />
		},
		{
			path: 'map-proto/:stateParam',
			element: <GoogleMaps />
		},
		{
			path: 'faq',
			element: <FAQPage />
		},
		{
			path: 'metrics-info',
			element: <MetricsInfo />
		},
		{
			path: 'company/:id',
			element: <CompanyProfile />
		},
		{
			path: 'ask-us',
			element: <AskUsAnything />
		},
		{
			path: 'support',
			element: <SupportDashboard />
		},
		{
			path: 'market-insights',
			element: <MarketInsights />
		},
		{
			path: 'media-library',
			element: <MediaLibrary />
		},
		{
			path: '*',
			element: <Page404 />
		},
		{
			path: 'triangle-of-fate',
			element: <Triangle />
		}
	]
  }
];


export default AppRoute;
