
const SearchIconBig = () => {

  return <svg width="86" height="88" viewBox="0 0 86 88" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_125_3774" mask-type="alpha" maskUnits="userSpaceOnUse" x="5" y="7" width="81" height="81">
      <ellipse cx="45.6523" cy="47.5" rx="40" ry="40" fill="url(#paint0_linear_125_3774)"/>
    </mask>
    <g mask="url(#mask0_125_3774)">
      <circle opacity="0.8" cx="45.9995" cy="48" r="40" fill="url(#paint1_linear_125_3774)"/>
      <rect x="43.002" y="50.2479" width="5.95041" height="9.25619" transform="rotate(-39.4833 43.002 50.2479)" fill="#66B39C"/>
      <path d="M47.5655 51.6281L43.4224 50.7582L43.002 50.2479L47.5945 46.4643L53.4801 53.6084L50.9287 55.7104L47.5655 51.6281Z" fill="#62AC96"/>
      <ellipse cx="28.6475" cy="28.6476" rx="19.8347" ry="19.8347" transform="rotate(5.51676 28.6475 28.6476)" fill="#e3cee1" fillOpacity="0.2"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M22.3122 47.443C22.1064 47.3735 21.9011 47.3005 21.6962 47.2238C11.4366 43.3845 6.23195 31.9551 10.0713 21.6955C13.9106 11.4359 25.34 6.23128 35.5995 10.0706C40.2663 11.817 43.8872 15.1339 46.0868 19.1968C44.0741 15.6007 40.8333 12.7346 36.6063 11.1528C26.3467 7.31346 14.9173 12.5181 11.078 22.7777C7.38452 32.6475 12.2799 43.3379 22.3122 47.443ZM47.1097 35.8983C47.1486 35.799 47.1869 35.6992 47.2244 35.5989C48.5965 31.9324 48.8135 28.1166 48.0576 24.5512C48.7723 28.101 48.5068 31.9991 47.1097 35.8983Z" fill="#B668AE"/>
      <g filter="url(#filter0_d_125_3774)">
        <path d="M51.6808 30.8712C50.4522 43.5921 39.1438 52.9085 26.4229 51.6798C13.7019 50.4512 4.38558 39.1428 5.61422 26.4219C6.84286 13.7009 18.1512 4.38457 30.8722 5.61321C43.5931 6.84185 52.9094 18.1502 51.6808 30.8712Z" stroke="#66B39C" strokeWidth="6.61157" shapeRendering="crispEdges"/>
      </g>
      <path d="M16.557 23.1616C16.9958 18.6184 21.0345 15.2911 25.5777 15.7299" stroke="#D2E8E5" strokeWidth="3.30578" strokeLinecap="round"/>
      <path d="M47.0898 59.5965L48.8461 57.4436C48.8736 57.4099 48.9044 57.379 48.938 57.3513L53.4295 53.6509C53.4631 53.6233 53.4994 53.599 53.5377 53.5784L55.9869 52.2666C56.2589 52.121 56.5955 52.1828 56.798 52.4156L88.8937 89.3236C88.965 89.4055 89.015 89.5038 89.0393 89.6096L89.2414 90.4911C89.2974 90.7352 89.2107 90.99 89.0174 91.1492L78.9321 99.4579C78.7389 99.6172 78.4722 99.6535 78.2433 99.5519L77.4168 99.1847C77.3176 99.1406 77.2307 99.0728 77.1639 98.9871L47.0808 60.4211C46.891 60.1778 46.8948 59.8356 47.0898 59.5965Z" fill="#B668AE"/>
      <path d="M52.355 57.9622C50.7098 57.8032 47.5712 61.0467 47.5712 61.0467L77.2656 99.1172L78.8719 99.5072L84.2299 95.093C84.2299 95.093 53.7511 58.0971 52.355 57.9622Z" fill="#478A7D"/>
      <path d="M54.7412 55.1395L58.9452 60.2424" stroke="#50A093" strokeWidth="1.32231" strokeLinecap="round"/>
    </g>
    <rect x="43.0015" y="50.2292" width="5.95041" height="9.25619" transform="rotate(-39.4833 43.0015 50.2292)" fill="#4D9587"/>
    <path d="M47.565 51.6094L43.4219 50.7395L43.0015 50.2292L47.5941 46.4457L53.4796 53.5897L50.9282 55.6917L47.565 51.6094Z" fill="#478A7D"/>
    <ellipse cx="28.6475" cy="28.6491" rx="19.8347" ry="19.8347" transform="rotate(5.51676 28.6475 28.6491)" fill="#A0D0C6"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M22.313 47.4449C22.107 47.3753 21.9013 47.3022 21.6961 47.2254C11.4366 43.3861 6.23195 31.9566 10.0713 21.6971C13.9106 11.4375 25.34 6.23286 35.5995 10.0722C40.265 11.8181 43.8851 15.1335 46.0848 19.1947C44.072 15.6004 40.832 12.7356 36.6063 11.1543C26.3467 7.31501 14.9174 12.5197 11.078 22.7792C7.38448 32.6493 12.2802 43.3401 22.313 47.4449ZM47.11 35.899C47.1489 35.8 47.187 35.7005 47.2244 35.6005C48.5958 31.9357 48.8133 28.1217 48.0586 24.5576C48.7721 28.1059 48.5063 32.0019 47.11 35.899Z" fill="#4A9689"/>
    <g filter="url(#filter1_d_125_3774)">
      <path d="M51.6808 30.8731C50.4522 43.5941 39.1438 52.9104 26.4229 51.6818C13.7019 50.4531 4.38558 39.1448 5.61422 26.4238C6.84286 13.7029 18.1512 4.38652 30.8722 5.61516C43.5931 6.84381 52.9094 18.1522 51.6808 30.8731Z" stroke="url(#paint2_linear_125_3774)" strokeWidth="6.61157" shapeRendering="crispEdges"/>
    </g>
    <path d="M16.557 23.1627C16.9958 18.6195 21.0345 15.2922 25.5777 15.731" stroke="#D2E8E5" strokeWidth="3.30578" strokeLinecap="round"/>
    <path d="M54.7412 55.1208L58.9452 60.2237" stroke="#529C90" strokeWidth="1.32231" strokeLinecap="round"/>
    <defs>
      <filter id="filter0_d_125_3774" x="-1.76772" y="2.19836" width="56.8634" height="54.2187" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dx="-3.30578" dy="0.661157"/>
        <feGaussianBlur stdDeviation="0.330578"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.02 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_125_3774"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_125_3774" result="shape"/>
      </filter>
      <filter id="filter1_d_125_3774" x="0.876906" y="2.20032" width="54.2188" height="56.8633" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dx="-0.661157" dy="3.30578"/>
        <feGaussianBlur stdDeviation="0.330578"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.02 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_125_3774"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_125_3774" result="shape"/>
      </filter>
      <linearGradient id="paint0_linear_125_3774" x1="8.65234" y1="-2.5" x2="2.53543" y2="87.6759" gradientUnits="userSpaceOnUse">
        <stop stopColor="#e3cee1"/>
        <stop offset="1" stopColor="#66B39C"/>
      </linearGradient>
      <linearGradient id="paint1_linear_125_3774" x1="8.99951" y1="-2" x2="2.8826" y2="88.1759" gradientUnits="userSpaceOnUse">
        <stop stopColor="#e3cee1"/>
        <stop offset="1" stopColor="#66B39C"/>
      </linearGradient>
      <linearGradient id="paint2_linear_125_3774" x1="4.86621" y1="-0.217773" x2="6.11909" y2="58.5028" gradientUnits="userSpaceOnUse">
        <stop stopColor="#66B39C"/>
        <stop offset="1" stopColor="#B668AE"/>
      </linearGradient>
    </defs>
  </svg>;
}

export default SearchIconBig;
