import React, { useContext, useEffect, useState } from "react";
import { AppSettings } from "../config/app-settings";
import { APIHeaders } from "../components/shared/helpers";
import axios from "axios";
import { useRecoilState } from "recoil";
import { showMediaModalAtom, showNewCompanyModalAtom } from "../atoms/profileAtom";
import { Link, useNavigate } from "react-router-dom";
import { sortArrayWithPrefixFirst } from "./custom-comps/NewCompSetStep2";
import ModalImage from "../components/modals/ModalImage";
import LoadingImage from "../components/shared/LoadingImage";
import SectorIcon from "../components/shared/SectorIcon";
import MinusIcon from "../components/shared/MinusIcon";
import Masonry from 'react-masonry-css';
import {
  findIndexByValue, lightenVeryDarkColor,
  metricOptions,
  sortArrayByLocationName,
  sortArrayByProduct,
  sortDates,
  sortedList,
  isDateValid
} from "../utils/helpers";

import {
  SortDropdown,
  SortDropdownOptionStyle,
  colorVariantsCompany,
  CompaniesBySectorContainer,
  SearchedCompanyList,
  SearchedItem,
  CompaniesByLocationContainer,
  FilterByComponent,
  SearchCompanyOptionsWrapper,
  LogosToggle,
  ItemsGridWrapper,
  ItemGrid,
  MetricTitle,
  ImageContainer,
  ListMetricsByCategory,
  ListCompaniesBySector,
  LogoWrapperSmall,
} from "./StyledComponent/mediaPages";
import CustomCompIcon from "../components/shared/CustomCompIcon";

export const getInvalidDateObject = ( arr ) => {
  let _ivalidDates = [];
  arr.map( img => {
    if ( !isDateValid( img.tags.date ) ) {
      _ivalidDates.push( img );
      if ( img.tags.date !== undefined ) {
        console.log( img.tags.date )
      }
    }

  } );
  // console.log(JSON.stringify(_ivalidDates));
  // console.log( _ivalidDates );
  // console.log( _ivalidDates.length );
}

const MediaLibrary = () => {
  const context = useContext( AppSettings );
  const baseURL = process.env.REACT_APP_BASE_URL;
  const [ pictureList, setPictureList ] = useState( [] );
  const [ filteredPictureList, setFilteredPictureList ] = useState( [] );
  const [ loadingImages, setLoadingImages ] = useState( true );
  const [ metricsList, setMetricsList ] = useState( [] );
  const [ sectorsList, setSectorsList ] = useState( [] );
  const [ companiesList, setCompaniesList ] = useState( [] );
  const [ filterBy, setFilterBy ] = useState( 'products' );
  const [ sortBy, setSortBy ] = useState( '' );
  const [ sortByDropdown, setSortByDropdown ] = useState( false );
  const [ order, setOrder ] = useState( 'asc' ); //desc
  const [ showModal, setShowModal ] = useRecoilState( showMediaModalAtom );
  const [ searchCompany, setSearchCompany ] = useState( '' );
  const [ searchCompanyArray, setSearchCompanyArray ] = useState( [] );
  const [ showLogos, setShowLogo ] = useState( true );
  const [ showSectorsList, setShowSectorsList ] = useState( false );
  const [ sectorCollapse, setSectorCollapse ] = useState( '' );
  const [ found, setFound ] = useState( [] );
  const [ filterCompaniesBySector, setFilterCompaniesBySector ] = useState( '' );
  const [ filterCompaniesByLocations, setFilterCompaniesByLocations ] = useState( '' );
  const [ dropdownCompaniesByLocations, setDropdownCompaniesByLocations ] = useState( '' );
  const [ filterMetricsByCategory, setFilterMetricsByCategory ] = useState( '' )
  const [ foundBySector, setFoundBySector ] = useState( [] );
  const [ imageObject, setImageObject ] = useState( [] );
  const [ modalLogo, setModalLogo ] = useState( [] );
  const [ showNewCompanyModal, setShowNewCompanyModal ] = useRecoilState( showNewCompanyModalAtom );
  const [ filterMetricsList, setFilterMetricsList ] = useState( [] );
  const [ currentLocationsListByCompany, setCurrentLocationsListByCompany ] = useState( [] );
  const [ changedLocationOption, setChangedLocationOption ] = useState( false );
  const navigate = useNavigate();
  const baseLogoURL = process.env.REACT_APP_LOGOS_BASE_URL_600;
  const signature = process.env.REACT_APP_LOGOS_SIGNATURE;
  const companiesLimit = 10;
  const metricsLimit = 10;
  const locationsLimit = 4;
  const sortOption = [ 'Alphabetically', 'Date' ];
  const breakpointColumnsObj = {
    default: 4,
    1800: 3,
    1400: 2,
    500: 1
  };

  const IconStar = ()=> {
    return <i className='fa-solid fa-photo-film fs-16px mt-1' title='media'></i>
  }

  useEffect(
    () => {
      context.handleSetAppHeaderNone( false );
      context.handleSetAppSidebarNone( false );
      context.handleSetAppContentClass( 'mh-100vh-88' );
      context.handleSetAppTitle( 'Media' );
      context.handleSetAppIcon( <IconStar /> );
    },
    []
  );

  useEffect( () => {
    getPictures();
    getMetrics();
    getSectors();
  }, [] )

  useEffect( () => {
    console.log( filteredPictureList, sortBy, order );

    if ( sortBy === 'Alphabetically' && filterBy === 'locations' ) {
      setFilteredPictureList( sortArrayByLocationName( filteredPictureList, order ) );
    } else if ( sortBy === 'Alphabetically' && filterBy === 'products' ) {
      setFilteredPictureList( sortArrayByProduct( filteredPictureList, order ) );
    } else if ( sortBy === 'Date' ) {
      setFilteredPictureList( sortDates( filteredPictureList, order ) );
    }

  }, [ filteredPictureList, sortBy, order ] )

  const handleSearchCompany = ( e ) => {
    setSearchCompany( e.target.value );
    let _found = [];
    companiesList.map( ( comp ) => {
      if ( comp.name.toUpperCase().indexOf( e.target.value.toUpperCase() ) >= 0 ) {
        _found.push( comp )
      }
    } );
    let _sortedFound = sortArrayWithPrefixFirst( _found, e.target.value.toUpperCase() )
    setFound( _sortedFound );
  }

  const handleRemoveSearch = ( e ) => {
    let _filtered = searchCompanyArray.filter( item => item.id !== e );
    setSearchCompanyArray( _filtered )
  }

  const handleCheckOptionLocationAll = ( checked, company ) => {
    let companyLocationIDs = pictureList.filter( f =>
      f.tags.company === company && (f.tags.locationphototype === 'Exterior' || f.tags.locationphototype === 'Interior')
    );
    let companyLocationIDsUniq = filterUniqueByName( companyLocationIDs ).map( el => el.tags.location );
    // console.log( companyLocationIDsUniq );

    if ( checked ) {
      const filteredArray = currentLocationsListByCompany.filter( element => !companyLocationIDsUniq.includes( element ) );
      setCurrentLocationsListByCompany( filteredArray );
      setChangedLocationOption( !changedLocationOption );
    } else {
      const filteredArray = currentLocationsListByCompany.concat( companyLocationIDsUniq );
      setCurrentLocationsListByCompany( filteredArray );
    }
  }

  const checkAllLocationCompany = ( id ) => {
    console.log( id );
    let _currentLocationsListByCompany = currentLocationsListByCompany;
    pictureList.map( f => {
      if ( f.tags.company === id && (f.tags.locationphototype === 'Exterior' || f.tags.locationphototype === 'Interior') ) {
        if ( !currentLocationsListByCompany.includes( f.tags.location ) ) {
          _currentLocationsListByCompany.push( f.tags.location );
        }
      }
    } )
    setCurrentLocationsListByCompany( _currentLocationsListByCompany );
  }

  const handleCheckOption = ( e ) => {
    searchCompanyArray?.map( el => {
      if ( searchCompanyArray.map( el => el.id ).includes( e.target.value ) ) {
        let _filtered = searchCompanyArray.filter( item => item.id !== e.target.value );
        setSearchCompanyArray( _filtered )
      } else {
        let _temp = searchCompanyArray.concat( {
          id: e.target.value,
          name: e.target.dataset.value,
          logo: e.target.dataset.logo,
          color: e.target.dataset.color,
          refId: e.target.dataset.refid,
          isPublic: e.target.dataset.ispublic === 'true'
        } );
        setSearchCompanyArray( _temp );
        checkAllLocationCompany( e.target.dataset.refid );
      }
    } );

    if ( searchCompanyArray.length === 0 ) {
      setSearchCompanyArray( [ {
        id: e.target.value,
        name: e.target.dataset.value,
        logo: e.target.dataset.logo,
        color: e.target.dataset.color,
        refId: e.target.dataset.refid,
        isPublic: e.target.dataset.ispublic === 'true'
      } ] );
      checkAllLocationCompany( e.target.dataset.refid );
    }
    // console.log( searchCompanyArray )
  }

  useEffect( () => {
    if ( searchCompanyArray.length > 0 ) {
      let _filteredPictureList = [];
      _filteredPictureList = pictureList.filter( image => {
        return searchCompanyArray.some( refIdObj => refIdObj.refId === image.tags.company );
      } );
      setFilteredPictureList( _filteredPictureList );
    } else if ( searchCompanyArray.length === 0 ) {
      setFilteredPictureList( pictureList );
    }
    console.log( searchCompanyArray )

  }, [ searchCompanyArray ] )

  useEffect( () => {
    if ( pictureList.length > 0 ) {
      setFilteredPictureList( sortArrayByLocationName( pictureList, order ) );
      // getInvalidDateObject( pictureList );
    }
  }, [ pictureList ] )

  useEffect( () => {
    if ( sectorsList.length ) {
      const companiesArray = [];
      sectorsList.forEach( entry =>
        entry.companies.forEach( company => {
          companiesArray.push( {
            id: company.id,
            name: company.name,
            refId: company.refId,
            logo600FileName: company.logo600FileName,
            color: company.color
          } )
        } )
      )
      setCompaniesList( companiesArray );
      // console.log( 'companiesArray', companiesArray )

      setFoundBySector( sectorsList );
    }

  }, [ sectorsList ] )

  const handleFilterBy = ( by ) => {
    setFilterBy( by );
    if ( by === 'products' ) {
      setFilterMetricsList( [] );
      setFilterMetricsByCategory( [] );
      setSearchCompanyArray( [] );
      setCurrentLocationsListByCompany( [] );
    } else {
      setSearchCompanyArray( [] );
    }
  }

  const handlePutImageToModal = ( pic, logoImg ) => {
    setImageObject( pic );
    setShowModal( true );
    setModalLogo( logoImg );
  }

  const handleCollapseSector = ( sector ) => {
    setSectorCollapse( sector === sectorCollapse ? '' : sector );
  }

  const handleSearchBySector = () => {
    setShowSectorsList( !showSectorsList )
  }

  const handleSearchByLocation = ( e ) => {
    setFilterCompaniesByLocations( e )
  }

  const handleDropdownByLocation = ( e ) => {
    setDropdownCompaniesByLocations( e )
  }

  const handleMetricByCategory = ( e ) => {
    // console.log( e )
    setFilterMetricsByCategory( e === filterMetricsByCategory ? '' : e );
  }

  const handleChangeFilterCompanyBySector = ( e ) => {
    // console.log( e.target.value );
    setFilterCompaniesBySector( e.target.value );
    if ( !showSectorsList ) {
      setShowSectorsList( true );
    }

    function companyNameContainsSymbol ( company, symbol ) {
      return company.name.toUpperCase().includes( symbol.toUpperCase() );
    }

    const filteredArray = sectorsList.map( category => ({
      ...category,
      companies: category.companies.filter( company => companyNameContainsSymbol( company, e.target.value ) )
    }) ).filter( category => category.companies.length > 0 );

    setFoundBySector( filteredArray );
  }

  const handleClearAll = () => {
    setSearchCompanyArray( [] );
    setSearchCompany( '' );
    setSectorCollapse( '' );
    setFilteredPictureList( pictureList );
    setFilterCompaniesByLocations( '' );
  }

  const getPictures = () => {
    // setLoadedCompanies( false )
    let data = JSON.stringify( {
      "filter": {
        "thumbnail": false
      }
    } );
    const config = {
      method: 'get',
      url: `${baseURL}/pictures?filter[thumbnail]=false`,
      // url: `${baseURL}/pictures?filter[thumbnail]=true`,
      headers: APIHeaders,
      withCredentials: true,
      credentials: "include",
      redirect: 'follow'
    };
    axios( config )
      .then( ( response ) => {
        console.log( response.data.data )
        // setPictureList( sortedList( response.data.data ) );
        setPictureList( response.data.data );
      } )
      .catch( ( error ) => {
        // console.log( error.response.status );
        if ( error.response.status === 401 ) {
          navigate( '/user/login', true )
        }
      } ).finally( () => {
      setLoadingImages( false );
    } )
  }

  const getMetrics = () => {
    const config = {
      method: 'get',
      // url: `${baseURL}/pictures?filter[location]=ca945f64-bc5f-ed11-9561-002248046e4e&filter[company]=true&thumbnail=false`,
      url: `${baseURL}/media/metrics`,
      headers: APIHeaders,
      withCredentials: true,
      credentials: "include",
      // data : data,
      redirect: 'follow'
    };
    axios( config )
      .then( ( response ) => {
        console.log( response.data.data )
        // setPictureList( sortedList( response.data.data ) );
        setMetricsList( response.data.data );
      } )
      .catch( ( error ) => {
        // console.log( error.response.status );
        if ( error.response.status === 401 ) {
          navigate( '/user/login', true )
        }
      } ).finally( () => {
      // setLoadedCompanies( true );
    } )
  }

  const getSectors = () => {
    const config = {
      method: 'get',
      // url: `${baseURL}/sectors?all=true`,
      url: `${baseURL}/sectors`,
      headers: APIHeaders,
      withCredentials: true,
      credentials: "include",
      redirect: 'follow'
    };
    axios( config )
      .then( ( response ) => {
        console.log( response.data.data )
        // setPictureList( sortedList( response.data.data ) );
        setSectorsList( sortedList( response.data.data ) );
      } )
      .catch( ( error ) => {
        // console.log( error.response.status );
        if ( error && error.response.status === 401 ) {
          navigate( '/user/login', true )
        }
      } ).finally( () => {
      // setLoadedCompanies( true );
    } )
  }

  const handleRemoveMetric = ( metric ) => {
    let _prevMetricsFilter = filterMetricsList;
    _prevMetricsFilter = _prevMetricsFilter.filter( item => item !== metric )
    setFilterMetricsList( _prevMetricsFilter );
  }

  const InputItem = ( { metric, seeAll, metricCat } ) => {
    const [ checked, setChecked ] = useState( false );

    const handleCheckOptionMetric = ( metric ) => {
      setChecked( !checked );
      console.log( seeAll, metricCat, metric );
      let _prevMetricsFilter = filterMetricsList;
      if ( !_prevMetricsFilter.some( item => item === metric ) && metric !== 'See All' ) {
        _prevMetricsFilter.push( metric );
        setFilterMetricsList( _prevMetricsFilter );
      } else {
        _prevMetricsFilter = _prevMetricsFilter.filter( item => item !== metric )
        setFilterMetricsList( _prevMetricsFilter );
      }
    }

    useEffect( () => {
      if ( metric !== 'See All' ) {
        setChecked( filterMetricsList.map( el => el ).includes( metric ) )
      }
    }, [ filterMetricsList ] )

    return <>
      <input
        className="form-check-input"
        type="checkbox"
        checked={checked}
        disabled={filterMetricsList.length >= metricsLimit && !filterMetricsList.map( el => el ).includes( metric )}
        onChange={() => handleCheckOptionMetric( metric )}
        id={`checkbox-${metricCat}-${metric}`}
        name={`checkbox-${metricCat}-${metric}`}
        value={metric} />
      <label
        className="form-check-label"
        htmlFor={`checkbox-${metricCat}-${metric}`}>{metric}
      </label>
    </>
  }

  const MetricsList = ( { metricCat } ) => {

    return <ListMetricsByCategory
      className={metricCat.name === filterMetricsByCategory ? "position-absolute" : "d-none"}
    >
      {metricOptions( metricCat.id, metricsList ).map( metric =>
        <div
          className="d-flex align-items-center py-2 w-100"
          key={"metric-option-" + metric.value}
        >
          <InputItem
            metric={metric.value}
            metricCat={metricCat.name}
          />
        </div>
      )}
    </ListMetricsByCategory>
  }

  const handleByMetricFiltering = () => {
    // console.log( filterMetricsList );
    // console.log(currentLocationsListByCompany)

    if ( filterBy === 'product' ) {
      let _filteredList = pictureList.filter( pic => pic.tags.metric !== undefined && pic.tags.metric !== 'product' );
      const filteredProducts = _filteredList.filter( product => filterMetricsList.includes( product.tags.metric.trim() ) );
      setFilteredPictureList( filteredProducts )
    } else if ( filterBy === 'locations' ) {
      // let _filteredList = pictureList.filter( pic => pic.tags.metric !== undefined && pic.tags.metric !== filterBy );
      // console.log(_filteredList);
      const filteredProducts = pictureList.filter( product => currentLocationsListByCompany.includes( product.tags.location ) );
      // console.log(filteredProducts)
      setFilteredPictureList( filteredProducts )
    }

  }

  const SearchedMetricsList = () => {
    return filterMetricsList.length > 0 && <SearchedCompanyList>
      {filterMetricsList.map( metric => <SearchedItem
        key={"metric-" + metric}
        style={{
          backgroundColor: colorVariantsCompany[ filterMetricsList.indexOf( metric ) ]
        }}
      >
        <span>{metric}</span>
        <Link
          to="#"
          className="remove-item"
          onClick={( e ) => handleRemoveMetric( metric )} />
      </SearchedItem> )}
    </SearchedCompanyList>
  }

  const handleClearByMetricFilter = () => {
    setFilterMetricsList( [] );
  }

  function filterUniqueByName ( arr ) {
    const uniqueMap = new Map();
    arr.forEach( item => uniqueMap.set( item.tags.location, item ) );
    return Array.from( uniqueMap.values() );
  }

  function addOrRemoveElementFromArray ( array, element ) {
    const index = array.indexOf( element );
    if ( index !== -1 ) {
      array.splice( index, 1 );
    } else {
      array.push( element );
    }
    return array;
  }

  const InputLocation = ( { image, companyName, refId } ) => {
    const [ checked, setChecked ] = useState( currentLocationsListByCompany.map( el => el ).includes( image.tags.location ) );

    useEffect( () => {
      setChecked( currentLocationsListByCompany.map( el => el ).includes( image.tags.location ) )
    }, [ changedLocationOption ] )

    return <>
      <input
        className="form-check-input"
        type="checkbox"
        checked={checked}
        // disabled={searchCompanyArray.length >= companiesLimit && !searchCompanyArray.map( el => el.name ).includes( comp.name )}
        onChange={( e ) => handleCheckOptionLocation( e )}
        id={`checkbox-search-${image.name}`}
        name={companyName}
        data-refid={image.tags.location}
        data-value={companyName}
        value={refId} />
      <label
        className="form-check-label"
        htmlFor={`checkbox-search-${image.name}`}>{image.tags.locationname}
      </label>
    </>
  }

  const LocationListItem = ( { image, companyName, refId } ) => {
    const [ checked, setChecked ] = useState( currentLocationsListByCompany.map( el => el ).includes( image.tags.location ) );

    useEffect( () => {
      setChecked( currentLocationsListByCompany.map( el => el ).includes( image.tags.location ) )
    }, [ changedLocationOption ] )

    return <>{checked &&
      <div className="mx-2">{image.tags.locationname}</div>}
    </>
  }

  const pictures = [
    {
      "name": "Beatnic/Beatnic Stores/Beatnic-BACK BAY-Exterior-2024-01-04-Yelp.jpg",
      "uri": "https://4wiqreviewstorage.blob.core.windows.net/4wallapp-images/Beatnic/Beatnic Stores/Beatnic-BACK BAY-Exterior-2024-01-04-Yelp.jpg?sv=2022-11-spr=httsig=rjUmJ9%2BPMohmwEw5XwnK5g4%2BQ25EmHMtomA%2B%2BznBqiM%3D",
      "tags": {
        "company": "b1851065-84ee-ed11-8849-002248081d62",
        "date": "2024-01-04",
        "hasthumbnail": "true",
        "location": "a7feda23-0df3-ed11-8848-002248081c70",
        "locationphototype": "Exterior",
        "source": "Yelp",
        "thumbnail": "false",
        "thumbnailuri": "https://4wiqreviewstorage.blob.core.windows.net/4wallapp-images/Beatnic/Beatnic Stores/Beatnic-BACK BAY-Exterior-2024-01-04-Yelp_thumbnail.jpg?BPMohmwEw5XwnK5g4%2BQ25EmHMtomA%2B%2BznBqiM%3D"
      }
    },
    {
      "name": "Beatnic/Beatnic Stores/Beatnic-BACK BAY-Interior-2024-01-04-Google Reviews.jpg",
      "uri": "https://4wiqreviewstorage.blob.core.windows.net/4wallapp-images/Beatnic/Beatnic Stores/Beatnic-BACK BAY-Interior-2024-01-04-Google Reviews.jpg?sv=2022-11J9%2BPMohmwEw5XwnK5g4%2BQ25EmHMtomA%2B%2BznBqiM%3D",
      "tags": {
        "company": "b1851065-84ee-ed11-8849-002248081d62",
        "date": "2024-01-04",
        "hasthumbnail": "true",
        "location": "a7feda23-0df3-ed11-8848-002248081c70",
        "locationphototype": "Interior",
        "source": "Google Reviews",
        "thumbnail": "false",
        "thumbnailuri": "https://4wiqreviewstorage.blob.core.windows.net/4wallapp-images/Beatnic/Beatnic Stores/Beatnic-BACK BAY-Interior-2024-01-04-Google Reviews_t2BPMohmwEw5XwnK5g4%2BQ25EmHMtomA%2B%2BznBqiM%3D"
      },
    },
    {
      "name": "Beatnic/Beatnic Stores/Beatnic-SEAPORT DISTRICT-Exterior-2024-01-04-Yelp.jpg",
      "uri": "https://4wiqreviewstorage.blob.core.windows.net/4wallapp-images/Beatnic/Beatnic Stores/Beatnic-SEAPORT DISTRICT-Exterior-2024-01-04-Yelp.jpg?sv=2022-19%2BPMohmwEw5XwnK5g4%2BQ25EmHMtomA%2B%2BznBqiM%3D",
      "tags": {
        "company": "b1851065-84ee-ed11-8849-002248081d62",
        "date": "2024-01-04",
        "hasthumbnail": "true",
        "location": "5f21bf1a-1af3-ed11-8848-002248081c70",
        "locationphototype": "Exterior",
        "source": "Yelp",
        "thumbnail": "false",
        "thumbnailuri": "https://4wiqreviewstorage.blob.core.windows.net/4wallapp-images/Beatnic/Beatnic Stores/Beatnic-SEAPORT DISTRICT-Exterior-2024-01-04-Yelp_thumbnail.jpg?sv=2022jUmJ9%2BPMohmwEw5XwnK5g4%2BQ25EmHMtomA%2B%2BznBqiM%3D"
      }
    },
  ]

  function checkLocations ( pictures, locations ) {
    const pictureLocations = pictures.map( picture => picture );
    return pictureLocations.every( location => locations.includes( location ) );
  }

  const SortDropdownOption = ( { name } ) => {

    return <SortDropdownOptionStyle
      className={sortBy === name && "bg-gray-200"}
      onClick={() => handleChangeSorting( name )}
    >{name} {sortBy === name ?
      <i className={order === 'asc' ?
        "fa fa-caret-up gray-active" :
        "fa fa-caret-down gray-active"}></i> :
      <i className="fa fa-sort gray-inactive" />}
    </SortDropdownOptionStyle>
  }

  const checkAllLocations = ( refId ) => {
    let companyLocationIDs = pictureList.filter( f =>
      f.tags.company === refId && (f.tags.locationphototype === 'Exterior' || f.tags.locationphototype === 'Interior')
    );
    let companyLocationUniq = filterUniqueByName( companyLocationIDs )
    let companyLocationIDsUniq = companyLocationUniq.map( el => el.tags.location )

    return checkLocations( companyLocationIDsUniq, currentLocationsListByCompany );
  }

  const InputLocationAll = ( { refId, companyName, companyId } ) => {
    const [ checkedAll, setCheckedAll ] = useState( checkAllLocations( refId ) );
    // const [ checkedAll, setCheckedAll ] = useState( true );

    useEffect( () => {
      // console.log( companyLocationIDsUniq );
      // console.log( currentLocationsListByCompany )
      // console.log('tik', changedLocationOption)
      if ( checkedAll !== checkAllLocations( refId ) ) {
        // console.log('tok', checkLocations( companyLocationIDsUniq, currentLocationsListByCompany ),checkedAll )
        setCheckedAll( checkAllLocations( refId ) )
      }
    }, [ changedLocationOption ] )

    return <>
      <input
        className="form-check-input"
        type="checkbox"
        checked={checkedAll}
        // disabled={searchCompanyArray.length >= companiesLimit && !searchCompanyArray.map( el => el.name ).includes( comp.name )}
        onChange={() => handleCheckOptionLocationAll( checkedAll, refId )}
        id={`checkbox-search-all-${companyName + refId}`}
        name={companyName}
        data-refid={refId}
        data-value={companyName + refId}
        value={companyName + refId} />
      <label
        className="form-check-label"
        htmlFor={`checkbox-search-all-${companyName + refId}`}>See All
      </label>
    </>
  }

  const handleChangeSorting = ( by ) => {
    if ( sortBy === by ) {
      setOrder( order === 'asc' ? 'desc' : 'asc' );
    } else {
      setSortBy( sortBy === 'Alphabetically' ? 'Date' : 'Alphabetically' );
    }
  }

  const handleCheckOptionLocation = ( e ) => {
    const resultArray = addOrRemoveElementFromArray( currentLocationsListByCompany, e.target.dataset.refid );
    // console.log( resultArray );
    setCurrentLocationsListByCompany( resultArray )

    if ( currentLocationsListByCompany.length === 0 ) setCurrentLocationsListByCompany( [ e.target.dataset.refid ] )

    setChangedLocationOption( !changedLocationOption );
  }

  const LocationListOptions = ( { refId, companyName, companyId } ) => {
    let _companyFilteredImages = filteredPictureList.filter( comp => comp.tags.company === refId )
    _companyFilteredImages = _companyFilteredImages.filter( comp => comp.tags.location !== '' && comp.tags.location )
    const uniqueFilteredImages = filterUniqueByName( _companyFilteredImages );

    return uniqueFilteredImages.length > 0 && <>
      <div className="col-3 d-flex align-items-center">
        <Link
          to="#"
          onClick={( e ) => handleRemoveSearch( companyId )}
        >
          <MinusIcon
            size="18"
          />
        </Link>
        <div className="w-10px"></div>
        <div className="w-100">
          <div className="position-relative">
            <input
              type="text"
              name="dropdown-sector"
              // value={companyNam}
              onClick={() => handleSearchByLocation( companyName )}
              onChange={( e ) => handleChangeFilterCompanyBySector( e )}
              className="form-control"
              placeholder={companyName} />
            <i
              onClick={() => handleSearchByLocation( companyName )}
              className="fa fa-chevron-down clear-field-button"
            />
          </div>

          {companyName === filterCompaniesByLocations &&
            <CompaniesByLocationContainer className="position-absolute">
              <div
                className="overlay position-fixed w-100 h-100 top-0"
                style={{
                  left: "0",
                  zIndex: "-1"
                }}
                onClick={() => setFilterCompaniesByLocations( '' )}></div>
              <div
                className="form-check mb-2"
                key={'input-all-' + companyName + refId}
              >
                <InputLocationAll
                  refId={refId}
                  companyName={companyName}
                  companyId={companyId}
                />
              </div>
              {uniqueFilteredImages.map( image => <div
                className="form-check mb-2"
                key={'input-all-' + image.name}
              >
                <InputLocation
                  image={image}
                />
              </div> )}
            </CompaniesByLocationContainer>}
        </div>
        <div className="w-10px"></div>
      </div></>;
  }

  const LocationList = ( { refId, companyName, companyId } ) => {
    let _companyFilteredImages = filteredPictureList.filter( comp => comp.tags.company === refId )
    _companyFilteredImages = _companyFilteredImages.filter( comp => comp.tags.location !== '' && comp.tags.location )
    const uniqueFilteredImages = filterUniqueByName( _companyFilteredImages );

    return uniqueFilteredImages.length > 0 && <>
      <div className="d-flex align-items-center">
        <div className="w-100">
          <div className="position-relative">
            <input
              type="text"
              name="dropdown-sector"
              onClick={() => handleDropdownByLocation( companyName )}
              onChange={( e ) => handleChangeFilterCompanyBySector( e )}
              className="form-control bg-transparent border-0 h-40px fw-400 fs-14px"
              value="Location Count" />
            <i
              onClick={() => handleDropdownByLocation( companyName )}
              className="fa fa-chevron-down clear-field-button-black" />
          </div>

          {companyName === dropdownCompaniesByLocations &&
            <CompaniesByLocationContainer className="position-absolute">
              <div
                className="overlay position-fixed w-100 h-100 top-0"
                style={{
                  left: "0",
                  zIndex: "-1"
                }}
                onClick={() => setDropdownCompaniesByLocations( '' )}></div>

              {uniqueFilteredImages.map( image => <div
                className="mb-2"
                key={'input-all-' + image.name}
              >
                <LocationListItem
                  image={image}
                />
              </div> )}
            </CompaniesByLocationContainer>}
        </div>
      </div></>;
  }

  return (
    <div className="media-library">
      {showModal && <ModalImage
        imageObject={imageObject}
        logo={modalLogo}
      />}
      <FilterByComponent>
        <div className="link-button">
          <Link
            to="#"
            className={filterBy === 'products' ? 'active' : ''}
            onClick={() => handleFilterBy( 'products' )}
          >Products</Link>
          <Link
            to="#"
            className={filterBy === 'locations' ? 'active' : ''}
            onClick={() => handleFilterBy( 'locations' )}
          >Locations</Link>
        </div>

        <div className={filterBy === 'products' ? "active pt-4 d-flex" : 'd-none'}>
          <div className="w-50 position-relative">
            <h4 className="fw-400">Search Companies</h4>
            <p>Search and add <span>up to {companiesLimit} companies</span> to view Product Media</p>

            <div className="w-100 position-relative">
              <i className="fa fa-search position-absolute fs-4 color-secondary"
                 style={{
                   paddingTop: "11px",
                   paddingLeft: "11px"
                 }}></i>
              <input
                type="text"
                value={searchCompany}
                disabled={searchCompanyArray.length >= (filterBy === 'products' ? companiesLimit : locationsLimit) || loadingImages}
                onChange={handleSearchCompany}
                name="search-company"
                className="form-control"
                style={{
                  paddingLeft: "35px"
                }}
                placeholder="Search" />
              {searchCompany && <i
                onClick={() => setSearchCompany( '' )}
                className="fa fa-times-circle clear-field-button"></i>}
            </div>
            {searchCompany && found && <SearchCompanyOptionsWrapper
              className={found.length > 10 ?
                "overflow-auto h-350px w-100" :
                "overflow-auto w-100"}
              style={{
                // zIndex: "11",
                border: "1px solid var(--app-component-border-color)"
              }}>
              <div
                className="overlay position-fixed w-100 h-100 top-0"
                style={{
                  left: "0",
                  zIndex: "-1"
                }}
                onClick={() => setSearchCompany( '' )}></div>
              {found.map( ( comp, index ) => {
                if ( index < 300 ) return <div
                  className="form-check mb-2"
                  key={'SearchCompanyOptionsWrapper-' + comp.id}
                >
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={searchCompanyArray.map( el => el.name ).includes( comp.name )}
                    disabled={searchCompanyArray.length >= (filterBy === 'products' ? companiesLimit : locationsLimit) && !searchCompanyArray.map( el => el.name ).includes( comp.name )}
                    onChange={handleCheckOption}
                    id={`checkbox-search-${comp.id}`}
                    name={comp.name}
                    data-logo={comp.logo600FileName || ''}
                    data-refid={comp.refId}
                    data-color={comp.color}
                    data-value={comp.name}
                    value={comp.id} />
                  <label
                    className="form-check-label"
                    htmlFor={`checkbox-search-${comp.id}`}>{comp.name}
                  </label>
                </div>
              } )}

            </SearchCompanyOptionsWrapper>}
            <div className="w-20px"></div>
            <div className="pt-3 text-right w-100 d-flex justify-content-end">
              <p className="fs-5 fw-300">Don’t see a company? <Link to="#" onClick={() => setShowNewCompanyModal( true )} className="color-purple text-link">Request New Company</Link>
              </p>
            </div>

          </div>
          <div className="w-45px"></div>
          <div className="w-50 position-relative">
            <h4 className="fw-400">Search Companies by Sector</h4>
            <p>Search companies by sector. <span>Add up to {companiesLimit}</span> to view Product Media</p>
            <div className="w-100 position-relative">
              <input
                type="text"
                name="dropdown-sector"
                value={filterCompaniesBySector}
                disabled={loadingImages}
                onClick={handleSearchBySector}
                onChange={( e ) => handleChangeFilterCompanyBySector( e )}
                className="form-control"
                placeholder="Sector" />
              <i
                className="fa fa-chevron-down clear-field-button"
                onClick={handleSearchBySector}
              />
              {filterCompaniesBySector && <i
                className="fa fa-times-circle clear-field-button" style={{
                right: "-42px"
              }}
                onClick={() => {
                  setFilterCompaniesBySector( '' );
                  setFoundBySector( sectorsList );
                }
                }
              ></i>}
            </div>
            {showSectorsList && <CompaniesBySectorContainer
              className="border-1 w-100 position-absolute"
            >
              <div
                className="overlay position-fixed w-100 h-100 top-0"
                style={{
                  left: "0",
                  zIndex: "-1"
                }}
                onClick={handleSearchBySector}></div>

              <div className="d-flex justify-content-end">
                <div
                  className="overlay position-fixed w-100 h-100 top-0"
                  style={{
                    left: "0",
                    zIndex: "-1"
                  }}
                  onClick={handleSearchBySector}></div>
                <LogosToggle>
                  <div className="form-check-reverse form-switch mx-3">
                    <input
                      className="form-check-input fs-18px"
                      type="checkbox"
                      name="flexSwitchCheckDefault"
                      id="flexSwitchCheckDefault"
                      defaultChecked={showLogos}
                      value={showLogos}
                      onChange={() => setShowLogo( !showLogos )}
                    />
                    <label
                      className="form-check-label fz-12px lh-20"
                      htmlFor="flexSwitchCheckDefault">View Logos</label>
                  </div>
                </LogosToggle>
              </div>
              <hr />
              {foundBySector.map( sector => <div
                className="d-flex flex-row flex-wrap align-items-center pb-3"
                key={'sector-list-' + sector.name}
              >
                <div className="d-flex justify-content-between align-items-center w-100 cursor-pointer"
                     onClick={() => handleCollapseSector( sector.name )}
                >
                  <div className="d-flex align-items-center">
                    <SectorIcon
                      sector={sector.name}
                    />
                    <h5 className="fw-400 m-0 mx-1">{sector.name} {sector.companies.length > 1 ? `(${sector.companies.length})` : <></>}</h5>
                  </div>
                  <div className="cursor-pointer p-1">
                    <i
                      className={sectorCollapse === sector.name ? "fa fa-chevron-down" : "fa fa-chevron-right"}
                      onClick={() => handleCollapseSector( sector.name )}
                    />
                  </div>
                </div>
                {sectorCollapse === sector.name && <ListCompaniesBySector
                  className="d-flex flex-wrap"
                >
                  {sector.companies.map( comp => <div
                    className="d-flex align-items-center py-2 w-100"
                    key={"ListCompaniesBySector-" + comp.id}
                  >
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={searchCompanyArray.map( el => el.name ).includes( comp.name )}
                      disabled={searchCompanyArray.length >= (filterBy === 'products' ? companiesLimit : locationsLimit) && !searchCompanyArray.map( el => el.name ).includes( comp.name )}
                      onChange={handleCheckOption}
                      id={`checkbox-sector-${comp.id}`}
                      name={comp.name}
                      data-logo={comp.logo600FileName || ''}
                      data-color={comp.color}
                      data-refid={comp.refId}
                      data-value={comp.name}
                      value={comp.id} />
                    {comp.logo600FileName && showLogos && <LogoWrapperSmall>
                      <LoadingImage
                        src={baseLogoURL + comp.logo600FileName + signature}
                        alt={comp.name}
                        variant="small"
                      />
                    </LogoWrapperSmall>}
                    <label
                      className="form-check-label"
                      htmlFor={`checkbox-sector-${comp.id}`}>{comp.name}
                    </label>
                  </div> )}
                </ListCompaniesBySector>}
              </div> )}
            </CompaniesBySectorContainer>}
          </div>
        </div>

        <div className={filterBy === 'locations' ? "active d-flex pt-4" : 'd-none'}>
          <div className="w-50 position-relative">
            <h4 className="fw-400">Search Companies</h4>
            <p>Search and add <span>up to {locationsLimit} companies</span> to view Location Media</p>
            <div className="w-100 position-relative">
              <i className="fa fa-search position-absolute fs-4 color-secondary"
                 style={{
                   paddingTop: "11px",
                   paddingLeft: "11px"
                 }}></i>
              <input
                type="text"
                value={searchCompany}
                disabled={searchCompanyArray.length >= (filterBy === 'products' ? companiesLimit : locationsLimit) || loadingImages}
                onChange={handleSearchCompany}
                name="search-company"
                className="form-control"
                style={{
                  paddingLeft: "35px"
                }}
                placeholder="Search" />
              {searchCompany && <i
                onClick={() => setSearchCompany( '' )}
                className="fa fa-times-circle clear-field-button"></i>}
            </div>
            {searchCompany && found && <SearchCompanyOptionsWrapper
              className={found.length > 10 ?
                "mt-1 p-3 position-absolute overflow-auto h-350px w-100" :
                "mt-1 p-3 position-absolute overflow-auto w-100"}
              style={{
                backgroundColor: "#fff",
                zIndex: "11",
                border: "1px solid var(--app-component-border-color)"
              }}>
              <div
                className="overlay position-fixed w-100 h-100 top-0"
                style={{
                  left: "0",
                  zIndex: "-1"
                }}
                onClick={() => setSearchCompany( '' )}></div>
              {found.map( ( comp, index ) => {
                if ( index < 300 ) return <div
                  className="form-check mb-2"
                  key={'input-' + comp.id}
                >
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={searchCompanyArray.map( el => el.name ).includes( comp.name )}
                    disabled={searchCompanyArray.length >= (filterBy === 'products' ? companiesLimit : locationsLimit) && !searchCompanyArray.map( el => el.name ).includes( comp.name )}
                    onChange={handleCheckOption}
                    id={`checkbox-search-${comp.id}`}
                    name={comp.name}
                    data-logo={comp.logo600FileName || ''}
                    data-refid={comp.refId}
                    data-color={comp.color}
                    data-value={comp.name}
                    value={comp.id} />
                  <label
                    className="form-check-label"
                    htmlFor={`checkbox-search-${comp.id}`}>{comp.name}
                  </label>
                </div>
              } )}

            </SearchCompanyOptionsWrapper>}
            <div className="pt-3 text-right w-100 d-flex justify-content-end">
              <p className="fs-5 fw-300">Don’t see a company? <Link to="#" onClick={() => setShowNewCompanyModal( true )} className="color-purple text-link">Request New Company</Link>
              </p>
            </div>

          </div>
          <div className="w-45px"></div>
          <div className="w-50 position-relative">
            <h4 className="fw-400">Search Companies by Sector</h4>
            <p>Search and add <span>up to {locationsLimit} companies</span> to view Location Media</p>
            <div className="w-100 position-relative">
              <input
                type="text"
                name="dropdown-sector"
                value={filterCompaniesBySector}
                disabled={loadingImages}
                onClick={handleSearchBySector}
                onChange={( e ) => handleChangeFilterCompanyBySector( e )}
                className="form-control"
                placeholder="Sector" />
              <i
                className="fa fa-chevron-down clear-field-button"
                onClick={handleSearchBySector}
              />
              {filterCompaniesBySector && <i
                className="fa fa-times-circle clear-field-button" style={{
                right: "-42px"
              }}
                onClick={() => {
                  setFilterCompaniesBySector( '' );
                  setFoundBySector( sectorsList );
                }
                }
              ></i>}
            </div>
            {showSectorsList && <CompaniesBySectorContainer
              className="border-1 w-100 position-absolute"
            >
              <div
                className="overlay position-fixed w-100 h-100 top-0"
                style={{
                  left: "0",
                  zIndex: "-1"
                }}
                onClick={handleSearchBySector}></div>
              <div className="d-flex justify-content-end">
                <LogosToggle>
                  <div className="form-check-reverse form-switch mx-3">
                    <input
                      className="form-check-input fs-18px"
                      type="checkbox"
                      name="flexSwitchCheckDefault"
                      id="flexSwitchCheckDefault"
                      defaultChecked={showLogos}
                      value={showLogos}
                      onChange={() => setShowLogo( !showLogos )}
                    />
                    <label
                      className="form-check-label fz-12px lh-20"
                      htmlFor="flexSwitchCheckDefault">View Logos</label>
                  </div>
                </LogosToggle>
              </div>
              <hr />
              {foundBySector.map( sector => <div
                className="d-flex flex-row flex-wrap align-items-center pb-3"
                key={'sector-' + sector.name}
              >
                <div className="d-flex justify-content-between align-items-center w-100 cursor-pointer"
                     onClick={() => handleCollapseSector( sector.name )}
                >
                  <div className="d-flex align-items-center">
                    <SectorIcon
                      sector={sector.name}
                    />
                    <h5 className="fw-400 m-0 mx-1">{sector.name} {sector.companies.length > 1 ? `(${sector.companies.length})` : <></>}</h5>
                  </div>
                  <div className="cursor-pointer p-1">
                    <i
                      className={sectorCollapse === sector.name ? "fa fa-chevron-down" : "fa fa-chevron-right"}
                      onClick={() => handleCollapseSector( sector.name )}
                    />
                  </div>
                </div>
                {sectorCollapse === sector.name && <ListCompaniesBySector
                  className="d-flex flex-wrap"
                >
                  {sector.companies.map( comp => <div
                    className="d-flex align-items-center py-2 w-100"
                    key={'checkbox-' + comp.refId}
                  >
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={searchCompanyArray.map( el => el.name ).includes( comp.name )}
                      disabled={searchCompanyArray.length >= (filterBy === 'products' ? companiesLimit : locationsLimit) && !searchCompanyArray.map( el => el.name ).includes( comp.name )}
                      onChange={handleCheckOption}
                      id={`checkbox-sector-${comp.id}`}
                      name={comp.name}
                      data-logo={comp.logo600FileName || ''}
                      data-color={comp.color}
                      data-refid={comp.refId}
                      data-value={comp.name}
                      value={comp.id} />
                    {comp.logo600FileName && showLogos && <LogoWrapperSmall>
                      <LoadingImage
                        src={baseLogoURL + comp.logo600FileName + signature}
                        alt={comp.name}
                        variant="small"
                      />
                    </LogoWrapperSmall>}
                    <label
                      className="form-check-label"
                      htmlFor={`checkbox-sector-${comp.id}`}>{comp.name}
                    </label>
                  </div> )}
                </ListCompaniesBySector>}
              </div> )}
            </CompaniesBySectorContainer>}
          </div>
        </div>

        {filterBy === 'locations' && <div className="d-flex row">
          {filteredPictureList.filter( comp => comp.tags.location !== '' && comp.tags.location ).length > 0 && searchCompanyArray.length > 0 && <>
            <div className="h-10px"></div>
            <h4 className="fw-400">Choose Company Locations</h4>
            <p>Choose company locations from the dropdowns below</p></>}
          {searchCompanyArray.map( comp =>
            <LocationListOptions
              refId={comp.refId}
              companyName={comp.name}
              companyId={comp.id}
              key={'locationListOptions-' + comp.refId}
            /> )}
        </div>}
        <div className="d-flex justify-content-end w-100 pt-3">
          {/*<Link*/}
          {/*  to="#"*/}
          {/*  className="buttn-green mx-3"*/}
          {/*  onClick={handleByMetricFiltering}*/}
          {/*>Submit</Link>*/}
          <Link
            to="#"
            className="buttn-green-outline"
            onClick={handleClearAll}
          >Clear All</Link>
        </div>
      </FilterByComponent>
      <ItemsGridWrapper>
        <div className="w-100 d-flex justify-content-end position-relative py-2 my-3">
          <Link
            to="#"
            className="fs-14px color-secondary text-decoration-none"
            onClick={() => setSortByDropdown( !sortByDropdown )}
          >{sortBy ? 'Sort': 'Sort by'} {sortBy}<i className="px-1 fa fa-chevron-down"></i></Link>
          {sortByDropdown && <SortDropdown>
            <div
              className="overlay position-fixed w-100 h-100 top-0"
              style={{
                left: "0",
                zIndex: "-1"
              }}
              onClick={() => setSortByDropdown( false )}></div>
            {sortOption.map( option =>
              <SortDropdownOption
                key={'sort-' + option}
                name={option}
              /> )}
          </SortDropdown>}
        </div>
        {searchCompanyArray.length > 0 && <SearchedCompanyList>
          {searchCompanyArray.map( comp => <>
            <SearchedItem
              key={comp.name + comp.logo}
              style={{
                backgroundColor: (comp.color !== undefined && comp.color !== null) ? lightenVeryDarkColor( comp.color, .7 ) : colorVariantsCompany[ findIndexByValue( searchCompanyArray, comp.name ) ]
              }}
            >
              <img src={baseLogoURL + comp.logo + signature}
                   style={{
                     'width': 'auto',
                     "max-height": '30px',
                     "max-width": "100px",
                     "marginRight": "15px"
                   }} alt="" />
              <span>{comp.name}</span>
              <Link
                to="#"
                className="remove-item"
                onClick={() => handleRemoveSearch( comp.id )} />
            </SearchedItem>
            {filterBy === 'locations' && <div
              style={{
                height: "40px",
                marginRight: "24px",
                borderRadius: "8px",
                backgroundColor: (comp.color !== undefined && comp.color !== null) ? lightenVeryDarkColor( comp.color, 0.7 ) : colorVariantsCompany[ findIndexByValue( searchCompanyArray, comp.name ) ]
              }}
            >
              <LocationList
                refId={comp.refId}
                companyName={comp.name}
                companyId={comp.id}
              />
            </div>}
          </> )}
        </SearchedCompanyList>}

        <SearchedMetricsList />

        {loadingImages &&
          <div className="d-flex justify-content-center align-items-center center-block h-500px"><div className="spinner-border m-5" role="status">
            <span className="sr-only">Loading...</span>
          </div></div>}
        {(filteredPictureList.length === 0 && !loadingImages) &&
          <div className="w-100 center-block">
            <h3 className="text-center fw-400">No Results</h3>
          </div>}

        <Masonry
          breakpointCols={breakpointColumnsObj}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column"
        >
          {filteredPictureList.length > 0 && !loadingImages && filteredPictureList.map( ( pic, i ) => {
            let logoImg = [];
            logoImg = companiesList.find( comp =>
              comp.refId === pic.tags.company
            )
            // console.log( (logoImg !== undefined && logoImg.color !== undefined) ?? '#fff')
            //pic.tags.metric !== undefined
            return filterBy === 'products' ? pic.tags.metric && pic.tags.metric === 'product' && logoImg &&
              <ItemGrid
                className="align-items-center"
                key={'ItemsGrid' + pic.name + i}
                style={{
                  // backgroundColor: (logoImg !== undefined && logoImg.color !== null) ? logoImg.color : 'rgba(177, 216, 211, 1)',
                  backgroundColor: (logoImg !== undefined && logoImg.color !== null) ? lightenVeryDarkColor( logoImg.color, 0.7 ) : ((findIndexByValue( searchCompanyArray, logoImg.name ) >= 0 && logoImg !== undefined && logoImg.name) ? colorVariantsCompany[ findIndexByValue( searchCompanyArray, logoImg.name ) ] : 'rgba(177, 216, 211, 1)')
                }}
              >
                <MetricTitle
                  key={i + pic.tags.company}
                  style={{
                    backgroundColor: 'rgba(255,255,255, .4)',
                  }}
                >
                  <div>
                    {logoImg !== undefined && logoImg.logo600FileName !== null && logoImg.logo600FileName !== undefined &&
                      <img
                        src={baseLogoURL + logoImg.logo600FileName + signature}
                        style={{
                          'width': 'auto',
                          "max-height": '50px',
                          "max-width": "120px"
                        }}
                        alt={pic.name}
                      />}
                    {/*{logoImg === undefined && logoImg.logo600FileName === null && <h5 className="fw-400">Company name</h5>}*/}
                  </div>
                  <div>
                    <h4 className="fw-400">{(pic.tags.metric === 'product' ? pic.tags.product : 'Metric Name')}</h4>
                    <p>{pic.tags.source} - {pic.tags.date}</p>
                  </div>

                </MetricTitle>
                <ImageContainer
                  onClick={() => handlePutImageToModal( pic, logoImg )}
                >
                  <LoadingImage src={pic.uri} style={{ 'width': '100%' }}
                                alt=""
                  />
                </ImageContainer>
              </ItemGrid> : (pic.tags.locationphototype === 'Exterior' || pic.tags.locationphototype === 'Interior') && i < 50 && logoImg &&
              <ItemGrid
                className="align-items-center" key={pic.name}
                style={{
                  // backgroundColor: (logoImg !== undefined && logoImg.color !== null) ? logoImg.color : 'rgba(177, 216, 211, 1)',
                  backgroundColor: (logoImg !== undefined && logoImg.color !== null) ? lightenVeryDarkColor( logoImg.color, 0.7 ) : ((findIndexByValue( searchCompanyArray, logoImg.name ) >= 0 && logoImg !== undefined && logoImg.name) ? colorVariantsCompany[ findIndexByValue( searchCompanyArray, logoImg.name ) ] : 'rgba(177, 216, 211, 1)')
                }}
              >
                <MetricTitle
                  key={i + pic.tags.company}
                  style={{
                    backgroundColor: 'rgba(255,255,255, .4)',
                  }}
                >
                  <div>
                    {logoImg !== undefined && logoImg.logo600FileName !== null && logoImg.logo600FileName !== undefined &&
                      <img
                        src={baseLogoURL + logoImg.logo600FileName + signature}
                        style={{
                          'width': 'auto',
                          "max-height": '50px',
                          "max-width": "120px"
                        }}
                        alt={pic.name}
                      />}
                    {/*{logoImg === undefined && logoImg.logo600FileName === null && <h5 className="fw-400">Company name</h5>}*/}
                  </div>
                  <div>
                    <h4 className="fw-400">{(pic.tags.locationname || 'Metric Name')}</h4>
                    <p>{pic.tags.source} - {pic.tags.date}</p>
                  </div>

                </MetricTitle>
                <ImageContainer
                  onClick={() => handlePutImageToModal( pic, logoImg )}
                >
                  <LoadingImage src={pic.uri} style={{ 'width': '100%' }}
                                alt=""
                  />
                </ImageContainer>
              </ItemGrid>
          } )}
          <div className="col-6 d-flex align-items-center">
            <div className="input-group d-flex align-items-center w-100">
            </div>
          </div>
        </Masonry>
      </ItemsGridWrapper>
    </div>
  )
}

export default MediaLibrary;
