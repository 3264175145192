import { useRecoilState } from "recoil";
import { showNewCompanyModalAtom } from "../../atoms/profileAtom";
import { Link } from "react-router-dom";
import CompaniesIcon from "./CompaniesIcon";
import React from "react";


const AddNewCompanyModalButton = () => {
  const [ showNewCompanyModal, setShowNewCompanyModal ] = useRecoilState( showNewCompanyModalAtom );

  return <div className="menu-item">
    <Link
      to="#"
      className="menu-link"
      onClick={()=>setShowNewCompanyModal(true)}
    >
      <div className="menu-icon" title="Request New Company"><i className="fa fa-pen-to-square"></i></div>
      <div className="menu-text">Request New Company</div>
    </Link>
  </div>
}

export default AddNewCompanyModalButton
