import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from 'styled-components';
import NewCompSetStep1 from "./NewCompSetStep1";
import NewCompSetStep2 from "./NewCompSetStep2";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  chosenMetricsAtom, chosenTagsAtom,
  companiesLimitAtom,
  CSMetricsAttributesAtom,
  customCompAtom, inProgressNewCustomCompAtom, inProgressNewCustomCompDialogAtom, inProgressNewCustomCompTypeAtom, linkToMenuItemAtom,
  locationsListAtom,
  locationsLoadedListAtom,
  metricListAtom,
  metricSameStartAtom,
  newCustomCompNameAtom,
  newCustomCompTypeAtom,
  nextStepTitleAtom,
  resultViewMarketTrackerAtom,
  searchCompanyArrayAtom, searchFieldGoogleMapAtom,
  selectedStateAtom,
  stepOfCreationCompsAtom,
  trackerIDAtom
} from "../../atoms/profileAtom";
import { AppSettings } from "../../config/app-settings";
import MinusIcon from "../../components/shared/MinusIcon";
import { APIHeadersRAW, kitcut } from "../../components/shared/helpers";
import axios from "axios";
import PerformanceMapIcon from "../../components/shared/PerfomanceMapIcon";
import TimeLineIcon from "../../components/shared/TimeLineIcon";
import MarketTracker from "./MarketTracker";
import MarketTrackerTimeline from "./MarketTrackerTimeline";
import { InProgress } from "../custom-comps/new-comp-set";
import MinusIconNoBorder from "../../components/shared/MinusIconNoBorder";
import moment from "moment";
import { PublicTagStyle } from "../../components/modals/SelectCompanyBySector";
import CustomCompIcon from "../../components/shared/CustomCompIcon";

const CompSetContainer = styled.div`
  display: flex;
  margin-left: -30px;
  margin-right: -30px;
  margin-bottom: -20px;
  padding-left: 15px;
  height: calc(100vh - 168px);
  justify-content: space-between;
`;

const WizardContainer = styled.div`
  margin-right: -30px;
  margin-left: -30px;
  height: 60px;
  width: calc(100% + 60px);
  padding-left: 15px;
  border-bottom: 1px solid #EBEBEB;
  display: flex;
  justify-content: space-between;
`;

const Sidebar = styled.div`
  width: 400px;
  background-color: #f8f8f8;
  border-top: 7px solid var(--bs-primary);
  border-left: 1px solid #828282;

`;

const NewMarketTrackerSet = () => {
  const [ stepOfCreationComps, setStepOfCreationComps ] = useRecoilState( stepOfCreationCompsAtom );
  const [ searchCompanyArray, setSearchCompanyArray ] = useRecoilState( searchCompanyArrayAtom );
  const [ companiesLimit, setCompaniesLimit ] = useRecoilState( companiesLimitAtom );
  const [ compName, setCompName] = useRecoilState( newCustomCompNameAtom );
  const compType = useRecoilValue( newCustomCompTypeAtom );
  const [ classStep2, setClassStep2 ] = useState( 'nav-link disabled' );
  const [ classStep3, setClassStep3 ] = useState( 'nav-link disabled' );
  const [ classStep4, setClassStep4 ] = useState( 'nav-link disabled' );
  const [ wizardClass, setWizardClass ] = useState( 'nav-wizards-container col-9' );
  const [ nextStepTitle, setNextStepTitle ] = useRecoilState( nextStepTitleAtom );
  const [ chosenMetrics, setChosenMetrics ] = useRecoilState( chosenMetricsAtom );
  const [ trackerID, setTrackerID ] = useRecoilState( trackerIDAtom );
  const metricList = useRecoilValue( metricListAtom );
  const [ metricSameStart, setMetricSameStart ] = useRecoilState( metricSameStartAtom );
  const [ CSMetricsAttributes, setCSMetricsAttributes ] = useRecoilState( CSMetricsAttributesAtom );
  const [ resultViewMarketTracker, setResultViewMarketTracker ] = useRecoilState( resultViewMarketTrackerAtom );
  const [ inProgressNewCustomComp, setInProgressNewCustomComp ] = useRecoilState( inProgressNewCustomCompAtom );
  const [ inProgressNewCustomCompType, setInProgressNewCustomCompType] = useRecoilState( inProgressNewCustomCompTypeAtom );
  const [ linkToMenuItem, setLinkToMenuItem] = useRecoilState( linkToMenuItemAtom );
  const [ showModal, setShowModal] = useRecoilState( inProgressNewCustomCompDialogAtom );
  const [ state, setState ] = useRecoilState( selectedStateAtom );
  const [ filteredMetrics, setFilteredMetrics ] = useState( [] );
  const [ sidebarTitle, setSidebarTitle ] = useState( '' );
  const [ successComp, setSuccessComp ] = useState( false );
  const [ customComp, setCustomComp ] = useRecoilState( customCompAtom );
  const [ locationList, setLocationList ] = useRecoilState( locationsListAtom );
  const [ loadedList, setLoadedList ] = useRecoilState( locationsLoadedListAtom );
  const [ isAllSameStartOption, setIsAllSameStartOption ] = useState( false );
  const [ inProgress, setInProgress ] = useState( false );
  const chosenTags = useRecoilValue( chosenTagsAtom );
  const searchField = useRecoilValue( searchFieldGoogleMapAtom );
  const context = useContext( AppSettings );
  const baseURL = process.env.REACT_APP_BASE_URL;
  const navigate = useNavigate();

  useEffect( () => {
    context.handleSetAppTitle( 'New Market Tracker' );
    context.handleSetAppIcon( <CustomCompIcon /> );
    context.handleSetAppContentClass( 'mh-100vh-88' );
    setState( '' );
    setResultViewMarketTracker( false );
    setLocationList( [] );
    setLoadedList( false );
    setTrackerID( '' );
    setInProgressNewCustomComp(true);
    setInProgressNewCustomCompType('Market Tracker');

    if (!compName) {
      let newName = '';
      const currentDate = new Date();
      // Format the date as "8/22/2023"
      const formattedDate = moment(currentDate).format('M/D/YYYY');
      let firstName = localStorage.getItem( 'first_name' );
      let _firstNameArray = firstName.split('');
      let lastName = localStorage.getItem( 'last_name' );
      newName = _firstNameArray[0] + lastName + " - " + formattedDate;
      setCompName(newName);
    }
  }, [] )

  const handleRemoveCompany = ( e ) => {
    let _filtered = searchCompanyArray.filter( item => item.id !== e );
    setSearchCompanyArray( _filtered )
  }

  const handleSaveCustomComps = ( e, action ) => {
    // return false;
    setInProgress( true );
    e.preventDefault();
    let _existingTags = [];
    let _newTags = [];
    chosenTags.map(tag => {
      if (tag.id !== undefined) {
        _existingTags.push({"tag_id": tag.id})
      } else {
        _newTags.push({"label": tag.label})
      }
    });

    console.log("existing ", _existingTags)
    console.log("new ", _newTags)
    let _url = `${baseURL}/custom_comps`

    let metricData = {};
    metricData[ 'custom_comp' ] = {
      "name": compName,
      "type": compType,
      "state": state,
      "gmap_search": searchField,
      "taggings_attributes": _existingTags,
      "tags": _newTags,
      "resource_ids": searchCompanyArray.map( el => el.id ),
      "custom_comp_metrics_attributes": []
    }

    const config = {
      method: 'post',
      url: _url,
      headers: APIHeadersRAW,
      withCredentials: true,
      credentials: "include",
      data: metricData,
    };
    axios( config ).then( resp => {
      // console.log( resp );
      if ( resp.data ) {
        setCustomComp( resp.data.data );
        setSuccessComp( true );
        setStepOfCreationComps( 4 );
      }
      if ( action === 'save' ) {
        setInProgressNewCustomComp(false);
        navigate( '/market-tracker', { replace: false } );
      }
      setInProgress( false );
    } ).catch( error => {
      console.log( error )
    } )
  }

  useEffect( () => {
    if ( compType && stepOfCreationComps === 1 ) {
      setWizardClass( 'nav-wizards-container col-9' )
    }
    if ( stepOfCreationComps === 2 ) {
      setSidebarTitle( 'Companies' )
    }
    if ( stepOfCreationComps === 3 ) {
      setWizardClass( 'nav-wizards-container col-10' )
    }
    if ( stepOfCreationComps === 2 && (compType === 'Table' || compType === 'Time Series' || compType === 'Scatter Plot') ) {
      setNextStepTitle( 'Next' )
    }
    if ( stepOfCreationComps === 2 && (compType === 'CustomComps::MarketTimeline') ) {
      setNextStepTitle( 'Generate Timeline' )
    }

  }, [ compType, stepOfCreationComps ] )

  useEffect( () => {
    if ( stepOfCreationComps > 2 ) {
      setClassStep2( 'nav-link completed' )
    } else if ( stepOfCreationComps === 2 ) {
      setClassStep2( 'nav-link active' )
    } else if ( stepOfCreationComps === 1 ) {
      setClassStep2( 'nav-link disabled' )
    }
    if ( stepOfCreationComps < 3 ) {
      setClassStep3( 'nav-link disabled' );
      setClassStep4( 'nav-link disabled' );
    } else if ( stepOfCreationComps === 4 ) {
      setClassStep3( 'nav-link completed' );
      setClassStep4('nav-link active');
    } else {
      setClassStep4( 'nav-link disabled' );
      setClassStep3( 'nav-link active' )
    }
  }, [ stepOfCreationComps ] )

  useEffect( () => {
    if ( metricList && chosenMetrics ) {
      let _temp = [];
      metricList.forEach( metric => {
        chosenMetrics.map( choose => {
          if ( +metric.id === +choose ) {
            _temp = [ ..._temp, metric ];
          }
        } )
      } )

      setFilteredMetrics( _temp );

      let _isAllSameStartOption = [];
      chosenMetrics.forEach( choose => {
        metricList.map( metric => {
          if ( +metric.id === +choose ) {
            _isAllSameStartOption.push( metric.same_start );
          }
        } )
      } )
      setIsAllSameStartOption( _isAllSameStartOption.every( v => v === true ) );
    }
  }, [ metricList, chosenMetrics ] )

  useEffect( () => {
    if ( !isAllSameStartOption ) {
      setMetricSameStart( false );
    }

  }, [ isAllSameStartOption ] )

  return <>
    <WizardContainer>
      <div className={wizardClass}>
        <nav className="nav nav-wizards-2 mb-20px">
          <div className="nav-item col">
            <Link
              to="#"
              className={'nav-link completed'}
              onClick={( e ) => {
                e.preventDefault();
                setShowModal( true );
                setLinkToMenuItem( '/market-tracker/' );
              }}
            >
              <div className="nav-text">My Trackers</div>
            </Link>
          </div>
          <div className="nav-item col">
            <Link to="#"
                  className={stepOfCreationComps === 1 ? 'nav-link active' : 'nav-link completed'}
                  onClick={() => setStepOfCreationComps( 1 )}>
              <div className="nav-text">1. Name & Type</div>
            </Link>
          </div>
          <div className="nav-item col">
            <Link
              to="#"
              className={classStep2}
              onClick={() => setStepOfCreationComps( 2 )}
            >
              <div className="nav-text">2. Pick Companies</div>
            </Link>
          </div>
          {compType && <>
            {compType !== 'CustomComps::MarketTimeline' ? <>
              <div className="nav-item col">
                <Link
                  to="#"
                  onClick={() => setStepOfCreationComps( 3 )}
                  className={classStep3}>
                  <div className="nav-text">3. Choose State</div>
                </Link>
              </div>
            </> : <></>}
            {stepOfCreationComps >= 3 && <div className="nav-item col">
              <Link
                to="#"
                className={'nav-link active btn-outline-green2'}
              >
                <div className="nav-text">{kitcut( compName, 20 )}</div>
              </Link>
            </div>}
          </>
          }
        </nav>
      </div>
      {stepOfCreationComps >= 3 && <div className="col-1">
        <Link to="#"
              className="fs-16px w-75px h-40px btn bg-green2 btn-outline-light"
              onClick={( e ) => handleSaveCustomComps( e, 'save' )}
        >Save</Link>
      </div>}
    </WizardContainer>
    <CompSetContainer>
      {(stepOfCreationComps === 1 || stepOfCreationComps === 2) &&
        <div style={{ maxWidth: '1200px', width: '100%', overflowX: "hidden", height: "calc(100vh - 178px)" }}>
          {stepOfCreationComps === 1 && <NewCompSetStep1 />}
          {stepOfCreationComps === 2 && <NewCompSetStep2 />}
        </div>
      }
      {(stepOfCreationComps === 3 || stepOfCreationComps === 4) && compType === 'CustomComps::MarketTrackerMap' &&
        <div style={{ width: '100%', overflowX: "hidden", height: "calc(100vh - 178px)" }}>
          <MarketTracker />
        </div>
      }

      {stepOfCreationComps === 3 && compType === 'CustomComps::MarketTimeline' &&
        <div style={{ width: '100%', overflowX: "hidden", height: "calc(100vh - 178px)" }}>
          <MarketTrackerTimeline preview={true} />
        </div>}

      {(stepOfCreationComps > 1 && stepOfCreationComps < 3) &&
        <Sidebar className="right-sidebar col-3 px-3 py-4"
        >
          <div className="d-flex flex-column justify-content-between m-2 h-100">
            <div>
              <div className="color-purple d-flex align-items-center my-2">
                {compType === 'CustomComps::MarketTrackerMap' && <PerformanceMapIcon width="25" height="22" />}
                {compType === 'CustomComps::MarketTimeline' && <TimeLineIcon size='25' />}
                <span className="mx-1 fs-15px">{compType === 'CustomComps::MarketTrackerMap' ? 'Market Performance Map' : 'Market Entry Timeline'}</span>
              </div>
              <h3>{compName} <span className="color-purple">{sidebarTitle}</span></h3>

              {stepOfCreationComps < 3 && <>
                <p className="py-1 fs-14px">Search or browse to add <b>up to {companiesLimit} companies</b> to your list</p>
                {searchCompanyArray && searchCompanyArray.map( el => {
                  return <div
                    className="lh-18 d-flex align-items-center mb-1 fs-14px"
                    key={el.name}>
                  <span className="cursor-pointer" onClick={() => handleRemoveCompany( el.id )}>
                    <MinusIcon />
                  </span>
                    <span className="mx-2">{el.name}{el.isPublic && <PublicTagStyle>public</PublicTagStyle>}</span>
                  </div>
                } )}
                {searchCompanyArray.length ? <div className="py-3">
                  <Link
                    to="#"
                    className="btn btn-outline-black px-2"
                    onClick={() => setSearchCompanyArray( [] )}
                  ><MinusIconNoBorder /> Clear All</Link>
                </div> : <></>}
              </>}

            </div>
            <div>

              <div className="d-flex justify-content-end">
                <Link to=""
                      className="fs-16px btn w-150px color-green2 btn-outline-green2 text-uppercase"
                      onClick={() => setStepOfCreationComps( stepOfCreationComps => stepOfCreationComps - 1 )}
                >Back</Link>
                <div className="w-15px"></div>

                {stepOfCreationComps === 2 &&
                  <Link to=""
                        className={searchCompanyArray.length ? "fs-16px btn w-200px text-light bg-green2 text-uppercase" : "fs-16px btn w-200px text-light bg-green2 text-uppercase disabled"}
                        onClick={() => setStepOfCreationComps( stepOfCreationComps => stepOfCreationComps + 1 )}
                  >{inProgress ? <InProgress /> : nextStepTitle}</Link>}

                {stepOfCreationComps === 3 &&
                  <Link
                    to="#"
                    className={CSMetricsAttributes.length > 0 ? "fs-16px btn w-200px text-light bg-green2 text-uppercase" : "fs-16px btn w-200px text-light bg-green2 text-uppercase disabled"}
                    onClick={( e ) => handleSaveCustomComps( e, 'preview' )}>{inProgress ? <InProgress /> : nextStepTitle}</Link>}

                {stepOfCreationComps === 2 && compType === 'MarketTimeline' &&
                  <Link
                    to="#"
                    className={CSMetricsAttributes.length > 0 ? "fs-16px btn w-200px text-light bg-green2 text-uppercase" : "fs-16px btn w-200px text-light bg-green2 text-uppercase disabled"}
                    onClick={( e ) => handleSaveCustomComps( e, 'preview' )}>{inProgress ? <InProgress /> : nextStepTitle}</Link>}
              </div>
            </div>
          </div>
        </Sidebar>}
    </CompSetContainer>
  </>;
}

export default NewMarketTrackerSet;
