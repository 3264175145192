import axios from "axios";
import { createContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { roleAtom, userIDAtom } from "../../atoms/profileAtom";

const AuthContext = createContext();
const baseURL = process.env.REACT_APP_BASE_URL;

export const AuthContextProvider = ( { children } ) => {
	const [ role, setRole ] = useRecoilState( roleAtom );
	const [ userID, setUserID ] = useRecoilState( userIDAtom );

	const [ user, setUser ] = useState( () => {
		let userProfile;
    userProfile = localStorage.getItem( "role" );
		if ( userProfile ) {
			// return JSON.parse(userProfile);
			return userProfile;
		}
		return null;
	} );

  const [ userIDValue, setUserValue ] = useState( () => {
    return localStorage.getItem( "id" ) ?? null;
  } );

	useEffect( () => {
		// console.log(user)
		setRole( user );
	}, [ user ] )

	useEffect( () => {
		// console.log(user)
    setUserID( userIDValue );
	}, [ userIDValue ] )

	const navigate = useNavigate();
	const login = async ( payload ) => {
		await axios.post( `${baseURL}/sign_in`, payload, {
			withCredentials: true,
		} );
		let apiResponse = await axios.get( `${baseURL}/user`, {
			withCredentials: true,
		} );
		localStorage.setItem( "userProfile", JSON.stringify( apiResponse.data ) );
		setUser( apiResponse.data );
		navigate( "/" );
	};

	const logout = async () => {
		//await axios.get( "http://localhost:4000/logout", { withCredentials: true } );
		localStorage.removeItem( "id" );
		setUser( null );
    let sessionID = '1';

    const config = {
      method: 'delete',
      url: `${baseURL}/sign_out`,
      headers: {
        'id': sessionID,
      },
    };

    axios( config ).then(()=>{
      console.log('Logout')
    });

    navigate( '/user/login', { replace: false } );

	};
	// console.log( user )

	return (
		<>
			<AuthContext.Provider value={{ user, login, logout }}>
				{children}
			</AuthContext.Provider>
		</>
	);
};

export default AuthContext;
