import { CompetitorsStyle } from "./styles";
import React, { useEffect, useState } from "react";
import { APIHeaders } from "../../components/shared/helpers";
import axios from "axios";
import { Link } from "react-router-dom";

const Competitors = ( { competitors } ) => {
  const baseURL = process.env.REACT_APP_BASE_URL;
  const baseLogoURL = process.env.REACT_APP_LOGOS_BASE_URL;
  const signature = process.env.REACT_APP_LOGOS_SIGNATURE;
  const [ competitorsList, setCompetitorsList ] = useState( [] );
  const [ competitorsLoaded, setCompetitorsLoaded ] = useState( false );

  const getCompanyListByID = () => {
    setCompetitorsLoaded( false );
    let params = '';
    competitors.map( el => {
      return params = params + '&ids[]=' + el;
    } )

    const config = {
      method: 'get',
      url: `${baseURL}/companies?${params}`,
      headers: APIHeaders,
      withCredentials: true,
      credentials: "include",
    };
    axios( config )
      .then( ( resp ) => {
        // console.log( resp.data.data );
        if ( resp.data.data.length ) {
          setCompetitorsList( resp.data.data );
        }
      } )
      .finally( () => {
        setCompetitorsLoaded( true );
      } )
  }

  useEffect( () => {
    if ( competitorsList.length === 0 && !competitorsLoaded ) {
      getCompanyListByID();
    }
  }, [ competitors ] )

  return <CompetitorsStyle>
    <h3>Competitors</h3>
    <div className="list">
      {competitorsList.map( el => {
        return <Link to={'/company/' + el.id} key={'competitor-' + el.id} className="logo-wrapper">
          <img src={baseLogoURL + el.logoFileName + signature} alt={el.name} title={el.name} />
        </Link>
      } )}
    </div>
  </CompetitorsStyle>
}

export default Competitors
