import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import axios from "axios";
import { useRecoilState, useSetRecoilState } from "recoil";
import { showCreateOrgModalAtom, updateCompanyListAtom } from "../../atoms/profileAtom";
import { APIHeaders, toasterNotify } from "../shared/helpers";
import DatePicker from "react-datepicker";
import moment from "moment";
import { CalendarIcon } from "./EditOrganizationModal";

const CreateOrganizationModal = () => {
	const baseURL = process.env.REACT_APP_BASE_URL;
	const [ showCreateOrgModal, setShowCreateOrgModal ] = useRecoilState( showCreateOrgModalAtom );
	const [ companyType, setCompanyType ] = useState( '' );
	const [ name, setName ] = useState( '' );
	const [ companyID, setCompanyID ] = useState( '' );
	const [ frequency, setFrequency ] = useState( 'one_time' );
	const [ tier, setTier ] = useState( 'basic' );
	const [ paymentDetails, setPaymentDetails ] = useState( '' );
	const [ paymentDate, setPaymentDate ] = useState( '' );
	const [ nameError, setNameError ] = useState( false );
	const [ companyTypeError, setCompanyTypeError ] = useState( false );
	const [ activateCompany, setActivateCompany ] = useState( false );
	const setUpdateCompanyList = useSetRecoilState( updateCompanyListAtom );

	const onNameChange = ( e ) => {
		setNameError( false );
		setName( e.target.value );
	}

	const onCompanyIDChange = ( e ) => {
		setCompanyID( e.target.value );
	}

	const handleSelectCompanyType = ( e ) => {
		setCompanyTypeError( false );
		setCompanyType( e.target.value );
	}

	const handleSelectCompanyFrequency = ( e ) => {
		setFrequency( e.target.value )
	}

	const handleSelectCompanyTier = ( e ) => {
		setTier( e.target.value )
	}

	const handleOnChangeStatus = () => {
		setActivateCompany( !activateCompany )
	}

	const onDetailsChange = ( e ) => {
		setPaymentDetails( e.target.value )
	}

	const reInitFields = () => {
		setName( '' );
		setCompanyID( '' );
		setActivateCompany( false );
		setCompanyType( '' );
		setTier( '' );
		setFrequency( '' );
		setPaymentDetails( '' );
		setPaymentDate( '' );
		setNameError( false );
		setCompanyTypeError( false );
	}

	const preValidateForm = () => {
		name.length > 0 ? setNameError( false ) : setNameError( true );
		companyType.length > 0 ? setCompanyTypeError( false ) : setCompanyTypeError( true );
	}

	const handleDateChange = ( e ) => {
		setPaymentDate( e )
	};

	const handleDateSelect = ( e ) => {
		console.log( e )
	};

	const handleCreateCompany = ( e ) => {
		// console.log( 'isValidForm: ' + isValidForm );
		preValidateForm();
		const data = new FormData();
		data.append( 'organization[name]', name );
		companyID && data.append( "organization[company_id]", companyID );
		data.append( "organization[company_type]", companyType );
		paymentDetails && data.append( "organization[payment_details]", paymentDetails );
		paymentDate && data.append( "organization[payment_date]", moment( paymentDate ).format( 'MM/DD/Y' ) );
		frequency && data.append( "organization[frequency]", frequency );
		tier && data.append( "organization[tier]", tier );
		if ( activateCompany ) {
			data.append( 'organization[activated]', true );
		}
		const config = {
			method: 'post',
			url: `${baseURL}/organizations`,
			headers: APIHeaders,
			withCredentials: true,
			credentials: "include",
			data: data,
		};
		if ( name.length && companyType.length ) {
			axios( config )
				.then( () => {
					toasterNotify( 'Organization created', '', 'success' );
					setUpdateCompanyList( updateCompanyList => !updateCompanyList );
					reInitFields();
					setShowCreateOrgModal( false );
				} )
				.catch( ( error ) => {
					if ( error.response.status !== 500 ) {
						for ( const [ title, message ] of Object.entries( error.response.data.error ) ) {
							toasterNotify( title?.toUpperCase(), message, 'danger' );
						}
					}
					console.log( error.response.data )
				} )
		}
	}

	const handleClose = () => {
		setShowCreateOrgModal( false );
		reInitFields();
	}

	return (
		<Modal show={showCreateOrgModal} onHide={handleClose}>
			<Modal.Header closeButton>
				<Modal.Title>Create Organization</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="modal-body">
					<form>
						<div className="row mb-2">
							<div className="col-md-6 col-12">
								<div className="form-floating mb-20px">
									<input
										type="text"
										className={nameError ? 'form-control fs-13px h-45px is-invalid' : 'form-control fs-13px h-45px '}
										name="name"
										placeholder="Name"
										value={name}
										onChange={onNameChange}
									/>
									<label htmlFor="name" className="d-flex h-45px fs-13px align-items-center">Name</label>
									{nameError && <div className="invalid-feedback">
										Please enter Organization Name.
									</div>}
								</div>
							</div>
							<div className="col-md-6 col-12">
								<div className="form-floating mb-20px">
									<input
										type="text"
										className={'form-control fs-13px h-45px '}
										name="company_id"
										placeholder="Organization ID"
										value={companyID}
										onChange={onCompanyIDChange}
									/>
									<label htmlFor="first_name" className="d-flex h-45px fs-13px align-items-center">Organization ID</label>
								</div>
							</div>
						</div>
						<div className="row mb-2">
							<div className="col-md-6 col-12">
								<div className="row mb-15px">
									<div className="col-md-12">
										<select className={companyTypeError ? "form-select h-45px is-invalid" : "form-select h-45px "}
												value={companyType}
												onChange={handleSelectCompanyType}
												placeholder="Organization Type"
										>
											<option disabled="disabled" value="">Type</option>
											<option value='firm'>Firm</option>
											<option value='company'>Company</option>
										</select>
										{companyTypeError && <div className="invalid-feedback">
											Please choose Organization Type.
										</div>}
									</div>
								</div>
							</div>
							<div className="col-md-6 col-12">
								<div className="row mb-15px">
									<div className="col-md-12">
										<select className={"form-select h-45px "}
												value={tier}
												onChange={handleSelectCompanyTier}
												placeholder="Select payment Tier"
										>
											<option disabled="disabled" value="">Payment Tier</option>
											<option value='basic'>Basic</option>
											<option value='premium'>Premium</option>
											<option value='enterprise'>Enterprise</option>
										</select>
									</div>
								</div>
							</div>
						</div>
						<div className="row mb-2">
							<div className="col-md-6 col-12">
								<div className="row mb-15px">
									<div className="col-md-12">
										<select className={"form-select h-45px "}
												value={frequency}
												onChange={handleSelectCompanyFrequency}
												placeholder="Payment Frequency"
										>
											<option disabled="disabled" value="">Payment Frequency</option>
											<option value='one_time'>One time</option>
											<option value='monthly'>Monthly</option>
											<option value='quarterly'>Quarterly</option>
											<option value='yearly'>Yearly</option>
											<option value='other'>Other</option>
										</select>
									</div>
								</div>
							</div>
							<div className="col-md-6 col-12">
								<div style={{ position: "relative" }}>
									<DatePicker
										className="form-control h-45px"
										value={paymentDate && moment( paymentDate, "MM/DD/Y" )}
										selected={paymentDate && new Date( paymentDate )} //moment("12-25-1995", "MM-DD-YYYY");
										placeholderText="Payment Date"
										format="MM/DD/Y"
										onSelect={handleDateSelect}
										onChange={handleDateChange}
									/>
									<CalendarIcon>
										<i className="fa fa-calendar" />
									</CalendarIcon>
								</div>
							</div>

						</div>
						<div className="row">
							<div className="col-md-6 col-12">
								<div className="form-check form-switch mt-2 mb-30px">
									<label className="form-check-label col-form-label" htmlFor="flexSwitchCheckDefault">Activate</label>
									<input className="form-check-input mt-2" checked={activateCompany} type="checkbox" onChange={handleOnChangeStatus} id="flexSwitchCheckDefault" />
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-12">
								<div className="form-floating mb-20px">
									<textarea
										className={"form-control h-100px "}
										name="paymentDetails"
										placeholder="Payment Details"
										value={paymentDetails}
										onChange={onDetailsChange}
									/>
									<label htmlFor="paymentDetails" className="d-flex h-45px fs-13px align-items-center">Payment Details</label>
								</div>
							</div>
						</div>
					</form>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<button className="btn btn-white" onClick={handleClose}>Close</button>
				<button type="submit" className="btn btn-success" onClick={handleCreateCompany}>Create</button>
			</Modal.Footer>
		</Modal>
	)
}

export default CreateOrganizationModal;
