import React, { useEffect, useContext, useState } from "react";
import { AppSettings } from "../../config/app-settings";
import { Link, useNavigate } from "react-router-dom";
import styled from 'styled-components';
import moment from "moment";
import { APIHeaders, kitcut } from "../../components/shared/helpers";
import TableIcon from "../../components/shared/TableIcon";
import ScatterPlotIcon from "../../components/shared/ScatterPlotIcon";
import TimeSeriesIcon from "../../components/shared/TimeSeriesIcon";
import SweetAlert from "sweetalert-react";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  chosenMetricsAtom,
  CSMetricsAttributesAtom, inProgressNewCustomCompAtom,
  newCustomCompNameAtom,
  newCustomCompTypeAtom, roleAtom,
  searchCompanyArrayAtom,
  selectedSectorsAtom,
  stepOfCreationCompsAtom,
  timeframeSelectedLabelAtom
} from "../../atoms/profileAtom";
import axios from "axios";
import ListIcon from "../../components/shared/ListIcon";
import { AbsoluteCenter, IconAndLabel } from "../market-tracker/Dashboard";
import Taglist from "./Taglist";
import Select from "react-select";
import { stylesSelectWide } from "../Companies";
import PerformanceMapIcon from "../../components/shared/PerfomanceMapIcon";
import TimeLineIcon from "../../components/shared/TimeLineIcon";
import CustomCompIcon from "../../components/shared/CustomCompIcon";

export const CompsItem = styled.div`
  //min-width: 280px;
  min-width: 320px;
  width: 100%;
  //height: 302px;
  padding: 10px;
  margin-right: 10px;
  margin-left: 10px;
  margin-bottom: 25px;
  background: rgba(214, 194, 212, 1);
  //background: #FFFFFF;
  //border: 1px solid #828282;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  position: relative;
  box-shadow: 0 0 10px rgba(130, 130, 130, 0);
  transition: .3s;
  overflow: hidden;

  @media screen and (max-width: 2560px) {
    width: calc(20% - 20px);
  }

  @media screen and (max-width: 1800px) {
    width: calc(20% - 20px);
  }

  @media screen and (max-width: 17700px) {
    width: calc(25% - 20px);
  }

  @media screen and (max-width: 1550px) {
    width: calc(33% - 20px);
  }

  @media screen and (max-width: 1460px) {
    min-width: 280px;
    width: calc(33% - 20px);
  }

  @media screen and (max-width: 1300px) {
    width: calc(50% - 20px);
  }

  @media screen and (max-width: 1024px) {
    min-width: 280px;
    width: calc(50% - 20px);
  }

  &:hover {
    box-shadow: 0 0 10px rgba(130, 130, 130, .4);
  }

  .tile-content {
    margin-top: 10px;
    border-radius: 20px;
    background-color: #fff;
    padding: 10px;
  }

  .header {
    width: 100%;
    padding: 12px;
    //max-height: 72px;
    border-radius: 20px;
    cursor: pointer;
     background: #fff;
    border-bottom: 1px solid #E2E2E2;

    h4 {
      font-size: 18px;
      line-height: 24px;
      margin: 0 0 8px 0;
      //color: var(--bs-blue);
      font-weight: 400;
      color: black;
      max-height: 1.5rem;
    }

    .type-comps, .date {
      font-size: 12px;
      line-height: 20px;
      color: #333333;
      //margin-bottom: 8px;
    }

    .type-comps {
      color: #B668AE;
    }
  }

  .icons {
    display: flex;
    padding: 8px;
    flex-wrap: wrap;
    justify-content: start;
    overflow: hidden;
    min-height: 120px;

    img {
      width: 100%;
      //margin: 5px 10px;
      //max-width: 100px;
      //max-height: 40px;
    }
  }
`;

const DeleteComps = styled.div`
  position: absolute;
  top: auto;
  bottom: 6px;
  left: auto;
  right: 10px;
  cursor: pointer;
  font-size: 16px;
  color: #828282;
  transition: all 0.3s;
  z-index: 20;

  i {
    &:hover {
      color: red;
    }
  }
`;

const UserInfo = styled.div`
  font-size: 14px;
  font-weight: 300;
`;

const FooterTile = styled.div`
  padding: 2px 2px 0px 8px;
  margin-right: 30px;
`;

const BadgeMore = styled.div`
  position: relative;
  top: 10px;
  bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #333;
  font-weight: 400;
  font-size: 12px;
`;

const TopHeaderPanel = styled.div`
  position: fixed;
  top: 88px;
  left: 365px;
  background-color: #f5f3f2;
  z-index: 150;
  padding: 10px;
  width: calc(100vw - 384px);
  border-bottom: 1px solid #f2f2f2;
`;

const TitleTypeComp = ( { type } ) => {
  let _typeListHuman;
  let _iconType;

  if ( type === 'CustomComps::Table' ) {
    _typeListHuman = 'Table';
    _iconType = <TableIcon />
  } else if ( type === 'CustomComps::ScatterPlot' ) {
    _typeListHuman = 'Scatter Plot';
    _iconType = <ScatterPlotIcon />
  } else if ( type === 'CustomComps::TimeSeries' ) {
    _typeListHuman = 'Time Series';
    _iconType = <TimeSeriesIcon />
  } else if ( type === 'CustomComps::CompanyList' ) {
    _typeListHuman = 'Company Fundraising History';
    _iconType = <ListIcon />
  } else if ( type === 'CustomComps::SectorList' ) {
    _typeListHuman = 'Sector Investment List';
    _iconType = <ListIcon />
  } else if ( type === 'CustomComps::StoreList' ) {
    _typeListHuman = 'Store List';
    _iconType = <ListIcon />
  } else if ( type === 'CustomComps::InvestorList' ) {
    _typeListHuman = 'Firm Retail Investments';
    _iconType = <ListIcon />
  } if ( type === 'CustomComps::MarketTrackerMap' ) {
    _typeListHuman = 'Market Map';
    _iconType = <PerformanceMapIcon width="25" height="20" />
  } else if ( type === 'CustomComps::MarketTimeline' ) {
    _typeListHuman = 'Entry Timeline';
    _iconType = <TimeLineIcon size="25" />
  }

  return <span className="type-comps">{_iconType} {_typeListHuman}</span>;
}

const linkToComp = ( id, type ) => {
  let _type = "/custom-comps/list";  //chart
  if ( type === 'CustomComps::Table' ) {
    _type = '/custom-comps/table';
  } else if ( type === 'CustomComps::ScatterPlot' ) {
    _type = '/custom-comps/scatter-plot';
  } else if ( type === 'CustomComps::TimeSeries' ) {
    _type = '/custom-comps/chart';
  } else if ( type === 'CustomComps::StoreList' ) {
    _type = '/custom-comps/store-list';
  } else if ( type === 'CustomComps::MarketTrackerMap' ) {
    _type = '/market-tracker/map';
  } else if ( type === 'CustomComps::MarketTimeline' ) {
    _type = '/market-tracker/timeline';
  }

  return `${_type}/${id}`;
}

export const sortingList = ( list ) => {
  let _data = list;
  _data.sort( function ( a, b ) {
    if ( a.id < b.id ) {
      return 1;
    }
    if ( a.id > b.id ) {
      return -1;
    }
    return 0;
  } )
  return _data;
}

export const checkIsTagged = ( el, compsTagsFilter ) => {
  if ( compsTagsFilter.length > 0 && el.taggings.length > 0 ) {

    return el.taggings.some( element1 => compsTagsFilter.find( element2 => element2.label === element1.label ) )
  } else return compsTagsFilter.length <= 0;
}

const MyCompsDashboard = ( {popular} ) => {
  const context = useContext( AppSettings );
  const [ confirmDeleteShow, setConfirmDeleteShow ] = useState( false );
  const [ idComps, setIdComps ] = useState( '' );
  const [ customCompsList, setCustomCompsList ] = useState( [] );
  const [ isLoadedList, setIsLoadedList ] = useState( false );
  const [ compName, setCompName ] = useRecoilState( newCustomCompNameAtom );
  const [ compType, setCompType ] = useRecoilState( newCustomCompTypeAtom );
  const [ stepOfCreationComps, setStepOfCreationComps ] = useRecoilState( stepOfCreationCompsAtom );
  const [ searchCompanyArray, setSearchCompanyArray ] = useRecoilState( searchCompanyArrayAtom );
  const [ chosenMetrics, setChosenMetrics ] = useRecoilState( chosenMetricsAtom );
  const [ CSMetricsAttributes, setCSMetricsAttributes ] = useRecoilState( CSMetricsAttributesAtom );
  const [ selectedSectors, setSelectedSectors ] = useRecoilState( selectedSectorsAtom );
  const [ timeframeSelectedLabel, setTimeframeSelectedLabel ] = useRecoilState( timeframeSelectedLabelAtom );
  const [ inProgressNewCustomComp, setInProgressNewCustomComp ] = useRecoilState( inProgressNewCustomCompAtom );
  const [ customCompsListCount, setCustomCompsListCount ] = useState( 0 );
  const [ companiesDetails, setCompaniesDetails ] = useState( [] );
  const [ compsTagsList, setCompsTagsList ] = useState( [] );
  const [ compsTagsFilter, setCompsTagsFilter ] = useState( [] );
  const [ companiesDetailsLoaded, setCompaniesDetailsLoaded ] = useState( false );
  const navigate = useNavigate();
  const baseURL = process.env.REACT_APP_BASE_URL;
  const role = useRecoilValue( roleAtom );
  const isAdmin = role === 'admin';

  const confirmDeleteCustomComps = ( id ) => {
    setConfirmDeleteShow( true );
    setIdComps( id );
  }

  const IconStar = ()=> {
    return <i className='fa fa-star fs-16px mt-1' title='media'></i>
  }

  useEffect(
    () => {
      context.handleSetAppHeaderNone( false );
      context.handleSetAppSidebarNone( false );
      context.handleSetAppContentClass( 'mh-100vh-88' );
      context.handleSetAppTitle( popular ? 'Popular Custom Comps' : 'My Custom Comps' );
      context.handleSetAppIcon( popular ? <IconStar /> : <CustomCompIcon /> );
    },
    []
  );

  const getCustomCompsList = () => {
    setIsLoadedList( false );
    const config = {
      method: 'get',
      url: `${baseURL}/custom_comps`,
      headers: APIHeaders,
      withCredentials: true,
      credentials: "include",
    };
    axios( config )
      .then( ( resp ) => {
        // setCustomCompsList( resp.data.data.reverse() sortingList(resp.data.data, 'id'));
        if ( popular ) {
          let _popList = resp.data.data.filter( comp => comp.default_comp === true )
          setCustomCompsList( sortingList( _popList ) );
          setCustomCompsListCount( _popList.length );
          console.log(_popList)
        } else {
          let _regularList = resp.data.data.filter( comp => comp.default_comp === false )
          setCustomCompsList( sortingList( _regularList ) );
          setCustomCompsListCount( _regularList.length );
        }
        setIsLoadedList( true );
        setCompsTagsFilter([]);
        // console.log(resp.data.data)
      } )
      .catch((error)=>{
        if ( error.response.status === 401 ) {
          navigate( '/user/login', {replace: true} )
        }
      })
  }

  useEffect( () => {
    getCustomCompsList();
  }, [] )

  useEffect( () => {
    if ( customCompsList.length > 0 ) {
      let _tagsList = [];
      customCompsList.map( comp => {
        if ( comp.taggings.length > 0 && !_tagsList.includes( comp.taggings ) ) {
          comp.taggings.map( tag => {
            let _val = tag.label;
            if ( !_tagsList.some( obj => obj.label === tag.label ) ) {
              let _tag = { "value": _val, "label": _val };
              _tagsList.push( _tag );
            }

          } )

        }
      } )

      setCompsTagsList( _tagsList )
    }
  }, [ customCompsList ] )

  // console.log( compsTagsList )

  const deleteCustomComps = () => {
    // console.log( idComps )
    setConfirmDeleteShow( false );
    const config = {
      method: 'delete',
      url: `${baseURL}/custom_comps/${idComps}`,
      headers: APIHeaders,
      withCredentials: true,
      credentials: "include",
    };
    axios( config ).then( resp => {
      console.log( resp );
    } ).catch( error => {
      console.log( error )
    } ).finally( () => {
      getCustomCompsList();
    } )
  }

  const getCompaniesDetails = ( ids ) => {
    setCompaniesDetailsLoaded( false )
    let params = '';
    ids.map( el => {
      return params = params + '&ids[]=' + el;
    } )

    const config = {
      method: 'get',
      url: `${baseURL}/companies?${params}`,
      headers: APIHeaders,
      withCredentials: true,
      credentials: "include",
    };
    axios( config )
      .then( ( resp ) => {
        setCompaniesDetails( resp.data.data );
      } ).finally( () => {
      setCompaniesDetailsLoaded( true );
    } )
  }

  useEffect( () => {
    if ( customCompsList.length ) {
      let _resourceIDs = [];
      customCompsList.forEach( comp => {
        comp.resource_ids.map( resource => {
          if ( !_resourceIDs.includes( resource ) ) {
            _resourceIDs.push( resource );
          }
        } )
      } )

      getCompaniesDetails( _resourceIDs );
    }
  }, [ customCompsListCount ] )

  const handleNewComp = ( e ) => {
    e.preventDefault();
    setCompName( '' );
    setCompType( '' );
    setStepOfCreationComps( 1 );
    setSearchCompanyArray( [] );
    setChosenMetrics( [] );
    setCSMetricsAttributes( [] );
    setSelectedSectors( [] );
    setTimeframeSelectedLabel( '' );
    setCompsTagsList([]);
    navigate( '/new-comp-set', { replace: false } );
  }

  const handleChangeTagsFilter = ( e ) => {
    // console.log( e, compsTagsList );
    setCompsTagsFilter( e );
  }

  if ( !isLoadedList ) return <div className="d-flex justify-content-center align-items-center text-center h-500px">
    <div className="spinner-border m-5" role="status">
      <span className="sr-only">Loading...</span>
    </div>
  </div>;

  return (
    <div className="row">
      <TopHeaderPanel className="top-header-panel">
        <div className="d-flex align-items-center justify-content-start">
          <div>
            <Link
              to="#"
              onClick={( e ) => handleNewComp( e )}
              className="my-lg-2 btn bg-green2 text-white fs-16px"
            ><i className="mx-2 fa fa-circle-plus"></i> New Custom Comp</Link>
          </div>

        </div>
        <div className="d-flex align-items-center justify-content-end pt-1">
          <div className="d-flex align-items-center">
            {compsTagsList.length > 0 ? <div className="d-flex align-items-center">
              <div className="fs-16px w-65px">Filter by</div>
              <div className="w-25px"></div>
              <div className="position-relative">
                <Select
                  isMulti
                  options={compsTagsList}
                  // value={filterSectorText}
                  onChange={handleChangeTagsFilter}
                  styles={stylesSelectWide}
                  placeholder="Select Tags"
                />
              </div>
            </div> : <></>}
          </div>
        </div>
      </TopHeaderPanel>
      <div className="col-12 mt-4" style={{
        paddingTop: '90px'
      }}>
        <div className="d-flex flex-wrap justify-content-start" style={{ marginRight: "-10px", marginLeft: "-10px" }}>
          {customCompsList && customCompsList.map( ( el, i ) => {
            let moreThan6Comp = false;
            let moreThan6CompCount = 0;
            if ( el.resource_ids.length > 6 ) {
              moreThan6Comp = true;
              moreThan6CompCount = el.resource_ids.length - 6;
            }
            // console.log(el)

            if (compsTagsFilter.length === 0 || (compsTagsFilter.length > 0 && checkIsTagged(el, compsTagsFilter))) return <CompsItem key={el.id + "-item-" + i + el.id} className="">
              <Link to={linkToComp( el.id, el.type )} className="text-decoration-none">
                <div className="header">
                  <h4>{kitcut( el.name, 26 )}</h4>
                  <div className="d-flex align-items-center justify-content-between">
                    <TitleTypeComp type={el.type} />
                    <span className="date">
									{moment( el.created_at ).format( 'MMM DD, yyyy' )}
								</span>
                  </div>
                </div>
              </Link>
              <div className="tile-content d-flex flex-column justify-content-between h-100">
                <div className="icons">
                  {(companiesDetailsLoaded || companiesDetails.length) ?
                    el.resource_ids.map( ( comp, i ) => {

                      return i < 6 ? <IconAndLabel
                        companiesDetails={companiesDetails}
                        comp={comp}
                        key={comp + i}
                      /> : <></>;
                    } ) : <div className="d-flex justify-content-center align-items-center text-center h-200px p-3 w-100">
                      <div className="spinner-border m-5" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    </div>}
                </div>
                <div className="position-relative">
                  {!popular && <DeleteComps onClick={() => confirmDeleteCustomComps( el.id )}>
                    <i className="fa fa-trash"></i>
                  </DeleteComps>}
                  {moreThan6Comp && <BadgeMore>+{moreThan6CompCount} more</BadgeMore>}
                  <FooterTile>
                    {/*<UserInfo>{isAdmin && el.user_name ? <><i className="fa fa-user"></i> <span>{el.user_name}</span></> : <></>}</UserInfo>*/}
                    {el.taggings.length > 0 ? <Taglist list={el.taggings} /> : <></>}
                  </FooterTile>
                </div>
              </div>
            </CompsItem>
          } )}
        </div>
      </div>
      {!customCompsList.length &&
        <AbsoluteCenter>
          <div className="d-flex w-100 justify-content-center mh-100vh-88 align-items-center">
            <p className="text-center text-muted">
              No Customs Comps generated. Click New Custom Comp to create one.
            </p>
          </div>
        </AbsoluteCenter>
      }
      <SweetAlert
        show={confirmDeleteShow}
        title="Are you sure you want to delete this Custom Comp?"
        type="error"
        confirmButtonText="Delete"
        showCancelButton={true}
        onCancel={() => setConfirmDeleteShow( false )}
        confirmButtonColor="rgb(255, 91, 87)"
        onConfirm={() => deleteCustomComps()}
      />
    </div>
  )
}

export default MyCompsDashboard;
