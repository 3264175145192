import React from 'react';

const ListIcon = ({color, size}) => {
	let _size = 21;
	let fillColor = "#B668AE";
	if ( color ) {
		fillColor = color;
	}
	if (size) _size = size;

	return <svg width={_size} height={_size} viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M2.00001 4.16667C3.10458 4.16667 4 3.23393 4 2.08333C4 0.932741 3.10458 0 2.00001 0C0.89544 0 0 0.932741 0 2.08333C0 3.23393 0.89544 4.16667 2.00001 4.16667Z" fill={fillColor}/>
		<rect x="6" width="44" height="4.16667" rx="2.08333" fill={fillColor}/>
		<path d="M2.00001 50.0002C3.10458 50.0002 4 49.0674 4 47.9168C4 46.7662 3.10458 45.8335 2.00001 45.8335C0.89544 45.8335 0 46.7662 0 47.9168C0 49.0674 0.89544 50.0002 2.00001 50.0002Z" fill={fillColor}/>
		<rect x="6" y="45.8335" width="44" height="4.16667" rx="2.08333" fill={fillColor}/>
		<path d="M2.00001 15.6252C3.10458 15.6252 4 14.6924 4 13.5418C4 12.3912 3.10458 11.4585 2.00001 11.4585C0.89544 11.4585 0 12.3912 0 13.5418C0 14.6924 0.89544 15.6252 2.00001 15.6252Z" fill={fillColor}/>
		<rect x="6" y="11.4585" width="44" height="4.16667" rx="2.08333" fill={fillColor}/>
		<path d="M2.00001 27.0832C3.10458 27.0832 4 26.1504 4 24.9998C4 23.8492 3.10458 22.9165 2.00001 22.9165C0.89544 22.9165 0 23.8492 0 24.9998C0 26.1504 0.89544 27.0832 2.00001 27.0832Z" fill={fillColor}/>
		<rect x="6" y="22.9165" width="44" height="4.16667" rx="2.08333" fill={fillColor}/>
		<path d="M2.00001 38.5417C3.10458 38.5417 4 37.6089 4 36.4583C4 35.3077 3.10458 34.375 2.00001 34.375C0.89544 34.375 0 35.3077 0 36.4583C0 37.6089 0.89544 38.5417 2.00001 38.5417Z" fill={fillColor}/>
		<rect x="6" y="34.375" width="44" height="4.16667" rx="2.08333" fill={fillColor}/>
	</svg>

}

export default ListIcon;
