import styled from "styled-components";

export const CompanyProfileStyle = styled.div`
  color: #1e1e1e;
  //padding-top: 25px;
  max-width: 1500px;
  
  h2 {
    font-size: 20px;
    font-weight: 400;
  }

  p {
    font-size: 14px;
    color: rgba(101, 106, 107, 1);

    span {
      color: rgba(30, 30, 30, 1);
    }
  }
  
  .base-info {
    display: flex;
    
    @media screen and (max-width: 1200px) {
      flex-wrap: wrap;
    }
  }
  
  .info-wrapper {
    display: flex;
  }
`;

export const LogoColumn = styled.div`
  width: 100%;
  max-width: 223px;
  //max-width: 297px;
  margin-bottom: 30px;
  margin-right: 20px;
  
  @media screen and (max-width: 1200px) {
    max-width: 100%;
  }
`;

export const WrapperLogo = styled.div`
  position: relative;
  overflow: hidden;
  padding: 10px;
  border: 1px solid #ebebeb;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  //height: 165px;
  height: 100%;
  margin-bottom: 20px;
  text-align: center;

  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
`;

export const SocialButtons = styled.div`
  display: flex;
  color: #B668AE;
  margin-right: -5px;
  margin-left: -5px;
  transition: all .3s;
  margin-bottom: 20px;

  a {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    margin-right: 5px;
    margin-left: 5px;
    justify-content: center;
    border: 1px solid #EBEBEB;
    background-color: #fff;
    border-radius: 6px;
    color: #B668AE;
    font-size: 16px;
    text-decoration: none;
    transition: all .3s;

    &:hover {
      background-color: #B668AE;
      color: #fff;

      svg path {
        fill: #fff;
      }
    }
  }

  svg path {
    fill: #B668AE;
    transition: all .3s;
  }
`;

export const CompanyDetailColumn = styled.div`
  //width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  //min-width: 320px;
  //max-width: 340px;
  //margin-left: 12px;
  //margin-right: 12px;
  margin-bottom: 30px;
`;

export const CompanyDescriptionStyle = styled.div`
  font-size: 14px;
  padding-top: 12px;
  color: #656A6B;
  min-height: 7rem;
`;

export const QuickStatsStyle = styled.div`
  font-size: 14px;
  line-height: 1.5;
  margin-bottom: 20px;

  .title {
    font-size: 14px;
    color: #656A6B;
    margin-bottom: 4px;
  }

  .value {
    font-size: 16px;
  }
`;

export const CompanyMetricsInfo = styled.div`
  display: flex;
  //background-color: #f9f9f9;
  border-radius: 8px;
  //border: 1px solid #EBEBEB;
  //padding: 12px 32px 18px;
  //margin-top: 30px;
  
  .column {
    width: 20%;
    //border: 1px solid red;
    
    .header {
      height: 64px;
      display: flex;
      align-items: center;
      line-height: 64px;
      font-size: 18px;
      font-weight: 400;
      border-bottom: 1px solid #EBEBEB;
      
      svg {
        margin-right: 8px;
      }
    }
    
    .metric-row {
      padding-top: 18px;
      padding-bottom: 18px;
      line-height: 1.5;
      border-bottom: 1px solid #EBEBEB;
      
      &:nth-last-of-type(1) {
        border-bottom: none;
      }

      .title {
        font-size: 14px;
        color: #656A6B;
        margin-bottom: 4px;
      }

      .value {
        font-size: 16px;
      }
    }
    
    .links-row {
      padding-top: 18px;
      padding-bottom: 18px;
      line-height: 1.5;
      
      a {
        font-size: 14px;
        color: #B668AE;
        text-decoration: none;
        margin-bottom: 4px;
      }
    }
  }`;

export const CompetitorsStyle = styled.div`
  margin-top: 30px;
  margin-bottom: 40px;
  
  h3 {
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 24px;
  }
  
  .list {
    display: flex;
    margin-right: -8px;
    margin-left: -8px;
    flex-wrap: wrap;
    
    .logo-wrapper {
      overflow: hidden;
      padding: 10px 25px;
      border: 1px solid #ebebeb;
      background-color: #F9F9F9;
      border-radius: 8px;
      margin-right: 8px;
      margin-left: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: calc(20% - 16px);
      min-width: 192px;
      height: 70px;
      margin-bottom: 16px;

      img {
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
      }
    }
  }
`;
