const MyComps = ({color, height}) => {
  let _color = color || 'white';
  let _height = height || '32px';

  return <svg xmlns="http://www.w3.org/2000/svg" width="20" height={_height} viewBox={`0 0 20 ${_height}`} fill="none">
    <path d="M12.7955 10.9038C12.7955 11.1691 12.5818 11.3837 12.3174 11.3837H10.5065V13.2018C10.5065 13.4672 10.2928 13.6818 10.0285 13.6818C9.76416 13.6818 9.55045 13.4672 9.55045 13.2018V11.3837H7.73954C7.47522 11.3837 7.26151 11.1691 7.26151 10.9038C7.26151 10.6384 7.47522 10.4238 7.73954 10.4238H9.55045V8.60568C9.55045 8.3403 9.76416 8.12574 10.0285 8.12574C10.2928 8.12574 10.5065 8.3403 10.5065 8.60568V10.4238H12.3174C12.5818 10.4238 12.7955 10.6384 12.7955 10.9038ZM18.9912 5.78814L17.6546 15.9065C17.5852 16.4391 17.1278 16.84 16.5935 16.84H3.40722C2.87295 16.84 2.41741 16.4391 2.34617 15.9083L1.00955 5.78814C0.968305 5.48135 1.06204 5.17268 1.2645 4.9393C1.46696 4.70591 1.76128 4.57228 2.06872 4.57228H2.21495V2.0747C2.21495 1.48183 2.69486 1 3.28537 1H6.3298C6.61663 1 6.8847 1.11105 7.08716 1.31432L8.63937 2.87272C8.66187 2.8953 8.68999 2.9066 8.71998 2.9066H16.7116C17.3021 2.9066 17.782 3.38842 17.782 3.98129V4.45559C17.782 4.51958 17.8345 4.5704 17.8964 4.5704H17.9283C18.2376 4.5704 18.53 4.70403 18.7325 4.93741C18.9368 5.17268 19.0306 5.48135 18.9912 5.78814ZM3.17102 2.07658V4.57416H16.8391C16.8353 4.53652 16.8278 4.49888 16.8278 4.45935V3.98506C16.8278 3.92106 16.7754 3.87025 16.7135 3.87025H8.72186C8.43504 3.87025 8.16696 3.7592 7.9645 3.55593L6.41229 1.99753C6.38979 1.97494 6.36167 1.96365 6.33168 1.96365H3.28725C3.22351 1.95989 3.17102 2.01259 3.17102 2.07658ZM18.0445 5.66203C18.0501 5.61874 18.0332 5.58863 18.0164 5.57169C18.0014 5.55287 17.9733 5.53217 17.9301 5.53217H17.8983H2.69486H2.0706C2.02748 5.53217 1.99936 5.55287 1.98437 5.57169C1.96937 5.58863 1.95062 5.62063 1.95625 5.66203L3.29287 15.7804C3.30037 15.8368 3.34911 15.8801 3.40722 15.8801H16.5916C16.6498 15.8801 16.6985 15.8368 16.706 15.7804L18.0445 5.66203Z" fill="white" stroke={_color} stroke-width="0.2"/>
  </svg>
}

export default MyComps;
