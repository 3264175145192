
const GroceryIcon = ({size}) => {
  let _size = size === 'L' ? 2 : 1;

  return <svg width={_size * 24} height={_size * 24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="12.0088" y="7.5603" width="6.995" height="13.1068" fill="#e3cee1"/>
    <path d="M3.50003 21.7499V6.74997H6.59264C6.59264 6.74997 7.14567 6.74997 7.50003 6.74997C7.50003 3.94498 9.79549 2.4698 12.0679 2.45591C14.295 2.4423 16.5 3.83238 16.5 6.74997H20.5V21.7499H3.50003ZM9.00001 6.74997H15C15 2.81943 9.00001 3.04595 9.00001 6.74997ZM5.00001 20.2499H19V8.24994H5.00001V20.2499Z" fill="#B668AE"/>
    <path d="M15.1865 12.4365C14.3109 13.3122 13.2487 13.75 12 13.75C10.7513 13.75 9.6891 13.3122 8.81345 12.4365C7.93782 11.5609 7.5 10.4987 7.5 9.25H8.99997C8.99997 10.0833 9.29164 10.7917 9.87497 11.375C10.4583 11.9583 11.1666 12.25 12 12.25C12.8333 12.25 13.5416 11.9583 14.125 11.375C14.7083 10.7917 15 10.0833 15 9.25H16.5C16.5 10.4987 16.0621 11.5609 15.1865 12.4365Z" fill="#B668AE"/>
    <rect x="6.07758" y="2.44409" width="11.8157" height="4.30945" fill="#F5F5F5"/>
    <path d="M8.81351 2.81272C7.93788 3.68835 7.50006 4.75052 7.50006 5.99922H9.00004C9.00004 5.16588 9.2917 4.45755 9.87504 3.87422C10.4584 3.29088 11.1667 2.99922 12 2.99922C12.8334 2.99922 13.5417 3.29088 14.125 3.87422C14.7084 4.45755 15 5.16588 15 5.99922H16.5C16.5 4.75052 16.0622 3.68835 15.1866 2.81272C14.3109 1.93708 13.2487 1.49927 12 1.49927C10.7513 1.49927 9.68916 1.93708 8.81351 2.81272Z" fill="#c897c3"/>
  </svg>;
}

export default GroceryIcon;
