import React from "react";
import styled from "styled-components";
import moment from "moment";

const CitiesListStyle = styled.div`
  //background-color: #fcfcfc;
  padding: ${props => props.index === props.show ? '0px 0px 5px 0px' : '0px 0px 0px 0px'};
  //border-bottom-right-radius: 4px;
  //border-bottom-left-radius: 4px;
  margin-right: 8px;
  margin-left: 8px;
   border-top: ${props => props.index === props.show ? '1px solid #F2F2F2' : 'none'};
  //border-right: 1px solid #F2F2F2s;
  //border-left: 1px solid #F2F2F2;
`;

const CitiesListStyleInner = styled.div`
  width: 100%;
  max-height: ${props => props.index === props.show ? '100px' : '0px'};
  overflow-y: auto;
  background-color: #fff;
  //margin-bottom: 10px;
  font-size: 12px;
  transition: all .3s;
`;

const CityStyle = styled.div`
  margin-bottom: 2px;
  padding: 2px 0px 2px 0px;
  //border-bottom: 1px solid #E0E0E0;
  color: #3C3C43;
  display: flex;
  justify-content: space-between;

  &:nth-last-of-type(1) {
    border-bottom: none;
  }
`;

const LabelsStyle = styled.div`
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  font-size: 12px;
margin-top: 4px;
  height: ${props => props.index === props.show ? '20px' : '0px'};
  color: #8D8D8D;
  //padding-right: 10px;
  transition: all .3s;
  padding-bottom: ${props => props.index === props.show ? '2px' : '0px'};
`;

const formattedDate = (date, isOpeningDateComplete) => {
  // if (isOpeningDateComplete) {
  //   _openingDate = moment( date.openingDate ).format( 'll' );
  // } else {
  //   _openingDate = moment( date.openingDate ).format( 'MMM, YYYY' );
  // }

  return isOpeningDateComplete ? moment( date ).format( 'll' ) : moment( date ).format( 'MMM, YYYY' );
}

const CitiesList = ( { citiesList, index, show, openingDate } ) => {
  const occurrences = citiesList.reduce( function ( acc, curr ) {
    return acc[ curr ] ? ++acc[ curr ] : acc[ curr ] = 1, acc
  }, {} );
  // let entryDateForCity = [ {'Houstons': '2020-02-01T00:00:00.000Z'}, {'Houstonf': '2020-02-01T00:00:00.000Z'}];
  // console.log(openingDate)

  const cityDates = openingDate.reduce((result, item) => {
    const cityName = item.city;
    const openingDate = item.openingDate;

    if (!result[cityName] || openingDate < result[cityName]) {
      result[cityName] = openingDate;
    }

    return result;
  }, {});

  const OpeningDate = ( { city } ) => {
    let _openingDate = '';
    openingDate.map( date => {
      if ( date.city === city ) {

        // if (date.isOpeningDateComplete) {
        //   _openingDate = moment( date.openingDate ).format( 'll' );
        // } else {
        //   _openingDate = moment( date.openingDate ).format( 'MMM, YYYY' );
        // }
        let _date = cityDates[city];
        _openingDate = formattedDate(_date, date.isOpeningDateComplete);
      }
    } )

    return <span>{_openingDate ? _openingDate : '—'}</span>
  }

  const sortable = Object.entries( occurrences )
    .sort( ( [ , a ], [ , b ] ) => b - a )
    .reduce( ( r, [ k, v ] ) => ({ ...r, [ k ]: v }), {} );

  return <CitiesListStyle
    index={index}
    show={show}
  >
    {Object.entries( sortable ).length > 0 && <LabelsStyle
      index={index}
      show={show}
    >
      <span>Location</span>
      <span>Entry Date</span>
    </LabelsStyle>}
    <CitiesListStyleInner
      index={index}
      show={show}
    >
      {Object.entries( sortable ).map( ( [ key, value ] ) => {
        return <CityStyle
          key={key}
        ><span>{key} ({value})</span> <OpeningDate city={key} />
        </CityStyle>;
      } )}
    </CitiesListStyleInner>
  </CitiesListStyle>
}

export default CitiesList;
