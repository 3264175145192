import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useRecoilState, useSetRecoilState } from "recoil";
import { showEditOrgModalAtom, updateCompanyListAtom } from "../../atoms/profileAtom";
import { APIHeaders, toasterNotify } from "../shared/helpers";
import axios from "axios";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from "moment";
import styled from "styled-components";

export const CalendarIcon = styled.div`
	position: absolute;
	top: 50%;
	left: auto;
	right: 10px;
	transform: translateY(-50%);
	font-size: 16px;
	color: var(--bs-secondary);
`;

const EditOrganizationModal = ( { organization } ) => {
	const baseURL = process.env.REACT_APP_BASE_URL;
	const [ showEditOrgModal, setShowEditOrgModal ] = useRecoilState( showEditOrgModalAtom );
	const [ updateCompanyList, setUpdateCompanyList ] = useRecoilState( updateCompanyListAtom );
	const [ companyType, setCompanyType ] = useState( organization.type );
	const [ name, setName ] = useState( organization.name );
	const [ ID, setID ] = useState( organization.id );
	const [ companyID, setCompanyID ] = useState( organization.company_id );
	const [ initCompanyID, setInitCompanyID ] = useState( organization.company_id );
	const [ frequency, setFrequency ] = useState( organization.frequency );
	const [ tier, setTier ] = useState( organization.tier );
	const [ paymentDetails, setPaymentDetails ] = useState( organization.payment_details );
	const [ paymentDate, setPaymentDate ] = useState( organization.payment_date ?? null );
	const [ nameError, setNameError ] = useState( false );
	const [ companyTierError, setCompanyTierError ] = useState( false );
	const [ companyFrequencyError, setCompanyFrequencyError ] = useState( false );
	const [ paymentDetailsError, setPaymentDetailsError ] = useState( false );
	const [ activateCompany, setActivateCompany ] = useState( organization.activated );

	useEffect( () => {
		setID( organization.id );
		setName( organization.name )
		setCompanyType( organization.company_type );
		setCompanyID( organization.company_id );
		setFrequency( organization.frequency );
		setTier( organization.tier );
		setPaymentDetails( organization.payment_details );
		setPaymentDate( organization.payment_date && moment(organization.payment_date).format('MM/DD/Y'));
		setNameError( false );
		setCompanyTierError( false );
		setCompanyFrequencyError( false );
		setPaymentDetailsError( false );
		setActivateCompany( organization.activated !== null );
	}, [ organization, ID ] )

	const handleSaveOrganization = () => {
		const data = new FormData();
		data.append( 'organization[name]', name );
		data.append( "organization[company_id]", companyID );
		data.append( "organization[company_type]", companyType );
		data.append( "organization[payment_details]", paymentDetails || '' );
		paymentDate && data.append( "organization[payment_date]",  moment(paymentDate).format('MM/DD/Y') || '' );//%m/%d/%Y
		data.append( "organization[frequency]", frequency );
		data.append( "organization[tier]", tier );
		data.append( 'organization[activated]', activateCompany );
		const config = {
			method: 'put',
			url: `${baseURL}/organizations/${ID}`,
			headers: APIHeaders,
			withCredentials: true,
			credentials: "include",
			data: data,
		};
		axios( config )
			.then( () => {
				toasterNotify( 'Organization changed', '', 'success' );
				setUpdateCompanyList( !updateCompanyList );
				setShowEditOrgModal( false );
			} )
			.catch( ( error ) => {
				if ( error.response.status === 500 ) {
					toasterNotify( 'Error', 'Something went wrong.', 'danger' )
				} else {
          // console.log(error.response?.data.error);
          if (error.response?.data.error ) {
            for ( const [ title, message ] of Object.entries( error.response?.data.error ) ) {
              toasterNotify( title?.toUpperCase(), message, 'danger' );
            }
          }
          if (error.response.data.name) {
            setNameError(true);
            toasterNotify( 'Name', error.response.data.name.toUpperCase(), 'danger' );
          }
				}
				console.log( error.response.data )
			} ).finally( () => {
			setUpdateCompanyList( updateCompanyList => !updateCompanyList );
		} )
	}

	const handleClose = () => {
		setShowEditOrgModal( false );
	}

	const onNameChange = ( e ) => {
		setNameError( false );
		setName( e.target.value );
	}

	const handleSelectCompanyFrequency = ( e ) => {
		setCompanyFrequencyError( false )
		setFrequency( e.target.value )
	}

	const handleSelectCompanyTier = ( e ) => {
		setCompanyTierError( false )
		setTier( e.target.value )
	}

	const handleOnChangeStatus = () => {
		setActivateCompany( !activateCompany )
	}

	const handleChangeCompanyID = (e) => {
		setCompanyID(e.target.value)
	}

	const onDetailsChange = ( e ) => {
		setPaymentDetailsError( false );
		setPaymentDetails( e.target.value )
	}

	const handleDateChange = (e) => {
		let diff = moment().diff(e, 'years',true) * -1;
		let diffAbs = Math.round(Math.abs(diff));
		if (moment(e).isValid() && diffAbs < 30){
			setPaymentDate(e)
		} else {
      setPaymentDate('');
    }
		//
	};

	const handleDateSelect = (e) =>{
		console.log(moment(e).isValid())
		console.log(e)
	};

	return (
		<Modal show={showEditOrgModal} onHide={handleClose}>
			<Modal.Header closeButton>
				<Modal.Title>Edit Organization</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="row mb-2">
					<div className="col-md-6 col-12">
						<div className="form-floating mb-15px">
							<input
								type="text"
								className={nameError ? 'form-control fs-13px h-45px is-invalid' : 'form-control fs-13px h-45px '}
								name="name"
								placeholder="Name"
								value={name}
								onChange={onNameChange}
							/>
							<label htmlFor="name" className="d-flex h-45px fs-13px align-items-center">Name</label>
							{nameError && <div className="invalid-feedback">
								Please enter Organization Name.
							</div>}
						</div>
					</div>
					<div className="col-md-6 col-12">
						<div className="form-floating mb-15px">
							<input
								type="text"
								className='form-control fs-13px h-45px '
								name="company_id"
								placeholder="Organization ID"
								onChange={handleChangeCompanyID}
								disabled={initCompanyID ?? 'disabled'}
								value={companyID}
							/>
							<label htmlFor="first_name" className="d-flex h-45px fs-13px align-items-center">Organization ID</label>
						</div>
					</div>
				</div>
				<div className="row mb-2">
					<div className="col-md-6 col-12">
						<div className="row mb-15px">
							<div className="col-md-12">
								<select className="form-select h-45px "
										value={companyType}
										disabled="disabled"
										placeholder="Type"
								>
									<option disabled="disabled" value="">Type</option>
									<option value='firm'>Firm</option>
									<option value='company'>Company</option>
								</select>
							</div>
						</div>
					</div>
					<div className="col-md-6 col-12">
						<div className="row mb-15px">
							<div className="col-md-12">
								<select className={companyTierError ? "form-select h-45px is-invalid" : "form-select h-45px "}
										value={tier}
										onChange={handleSelectCompanyTier}
										placeholder="Payment Tier"
								>
									<option disabled="disabled" value="">Payment Tier</option>
									<option value='basic'>Basic</option>
									<option value='premium'>Premium</option>
									<option value='enterprise'>Enterprise</option>
								</select>
								{companyTierError && <div className="invalid-feedback">
									Please choose Company Tier.
								</div>}
							</div>
						</div>
					</div>
				</div>
				<div className="row mb-1">
					<div className="col-md-6 col-12">
						<div className="row mb-15px">
							<div className="col-md-12">
								<select className={companyFrequencyError ? "form-select h-45px is-invalid" : "form-select h-45px "}
										value={frequency}
										onChange={handleSelectCompanyFrequency}
										placeholder="Payment Frequency"
								>
									<option disabled="disabled" value="">Payment Frequency</option>
									<option value='one_time'>One time</option>
									<option value='monthly'>Monthly</option>
									<option value='quarterly'>Quarterly</option>
									<option value='yearly'>Yearly</option>
									<option value='other'>Other</option>
								</select>
								{companyFrequencyError && <div className="invalid-feedback">
									Please choose Company frequency.
								</div>}
							</div>
						</div>
					</div>
					<div className="col-md-6 col-12">
						<div style={{position: "relative"}}>
							<DatePicker
								className="form-control h-45px"
								value={paymentDate && moment(paymentDate, "MM/DD/Y")}
								selected={paymentDate && new Date(paymentDate) } //moment("12-25-1995", "MM-DD-YYYY");
								placeholderText="Payment date"
								format="MM/DD/Y"
								onSelect={handleDateSelect}
								onChange={handleDateChange}
                showIcon={true}
                isClearable={paymentDate && true}
                clearButtonClassName="mx-4"
							/>
							<CalendarIcon>
								<i className="fa fa-calendar" />
							</CalendarIcon>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-md-6 col-12">
						<div className="form-check form-switch mt-2 mb-30px">
							<label className="form-check-label col-form-label" htmlFor="flexSwitchCheckDefault">Activate</label>
							<input
								className="form-check-input mt-2"
								checked={activateCompany}
								type="checkbox"
								onChange={handleOnChangeStatus}
							/>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-12">
						<div className="form-floating mb-20px">
							<textarea
								className={paymentDetailsError ? "form-control is-invalid h-100px" : "form-control h-100px"}
								name="paymentDetails"
								rows="2"
								placeholder="Payment Details"
								value={paymentDetails}
								onChange={onDetailsChange}
							/>
							<label htmlFor="paymentDetails" className="d-flex h-45px fs-13px align-items-center">Payment Details</label>
							{paymentDetailsError && <div className="invalid-feedback">
								Please enter payment details.
							</div>}
						</div>
					</div>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<button className="btn btn-white" onClick={handleClose}>Close</button>
				<button className="btn btn-success" onClick={handleSaveOrganization}>Save</button>
			</Modal.Footer>
		</Modal>
	)
}

export default EditOrganizationModal;
