import React, { useEffect, useContext, useState } from "react";
import MarketTracker from "./MarketTracker";
import { useRecoilState } from "recoil";
import {
  customCompAtom,
  locationsLoadedListAtom, needUpdateCompAtom,
  resultViewMarketTrackerAtom,
  searchCompanyArrayAtom, searchFieldGoogleMapAtom,
  selectedStateAtom,
  showCitiesIDCompanyAtom,
  stepOfCreationCompsAtom,
  trackerIDAtom
} from "../../atoms/profileAtom";
import { AppSettings } from "../../config/app-settings";
import { useParams } from "react-router-dom";
import { APIHeaders } from "../../components/shared/helpers";
import axios from "axios";
import TitleCustomComp from "../../components/shared/TitleCustomComp";
import MarkerTrackerIcon from "../../components/shared/MarkerTrackerIcon";
import styled from "styled-components";
import TitleTagCustomComp from "../../components/shared/TitleTagCustomComp";

export const remapResourceIDs = ( data ) => {
  let _data = [];
  data.forEach( element => {
    _data.push( { id: element } );
  } )
  return _data;
}

const WrapperCustomComp = styled.div`
  background-color: #fff;
  border-radius: 20px;
  padding: 16px 16px 5px 16px;
`

const MarketTrackerMap = () => {
  const [ stepOfCreationComps, setStepOfCreationComps ] = useRecoilState( stepOfCreationCompsAtom );
  const [ customComp, setCustomComp ] = useRecoilState( customCompAtom );
  const [ searchCompanyArray, setSearchCompanyArray ] = useRecoilState( searchCompanyArrayAtom );
  const [ resultViewMarketTracker, setResultViewMarketTracker ] = useRecoilState( resultViewMarketTrackerAtom );
  const [ needUpdateComp, setNeedUpdateComp ] = useRecoilState( needUpdateCompAtom );
  const [ isLoadedComp, setIsLoadedComp ] = useState( false );
  const [ compName, setCompName ] = useState( '' );
  const [ state, setState ] = useRecoilState( selectedStateAtom );
  const [ trackerID, setTrackerID ] = useRecoilState( trackerIDAtom );
  const [ loadedList, setLoadedList ] = useRecoilState( locationsLoadedListAtom );
  const [ showCitiesID, setShowCitiesID ] = useRecoilState( showCitiesIDCompanyAtom );
  const [ searchField, setSearchField ] = useRecoilState( searchFieldGoogleMapAtom );
  const context = useContext( AppSettings );
  const baseURL = process.env.REACT_APP_BASE_URL;
  const { id } = useParams();
  // console.log(searchCompanyArray)

  const getCustomComp = () => {
    setIsLoadedComp( false );
    const data = new FormData();
    const config = {
      method: 'get', url: `${baseURL}/custom_comps/${id}`, headers: APIHeaders, withCredentials: true, credentials: "include", data: data,
    };
    axios( config )
      .then( ( resp ) => {
        setCustomComp( resp.data.data );
        // setCompMetrics( resp.data.data.custom_comp_metrics );
        setIsLoadedComp( true );
        console.log( resp.data.data )
      } )
  }

  useEffect( () => {
    if ( customComp.resource_ids !== undefined && customComp.resource_ids.length > 0 ) {
      setSearchCompanyArray( remapResourceIDs( customComp.resource_ids ) );
    }
    setCompName( customComp.name );
    setState( customComp.state );
    setSearchField( customComp.gmap_search );
  }, [ customComp ] )

  useEffect( () => {
    setStepOfCreationComps( 3 );
    context.handleSetAppTitle( 'Market Tracker' );
    context.handleSetAppIcon( <MarkerTrackerIcon /> );
    context.handleSetAppContentClass( 'mh-100vh-88' );
    setResultViewMarketTracker( true );
    setSearchCompanyArray( [] );
    setLoadedList( false );
    setState( '' );
    setShowCitiesID( '' );
    setTrackerID( id );
  }, [ id ] )

  useEffect( () => {
    if ( !isLoadedComp ) {
      getCustomComp();
    }
  }, [ isLoadedComp, needUpdateComp ] )

  // compName,
  //   id,
  //   preview,
  //   isPopular,
  //   ownerID,
  //   taggings,
  //   marketTracker,
  return <>
    {isLoadedComp ? <>
      <TitleTagCustomComp
        compName={compName}
        isPopular={customComp.default_comp}
        ownerID={customComp.user_id}
        taggings={customComp.taggings}
        id={id}
        marketTracker
        icon="fa-location-dot"
        title="Market Tracker"
      />
      <WrapperCustomComp>
        <TitleCustomComp
          compName={compName}
          isPopular={customComp.default_comp}
          ownerID={customComp.user_id}
          taggings={customComp.taggings}
          id={id}
          marketTracker
          icon="fa-location-dot"
          title="Market Tracker"
        />
        <MarketTracker />
      </WrapperCustomComp>
    </> : <></>}
  </>;
}

export default MarketTrackerMap;
