import { Modal } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import {
  messagesLoadedAtom,
  questionsListAtom, questionsListLoadedAtom,
  showEditMessageModalAtom,
  showEditMessageModalIDAtom
} from "../../atoms/profileAtom";
import { APIHeadersRAW, toasterNotify } from "../../components/shared/helpers";
import axios from "axios";
import moment from "moment";

const SupportModal = () => {
  const [ showEditMessageModal, setShowEditMessageModal ] = useRecoilState( showEditMessageModalAtom );
  const [ showEditMessageModalID, setShowEditMessageModalID ] = useRecoilState( showEditMessageModalIDAtom );
  const [ questionsListLoaded, setQuestionsListLoaded ] = useRecoilState( questionsListLoadedAtom );
  const [ questionsList, setQuestionsList ] = useRecoilState( questionsListAtom );
  const [ loadedMessages, setLoadedMessages ] = useRecoilState( messagesLoadedAtom );
  const [ statusTicket, setStatusTicket ] = useState( '' );
  const [ ticketCreated, setTicketCreated ] = useState( '' );
  const [ ticketUpdated, setTicketUpdated ] = useState( '' );
  const [ userName, setUserName ] = useState( '' );
  const [ userEmail, setUserEmail ] = useState( '' );
  const [ userPhone, setUserPhone ] = useState( '' );
  const [ textMessage, setTextMessage ] = useState( '' );
  const [ selectedQuestion, setSelectedQuestion ] = useState( '' );
  const [ adminNotes, setAdminNotes ] = useState( '' );
  const baseURL = process.env.REACT_APP_BASE_URL;

  const handleClose = () => {
    setShowEditMessageModal( false );
  }

  useEffect( () => {
    questionsList.map( q => {
      if ( q.id === showEditMessageModalID ) {
        setStatusTicket( q.status );
        setUserName( q.user.full_name );
        setUserEmail( q.user.email );
        setUserPhone( q.user.phone );
        setTextMessage( q.details !== null ? q.details : '' );
        setAdminNotes( q.admin_notes  !== null ? q.admin_notes : '');
        setSelectedQuestion( q.options?.questions );
        setTicketCreated( q.created_at );
        setTicketUpdated( q.updated_at );
      }
    } )

  }, [ showEditMessageModalID ] )

  const handleChangeTicket = () => {

    const config = {
      method: 'put',
      url: `${baseURL}/ask_us_requests/${showEditMessageModalID}?status=${statusTicket}&admin_notes=${adminNotes}`,
      headers: APIHeadersRAW,
      withCredentials: true,
      credentials: "include",
    };
    axios( config ).then( () => {
      toasterNotify( 'Ticket updated', '', 'success' );
      setQuestionsListLoaded( false );
      setShowEditMessageModal( false );
      setLoadedMessages( false );
    } ).catch( ( error ) => {
      console.log( error )
      setShowEditMessageModal( false );
      // toasterNotify( 'Error', error.error, 'danger' );
    } )
  }

  const handleSelectStatus = ( e ) => {
    setStatusTicket( e.target.value );
  }

  const handleNotesChange = ( e ) => {
    setAdminNotes( e.target.value );
  }

  return <Modal show={showEditMessageModal} onHide={handleClose}>
    <Modal.Header closeButton>
      <Modal.Title>User's ticket</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <div className="row">
        <div className="col-6">
          <div className="mb-3">Name: <b>{userName}</b></div>
          <div className="mb-3">Email: <b>{userEmail}</b></div>
          <div className="mb-3">Phone: <b>{userPhone ? userPhone : '-'}</b>
          </div>
        </div>
        <div className="col-6">
          <div className="mb-3">Created: <b>{moment( ticketCreated ).format( 'MM-DD-yyyy hh:mm A' )}</b></div>
          <div className="mb-3">Updated: <b>{moment( ticketUpdated ).format( 'MM-DD-yyyy hh:mm A' )}</b></div>
        </div>
        <div className="col-12">

          <div className="mb-3">
            Questions: <b>{selectedQuestion ? selectedQuestion.map( ( q, i ) => q + (selectedQuestion.length - 1 > i ? ', ' : '') ) : ''}</b>
          </div>
          <div className="mb-3">
            <div className="mb-2">Message:</div>
            <textarea disabled value={textMessage} className="w-100 p-2" rows={3} />
          </div>
          <div className="mb-3">
            <div className="mb-2">Notes:</div>
            <textarea
              value={adminNotes}
              onChange={handleNotesChange}
              className="w-100 p-2"
              rows={3} />
          </div>
        </div>
        <div className="col-md-6 col-12">
          <div className="row mb-15px">
            <div className="col-md-12">
              <select className={"form-select h-45px "}
                      value={statusTicket}
                      onChange={handleSelectStatus}
                      placeholder="Status"
              >
                <option value='created' disabled>Created</option>
                <option value='in_process'>In progress</option>
                <option value='processed'>Closed</option>
              </select>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-12 d-flex justify-content-between">
          <button
            className="btn btn-secondary h-45px w-100px fs-16px"
            onClick={handleClose}
          >Cancel
          </button>
          <button
            className="btn btn-primary h-45px w-100px fs-16px"
            onClick={handleChangeTicket}
          >Save
          </button>
        </div>
      </div>
    </Modal.Body>
  </Modal>
}

export default SupportModal;
