import React from 'react';
import { Link } from 'react-router-dom';
import DropdownNotification from './dropdown/notification.jsx';
import DropdownLanguage from './dropdown/language.jsx';
import DropdownProfile from './dropdown/profile.jsx';
// import SearchForm from './search/form.jsx';
import DropdownMegaMenu from './dropdown/mega.jsx';

import { AppSettings } from './../../config/app-settings.js';

class Header extends React.Component {
	render() {
		return (
			<AppSettings.Consumer>
				{({toggleAppSidebarMobile, toggleAppSidebarEnd, toggleAppSidebarEndMobile, toggleAppTopMenuMobile, appHeaderLanguageBar, appHeaderMegaMenu, appHeaderInverse, appSidebarTwo, appTopMenu, appSidebarNone, appTitle, appIcon}) => (
					<div id="header" className={'app-header ' + (appHeaderInverse ? 'app-header-inverse' : '')}>
						<div className="navbar-header">
							{appSidebarTwo && (
								<button type="button" className="navbar-mobile-toggler" onClick={toggleAppSidebarEndMobile}>
									<span className="icon-bar"></span>
									<span className="icon-bar"></span>
									<span className="icon-bar"></span>
								</button>
							)}
							{/*<Link to="/" className="navbar-brand"><span className="navbar-logo"></span> <b>Codec 4W</b></Link>*/}

							{appHeaderMegaMenu && (
								<button type="button" className="navbar-mobile-toggler" data-bs-toggle="collapse" data-bs-target="#top-navbar">
									<span className="fa-stack fa-lg text-inverse">
										<i className="far fa-square fa-stack-2x"></i>
										<i className="fa fa-cog fa-stack-1x"></i>
									</span>
								</button>
							)}
							{appTopMenu && !appSidebarNone && (
							<button type="button" className="navbar-mobile-toggler" onClick={toggleAppTopMenuMobile}>
								<span className="fa-stack fa-lg text-inverse">
									<i className="far fa-square fa-stack-2x"></i>
									<i className="fa fa-cog fa-stack-1x"></i>
								</span>
							</button>
							)}
							{appSidebarNone && appTopMenu && (
								<button type="button" className="navbar-mobile-toggler" onClick={toggleAppTopMenuMobile}>
									<span className="icon-bar"></span>
									<span className="icon-bar"></span>
									<span className="icon-bar"></span>
								</button>
							)}
							{!appSidebarNone && (
								<button type="button" className="navbar-mobile-toggler" onClick={toggleAppSidebarMobile}>
									<span className="icon-bar"></span>
									<span className="icon-bar"></span>
									<span className="icon-bar"></span>
								</button>
							)}
						</div>

						{appHeaderMegaMenu && (
							<DropdownMegaMenu />
						)}

						<div className="navbar-nav">
							{/* <SearchForm /> */}
							<div className="title-icon">
								{appIcon}
							</div>
							<div className="text-light mx-2">
								<h3>{appTitle}</h3>
							</div>
							<div className=" navbar-nav d-flex align-items-center">
								{/*<DropdownNotification />*/}
								<DropdownProfile />
							</div>
						</div>
					</div>
				)}
			</AppSettings.Consumer>
		)
	}
}

export default Header;
