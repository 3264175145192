import styled from "styled-components";

export const TilesWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-top: 60px;
  align-items: flex-start;
  justify-content: space-between;
  max-width: 1500px;
  flex-wrap: wrap;
  color: #1E1E1E;
`;

export const TitleStyle = styled.div`
  display: flex;
  align-items: center;
  height: 2.5rem;
`;

export const FeaturesWrapper = styled.div`
  border-top: 1px solid #EBEBEB;
  border-bottom: 1px solid #EBEBEB;
  padding: 24px 0 5px 0;
  margin-top: 24px;
  display: flex;
  flex-wrap: wrap;
  
  h3 {
    display: block;
    width: 100%;
    font-weight: 400;
  }
`;

export const TileStyle = styled.div`
  display: flex;
  flex-direction: column;
  width: 32%;
  padding: 26px 16px;
  background-color: #fff;
  box-shadow: 0px 0px 4px 1px rgba(0,0,0,0.13);
  border-radius: 20px;
  justify-content: space-between;
  min-height: 370px;
  
  @media screen and (max-width: 1366px) {
    min-height: 410px;
  }
  
  @media screen and (max-width: 1024px) {
    width: 49%;
    margin-bottom: 20px;
  }
  
  @media screen and (max-width: 961px) {
    width: 100%;
  }

  h2 {
    font-weight: 400;
    font-size: 24px;
    padding: 0;
    margin: 0 0 0 16px;
  }
`;

export const FeatureStyle = styled.li`
  font-size: 16px;
  list-style: none;
  padding: 4px 5px 4px 8px;
  position: relative;

  &:before {
    width: 7px;
    height: 7px;
    background-color: #e3cee1;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    content: '';
    left: -10px;
  }
`;

export const LinksWrapper = styled.div`
  padding-top: 24px;
  display: flex;
  flex-direction: column;
  align-items: start;
  position: relative;
`;

export const LinkStyle = styled.div`
  
  a, span {
    display: block;
    width: 100%;
    margin-bottom: 16px;
    padding: 9px 20px;
    position: relative;
    //border: 1px solid #ebebeb;
    //box-shadow: 0 0 10px rgba(130,130,130,.5);
    //border: 1px solid #828282;
    background-color: rgba(227, 206, 225, 1);
    border-radius: 20px;
    font-size: 16px;
    color: #1E1E1E;
    text-decoration: none;
    transition: all .3s;
    
    &:hover {
      background-color: rgba(227, 206, 225, .7);
      box-shadow: 0 0 10px rgba(130,130,130, .2);
    }
  }
`;

export const SelectTips = styled.div`
  margin-top: 16px;
  input {
    padding: 8px 16px;
    border-radius: 10px;
    border-color: rgba(147, 148, 153, 1);
    font-size: 16px;
    font-weight: 300;
  }
`
export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background-color: transparent;
  opacity: .8;
  z-index: -1;
  overflow: hidden;
`;

export const DropdownLinks = styled.div`
  position: absolute;
  background-color: #fff;
  border: 1px solid rgba(147, 148, 153, 1);
  border-radius: 10px;
  padding: 10px 16px;
  top: calc(100% + 2px);
  z-index: 2222;
  min-width: 100%;
`

export const LinkTextStyle = styled.div`
  
  a, span {
    display: block;
    width: 100%;
    margin-bottom: 10px;
    padding: 3px 2px;
    position: relative;
    //border: 1px solid #ebebeb;
    //box-shadow: 0 0 10px rgba(130,130,130,.5);
    //border: 1px solid #828282;
    //background-color: rgba(227, 206, 225, 1);
    border-radius: 20px;
    font-size: 16px;
    color: #1E1E1E;
    text-decoration: none;
    transition: all .3s;
    
    //&:hover {
    //  background-color: rgba(227, 206, 225, .7);
    //  box-shadow: 0 0 10px rgba(130,130,130, .2);
    //}
  }
`;
