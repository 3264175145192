import React, { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  customCompAtom, needUpdateCompAtom,
} from "../../atoms/profileAtom";
import { useParams } from "react-router-dom";
import { APIHeaders } from "../../components/shared/helpers";
import axios from "axios";
import { TableChartWrapper } from "../Chart/TableChart";
import TitleCustomComp from "../../components/shared/TitleCustomComp";
import TitleTagCustomComp from "../../components/shared/TitleTagCustomComp";

const baseLogoURL = process.env.REACT_APP_LOGOS_BASE_URL;
const signature = process.env.REACT_APP_LOGOS_SIGNATURE;

const StoreListTable = ( { preview } ) => {
  const [ customComp, setCustomComp ] = useRecoilState( customCompAtom );
  const [ needUpdateComp, setNeedUpdateComp ] = useRecoilState( needUpdateCompAtom );
  const [ compData, setCompData ] = useState( [] );
  const [ logoLink, setLogoLink ] = useState( '' );
  const [ isLoadedComp, setIsLoadedComp ] = useState( false );
  let { id } = useParams();
  const baseURL = process.env.REACT_APP_BASE_URL;

  const getCustomComp = () => {
    setIsLoadedComp( false );
    const data = new FormData();
    const config = {
      method: 'get',
      url: `${baseURL}/custom_comps/${id}?include_data=true`,
      headers: APIHeaders,
      withCredentials: true,
      credentials: "include",
      data: data,
    };
    axios( config )
      .then( ( resp ) => {
        setCustomComp( resp.data.data );
        // console.log(resp.data.data)
      } ).finally( () => {
      setIsLoadedComp( true );
    } )
  }

  useEffect( () => {
    if ( customComp.comp_data !== undefined && customComp.comp_data.length ) {
      setCompData( customComp.comp_data );
      let _link = '';
      customComp.comp_data.length && customComp.comp_data.find( el => {
        if ( el.company.logoFileName !== null ) {
          _link = el.company.logoFileName
        }
      } );
      if ( _link ) {
        _link = baseLogoURL + _link + signature;
      }
      setLogoLink( _link );
    }
  }, [ customComp ] )

  useEffect( () => {
    if ( !preview && !customComp.length ) {
      getCustomComp();
    }
  }, [needUpdateComp] )

  if ( !isLoadedComp && !preview ) return <div className="d-flex justify-content-center align-items-center text-center min-vh-100">
    <div className="spinner-border m-5" role="status">
      <span className="sr-only">Loading...</span>
    </div>
  </div>

  return <>
    {<TableChartWrapper className={preview ? 'px-3 py-2' : ''}>
      <TitleTagCustomComp
        compName={customComp.name}
        preview={preview}
        isPopular={customComp.default_comp}
        taggings={customComp.taggings}
        ownerID={customComp.user_id}
        id={id}
      />
      <TitleCustomComp
        compName={customComp.name}
        preview={preview}
        isPopular={customComp.default_comp}
        taggings={customComp.taggings}
        ownerID={customComp.user_id}
        id={id}
      />
      {logoLink && <div className="w-200px pb-4"><img src={logoLink} alt={customComp.name} width="100%"/></div>}

      {compData.length > 0 && <table className="table table-hover table-bordered">
        <thead>
        <tr>
          <th scope="col">Store Name</th>
          <th scope="col">City</th>
          <th scope="col">Address</th>
          <th scope="col">Google Rating</th>
          <th scope="col">Google Reviews</th>
          <th scope="col">Open/Closed</th>
        </tr>
        </thead>
        <tbody>
        {compData.map( ( entry, index ) => {
          // console.log(entry);
          let _address = entry.googleMapUrl ? <a href={entry.googleMapUrl} target="_blank">{entry.fullAddress}</a> : entry.fullAddress;
          return <tr key={`tr-${index}`} className="text-center">
            <td>{entry.name}</td>
            <td>{entry.city}</td>
            <td>{_address}</td>
            <td>{entry.avgRating || '-'}</td>
            <td>{entry.numReviews || '-'}</td>
            <td>{entry.status ? entry.status.replace(/_/g, " ") : '-'}</td>
          </tr>
        } )}
        </tbody>
      </table>}
    </TableChartWrapper>}
  </>
}

export default StoreListTable;
