import HomeIcon from "../components/shared/HomeIcon";
import CompaniesIcon from "../components/shared/CompaniesIcon";
import CustomCompIcon from "../components/shared/CustomCompIcon";
import MarkerTrackerIcon from "../components/shared/MarkerTrackerIcon";
import AskUsIcon from "../components/shared/AskUsIcon";

const Menu = [
  { path: '/home' , title: 'Home', svgIcon: <HomeIcon /> },
  { path: '/companies', title: 'Companies', svgIcon: <CompaniesIcon />  },
  { path: '/custom-comps/', title: 'My Comps Library', svgIcon: <CustomCompIcon />},
  { path: '/new-comp-set', title: 'New Custom Comp', icon: "fa-solid fa-folder-plus"},
  { path: '/popular-custom-comps', title: 'Popular Custom Comps', icon: 'fa fa-star'},
  { path: '/market-tracker', title: 'Market Tracker', svgIcon: <MarkerTrackerIcon /> },
  { path: '/ask-us', title: 'Ask Us Anything', svgIcon: <AskUsIcon /> },
]

export default Menu;
