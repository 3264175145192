import React, { useEffect, useState } from "react";
import { abbrNum, APIHeaders, APIHeadersRAW, numberWithCommas } from "../components/shared/helpers";
import axios from "axios";
import { LogoName } from "./Chart/TableChart";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useRecoilState } from "recoil";
import { showSingleCompanyAtom, singleCompanyIDAtom } from "../atoms/profileAtom";

const TableWrapper = styled.div`
  th {
    color: black;
    font-weight: normal;
    max-width: 400px;
  }
  th, td {
    vertical-align: middle;
  }
`;

const CompaniesTableView = ( { ids, showLogos } ) => {
  const baseURL = process.env.REACT_APP_BASE_URL;
  const [ compIDs, setCompIDs ] = useState( ids );
  const [ companiesBaseData, setCompaniesBaseData ] = useState( [] );
  const [ companiesMetricData, setCompaniesMetricData ] = useState( [] );
  const [ loadingCompaniesData, setLoadingCompaniesData ] = useState( true );
  const [ loadingCompaniesBaseData, setLoadingCompaniesBaseData ] = useState( true );
  const [ openSingleCompany, setOpenSingleCompany ] = useRecoilState( showSingleCompanyAtom );
  const [ singleCompanyID, setSingleCompanyID ] = useRecoilState( singleCompanyIDAtom );
  const navigate = useNavigate()

  const loadCompaniesBasicInfo = () => {
    setLoadingCompaniesBaseData( true );
    let params = '';
    compIDs.map( el => {
      return params = params + '&ids[]=' + el;
    } )

    const config = {
      method: 'get',
      url: `${baseURL}/companies?${params}`,
      headers: APIHeaders,
      withCredentials: true,
      credentials: "include",
    };
    axios( config )
      .then( ( resp ) => {
        setCompaniesBaseData( resp.data.data );
      } )
      .finally(()=>{
        setLoadingCompaniesBaseData( false );
      })
  }

  const loadCompaniesMetricInfo = () => {
    let _data = [];
    let _reqCount = 0;
    setLoadingCompaniesData( true )
    compIDs.map( (comp, i) => {
      const config = {
        method: 'get',
        url: `${baseURL}/companies/${comp}`,
        headers: APIHeadersRAW,
        withCredentials: true,
        credentials: "include",
      };

      axios( config )
        .then( ( resp ) => {
          if ( resp.data ) {
            _data[ comp ] = resp.data.data[ comp ];
            // console.log( _data )
            _reqCount++
            if (_reqCount === ids.length) {
              setLoadingCompaniesData( false )
              setCompaniesMetricData( _data );
            }
          }
        } )
    } )
  }

  useEffect( () => {
    setCompIDs( ids );
    setLoadingCompaniesData( true );
    setLoadingCompaniesBaseData( true );
  }, [ ids ] );

  useEffect( () => {
    loadCompaniesBasicInfo();
    loadCompaniesMetricInfo();
  }, [ compIDs ] )

  if ( loadingCompaniesData && loadingCompaniesBaseData ) {
    return <div className="d-flex justify-content-center align-items-center text-center mh-100vh-300">
      <div className="spinner-border m-5" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  }

  return <TableWrapper className="row mt-2 px-2">
    <table className="table table-hover table-bordered text-center">
      <thead>
      <tr>
        <th scope="col">Company</th>
        <th scope="col">Sector(s)</th>
        <th scope="col">Location Count</th>
        <th scope="col">LTM Openings</th>
        <th scope="col">IG Followers</th>
        <th scope="col">Total Funding</th>
        <th scope="col">Avg. Locations Rating</th>
        <th scope="col">Avg. # Reviews / Locations</th>
      </tr>
      </thead>
      <tbody>
      {companiesMetricData && companiesMetricData.length ? compIDs.map( ids => {
        return companiesBaseData.map( ( comp, index ) => {
          if ( comp.id === +ids ) {
            // console.log( comp );
            let _sectorList = '';
            comp.sectors.map( ( el, i ) => {
              _sectorList += el.name + (comp.sectors.length - 1 > i ? ', ' : '');
            } );
            let _avg_google_reviews_location = companiesMetricData[ ids ]?.avg_google_reviews_location ? +companiesMetricData[ ids ].avg_google_reviews_location : '';
            let _totalFunding = +companiesMetricData[ ids ]?.total_funding ? "$" + abbrNum( companiesMetricData[ ids ]?.total_funding, 0 ) : '-';

            return <tr
              key={`tr-${index}`}
              className="text-center cursor-pointer"
              // onClick={() => {navigate( '/companies/' + ids, { replace: true } );}
                onClick={()=>{
                setOpenSingleCompany( true );
                setSingleCompanyID(ids)
              }
            }>
              <LogoName comp={comp} showLogo={showLogos} />
              <td>{_sectorList}</td>
              <td>{companiesMetricData[ ids ]?.total_stores_open ? numberWithCommas(+companiesMetricData[ ids ]?.total_stores_open) : '-'}</td>
              <td>{companiesMetricData[ ids ]?.new_stores_open ? numberWithCommas(+companiesMetricData[ ids ]?.new_stores_open) : '-'}</td>
              <td>{companiesMetricData[ ids ]?.ig_followers_per_company ? abbrNum(+companiesMetricData[ ids ]?.ig_followers_per_company, 0) : '-'}</td>
              <td>{_totalFunding}</td>
              <td>{companiesMetricData[ ids ]?.avg_google_rating_location ? (+companiesMetricData[ ids ]?.avg_google_rating_location).toFixed(1) : '-'}</td>
              <td>{_avg_google_reviews_location ? _avg_google_reviews_location.toFixed( 0 ) : '-'}</td>
            </tr>
          }
        } )
      } ) : <></>}
      </tbody>
    </table>
  </TableWrapper>
}

export default CompaniesTableView
