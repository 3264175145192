import React, { useEffect, useContext, useState } from "react";
import { AppSettings } from "../config/app-settings";
import DatePicker from "react-datepicker";
import moment from "moment";
import { Button } from "react-bootstrap";
import styled from "styled-components";

const TriangleBorder = styled.div`
  position: absolute;
  padding: 0;
  width: 0;
  height: 0;
  top: 6px;
  left: calc(50% - 98px);
  border-style: solid;
  border-width: 0 98px 170px 98px;
  border-color: transparent transparent #fff transparent;

  &::after {
    content: '';
    position: absolute;
    left: -107px;
    top: -15px;
    width: 0;
    z-index: -1;
    height: 0;
    border-style: solid;
    border-width: 0 107px 191px 107px;
    border-color: transparent transparent teal transparent;
  }
`;

const TriangleWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
`;

const CircleNum = styled.div`
  border-radius: 50%;
  background-color: ${props => props.color ? props.color : "#aaa"};;
  width: 35px;
  height: 35px;
  text-align: center;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  margin: 9px;
`

const toMaxArkan = ( val ) => {
  if ( +val > 22 ) {
    let _numbers = val.toString().split( '' );
    let _result = 0;
    for ( let i = 0; i < _numbers.length; i++ ) {
      _result = _result + +_numbers[ i ];
    }
    console.log( "_result: " + _result );
    if ( _result.toString().length === 1 ) {
      return _result;
    } else {
      _result = toMaxArkan( _result )
    }
    return _result;
  } else {
    return +val;
  }
}

const Triangle = () => {
  const context = useContext( AppSettings );
  // const [ birthDate, setBirthDate ] = useState( '' );
  const [ dayOfBirth, setDayOfBirth ] = useState('');
  const [ monthOfBirth, setMonthOfBirth ] = useState('');
  const [ yearOfBirth, setYearOfBirth ] = useState('');
  // const [ birthDate, setBirthDate ] = useState( 'Sun Sep 23 1985 00:00:00 GMT+0300 (Eastern European Summer Time)' );
  const [ name, setName ] = useState( '' );
  const [ sex, setSex ] = useState( '' );
  const [ disabledCalc, setDisabledCalc ] = useState( true );
  const [ m1, setM1 ] = useState( 0 );
  const [ m2, setM2 ] = useState( 0 );
  const [ m3, setM3 ] = useState( 0 );
  const [ m4, setM4 ] = useState( 0 );
  const [ m5, setM5 ] = useState( 0 );
  const [ m6, setM6 ] = useState( 0 );

  const NumElement = ( { value, color } ) => {
    return <CircleNum
      color={color}
    >
      {value}
    </CircleNum>
  }

  useEffect( () => {
    context.handleSetAppHeaderNone( true );
    context.handleSetAppSidebarNone( true );
    context.handleSetAppContentClass( 'p-0' );

  }, [] );

  const handleCalculate = () => {
    // let _dob = moment( birthDate ).format( "DD-MM-YYYY" );
    // let _dobArray = _dob.split( '-' );
    setM1( toMaxArkan( dayOfBirth ) );
    setM2( toMaxArkan( monthOfBirth ) );
    setM3( toMaxArkan( yearOfBirth ) );
  }

  const DaysList = () => {
    const options = [];

    for (let i = 1; i <= 31; i++) {
      options.push(<option key={'day-'+i} value={i}>{i}</option>);
    }
    return options;
  }

  const MonthList = () => {
    const options = [];

    for (let i = 1; i <= 12; i++) {
      options.push(<option key={'month-'+i} value={i}>{i}</option>);
    }
    return options;
  }
  const YearList = () => {
    const options = [];

    for (let i = 1900; i <= 2023; i++) {
      options.push(<option key={'year-'+i} value={i}>{i}</option>);
    }
    return options;
  }

  const handleSelectDay = (e) => {
    setDayOfBirth(e.target.value)
  }

  const handleSelectMonth = (e) => {
    setMonthOfBirth(e.target.value)
  }
  const handleSelectYear = (e) => {
    setYearOfBirth(e.target.value)
  }

  useEffect( () => {
    if ( m1 && m2 ) {
      setM4( toMaxArkan( m1 + m2 ) );
    }
  }, [ m1, m2 ] )

  useEffect( () => {
    if ( m3.toString().length > 1 ) {
      let _m3Array = m3.toString().split( '' );

      setM5( toMaxArkan( _m3Array.length > 1 ? (+_m3Array[ 0 ] + +_m3Array[ 1 ]) : +m3 ) );
    } else {
      setM5( +m3 );
    }
  }, [ m3 ] )

  useEffect( () => {
    if ( m4 && m5 ) {
      console.log( m3 )

      setM6( toMaxArkan( +m4 + +m5 ) );
    }
  }, [ m4, m5 ] )

  useEffect( () => {
    if ( name && dayOfBirth && monthOfBirth && yearOfBirth && sex ) {
      setDisabledCalc( false )
    } else {
      setDisabledCalc( true )
    }
  }, [ name, dayOfBirth, monthOfBirth, yearOfBirth, sex ] )

  // const handleDateChange = ( e ) => {
  //   setBirthDate( e )
  // };

  const onNameChange = ( e ) => {
    setName( e.target.value )
  }

  const handleSelectSex = e => {
    setSex( e.target.value )
  }

  return <div className="container p-3">
    <div className="row">
      <div className="col-12">
        <h3>Заголовок</h3>
        <h4>Опис</h4>
      </div>
    </div>
    <div className="row">
      <div className="col-6 col-md-3">
        <div className="form-floating mb-20px">
          <input
            type="text"
            className={'form-control fs-13px h-45px '}
            name="name"
            placeholder="Введіть Ім'я"
            value={name}
            onChange={onNameChange}
          />
          <label htmlFor="name" className="d-flex h-45px fs-13px align-items-center">Введіть Ім'я</label>
        </div>
      </div>
      <div className="col-12 col-md-2">
        <div className="mb-20px h-45px position-relative">
          <select className={"form-select h-45px "}
                  value={dayOfBirth}
                  onChange={handleSelectDay}
                  placeholder="День народження"
          >
            <option disabled="disabled" value="">День народження</option>
            <DaysList />
          </select>
        </div>
      </div>
      <div className="col-12 col-md-2">
        <div className="mb-20px h-45px position-relative">
          <select className={"form-select h-45px "}
                  value={monthOfBirth}
                  onChange={handleSelectMonth}
                  placeholder="Місяць народження"
          >
            <option disabled="disabled" value="">Місяць народження</option>
            <MonthList />
          </select>
        </div>
      </div>
      <div className="col-12 col-md-2">
        <div className="mb-20px h-45px position-relative">
          <select className={"form-select h-45px "}
                  value={yearOfBirth}
                  onChange={handleSelectYear}
                  placeholder="Рік народження"
          >
            <option disabled="disabled" value="">Рік народження</option>
            <YearList />
          </select>
        </div>
      </div>

      {/*<div className="col-6 col-md-4">*/}
      {/*  <DatePicker*/}
      {/*    className="form-control h-45px"*/}
      {/*    value={birthDate && moment( birthDate, "MM/DD/Y" )}*/}

      {/*    selected={birthDate && new Date( birthDate )} //moment("12-25-1995", "MM-DD-YYYY");*/}
      {/*    placeholderText="Choose your birthday"*/}
      {/*    format="MM/DD/Y"*/}
      {/*    // onSelect={handleDateSelect}*/}
      {/*    onChange={handleDateChange}*/}
      {/*  />*/}
      {/*</div>*/}
      <div className="col-12 col-md-2">
        <div className="mb-20px h-45px position-relative">
          <select className={"form-select h-45px "}
                  value={sex}
                  onChange={handleSelectSex}
                  placeholder="Стать"
          >
            <option disabled="disabled" value="">Стать</option>
            <option value='male'>Пан 👨</option>
            <option value='female'>Пані 🧑</option>
          </select>
        </div>
      </div>
      <div className="col-2 col-md-1 center">
        <Button
          className="h-45px"
          disabled={disabledCalc}
          onClick={handleCalculate}
        >Розрахувати</Button>
      </div>
    </div>
    <div className="row p-4 position-relative">
      <TriangleBorder />
      <TriangleWrapper>
        <div>
          <NumElement
            value={m6 || '??'}
            color={"red"}
          />
        </div>
        <div className="d-flex flex-row">
          <NumElement
            value={m4 || '??'}
            color={'#ff8c00'}
          />
          <NumElement
            value={m5 || '??'}
            color={'#00FF33'}
          />
        </div>
        <div className="d-flex flex-row">
          <NumElement
            value={m1 || '??'}
            color={'#00b2ff'}
          />
          <NumElement
            value={m2 || '??'}
            color={'#ff00d5'}
          />
          <NumElement
            value={m3 || '??'}
            color={'#858d15'}
          />
        </div>
      </TriangleWrapper>
    </div>

  </div>
}

export default Triangle;
