import React from 'react';
import { slideToggle } from './../../composables/slideToggle.js';

const PanelStat = React.createContext();

class Panel extends React.Component {
	constructor(props) {
		super(props);

		this.toggleExpand = () => {
			this.setState(state => ({
				expand: !this.state.expand
			}));
		}

		this.toggleRemove = () => {
			this.setState(state => ({
				remove: !this.state.remove
			}));
		}

		this.toggleCollapse = (e) => {
			slideToggle((e.target).closest('.panel').querySelector('.panel-body'));
		}

		this.toggleReload = () => {
			if (this.state.reload !== true) {
				this.setState(state => ({
					reload: true
				}));

				setTimeout(() => {
					this.setState(state => ({
						reload: false
					}));
				}, 2000);
			}
		}

		this.state = {
			expand: false,
			reload: false,
			remove: false,
			toggleExpand: this.toggleExpand,
			toggleReload: this.toggleReload,
			toggleRemove: this.toggleRemove,
			toggleCollapse: this.toggleCollapse
		}
	}
	render() {
		return (
			<PanelStat.Provider value={this.state}>
				{(!this.state.remove &&
					<div className={'panel panel-'+ (this.props.theme ? this.props.theme : 'inverse') +' '+ (this.state.expand ? 'panel-expand ' : ' ') + (this.state.reload ? 'panel-loading ' : ' ') + (this.props.className ? this.props.className : '')}>
						{ this.props.children }
					</div>
				)}
			</PanelStat.Provider>
		);
	}
};

function PanelHeader ( props ) {
	return (
		<div className={'panel-heading ' + props.className}>
			<h4 className="panel-title">{props.children}</h4>
			{(!props.noButton &&
				<PanelStat.Consumer>
					{( { toggleExpand, toggleRemove, toggleCollapse, toggleReload } ) => (
						<div className="panel-heading-btn">
							<button className="btn btn-xs btn-icon btn-circle btn-default" onClick={toggleExpand}>
								<i className="fa fa-expand"></i></button>
							&nbsp;&nbsp;
							<button className="btn btn-xs btn-icon btn-circle btn-success" onClick={toggleReload}>
								<i className="fa fa-redo"></i></button>
							&nbsp;&nbsp;
							<button className="btn btn-xs btn-icon btn-circle btn-warning" onClick={toggleCollapse}>
								<i className="fa fa-minus"></i></button>
							&nbsp;&nbsp;
							<button className="btn btn-xs btn-icon btn-circle btn-danger" onClick={toggleRemove}>
								<i className="fa fa-times"></i></button>
						</div>
					)}
				</PanelStat.Consumer>
			)}
		</div>
	)
}

function PanelBody ( props ) {
	return (
		<PanelStat.Consumer>
			{( { reload } ) => (
				<div className={"panel-body " + props.className}>
					{props.children}

					{(reload &&
						<div className="panel-loader">
							<span className="spinner spinner-sm"></span>
						</div>
					)}
				</div>
			)}
		</PanelStat.Consumer>
	);
};

function PanelFooter ( props ) {
	return (
		<div className={"panel-footer " + props.className}>
			{props.children}
		</div>
	);
};

export { Panel, PanelHeader, PanelBody, PanelFooter };
