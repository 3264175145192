import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";

const MetricInfoStyle = styled.div`
  border: 1px solid var(--app-component-active-bg);
  position: absolute;
  top: 170px;
  left: 98px;
  padding: 5px 10px;
  z-index: -1;
`;

const MetricsInfoType = ( data ) => {
  const { id } = useParams();
  const metricsList = data.data;
  const [ isShow, setIsShow ] = useState( false );
  // console.log(metricsList);

  useEffect( () => {
    let _types = [];
    let _isShow = false;
    if ( metricsList && metricsList.length > 0 ) {
      metricsList.map( metric => {
        if ( !_types.includes( metric.ts_chart_type ) ) {
          _types.push( metric.ts_chart_type );
          if ( _types.length > 1 ) {
            _isShow = true;
          }
        }
        return false;
      } );
    }
    setIsShow(_isShow);
  }, [ metricsList ] )

  // return null;

  return (
    isShow && metricsList.length > 1 && <MetricInfoStyle>
      {metricsList.map( metric => {
        return <div key={metric.metric_slug}>
          {metric.ts_chart_type === 'bar' ? <i className="fa-solid fa-chart-simple fs-16px m-2"></i> :
            <i className="fa-solid fa-chart-line fs-16px m-2"></i>}
          {metric.metric_name}
        </div>
      } )}
    </MetricInfoStyle>
  )
}

export default MetricsInfoType;
