
const FnbIcon = ({size}) => {
  let _size = size === 'L' ? 2 : 1;

  return <svg width={_size * 22} height={_size * 15} viewBox="0 0 22 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18.9721 12.1808L20.1491 10.9461L18.4683 7.80377L17.1106 11.2154L18.9721 12.1808ZM13.8183 11.25H15.4606L18.1741 4.54035L14.526 3.09418L13.8183 11.25ZM6.51441 11.25H8.15676L7.44906 3.09418L6.51441 3.46515L5.83711 3.73399L5.15981 4.00282L3.82979 4.53073L6.51441 11.25ZM3.00291 12.1808L4.86446 11.2154C4.34176 9.88306 4.0487 9.13609 3.52599 7.80377L1.82596 10.9846L3.00291 12.1808ZM9.65674 11.25H12.3183L13.1568 1.74995H8.81826L9.65674 11.25ZM2.71446 14.0307L0.0163879 11.3038L2.77599 5.99997L1.87409 3.69997L4.59043 2.62402L5.26951 2.35504L5.94859 2.08605L7.30676 1.54808L7.19139 0.25H14.7683L14.6683 1.53845L20.1202 3.72882L19.2183 5.94998L21.9836 11.2307L19.2913 14.0096L16.7644 12.75H5.17981L2.71446 14.0307Z" fill="#B668AE"/>
  </svg>;
}

export default FnbIcon;
