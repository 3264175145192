import React, { useEffect, useRef, useState } from "react";
import mapsStyles from "./mapsStyles";
import MarkerCircle from "../../components/shared/MarkerCircle";

import GoogleMapReact from 'google-map-react';
import { APIHeaders, convertRegion, TO_ABBREVIATED, states, centersStates } from "../../components/shared/helpers";
import axios from "axios";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  selectedStateAtom,
  searchCompanyArrayAtom,
  locationsListAtom,
  filteredCompaniesAtom,
  roleAtom,
  searchFieldGoogleMapAtom, collapsedLegendStatisticAtom, minimizeLocationLegendAtom
} from "../../atoms/profileAtom";
import { colorPatternDefault } from "../../components/shared/Colors";
import CompanyDetails from "./CompanyDetails";
import styled from "styled-components";
import CustomTooltipMap from "./CustomTooltipMap";
import { OverlayTrigger, Popover } from "react-bootstrap";
import CollapseLegendStaticticIcon from "../../components/shared/CollapseLegendStaticticIcon";

const centerUS = {
  lat: 38.7372578,
  lng: -98.0473973
} // default full width US position

const CompanyDetailsStyles = styled.div`
  position: absolute;
  top: 16px;
  background-color: #fff;
  border: 1px solid #F2F2F2;
  box-shadow: 0 0 8px -0.5px rgba(0, 0, 0, 0.14);
  border-radius: 20px;
  left: 12px;
  z-index: 100;
`;

const StatisticLegendCollapsedStyles = styled.div`
  position: absolute;
  z-index: 222;
  top: auto;
  bottom: 8px;
  width: 38px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 10px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 0 8px -0.5px rgba(0,0,0,0.14);
`;

const SearchBoxWrapper = styled.div`
  top: 16px;
  height: 30px;
  position: absolute;
  z-index: 111;
  left: ${props => props.minimizeLocationLegend ? "120px" : "240px"};
  //left: 240px;
  //transform: translateX(-50%);
  width: 290px;
  display: flex;
  justify-content: start;
  align-items: center
`;

const LogoWrapper = styled.div`
  width: 100px;
  height: 50px;
  padding: 5px;
  background-color: #fff;
  border-radius: 4px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  position: relative;
  z-index: 111;
  
  &:hover {
    z-index: 112;
  }
  
  img {
    max-width: 90px;
    max-height: 40px;
  }
`

const FindAreaButton = styled.div`
  position: absolute;
  width: 170px;
  left: 40px;
  //transform: translateX(-50%);
  //border: 1px solid #333;
  box-shadow: 0 1px 2px rgba(60, 64, 67, 0.3), 0 1px 3px 1px rgba(60, 64, 67, 0.15);
  color: #202124;
  background: #fff;
  border-radius: 21px;
  padding: 4px 10px;
  font-size: 14px;
  top: 40px;
  cursor: pointer;
`;

const initZoom = 4;

const baseURL = process.env.REACT_APP_BASE_URL;

const ShowAll4Wall = styled.div`
  position: absolute;
  width: 260px;
  top: 5px;
  left: auto;
  margin: 10px;
  right: 0;
  padding: 8px 24px 5px 10px;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;

  label.see-all-4wall-label {
    font-size: 16px;
    line-height: 18px;
    margin-left: 5px;
    margin-right: 5px;
    padding-right: 5px;
  }

  input {
    line-height: 18px;
  }
`;

const InfoCardAll4Wall = styled.div`
  background: #fff;
  position: absolute;
  top: -85px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  height: 80px;
  z-index: 111;
  left: 10px;
  border-radius: 4px;
  padding: 4px;
  transform: translateX(-50%);
  box-shadow: rgba(0, 0, 0, 0.3) 0 1px 4px -1px;
  width: 150px;
  text-align: center;

  .arrow-down {
    position: absolute;
    top: auto;
    bottom: -7px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #fff;
  }
  
  img {
    max-width: 140px;
    max-height: 40px;
    margin-bottom: 5px;
  }
`

const getMapOptions = ( maps: any ) => {
  return {
    disableDefaultUI: true,
    mapTypeControl: true,
    zoomControl: true,
    zoomControlOptions: {
      style: maps.ControlPosition.small,
      position: maps.ControlPosition.RIGHT_CENTER,
    },
    mapTypeControlOptions: {
      // style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
      position: maps.ControlPosition.INLINE_END_BLOCK_END,
    },
    // streetViewControl: true,

    strictBounds: true,
    styles: mapsStyles,
  };
};

const getLocation = ( location ) => {
  try {
    // console.log( centersStates[ location ] );
    return centersStates[ location ];
  } catch ( e ) {
    console.log( e )
  }
  return centerUS;
}

const getZoom = ( location ) => {
  try {
    if ( centersStates[ location ].zoom !== undefined ) {
      return centersStates[ location ].zoom;
    } else {
      return initZoom
    }
  } catch ( e ) {
    // console.log(e);
    return initZoom;
  }
}

const renderTooltip = ( text ) => (
  <Popover id="popover-contained-bottom" style={{ color: "black", background: "white" }}>
    {text}
  </Popover>
);

const GoogleMaps = ({width, height}) => {
  const [ zoom, setZoom ] = useState( null );
  const [ locationList, setLocationList ] = useRecoilState( locationsListAtom );
  const searchCompanyArray = useRecoilValue( searchCompanyArrayAtom );
  const [ filteredCompanies, setFilteredCompanies ] = useRecoilState( filteredCompaniesAtom );
  const [ collapsedLegendStatistic, setCollapsedLegendStatistic ] = useRecoilState( collapsedLegendStatisticAtom );
  const  minimizeLocationLegend  = useRecoilValue( minimizeLocationLegendAtom );
  const [ center, setCenter ] = useState( centerUS )
  const [ coordinates, setCoordinates ] = useState( [] );
  const [ isLoaded, setIsLoaded ] = useState( false );
  const [ isShowAll4Wall, setIsShowAll4Wall ] = useState( false );
  const [ all4wallCoordinates, setAll4wallCoordinates ] = useState( [] );
  const [ isMovedMap, setIsMovedMap ] = useState( true );
  const [ opacity, setOpacity ] = useState( 1 );
  const stateFilter = useRecoilValue( selectedStateAtom );
  const [ showTooltipID, setShowTooltipID ] = useState( '' );
  const [ showTooltipIDAll4wall, setShowTooltipIDAll4wall ] = useState( '' );
  const [ isLoadingAll4Wall, setIsLoadingAll4Wall ] = useState(false);
  const [ tooltipData, setTooltipData ] = useState( [] );
  const [ tooltipAddress, setTooltipAddress ] = useState( '' );
  const [ openingStatus, setOpeningStatus ] = useState( 'OPEN' );
  const [ companyName, setCompanyName ] = useState( '' );
  const [ companyLogo, setCompanyLogo ] = useState( '' );
  const [ companyColor, setCompanyColor ] = useState( '' );
  const [ boundNW, setBoundNW ] = useState( {} );
  const [ boundSE, setBoundSE ] = useState( {} );
  const [ searchField, setSearchField ] = useRecoilState( searchFieldGoogleMapAtom );
  const mapRef = useRef( null );
  const role = useRecoilValue( roleAtom );
  const isAdmin = role === 'admin';
  const zoomLevel4Wall = 13;
  const baseLogoURL = process.env.REACT_APP_LOGOS_BASE_URL;
  const signature = process.env.REACT_APP_LOGOS_SIGNATURE;
  const [companiesIds, setCompaniesIds] = useState([]);

  const onGoogleApiLoaded = ( { map } ) => {
    mapRef.current = map;
    initAutocomplete( map, searchField );
  }

  const MarkerUniversal = ( props ) => {
    const { color, logo, id, avgRating,
      numReviews,
      fullAddress,
      status,
      avgNumReviewsPerLocation,
      avgRatingPerLocation,
      name } = props;

    return <div
      onMouseEnter={() => handleMarkerHover( id, avgRating, numReviews, fullAddress, status, avgNumReviewsPerLocation, avgRatingPerLocation, name, logo )}
      style={{ opacity: opacity }}
    >
      {/*{showTooltipIDAll4wall === id && <InfoCardAll4Wall>*/}
      {/*  {logo && <img src={baseLogoURL + logo + signature} alt={name} />}*/}
      {/*  <h5>{name}</h5>*/}
      {/*  <div className="arrow-down"></div>*/}
      {/*</InfoCardAll4Wall>}*/}
      {zoom > 16 && logo ? <LogoWrapper><img src={baseLogoURL + logo + signature} alt={name} /></LogoWrapper> : <MarkerCircle
        color={color}
        size={20}
        borderColor={"black"}
      />}
    </div>
  }
  const Marker = ( props ) => {
    const {
      color,
      size,
      opacity,
      id,
      avgRating,
      numReviews,
      fullAddress,
      status,
      avgNumReviewsPerLocation,
      avgRatingPerLocation,
      name,
      logo
    } = props;
    const _defaultBorderColor = status === 'OPEN' ? 'white' : 'orange';
    return (
      <div
        onClick={() => handleMarkerClick( id, avgRating, numReviews, fullAddress, status, avgNumReviewsPerLocation, avgRatingPerLocation, name, logo, color )}
        onMouseEnter={() => handleMarkerHover( id, avgRating, numReviews, fullAddress, status, avgNumReviewsPerLocation, avgRatingPerLocation, name, logo, color )}
        className="custom-marker"
        style={{ opacity: opacity }}
      >
        <MarkerCircle
          color={color}
          size={id === showTooltipID ? 20 : 18}
          borderColor={id === showTooltipID ? "black" : _defaultBorderColor}
        />
      </div>
    );
  };

  const mapContainerStyle = {
    // width: localStorage.getItem( 'appSidebarMinify' ) === 'true' ? 'calc(100vw - 180px)' : 'calc(100vw - 410px)',
    width: width ? width : localStorage.getItem( 'appSidebarMinify' ) === 'true' ? 'calc(100vw - 210px)' : 'calc(100vw - 440px)',
    overflow: "hidden",
    height: height ? height : 'calc(100vh - 310px)',
    marginTop: '5px',
    position: 'relative'
  }

  useEffect( () => {
    let _companiesIds = [];
    searchCompanyArray.map( comp => _companiesIds.push(+comp.id));
    setCompaniesIds(_companiesIds);
  }, [ searchCompanyArray ] )

  useEffect( () => {
    if ( locationList.length === 0 && searchCompanyArray.length ) {
      setIsLoaded( false );
      getCompaniesList();
    } else {
      setIsLoaded( true );
    }

    setZoom( getZoom( stateFilter ) || 4 );
    setCenter( getLocation( stateFilter ) || centerUS );
  }, [ searchCompanyArray ] )
  // console.log(searchCompanyArray)

  useEffect( () => {
    let _filteredCompanies = [];
    locationList.map( company => {
      searchCompanyArray.map( comp => {
        if ( +company.company.id === +comp.id ) {
          let _company = {};
          // console.log(company)
          _company[ 'state' ] = company.state;
          _company[ 'city' ] = company.city;
          _company[ 'coordinates' ] = [ +company.longitude, +company.latitude ]
          _company[ 'status' ] = company.status;
          _company[ 'id' ] = +company.id;
          _company[ 'company_id' ] = +company.company.id;
          _company[ 'company_color' ] = company.company.color;
          _company[ 'company_name' ] = company.company.name;
          _company[ 'company_logo' ] = company.company.logoFileName;
          _company[ 'openingDate' ] = company.openingDate;
          _company[ 'fullAddress' ] = company.fullAddress;
          _company[ 'name' ] = company.name;
          _company[ 'avgNumReviewsPerLocation' ] = company.company.avgNumReviewsPerLocation;
          _company[ 'avgRatingPerLocation' ] = company.company.avgRatingPerLocation;
          if ( +company.longitude !== 0 && +company.latitude !== 0 ) {
            _filteredCompanies = [ ..._filteredCompanies, _company ];
          }
        }
      } )
    } )
    setFilteredCompanies( _filteredCompanies );
  }, [ locationList, searchCompanyArray ] )

  useEffect( () => {
    if ( stateFilter.length === 0 ) {
      setCoordinates( locationList.map( company => {
        return {
          name: company.name,
          id: company.id,
          lat: company.latitude,
          lng: company.longitude,
          status: company.status,
          avgRating: +company.avgRating,
          numReviews: +company.numReviews,
          fullAddress: company.fullAddress,
          logo: company.company.logoFileName,
          avgNumReviewsPerLocation: +company.company.avgNumReviewsPerLocation,
          avgRatingPerLocation: +company.company.avgRatingPerLocation
        }
      } ) )
    }
  }, [ locationList ] )

  useEffect( () => {
    let _temp = [];
    let __temp = [];
    if ( stateFilter ) {
      _temp = locationList.filter( company => company.state === stateFilter || company.state === convertRegion( stateFilter, TO_ABBREVIATED ) );
      if ( searchCompanyArray.length ) {
        searchCompanyArray.map( comp => {
          _temp.map( (company => {
            if ( +company.company.id === +comp.id ) {
              if ( +company.longitude !== 0 && +company.latitude !== 0 ) {
                __temp = [ ...__temp, company ];
              }
            }
          }) )
        } )
        setCoordinates( __temp.map( company => {
          // console.log(company)
          if ( company.latitude !== undefined && company.longitude !== undefined ) {
            let _id = +company.company.id;
            let _color;
            searchCompanyArray.map( ( comp, i ) => {
              if ( company.company.color === null || company.company.color === undefined ) {
                if ( _id === +comp.id ) {
                  _color = colorPatternDefault[ i ];
                }
              } else {
                _color = company.company.color
              }
            } )
            // console.log(company.company.avgNumReviewsPerLocation)
            return {
              name: company.company.name,
              id: company.id,
              lat: +company.latitude,
              lng: +company.longitude,
              color: _color,
              avgRating: +company.avgRating,
              numReviews: +company.numReviews,
              fullAddress: company.fullAddress,
              logo: company.company.logoFileName,
              status: company.status,
              avgNumReviewsPerLocation: +company.company.avgNumReviewsPerLocation,
              avgRatingPerLocation: +company.company.avgRatingPerLocation,
            }
          }
        } ) )
      }
    } else if ( searchCompanyArray.length ) {
      // console.log(searchCompanyArray.length);
      let _temp = [];
      searchCompanyArray.map( comp => {
        locationList.map( (company => {
          if ( +company.company.id === +comp.id ) {
            if ( +company.longitude !== 0 && +company.latitude !== 0 ) {
              _temp = [ ..._temp, company ];
            }
          }
        }) )
      } )
      setCoordinates( _temp.map( company => {
        if ( company.latitude !== undefined && company.longitude !== undefined ) {
          let _id = +company.company.id;
          let _color;
          searchCompanyArray.map( ( comp, i ) => {
            if ( company.company.color === null || company.company.color === undefined ) {
              if ( _id === +comp.id ) {
                _color = colorPatternDefault[ i ];
              }
            } else {
              _color = company.company.color
            }
          } )
          return {
            name: company.company.name,
            id: company.id,
            lat: +company.latitude,
            lng: +company.longitude,
            color: _color,
            avgRating: +company.avgRating,
            numReviews: +company.numReviews,
            fullAddress: company.fullAddress,
            status: company.status,
            avgNumReviewsPerLocation: +company.company.avgNumReviewsPerLocation,
            avgRatingPerLocation: +company.company.avgRatingPerLocation,
          }
        }
      } ) )
    } else {
      setCoordinates( locationList.map( company => {
        if ( company.latitude !== undefined && company.longitude !== undefined ) {
          let _id = +company.company.id;
          let _color;
          searchCompanyArray.map( ( comp, i ) => {
            if ( company.company.color === null || company.company.color === undefined ) {
              if ( _id === +comp.id ) {
                _color = colorPatternDefault[ i ];
              }
            } else {
              _color = company.company.color
            }
          } )
          return {
            name: company.company.name,
            id: company.id,
            lat: +company.latitude,
            lng: +company.longitude,
            color: _color,
            avgRating: +company.avgRating,
            numReviews: +company.numReviews,
            fullAddress: company.fullAddress,
            status: company.status,
            avgNumReviewsPerLocation: +company.company.avgNumReviewsPerLocation,
            avgRatingPerLocation: +company.company.avgRatingPerLocation,
          }
        }
      } ) )
    }
  }, [ stateFilter ] )

  useEffect( () => {
    if ( zoom < zoomLevel4Wall ) {
      setIsShowAll4Wall( false );
      setAll4wallCoordinates( [] );
    }
  }, [ zoom ] )

  useEffect( () => {
    if ( !isShowAll4Wall ) {
      setAll4wallCoordinates( [] );
    }
  }, [ isShowAll4Wall ] )

  useEffect( () => {
    if ( isShowAll4Wall && zoom >= zoomLevel4Wall ) {
      getAll4Wall();
    }
  }, [ boundSE, boundNW, isShowAll4Wall ] )

  const handleShowInfo4Wall = (id) => {
    if (showTooltipIDAll4wall !== id) {
      setShowTooltipIDAll4wall( id )
    }
  }

  const handleMarkerClick = ( id, avgRating, numReviews, fullAddress, status, avgNumReviewsPerLocation, avgRatingPerLocation, name, logo, color ) => {
    if ( showTooltipID !== id ) {
      setShowTooltipID( id )
      setTooltipData( {
        "Rating": avgRating,
        "Brand National Avg.": avgRatingPerLocation,
        "Reviews": numReviews,
        "Brand National Avg. ": avgNumReviewsPerLocation,
      } );
      setTooltipAddress( fullAddress );
      setOpeningStatus( status );
      setCompanyName( name );
      setCompanyColor( color );
      if (logo !== companyLogo) {
        setCompanyLogo( logo );
      }
    }
    setCollapsedLegendStatistic(false);
  }

  const handleMarkerHover = ( id, avgRating, numReviews, fullAddress, status, avgNumReviewsPerLocation, avgRatingPerLocation, name, logo, color ) => {
    if ( showTooltipID !== id ) {
      setShowTooltipID( id )
      setTooltipData( {
        "Rating": avgRating,
        "Brand National Avg.": avgRatingPerLocation,
        "Reviews": numReviews,
        "Brand National Avg. ": avgNumReviewsPerLocation,
      } );
      setTooltipAddress( fullAddress );
      setOpeningStatus( status );
      setCompanyName( name );
      setCompanyColor( color );
      if (logo !== companyLogo) {
        setCompanyLogo( logo );
      }
    }
  }

  const handleCollapseLegend = () => {
    setCollapsedLegendStatistic(!collapsedLegendStatistic);
    if (collapsedLegendStatistic) {
      setShowTooltipID('')
    }
  }

  const goSearch = ( init ) => {
    const input = document.getElementById( "pac-input" );
    setIsMovedMap( false );

    window.google.maps.event.trigger( input, 'focus', {} );
    window.google.maps.event.trigger( input, 'keydown', { keyCode: 13 } );
    return false;
  }

  const initAutocomplete = ( map ) => {
    const input = document.getElementById( "pac-input" );
    const searchBtn = document.getElementById( "search-button" );
    const searchBox = new window.google.maps.places.SearchBox( input );

    // map.controls[ window.google.maps.ControlPosition.TOP_CENTER ].push( input );

    searchBtn.addEventListener( 'click', () => {
      goSearch( false );
    } )

    map.addListener( "bounds_changed", () => {
      searchBox.setBounds( map.getBounds() );
    } );

    let markers = [];

    searchBox.addListener( "places_changed", () => {
      const places = searchBox.getPlaces();

      if ( places.length === 0 ) {
        return;
      }

      markers.forEach( ( marker ) => {
        marker.setMap( null );
      } );
      markers = [];

      const bounds = new window.google.maps.LatLngBounds();

      places.forEach( ( place ) => {
        if ( !place.geometry || !place.geometry.location ) {
          console.log( "Returned place contains no geometry" );
          return;
        }
        // console.log(place)

        const icon = {
          url: place.icon,
          size: new window.google.maps.Size( 71, 71 ),
          origin: new window.google.maps.Point( 0, 0 ),
          anchor: new window.google.maps.Point( 17, 34 ),
          scaledSize: new window.google.maps.Size( 25, 25 ),
        };

        const infowindow = new window.google.maps.InfoWindow( {
          content: `<div>${place.name}</div><div>${place.formatted_address}</div>`,
          ariaLabel: "Uluru",
        } );

        const marker = new window.google.maps.Marker( {
          map,
          icon,
          title: place.name,
          clickable: true,
          position: place.geometry.location,
        } );

        marker.addListener( 'click', function () {
          window.google.maps.event.addListener( map, 'click', function () {
            infowindow.close();
          } );

          infowindow.open( {
            anchor: marker,
            map,
          } );
        } );

        markers.push( marker );

        if ( place.geometry.viewport ) {
          bounds.union( place.geometry.viewport );
        } else {
          bounds.extend( place.geometry.location );
        }
      } );

      map.fitBounds( bounds );
    } );
  };

  const handleChangeSearch = ( e ) => {
    setSearchField( e.target.value );
    setIsMovedMap( false );
  }

  const handleShowAll4Wall = () => {
    setIsShowAll4Wall( !isShowAll4Wall );
    setAll4wallCoordinates([]);
  }

  const getAll4Wall = () => {
    let params = '';
    setIsLoadingAll4Wall(true);
    params = `&boxPoints[]=${boundNW.lat},${boundNW.lng}&boxPoints[]=${boundSE.lat},${boundSE.lng}`;
    if ( params && all4wallCoordinates.length < 2000) {
      const config = {
        method: 'get',
        url: `${baseURL}/locations?${params}`,
        headers: APIHeaders,
        withCredentials: true,
        credentials: "include",
      };
      axios( config )
        .then( ( response ) => {
          let _coordinates = [];
          response.data.data.forEach(coordinate=> {
            if (!companiesIds.includes(coordinate.company.id)) {
              _coordinates.push(coordinate);
            }
          })
          setAll4wallCoordinates( _coordinates );
          setIsLoadingAll4Wall(false);
          // setLocationList( response.data.data ); //searchCompanyArray
        } )
        .catch( ( error ) => {
          console.log( error );
        } ).finally( () => {
        setIsLoadingAll4Wall(false);
      } )
    }
  }

  const getCompaniesList = () => {
    setIsLoaded( false );
    let params = '';
    searchCompanyArray.map( el => {
      return params = params + '&companyIds[]=' + el.id;
    } )

    if ( params ) {
      const config = {
        method: 'get',
        url: `${baseURL}/locations?${params}&all=true`,
        headers: APIHeaders,
        withCredentials: true,
        credentials: "include",
      };
      axios( config )
        .then( ( response ) => {
          console.log( response.data.data )
          setLocationList( response.data.data );
          setIsLoaded( true );
        } )
        .catch( ( error ) => {
          console.log( error.response.status );
        } ).finally( () => {
        setIsLoaded( true )
      } )
    }
  }

  if ( !isLoaded && !zoom && !center && !coordinates.length ) {
    return <div className="d-flex justify-content-center align-items-center text-center h-500px">
      <div className="spinner-border m-5" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  }

  return <div style={mapContainerStyle}>
    {(zoom !== null && center !== null && coordinates.length) ? <GoogleMapReact
      bootstrapURLKeys={{
        key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
        libraries: [ 'places' ]
      }}
      // defaultCenter={center}
      center={center}
      zoom={zoom}
      yesIWantToUseGoogleMapApiInternals={true}
      onGoogleApiLoaded={onGoogleApiLoaded}
      options={getMapOptions}
      onZoomAnimationEnd={( e ) =>
        setZoom( e )
      }
      onChange={( e ) => {
        setCenter( { lat: e.center.lat, lng: e.center.lng } );
        setBoundSE( {
          lat: e.bounds.se.lat,
          lng: e.bounds.se.lng
        } );
        setBoundNW( {
          lat: e.bounds.nw.lat,
          lng: e.bounds.nw.lng
        } );
      }

      }
      onDrag={() => setOpacity( 0 )}
      onDragEnd={( e ) => {
        setOpacity( 1 );
        setIsMovedMap( true );
      }}
    >
      {coordinates && coordinates.map( company => {

        if ( company !== undefined && company.lat !== undefined ) {
          return <Marker
            opacity={opacity}
            key={company.id + '-' + company.lat}
            lat={company.lat}
            lng={company.lng}
            color={company.color || 'red'}
            name={company.name}
            id={company.id}
            avgRating={company.avgRating}
            numReviews={company.numReviews}
            fullAddress={company.fullAddress}
            logo={company.logo}
            status={company.status}
            avgNumReviewsPerLocation={company.avgNumReviewsPerLocation}
            avgRatingPerLocation={company.avgRatingPerLocation}
          />
        }
      } )}
      {all4wallCoordinates && all4wallCoordinates.map( entry => {

        return <MarkerUniversal
          opacity={opacity}
          key={entry.id}
          logo={entry.company.logoFileName}
          lat={+entry.latitude}
          lng={+entry.longitude}
          // color={entry.company.color}
          color='#66B39C'
          name={entry.company.name}
          id={entry.id}
          avgRating={+entry.avgRating}
          numReviews={+entry.numReviews}
          fullAddress={entry.fullAddress}
          status={entry.status}
          avgNumReviewsPerLocation={+entry.avgNumReviewsPerLocation}
          avgRatingPerLocation={+entry.avgRatingPerLocation}
        />
      } )}
    </GoogleMapReact> : <div></div>}
    <SearchBoxWrapper
      id="SearchBoxWrapper"
      minimizeLocationLegend={minimizeLocationLegend}
    >
      <input
        id="pac-input"
        style={{
          // "paddingRight": "20px"
        }}
        value={searchField}
        onChange={( e ) => handleChangeSearch( e )}
        type="text"
        name="pac-input"
        className="form-control w-250px h-30px fs-16px fw-400 mr-2"
        placeholder="Search for a place"
      />
      <label htmlFor="pac-input"></label>
      <FindAreaButton
        onClick={goSearch}
        className={searchField && isMovedMap ? "d-flex align-items-center" : "d-none"}
      >
        <i
          className="fa fa-search cursor-pointer mx-2 py-1 "
          id='search-button'
        />
        <span>Search this area</span>
      </FindAreaButton>
      <OverlayTrigger
        placement="top"
        delay={{ show: 400, hide: 400 }}
        overlay={renderTooltip( 'The search function retrieves data for your current zoom level on the map. If you navigate to a different area of the map, you may want to re-search for your desired term.' )}
      >
        <i
          className="mt-1  fa fa-circle-info fs-13px position-absolute "
          style={{
            left: "auto",
            right: "20px"
          }}
        />
      </OverlayTrigger>
    </SearchBoxWrapper>

    <ShowAll4Wall
      key='see-all-4wall'>
      {isLoadingAll4Wall ? <div className="spinner-border spinner-border-sm" role="status">
          <span className="sr-only">Loading...</span>
        </div> :
        <div className="form-check-reverse form-switch">
          <input
          className="form-check-input fs-18px h-20px"
          type="checkbox"
          id="see-all-4wall"
          disabled={zoom < zoomLevel4Wall && !isShowAll4Wall}
          checked={isShowAll4Wall}
          onChange={handleShowAll4Wall}
        />
          <label
            style={{ color: zoom < zoomLevel4Wall ? "grey" : 'black' }}
            className="form-check-label see-all-4wall-label mx-1"
            htmlFor="see-all-4wall">See all 4Wall locations</label>

          <OverlayTrigger
            placement="top"
            delay={{ show: 400, hide: 400 }}
            overlay={renderTooltip( 'More 4Wall locations are available when zooming in.' )}
          >
            <i
              className="mt-1 fa fa-circle-info fs-13px position-absolute color-dark"
              style={{
                left: "10px"
              }}
            ></i>

          </OverlayTrigger>
        </div>}

    </ShowAll4Wall>
    <CompanyDetailsStyles
      style={{
        maxHeight: window.innerHeight-380,
        overflow: 'auto'
      }}
    >
      {filteredCompanies ?
        <CompanyDetails
          locations={filteredCompanies}
          companiesIDs={searchCompanyArray}
          state={stateFilter}
        /> : <></>
      }
    </CompanyDetailsStyles>
    {showTooltipID && collapsedLegendStatistic && <StatisticLegendCollapsedStyles>
      <div
        className="cursor-pointer"
        onClick={()=>handleCollapseLegend()}
      ><CollapseLegendStaticticIcon />
      </div>
    </StatisticLegendCollapsedStyles>}

    {!collapsedLegendStatistic && <CustomTooltipMap
      show={showTooltipID}
      data={tooltipData}
      address={tooltipAddress}
      openingStatus={openingStatus}
      companyName={companyName}
      logoUrl={companyLogo}
      color={companyColor}
    />}
    {isAdmin && coordinates.length ? <div className="w-150px">Show {coordinates.length} from {locationList.length}</div> : <></>}
  </div>;
}

export default GoogleMaps;
