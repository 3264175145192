import { APIHeaders } from "../../components/shared/helpers";
import axios from "axios";
import { useState, useEffect } from "react";
import { useRecoilState } from "recoil";
import { messagesLoadedAtom, newMessagesAtom } from "../../atoms/profileAtom";

const BadgeNewMessage = () => {
  const [ newMessage, setNewMessage ] = useRecoilState( newMessagesAtom );
  const [ loadedMessages, setLoadedMessages ] = useRecoilState( messagesLoadedAtom );
  const baseURL = process.env.REACT_APP_BASE_URL;

  useEffect( () => {
    if ( !loadedMessages ) {
      loadQuestions();
    }
  }, [ loadedMessages ] )

  const loadQuestions = () => {
    setLoadedMessages( false );
    const config = {
      method: 'get',
      url: `${baseURL}/ask_us_requests`,
      headers: APIHeaders,
      withCredentials: true,
      credentials: "include",
    };
    axios( config )
      .then( ( resp ) => {
        let _count = resp.data.data.filter( m => m.status === 'created' ).length
        setNewMessage( _count );
      } ).finally( () => {
      setLoadedMessages( true )
    } )
  }

  return newMessage;
}

export default BadgeNewMessage;
