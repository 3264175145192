import { Modal } from "react-bootstrap";
import React, { useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { messagesLoadedAtom, newModalTitleAtom, questionsListLoadedAtom, showNewCompanyModalAtom } from "../../atoms/profileAtom";
import { APIHeaders, APIHeadersRAW, toasterNotify } from "../../components/shared/helpers";
import axios from "axios";

const RequestNewCompanyModal = () => {
  const baseURL = process.env.REACT_APP_BASE_URL;
  const [ showNewCompanyModal, setShowNewCompanyModal ] = useRecoilState( showNewCompanyModalAtom );
  const [ loadedMessages, setLoadedMessages ] = useRecoilState( messagesLoadedAtom );
  const [ questionsListLoaded, setQuestionsListLoaded ] = useRecoilState( questionsListLoadedAtom );
  const newModalTitle = useRecoilValue( newModalTitleAtom );
  const [ textMessage, setTextMessage ] = useState( '' );
  const [ companyName, setCompanyName ] = useState( '' );
  const [ companyError, setCompanyError ] = useState( false );
  const [ websiteCompany, setWebsiteCompany ] = useState( '' );
  const [ websiteError, setWebsiteError ] = useState( false );

  const reInitForm = () => {
    setWebsiteError( false );
    setWebsiteCompany( '' );
    setCompanyError( false );
    setCompanyName( '' );
    setTextMessage( '' )
  }
  const handleInput = ( e ) => {
    setTextMessage( e.target.value )
  }

  const onCompanyChange = ( e ) => {
    setCompanyError( false );
    setCompanyName( e.target.value );
  }

  const onWebsiteChange = ( e ) => {
    setWebsiteError( false );
    setWebsiteCompany( e.target.value );
  }

  const handleClose = () => {
    reInitForm();
    setShowNewCompanyModal( false );
  }

  const validateForm = () => {
    if ( !companyName ) {
      setCompanyError( true );
    }
    if ( !websiteCompany ) {
      setWebsiteError( true );
    }
  }

  const handleSaveUser = () => {
    validateForm();
    let data = {};
    data[ 'request_params' ] = {
      "request_type": "add_company",
      "company_name": companyName,
      "website": websiteCompany,
      "details": textMessage
    }
    if ( companyName !== '' && websiteCompany !== '' ) {
      const config = {
        method: 'post',
        url: `${baseURL}/ask_us_requests`,
        headers: APIHeadersRAW,
        withCredentials: true,
        credentials: "include",
        data: data,
      };
      axios( config )
        .then( ( response ) => {
          toasterNotify( 'Request submitted successfully', '', 'success' );
          setShowNewCompanyModal(false);
          setLoadedMessages(false);
          setQuestionsListLoaded(false);
          reInitForm();
        } )
        .catch( error => {
          toasterNotify( 'Error', error.message, 'danger' );
          console.log( error )
        } )
    }
  }

  return <Modal show={showNewCompanyModal} onHide={handleClose}>
    <Modal.Header closeButton>
      <Modal.Title>Request to add new {newModalTitle}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <div className="row fs-14px">
        <div className="col-12">
          <p>Would you like to see another {newModalTitle} at 4wall IQ? Fill in the {newModalTitle} name and website below, and use last field to tell us more about it!</p>
        </div>
        <div className="col-12">
          <div className="form-floating mb-20px">
            <input
              type="text"
              className={companyError ? 'form-control fs-13px h-45px is-invalid' : 'form-control fs-13px h-45px'}
              name="newName"
              placeholder={`${newModalTitle} Name`}
              value={companyName}
              onChange={onCompanyChange}
            />
            <label htmlFor="newName" className="d-flex h-45px align-items-center py-0">{newModalTitle} Name</label>
            {companyError && <div className="invalid-feedback">
              Please enter {newModalTitle} Name.
            </div>}
          </div>
        </div>
        <div className="col-12">
          <div className="form-floating mb-20px">
            <input
              type="url"
              className={websiteError ? 'form-control fs-13px h-45px is-invalid' : 'form-control fs-13px h-45px'}
              name="newWebsite"
              placeholder={`${newModalTitle} Website`}
              value={websiteCompany}
              onChange={onWebsiteChange}
            />
            <label htmlFor="newWebsite" className="d-flex h-45px align-items-center py-0">{newModalTitle} Website</label>
            {websiteError && <div className="invalid-feedback">
              Please enter {newModalTitle} Website.
            </div>}
          </div>
        </div>
        <div className="col-12">
          <div className="mb-3">
            <div className="form-floating mb-20px">
            <textarea
              value={textMessage}
              name="newDetails"
              onChange={handleInput}
              className="form-control w-100 py-4 h-100px"
              rows={5}
              placeholder={`Please enter detail information about the ${newModalTitle}`}
            />
              <label htmlFor="newDetails" className="d-flex h-45px align-items-center py-0">Please provide additional details about the {newModalTitle}</label>
            </div>
          </div>
        </div>
      </div>
    </Modal.Body>
    <Modal.Footer>
      <button className="btn btn-white" onClick={handleClose}>Close</button>
      <button
        className="btn btn-success"
        disabled={companyName.length === 0 && websiteCompany.length === 0}
        onClick={handleSaveUser}
      >Submit
      </button>
    </Modal.Footer>
  </Modal>
}

export default RequestNewCompanyModal;
