import React from 'react';

const TimeLineIcon = ({color, size}) => {
	let _size = 50;
	let fillColor = "#c897c3";
	if ( color ) {
    fillColor = color;
	}
	if (size) _size = size;

	return <svg width={_size} height={_size} viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="path-1-inside-1_3757_7309" fill="white">
      <path d="M14 28V12H16V28"/>
    </mask>
    <path d="M14 28V12H16V28" fill={fillColor}/>
    <path d="M14 12V11H13V12H14ZM16 12H17V11H16V12ZM15 28V12H13V28H15ZM14 13H16V11H14V13ZM15 12V28H17V12H15Z" fill={fillColor} mask="url(#path-1-inside-1_3757_7309)"/>
    <mask id="path-3-inside-2_3757_7309" fill="white">
      <path d="M36 28V11H38V28"/>
    </mask>
    <path d="M36 28V11H38V28" fill={fillColor}/>
    <path d="M36 11V10H35V11H36ZM38 11H39V10H38V11ZM37 28V11H35V28H37ZM36 12H38V10H36V12ZM37 11V28H39V11H37Z" fill={fillColor} mask="url(#path-3-inside-2_3757_7309)"/>
    <mask id="path-5-inside-3_3757_7309" fill="white">
      <path d="M20 46V28H22V46"/>
    </mask>
    <path d="M20 46V28H22V46" fill={fillColor}/>
    <path d="M20 28V27H19V28H20ZM22 28H23V27H22V28ZM21 46V28H19V46H21ZM20 29H22V27H20V29ZM21 28V46H23V28H21Z" fill={fillColor} mask="url(#path-5-inside-3_3757_7309)"/>
    <mask id="path-7-inside-4_3757_7309" fill="white">
      <path d="M31 46V28H33V46"/>
    </mask>
    <path d="M31 46V28H33V46" fill={fillColor}/>
    <path d="M31 28V27H30V28H31ZM33 28H34V27H33V28ZM32 46V28H30V46H32ZM31 29H33V27H31V29ZM32 28V46H34V28H32Z" fill={fillColor} mask="url(#path-7-inside-4_3757_7309)"/>
    <path d="M0.5 27C0.5 25.067 2.067 23.5 4 23.5C5.933 23.5 7.5 25.067 7.5 27C7.5 28.933 5.933 30.5 4 30.5C2.067 30.5 0.5 28.933 0.5 27Z" fill={fillColor} stroke={fillColor}/>
    <path d="M12.5 10C12.5 8.61929 13.6193 7.5 15 7.5C16.3807 7.5 17.5 8.61929 17.5 10C17.5 11.3807 16.3807 12.5 15 12.5C13.6193 12.5 12.5 11.3807 12.5 10Z" fill={fillColor} stroke={fillColor}/>
    <path d="M23.5 3C23.5 1.61929 24.6193 0.5 26 0.5C27.3807 0.5 28.5 1.61929 28.5 3C28.5 4.38071 27.3807 5.5 26 5.5C24.6193 5.5 23.5 4.38071 23.5 3Z" fill={fillColor} stroke={fillColor}/>
    <path d="M18.5 47C18.5 45.6193 19.6193 44.5 21 44.5C22.3807 44.5 23.5 45.6193 23.5 47C23.5 48.3807 22.3807 49.5 21 49.5C19.6193 49.5 18.5 48.3807 18.5 47Z" fill={fillColor} stroke={fillColor}/>
    <path d="M29.5 44C29.5 42.6193 30.6193 41.5 32 41.5C33.3807 41.5 34.5 42.6193 34.5 44C34.5 45.3807 33.3807 46.5 32 46.5C30.6193 46.5 29.5 45.3807 29.5 44Z" fill={fillColor} stroke={fillColor}/>
    <path d="M34.5 13C34.5 11.6193 35.6193 10.5 37 10.5C38.3807 10.5 39.5 11.6193 39.5 13C39.5 14.3807 38.3807 15.5 37 15.5C35.6193 15.5 34.5 14.3807 34.5 13Z" fill={fillColor} stroke={fillColor}/>
    <mask id="path-15-inside-5_3757_7309" fill="white">
      <path d="M45 28H6V26H45"/>
    </mask>
    <path d="M45 28H6V26H45" fill={fillColor}/>
    <path d="M6 28H5V29H6V28ZM6 26V25H5V26H6ZM45 27H6V29H45V27ZM7 28V26H5V28H7ZM6 27H45V25H6V27Z" fill={fillColor} mask="url(#path-15-inside-5_3757_7309)"/>
    <path d="M43.75 22.8197L49.1803 27L43.75 31.1803L43.75 22.8197Z" fill={fillColor} stroke={fillColor}/>
    <mask id="path-18-inside-6_3757_7309" fill="white">
      <path d="M25 28V4H27V28"/>
    </mask>
    <path d="M25 28V4H27V28" fill={fillColor}/>
    <path d="M25 4V3H24V4H25ZM27 4H28V3H27V4ZM26 28V4H24V28H26ZM25 5H27V3H25V5ZM26 4V28H28V4H26Z" fill={fillColor} mask="url(#path-18-inside-6_3757_7309)"/>
  </svg>


}

export default TimeLineIcon;
