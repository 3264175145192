import { useRecoilState, useRecoilValue } from "recoil";
import { customCompAtom, needUpdateCompAtom, roleAtom } from "../../atoms/profileAtom";
import React, { useEffect, useState } from "react";
import { TableChartWrapper } from "../Chart/TableChart";
import { abbrNum, APIHeaders } from "../../components/shared/helpers";
import axios from "axios";
import { useParams } from "react-router-dom";
import moment from "moment";
import CommonErrorComponent from "../../components/shared/CommonErrorComponent";
import TitleCustomComp from "../../components/shared/TitleCustomComp";
import TitleTagCustomComp from "../../components/shared/TitleTagCustomComp";

const remapCompData = ( data ) => {
  let _data = [ ...data ];
  let __data = [];
  let _rounds = [];
  _data.map( round => _rounds.push( round.roundNumber ) )
  _rounds = new Set( _rounds );

  _rounds.forEach( ( r ) => {
    let _investors = [];
    _data.forEach( round => {
      if ( +round.roundNumber === +r ) {
        _investors.push( round.investor.name );
      }
    } )
    let _baseRound = _data.find( el => el.roundNumber === r );
    let _round = Object.assign( {
      "investorList": _investors
    }, _baseRound );
    // console.log(_round)
    __data.push( _round )
  } )

  __data.sort( function ( a, b ) {
    if ( a.roundNumber > b.roundNumber ) {
      return 1;
    }
    if ( a.roundNumber < b.roundNumber ) {
      return -1;
    }
    return 0;
  } );

  return __data;
}

const investorsList = ( list ) => {
  if ( list ) {
    let _length = list.length;
    return list.map( ( item, index ) => {
      if ( index < _length - 1 ) {
        return `${item}, `
      } else {
        return item
      }
    } )
  }

  return <></>
}

const InvestmentTable = ( { preview } ) => {
  const [ customComp, setCustomComp ] = useRecoilState( customCompAtom );
  const [ compData, setCompData ] = useState( [] );
  const [ isLoadedComp, setIsLoadedComp ] = useState( false );
  const [ totalAmount, setTotalAmount ] = useState('');
  const [ totalValuation, setTotalValuation ] = useState('');
  let { id } = useParams();
  const [ needUpdateComp, setNeedUpdateComp ] = useRecoilState( needUpdateCompAtom );
  const baseURL = process.env.REACT_APP_BASE_URL;
  const [ errorMessageAPI, setErrorMessageAPI ] = useState('');
  const role = useRecoilValue( roleAtom );
  const isAdmin = role === 'admin';
  const [ invalidData, setInvalidData ] = useState( true );

  const getCustomComp = () => {
    setIsLoadedComp( false );
    const data = new FormData();
    const config = {
      method: 'get',
      url: `${baseURL}/custom_comps/${id}?include_data=true`,
      headers: APIHeaders,
      withCredentials: true,
      credentials: "include",
      data: data,
    };
    axios( config )
      .then( ( resp ) => {
        setCustomComp( resp.data.data );
        setInvalidData(false);
        // console.log(resp.data.data)
      } )
      .finally( () => {
      setIsLoadedComp( true );
    } )
      .catch(error=>{
        setInvalidData(true);
        setErrorMessageAPI(error.response.data.error.title);
      })
  }

  useEffect( () => {
    if ( !preview && !customComp.length ) {
      getCustomComp();
    }
  }, [needUpdateComp] )

  useEffect( () => {
    if ( customComp.comp_data !== undefined && customComp.comp_data.length ) {
      setCompData( remapCompData( customComp.comp_data ) )

    }
  }, [ customComp ] )

  useEffect( () => {
    setTotalAmount( compData.reduce(
      ( acc, currentValue ) => acc + currentValue.amount, 0
    ) );

    setTotalValuation(compData.slice(-1)[0]?.postValuation)
  }, [ compData ] )

  if ( !isLoadedComp && !preview ) return <div className="d-flex justify-content-center align-items-center text-center min-vh-100">
    <div className="spinner-border m-5" role="status">
      <span className="sr-only">Loading...</span>
    </div>
  </div>

  return <>
    {<TableChartWrapper className={preview ? 'px-3 py-2' : ''}>
      <TitleTagCustomComp
        compName={customComp.name}
        id={id}
        isPopular={customComp.default_comp}
        ownerID={customComp.user_id}
        taggings={customComp.taggings}
        preview={preview}
      />
      <TitleCustomComp
        compName={customComp.name}
        id={id}
        isPopular={customComp.default_comp}
        ownerID={customComp.user_id}
        taggings={customComp.taggings}
        preview={preview}
      />

      {compData.length > 0 && <table className="table table-hover table-bordered">
        <thead>
        <tr>
          {/*<th scope="col">Company</th>*/}
          <th scope="col">Round</th>
          <th scope="col">Date</th>
          <th scope="col">Amount</th>
          <th scope="col">Valuation</th>
          <th scope="col">Investors</th>
        </tr>
        </thead>
        <tbody>
        {compData.map( ( entry, index ) => {
          // console.log(entry);
          return <tr key={`tr-${index}`} className="text-center">
            {/*<td>{entry.company.name}</td>*/}
            <td>{entry.round}</td>
            <td>{moment( entry.investmentDate ).format( "MMMM YYYY" )}</td>
            <td title={entry.amount}>{entry.amount ? "$" + abbrNum( entry.amount, 1 ) : ''}</td>
            <td title={entry.postValuation}>{entry.postValuation ? "$" + abbrNum( entry.postValuation, 2 ) : ''}</td>
            <td>{investorsList( entry.investorList )}</td>
          </tr>
        } )}
        <tr className="text-center">
          <th colSpan="2" style={{ textAlign: "right", paddingRight: "30px" }}>Total:</th>
          <td>{totalAmount ? "$" + abbrNum(totalAmount, 1) : ''}</td>
          <td>{totalValuation ? "$" + abbrNum(totalValuation, 1) : ''}</td>
          <td></td>
        </tr>
        </tbody>
      </table>}
    </TableChartWrapper>}
    {compData.length === 0 && <div className="py-3"><h5>No Data available</h5></div>}

    {invalidData && <CommonErrorComponent
      error={errorMessageAPI}
      admin={isAdmin}
    />}
  </>
}

export default InvestmentTable;
