
const MaximazeLegendIcon = () => {

  return <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.146447 6.48238C-0.0488155 6.67765 -0.0488156 6.99423 0.146447 7.18949L3.32843 10.3715C3.52369 10.5667 3.84027 10.5667 4.03553 10.3715C4.2308 10.1762 4.2308 9.85963 4.03553 9.66436L1.20711 6.83594L4.03553 4.00751C4.2308 3.81225 4.2308 3.49567 4.03553 3.3004C3.84027 3.10514 3.52369 3.10514 3.32843 3.3004L0.146447 6.48238ZM7.36082 7.33594C7.63696 7.33594 7.86082 7.11208 7.86082 6.83594C7.86082 6.5598 7.63696 6.33594 7.36082 6.33594L7.36082 7.33594ZM0.5 7.33594L7.36082 7.33594L7.36082 6.33594L0.5 6.33594L0.5 7.33594Z" fill="#26252A"/>
    <path d="M19.3789 7.18949C19.5742 6.99423 19.5742 6.67765 19.3789 6.48238L16.197 3.3004C16.0017 3.10514 15.6851 3.10514 15.4899 3.3004C15.2946 3.49567 15.2946 3.81225 15.4899 4.00751L18.3183 6.83594L15.4899 9.66436C15.2946 9.85963 15.2946 10.1762 15.4899 10.3715C15.6851 10.5667 16.0017 10.5667 16.197 10.3715L19.3789 7.18949ZM12.1646 6.33594C11.8884 6.33594 11.6646 6.55979 11.6646 6.83594C11.6646 7.11208 11.8884 7.33594 12.1646 7.33594L12.1646 6.33594ZM19.0254 6.33594L12.1646 6.33594L12.1646 7.33594L19.0254 7.33594L19.0254 6.33594Z" fill="#26252A"/>
    <path d="M9.81348 1V13.35" stroke="#26252A" stroke-linecap="round"/>
  </svg>;
}

export default MaximazeLegendIcon;
