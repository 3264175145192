import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Bar, BarChart, CartesianGrid, Cell, ComposedChart, Line, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { abbrNum, APIHeaders } from "../../components/shared/helpers";
import axios from "axios";
import { useRecoilState, useRecoilValue } from "recoil";
import { customCompAtom, customCompIDAtom, needUpdateCompAtom, roleAtom } from "../../atoms/profileAtom";
import LegendComponent from "../../components/chart/LegendComponent";
import { colorPatternDefault } from "../../components/shared/Colors";
import MetricsInfoType from "../../components/chart/MetricsInfoType";
import CustomTooltip from "./CustomTooltip";
import CustomizedLabel from "./CustomizedLabel";
import CommonErrorComponent from "../../components/shared/CommonErrorComponent";
import TitleCustomComp from "../../components/shared/TitleCustomComp";
import { AppSettings } from "../../config/app-settings";
import CustomCompIcon from "../../components/shared/CustomCompIcon";
import TitleTagCustomComp from "../../components/shared/TitleTagCustomComp";


const remapData = ( data, compMetrics ) => {
  let _data = [];
  let _years = [];
  let _yearsSorted = [];

  function checkIfLine(id) {
    let metric_slug = id.split(" / ")[1];

    return compMetrics.find(elem => {
      return elem.metric_slug === metric_slug && elem.ts_chart_type === 'line'
    });
  }

  try {
    if ( typeof data === "object" ) {
      Object.keys( data ).forEach( key => {
        data[ key ].map( ( el, i ) => {
          if ( el.year && !_years.includes( +el.year ) ) {
            _years.push( +el.year );
          } else if ( el.year === undefined && !_years.includes( i ) ) {
            _years.push( +i );
          }
          return false;
        } )
      } )
    }

    if ( _years ) {
      _yearsSorted = _years.sort( function ( a, b ) {
        if ( a > b ) {
          return 1;
        }
        if ( a < b ) {
          return -1;
        }
        return 0;
      } );
      _yearsSorted.map( ( year, i ) => {
        let _obj = {};
        _obj[ 'year' ] = year;
        if ( typeof data === "object" ) {
          Object.keys( data ).forEach( key => {
            data[ key ].map( ( row, i ) => {
              if ( row.year === year || +row.year === +year || ( +year > 1999 && +i === +year) ) {
                for ( const [ k, value ] of Object.entries( row ) ) {
                  // if ( k === 'cogs' || k === 'ebitda' || k === 'fw_ebitda' || k === 'labor' || k === 'same_store_sales' || (+value > 0.01 && +value < 0.99)) {
                  if ( k === 'cogs' ||
                    k === 'ebitda' ||
                    k === 'fw_ebitda' ||
                    k === 'labor' ||
                    k === 'same_store_sales' ||
                    k === 'occupancy' ||
                    k === 'ga' ||
                    k === 'opex' ) {
                    _obj[ key + " / " + k ] = ( +value * 100 ).toFixed(1);
                  } else if ( k !== 'year' ) {
                    if (value !== null) {
                      _obj[ key + " / " + k ] = +value;
                    }
                  }
                }
              }
              return false;
            } )
          } )
        }
        _data.push( _obj );
        return false;
      } )
    } else {
      return data;
    }
  } catch ( e ) {
    return data;
  }
  // console.log(_data)
  const currentYear = new Date().getFullYear();
  let lastItem = _data[_data.length - 1];
  let preLastItem = _data[_data.length - 2];

  if (lastItem?.year === currentYear) {
    let lastItemModified = {year: currentYear};
    let preLastItemModified = preLastItem;
    for (const key in preLastItem) {
      if (Object.hasOwnProperty.call(preLastItem, key)) {
        console.log(key)
        if (key !== 'year' && checkIfLine(key)) {
          preLastItemModified[`${key} /predicted`] = preLastItem[key];
        }
      }
    }

    for (const key in lastItem) {
      if (Object.hasOwnProperty.call(lastItem, key)) {
        if (key !== 'year') {
          lastItemModified[`${key} /predicted`] = lastItem[key];
        }
      }
    }
    // console.log(lastItemModified);
    // console.log(preLastItemModified)

    _data.pop();
    _data.pop();
    _data.push( preLastItemModified );
    _data.push( lastItemModified );
  }

  return _data;
}

function lightenColor(hex, percent) {
  // Remove the '#' character if present
  try {
    hex = hex.replace('#', '');

    // Convert hexadecimal to RGB
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    // Calculate the new RGB values
    const newR = Math.min(255, r + (255 - r) * (percent / 100));
    const newG = Math.min(255, g + (255 - g) * (percent / 100));
    const newB = Math.min(255, b + (255 - b) * (percent / 100));

    // Convert new RGB values to hexadecimal
    return `#${Math.round( newR ).toString( 16 ).padStart( 2, '0' )}${Math.round( newG ).toString( 16 ).padStart( 2, '0' )}${Math.round( newB ).toString( 16 ).padStart( 2, '0' )}`;
  } catch ( e ) {
    return hex;
  }

}

const CombineChart = ( { preview } ) => {
  const { id } = useParams();
  const context = useContext( AppSettings );
  const baseURL = process.env.REACT_APP_BASE_URL;
  const [ customComp, setCustomComp ] = useRecoilState( customCompAtom );
  const [ compID, setCompID ] = useRecoilState( customCompIDAtom );
  const [ needUpdateComp, setNeedUpdateComp ] = useRecoilState( needUpdateCompAtom );
  const [ isLoadedComp, setIsLoadedComp ] = useState( false );
  const [ compData, setCompData ] = useState( {} );
  const [ remappedData, setRemappedData ] = useState( {} );
  const [ dataKey, setDataKey ] = useState( {} );
  const [ compMetrics, setCompMetrics ] = useState( {} );
  const [ invalidData, setInvalidData ] = useState( true );
  // let rreMapped = remapData( dataV3 );
  const [ companiesList, setCompaniesList ] = useState( [] );
  const [ companiesListIDs, setCompaniesListIDs ] = useState( [] );
  const [ errorMessageAPI, setErrorMessageAPI ] = useState('');
  const role = useRecoilValue( roleAtom );
  let _dataKeys = [];
  const isAdmin = role === 'admin';
  // console.log(compMetrics)

  useEffect(
    () => {
      context.handleSetAppTitle( 'My Comps Library' );
      context.handleSetAppIcon( <CustomCompIcon /> );
    },
    []
  );

  useEffect( () => {
    if ( preview === undefined && !isLoadedComp) {
      getCustomComp();
    }
  }, [needUpdateComp] )

  useEffect( () => {
    if ( id !== compID ) {
      setCompID( id );
      setIsLoadedComp(false);
    }
  }, [ id ] )

  useEffect( () => {
    if ( !preview && !isLoadedComp) {
      getCustomComp();
    }
  }, [] )

  useEffect( () => {
    if ( preview && customComp !== undefined ) {
      setIsLoadedComp( true );
      // setInvalidData( false );
    }
  }, [ customComp ] )

  useEffect( () => {
    if ( compData !== undefined ) {
      setRemappedData( remapData( compData, compMetrics ) );
    }
  }, [ compData ] )

  useEffect( () => {
    setCompData( customComp.comp_data );
    setCompMetrics( customComp.custom_comp_metrics );
  }, [ customComp ] )

  useEffect( () => {
    // console.log(companiesListIDs)
    if ( compData ) {
      let _list = [];

      Object.keys( compData ).forEach( key => {
        return _list = [ ..._list, key ];
      } );

      setCompaniesListIDs( _list )
    }
  }, [ compData ] )

  useEffect( () => {
    if ( remappedData && remappedData.length > 0 ) {
      remappedData.map( ( el ) => {
        for ( const [ k, value ] of Object.entries( el ) ) {
          if ( k !== 'year' && !_dataKeys.includes( k ) ) {
            _dataKeys.push( k );
          }
        }
        return false;
      } );
      setDataKey( _dataKeys );
      setInvalidData( false );
    }

    if ( remappedData && remappedData.length === undefined ) {
      for ( const [ k, value ] of Object.entries( remappedData ) ) {
        Object.entries( value ).map( metric => {
          if ( !_dataKeys.includes( metric[ 0 ] ) ) {
            _dataKeys.push( metric[ 0 ] );
          }
          return false;
        } );
      }

      setDataKey( _dataKeys );
      setInvalidData( false );
    }

  }, [ remappedData ] )

  const getCustomComp = () => {
    setIsLoadedComp( false );
    const data = new FormData();
    const config = {
      method: 'get',
      url: `${baseURL}/custom_comps/${id}?include_data=true`,
      headers: APIHeaders,
      withCredentials: true,
      credentials: "include",
      data: data,
    };
    axios( config )
      .then( ( resp ) => {
        setCustomComp( resp.data.data );
        setCompData( resp.data.data.comp_data );
        setCompMetrics( resp.data.data.custom_comp_metrics );
        setIsLoadedComp( true );
        setInvalidData( false );
        // console.log(resp.data.data)
      } )
      .catch(error => {
        setErrorMessageAPI(error.response.data.error.title);
        setIsLoadedComp( true );
        setInvalidData( true );
      })
  }

  useEffect(()=> {
    if ( companiesListIDs.length ) {
      getCompanyListByID();
    }
  }, [companiesListIDs])

  const getCompanyListByID = () => {
    let params = '';
    companiesListIDs.map(el=> {
      return params = params + '&ids[]='+el;
    })

    const config = {
      method: 'get',
      url: `${baseURL}/companies?${params}`,
      headers: APIHeaders,
      withCredentials: true,
      credentials: "include",
    };
    axios( config )
      .then( ( resp ) => {
        setCompaniesList( resp.data.data );
      } )
  }

  console.log(remappedData);

  if ( !isLoadedComp || id !== compID ) {
    return <div className="d-flex justify-content-center align-items-center text-center min-vh-100">
      <div className="spinner-border m-5" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  }

  const formatXAxis = (tickItem) => {
    let _tick = tickItem > 10000 ? "$" + abbrNum(tickItem, 1): tickItem; // probably money

    return _tick.toString();
  }

  return <div className="row">
    <div className="col-12 position-relative">
      {!preview && <TitleTagCustomComp
        compName={customComp.name}
        preview={preview}
        isPopular={customComp.default_comp}
        ownerID={customComp.user_id}
        taggings={customComp.taggings}
        id={id}
      />}
      {!preview && <TitleCustomComp
        compName={customComp.name}
        preview={preview}
        isPopular={customComp.default_comp}
        ownerID={customComp.user_id}
        taggings={customComp.taggings}
        id={id}
      />}
      {id === compID && <LegendComponent data={companiesList} />}

      {invalidData && <CommonErrorComponent
        error={errorMessageAPI}
        admin={isAdmin}
      />}

      {(remappedData.length > 0 && !invalidData && companiesList) ? <ResponsiveContainer width='100%' height={500}>
        <ComposedChart
          data={remappedData}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 20,
          }}
        >
          <CartesianGrid vertical={false} strokeDasharray="2000" />
          <XAxis dataKey="year" label={{ value: 'Year', offset: "-5", position: 'insideBottom' }}/>
          <YAxis yAxisId="left" orientation="left" label={CustomizedLabel(compMetrics, 'left', 'line')} tickFormatter={formatXAxis} />
          <YAxis yAxisId="right" orientation="right" label={CustomizedLabel(compMetrics, 'right', 'bar')} tickFormatter={formatXAxis} />
          <Tooltip
            content={
              <CustomTooltip
                metric={compMetrics}
                companiesList={companiesList} />
            }
            cursor={{ fill: "transparent" }}
          />
          {dataKey && dataKey.length > 0 ? dataKey.map( ( el, i ) => {
            let _color;
            let _typeChart;
            let countLines = 0;

            companiesList.map( (company, index) => {
              if ( el.indexOf( company.id ) === 0 && company.color !== undefined && company.color !== null ) {
                _color = company.color;
              } else if ( el.indexOf( company.id ) === 0 ) {
                _color = colorPatternDefault[ index ]
              }
            } )

            compMetrics.map( metric => {
              if (el.includes(metric.metric_slug)) {
                _typeChart = metric.ts_chart_type;
              }
              if (metric.ts_chart_type === 'line') {
                countLines++
              }
            });
            // console.log(i % 2 === 0);

            if (el.includes('/predicted')) {

              if (_typeChart === 'line') {
                return <Line
                  key={i}
                  yAxisId="left"
                  type="monotone"
                  dataKey={el}
                  stroke={_color}
                  strokeDasharray='5 5'
                />
              }
              if (_typeChart === 'bar') {
                return <Bar
                  key={i}
                  yAxisId="right"
                  dataKey={el}
                  barSize={20}
                  strokeWidth="2"
                  strokeDasharray='5 5'
                  stroke={_color}
                  fill={lightenColor(_color,  40)}
                />
              }


            } else if ( _typeChart === 'bar' ) {
                return <Bar
                  key={i}
                  yAxisId="right"
                  dataKey={el}
                  barSize={20}
                  fill={_color}
                />;
              } else {
                return <Line
                  key={i}
                  yAxisId="left"
                  connectNulls={true}
                  type="monotone"
                  dataKey={el}
                  stroke={_color}

                  // strokeDasharray={ (countLines > 1 && i % 2 === 0) ? '3 3' : '0'}
                />
              }

          } ) : null}
          }

          } )}
        </ComposedChart>
      </ResponsiveContainer> : <></>}
      {remappedData === compData && <ComposedChart
        data={remappedData}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis />
        <YAxis yAxisId="left" orientation="left" />
        <YAxis yAxisId="right" orientation="right" />
        <Tooltip />
        <Line data={remappedData[ 4 ]} yAxisId="right" type="monotone" dataKey='fb_followers_per_company' stroke={'green'} />

        {dataKey && dataKey.length > 0 ? dataKey.map( ( el, i ) => {
         let _color;

       companiesListIDs.map( ( comp, i ) => {
         if ( el.indexOf( comp ) === 0 ) {
           _color = colorPatternDefault[ i ]
         }
       })
      {/*    console.log( el )*/}

        } ) : null}
      </ComposedChart>}
      <MetricsInfoType data={compMetrics} />
    </div>
  </div>
}

export default CombineChart;
