
const HospitalityIcon = ({size}) => {
  let _size = size === 'L' ? 2 : 1;

  return <svg width={_size * 24} height={_size * 24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.71784 11.0845L12.0518 12.5432L15.2246 11.0845L16.7927 12.5432V14.476L12.0518 19.3628L7.01923 14.476V12.9808L8.29561 11.0845H9.71784Z" fill="#e3cee1"/>
    <circle cx="5.01196" cy="7.49268" r="1.8" stroke="#c897c3" strokeWidth="1.4"/>
    <circle cx="18.9999" cy="7.49268" r="1.8" stroke="#c897c3" strokeWidth="1.4"/>
    <circle cx="12.006" cy="5.9729" r="2.2729" stroke="#c897c3" strokeWidth="1.4"/>
    <path d="M7.73076 13.3749C7.73076 13.9262 8.00383 14.548 8.54998 15.2403C9.09615 15.9326 10.1853 17.0198 11.8173 18.5019L12 18.6653L12.202 18.473C13.7686 17.0564 14.8382 15.9941 15.4106 15.2862C15.983 14.5783 16.2693 13.9412 16.2693 13.3749C16.2693 12.9165 16.1184 12.5279 15.8166 12.209C15.5148 11.8901 15.1426 11.7307 14.7 11.7307C14.4276 11.7307 14.1697 11.7903 13.9264 11.9095C13.6832 12.0288 13.4788 12.1929 13.3135 12.4018L12.2 13.7307H11.7846L10.6616 12.4018C10.4962 12.1929 10.2933 12.0288 10.0529 11.9095C9.81252 11.7903 9.56156 11.7307 9.30001 11.7307C8.83727 11.7307 8.46005 11.8901 8.16833 12.209C7.87661 12.5279 7.73076 12.9165 7.73076 13.3749ZM6.23078 13.3749C6.23078 12.5557 6.51956 11.8269 7.09713 11.1884C7.6747 10.5499 8.40899 10.2307 9.30001 10.2307C9.80386 10.2307 10.2805 10.3474 10.7298 10.5807C11.1792 10.814 11.5609 11.1243 11.875 11.5115L12 11.6557L12.125 11.5019C12.4455 11.1211 12.8263 10.814 13.2673 10.5807C13.7083 10.3474 14.1859 10.2307 14.7 10.2307C15.5846 10.2307 16.3173 10.5529 16.8981 11.1973C17.4788 11.8417 17.7692 12.5675 17.7692 13.3749C17.7692 14.207 17.4564 15.041 16.8308 15.8769C16.2051 16.7127 14.9551 17.9877 13.0808 19.7018L12 20.6922L10.9385 19.7307C9.01796 17.9743 7.75322 16.6855 7.14426 15.8644C6.53527 15.0432 6.23078 14.2134 6.23078 13.3749ZM12.075 21.4999V19.9999H20V12.9999H19.0885V11.5H21.5V21.4999H12.075ZM4.00001 19.9999H12.075V21.4999H2.50003V11.5H4.91153V12.9999H4.00001V19.9999Z" fill="#B668AE"/>
  </svg>;
}

export default HospitalityIcon;
