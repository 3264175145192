
const AskUsIcon = ({color}) => {
  let _color = color ?? 'currentColor';

  return <svg width="20" height="19" viewBox="0 0 20 19" fill={_color} xmlns="http://www.w3.org/2000/svg">
    <path d="M11.1538 9.7615H12.3461C12.3589 9.31023 12.4169 8.9753 12.5201 8.7567C12.6233 8.53812 12.8679 8.24228 13.2538 7.8692C13.7153 7.4269 14.0294 7.05318 14.1961 6.74805C14.3627 6.44293 14.4461 6.08845 14.4461 5.6846C14.4461 4.97307 14.1948 4.38621 13.6922 3.92403C13.1897 3.46184 12.5422 3.23075 11.7499 3.23075C11.1307 3.23075 10.5862 3.40158 10.1163 3.74325C9.64642 4.08492 9.30507 4.54357 9.09225 5.1192L10.173 5.57305C10.3422 5.1692 10.5592 4.86632 10.824 4.6644C11.0887 4.46247 11.3974 4.3615 11.7499 4.3615C12.1884 4.3615 12.5486 4.48842 12.8307 4.74227C13.1128 4.99612 13.2538 5.33066 13.2538 5.74587C13.2538 5.99861 13.1823 6.23556 13.0394 6.45672C12.8964 6.67787 12.6487 6.94999 12.2961 7.27308C11.8102 7.69871 11.4983 8.05703 11.3605 8.34805C11.2227 8.63908 11.1538 9.11023 11.1538 9.7615ZM11.7477 12.7692C11.994 12.7692 12.2086 12.6786 12.3913 12.4974C12.574 12.3163 12.6653 12.1025 12.6653 11.8561C12.6653 11.6097 12.5747 11.3952 12.3935 11.2125C12.2124 11.0298 11.9986 10.9384 11.7522 10.9384C11.5058 10.9384 11.2913 11.029 11.1086 11.2102C10.9259 11.3914 10.8345 11.6051 10.8345 11.8515C10.8345 12.0979 10.9251 12.3125 11.1063 12.4952C11.2875 12.6779 11.5013 12.7692 11.7477 12.7692ZM4.24998 15.5V0.5H19.2499V15.5H4.24998ZM5.74993 14H17.7499V1.99997H5.74993V14ZM0.75 18.9999V4.3077H2.24998V17.5H15.4422V18.9999H0.75Z" fill={_color}/>
  </svg>;
}

export default AskUsIcon;
