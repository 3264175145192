import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { customCompAtom, customCompIDAtom, needUpdateCompAtom, roleAtom } from "../../atoms/profileAtom";
import { abbrNum, APIHeaders, numberWithCommas } from "../../components/shared/helpers";
import axios from "axios";
import styled from "styled-components";
import CommonErrorComponent from "../../components/shared/CommonErrorComponent";
import TitleCustomComp from "../../components/shared/TitleCustomComp";
import { AppSettings } from "../../config/app-settings";
import CustomCompIcon from "../../components/shared/CustomCompIcon";
import TitleTagCustomComp from "../../components/shared/TitleTagCustomComp";
const baseLogoURL = process.env.REACT_APP_LOGOS_BASE_URL;
const signature = process.env.REACT_APP_LOGOS_SIGNATURE;

export const TableChartWrapper = styled.div`
  overflow-x: auto;
  
  table {
    thead {
      font-size: 16px;
      text-align: center;
      font-weight: 500;
      color: #828282;

      th, td {
        vertical-align: middle;
      }
    }
    tbody {
      font-size: 15px;
      color: #333333;
      
      th {
        color: black;
        font-weight: normal;
        max-width: 400px;
      }
      th, td {
        vertical-align: middle;
      }
      p {
        font-size: 14px;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
      }
    }
  }
`;

const LogoWrapper = styled.div`
  width: 100px;
  height: 40px;
  overflow: hidden; 
  display: flex; 
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  color: #828282;
  text-align: center;
  
  img {
    max-width: 100px;
    max-height: 30px;
  }
`;

export const LogoName = ({comp, showLogo}) => {
  let _logo;
  if (comp.logoFileName !== null) {
    _logo = <LogoWrapper> <img src={baseLogoURL + comp.logoFileName + signature} alt={comp.name} title={comp.name}/></LogoWrapper>
  } else {
    _logo = <LogoWrapper className="w-100"><i className="fa fa-store mx-2" title={comp.name}></i>{comp.name}</LogoWrapper>
  }

  return <th scope="row" className="text-center" style={{height: showLogo ? '60px' : "auto"}}>{showLogo ? _logo : comp.name}</th>
}

const TableChart = ( { preview } ) => {
  let { id } = useParams();
  const context = useContext( AppSettings );
  const baseURL = process.env.REACT_APP_BASE_URL;
  const [ customComp, setCustomComp ] = useRecoilState( customCompAtom );
  const [ needUpdateComp, setNeedUpdateComp ] = useRecoilState( needUpdateCompAtom );
  const [ compID, setCompID ] = useRecoilState( customCompIDAtom );
  const [ isLoadedComp, setIsLoadedComp ] = useState( false );
  const [ compData, setCompData ] = useState( {} );
  const [ compMetrics, setCompMetrics ] = useState( {} );
  const [ invalidData, setInvalidData ] = useState( true );
  const [ showLogo, setShowLogo ] = useState( true );
  const [ companiesList, setCompaniesList ] = useState( [] );
  const [ resourceIDs, setResourceIDs ] = useState( [] );
  const [ errorMessageAPI, setErrorMessageAPI ] = useState('');
  const role = useRecoilValue( roleAtom );
  const isAdmin = role === 'admin';

  useEffect(
    () => {
      setIsLoadedComp( false );
      context.handleSetAppTitle( 'My Comps Library' );
      context.handleSetAppIcon( <CustomCompIcon /> );
    },
    []
  );

  const getCustomComp = () => {
    setIsLoadedComp( false );
    const data = new FormData();
    const config = {
      method: 'get',
      url: `${baseURL}/custom_comps/${id}?include_data=true`,
      headers: APIHeaders,
      withCredentials: true,
      credentials: "include",
      data: data,
    };
    axios( config )
      .then( ( resp ) => {
        setCustomComp( resp.data.data );
        // console.log(resp.data.data)
      } )
      .catch(error=>{
        setErrorMessageAPI(error.response.data.error.title);
        setIsLoadedComp( true );
        setInvalidData( true );
      })
  }

  const getCompanyList = () => {
    let params = '';
    resourceIDs.map( el => {
      return params = params + '&ids[]=' + el;
    } )
    const config = {
      method: 'get',
      url: `${baseURL}/companies?${params}`,
      headers: APIHeaders,
      withCredentials: true,
      credentials: "include",
    };
    axios( config )
      .then( ( resp ) => {
        setCompaniesList( resp.data.data );
      } )

  }

  useEffect( () => {
    if ( id !== compID && !preview) {
      setCompID( id );
      setCustomComp([]);
      setIsLoadedComp(false);
    }
  }, [ id ] )

  useEffect( () => {
    setCompData( customComp.comp_data );
    setCompMetrics( customComp.custom_comp_metrics );
    setResourceIDs( customComp.resource_ids )
    setIsLoadedComp( true );
    setInvalidData( false );
  }, [ customComp ] )

  useEffect( () => {
    if ( resourceIDs ) {
      getCompanyList();
    }
  }, [ resourceIDs ] )
  // console.log( companiesList, compMetrics, compData )

  useEffect( () => {
    if ( !customComp.length > 0 && !preview ) {
      getCustomComp();
    }
  }, [needUpdateComp] )

  if ( !isLoadedComp) {
    return <div className="d-flex justify-content-center align-items-center text-center min-vh-100">
      <div className="spinner-border m-5" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  }

  return <>{(customComp && compMetrics && compMetrics.length) &&
    <TableChartWrapper className={preview ? 'px-3 py-2' : ''}>
      <TitleTagCustomComp
        compName={customComp.name}
        preview={preview}
        isPopular={customComp.default_comp}
        id={id}
        taggings={customComp.taggings}
        ownerID={customComp.user_id}
        showLogo={() => setShowLogo( !showLogo )}
        isShowLogo={showLogo}
      />
      <TitleCustomComp
        compName={customComp.name}
        preview={preview}
        isPopular={customComp.default_comp}
        id={id}
        taggings={customComp.taggings}
        ownerID={customComp.user_id}
        showLogo={() => setShowLogo( !showLogo )}
        isShowLogo={showLogo}
      />
      <table className="table table-hover table-bordered">
        <thead>
        <tr>
          <th scope="col">Company</th>
          {compMetrics.map( (metric, i) => {
            return <th scope="col" key={`${metric.metric_id }-${i}`}>{metric.metric_name}</th>
          } )}
        </tr>
        </thead>
        <tbody>
        {resourceIDs.map( ids => {
          return companiesList.map( (comp, index) => {
            if ( comp.id === +ids ) {
              // console.log( comp )
              return <tr key={`tr-${index}`} className="text-center">
                <LogoName comp={comp} showLogo={showLogo} />
                {compMetrics.map( metric => {
                  let _value;
                  if (compData[ ids ][ metric.metric_slug ] === null) {
                    _value = '-';
                  } else {
                    _value = !isNaN(+compData[ ids ][ metric.metric_slug ]) ? +compData[ ids ][ metric.metric_slug ] : '-';
                  }
                  if (_value && _value !== '-' && (metric.metric_slug === 'auv' ||
                    metric.metric_slug === 'capex_unit' ||
                    metric.metric_slug === 'market_cap' ||
                    metric.metric_slug === 'valuation' ||
                    metric.metric_slug === 'enterprice_value' )) {
                    _value = "$" + abbrNum(_value, 1);
                  } else if (_value && _value !== '-' && (metric.metric_slug === 'revenue' || metric.metric_slug === 'total_funding'))  {
                    _value = "$" + abbrNum(_value, 0);
                  } else if (_value && _value !== '-' && (metric.metric_slug === 'ev_ltm_ebitda' || metric.metric_slug === 'ev_ltm_sales'  || metric.metric_slug === 'sales_capex' )) {
                    _value = `${+_value.toFixed(1)}x`;
                  } else if (_value && _value !== '-' && metric.metric_slug === 'avg_google_reviews_location') {
                    _value = numberWithCommas(_value.toFixed(0));
                  } else if (_value && _value !== '-' && ( metric.metric_slug === 'ebitda')) {
                    _value = (_value * 100).toFixed(1) + '%';
                  } else if (_value && _value !== '-' && ( metric.metric_slug === 'ebitda_capex')) {
                    _value = (_value * 100).toFixed(0) + '%';
                  } else if (_value && _value !== '-' && ( metric.metric_slug === 'ig_followers_per_company' ||
                    metric.metric_slug === 'tt_followers_per_company' ||
                    metric.metric_slug === 'fb_followers_per_company' )) {
                    _value = abbrNum(_value, 0);
                  } else if ( _value !== '-' && (_value > 0.001 && _value < 0.999 && metric.metric_slug !== 'avg_google_rating_location' && metric.metric_slug !== 'avg_google_reviews_location') || (metric.metric_slug === 'same_store_sales' && _value !== '-') ) {
                    _value = (_value * 100).toFixed(1) + '%'; //probably %% value
                  } else {
                    _value = !isNaN(_value) ? _value : '-';
                  }

                  return <td key={metric.metric_slug}>{_value}</td>
                } )}
              </tr>
            }
          } )
        } )}
        </tbody>
      </table>
    </TableChartWrapper>}
    {invalidData && <CommonErrorComponent
      error={errorMessageAPI}
      admin={isAdmin}
    />}
  </>
}

export default TableChart;
