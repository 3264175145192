import styled from 'styled-components';

const variantsColors = [
  '182, 104, 174',
  '143, 130, 218',
  '177, 216, 211',
  '182, 104, 174',
  '143, 130, 218',
  '177, 216, 211'
];

const TaglistStyle = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 8px;
  
  .tag {
    display: inline-block;
    background-color: #EBEBEB;
    border-radius: 6px;
    padding: 7px 6px;
    margin-right: 8px;
    margin-bottom: 4px;
  }
`;

const Taglist = ( { list } ) => {

  return <TaglistStyle>{list.map( (tag, i) => i < 3 ? <div
    className="tag"
    style={{
      border: `1px solid rgba(${variantsColors[i]}`,
      backgroundColor: `rgba(${variantsColors[i]}, 0.4)`,
    }}
    key={'tag-' + tag.label + i}
  >{tag.label}</div> : <></> )}</TaglistStyle>
}

export default Taglist;
