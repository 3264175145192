const OpenMenuBar = ( { color, height } ) => {
  let _height = height ? height : 24;
  let _color = color ? color : "currentColor";

  return <svg width={_height} height={_height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path d="M3.2981 17.6344V16.1345H15.798V17.6344H3.2981ZM19.6481 16.6537L14.9942 11.9998L19.6481 7.34601L20.7019 8.39983L17.1019 11.9998L20.7019 15.5998L19.6481 16.6537ZM3.2981 12.7498V11.2499H12.798V12.7498H3.2981ZM3.2981 7.86521V6.36523H15.798V7.86521H3.2981Z" fill={_color} />
    </g>
  </svg>
}

export default OpenMenuBar;
