import React, { useEffect, useMemo, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  chosenMetricsAtom,
  CSMetricsAttributesAtom,
  metricLimitAtom,
  metricListAtom,
  newCustomCompTypeAtom,
} from "../../atoms/profileAtom";
import { APIHeaders } from "../../components/shared/helpers";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const baseURL = process.env.REACT_APP_BASE_URL;

const metricCategories = [ 'Real estate', 'Brand', 'Product', 'People', 'Valuation & Funding', 'Unit Economics & Financials' ];

const NewCompSetStep3 = () => {
  const [ metricList, setMetricList ] = useRecoilState( metricListAtom );
  const [ chosenMetrics, setChosenMetrics ] = useRecoilState( chosenMetricsAtom );
  const metricLimit = useRecoilValue( metricLimitAtom );
  const [ CSMetricsAttributes, setCSMetricsAttributes ] = useRecoilState( CSMetricsAttributesAtom );
  const [ isLoadedMetric, setIsLoadedMetric ] = useState( false );
  const [ sameStart, setSameStart ] = useState(false);
  const compType = useRecoilValue( newCustomCompTypeAtom );
  const navigate = useNavigate();

  const getTSChartType = (id) =>{
    let _type = 'line';
    metricList.map(metric=>{
      if( +metric.id === id && metric.ts_chart_type !== 'both' ) {
        _type = metric.ts_chart_type;
      }
    })

    return _type;
  }

  const handleCheckOption = ( e ) => {
    chosenMetrics?.map( el => {
      if ( chosenMetrics.map( el => +el ).includes( +e.target.value ) ) {
        let _filtered = chosenMetrics.filter( item => +item !== +e.target.value );
        let _filteredCSMetrics = CSMetricsAttributes.map( el => el ).filter( attr => +attr.metric_id !== +e.target.value );
        setCSMetricsAttributes( _filteredCSMetrics );
        setChosenMetrics( _filtered )
      } else {
        let _temp = chosenMetrics.concat(  e.target.value );
        setChosenMetrics( _temp );
        setCSMetricsAttributes( [ ...CSMetricsAttributes, {
          "metric_id": +e.target.value,
          "ts_chart_type": getTSChartType(+e.target.value),
          "same_start": sameStart
        }])
      }
    } );

    if ( chosenMetrics.length === 0 ) setChosenMetrics( [  +e.target.value ] );
    if ( CSMetricsAttributes.length === 0 ) setCSMetricsAttributes([
      {
        "metric_id": +e.target.value,
        "ts_chart_type": getTSChartType(e.target.value),
        "same_start": sameStart
      }
    ]);
  }

  const getMetricList = () => {
    setIsLoadedMetric( false );
    const config = {
      method: 'get',
      url: `${baseURL}/metrics`,
      headers: APIHeaders,
      withCredentials: true,
      credentials: "include",
    };
    axios( config )
      .then( ( response ) => {
        // console.log( response.data.data )
        setMetricList( response.data.data );
      } )
      .catch( ( error ) => {
        console.log( error.response.status );
        if ( error.response.status === 401 ) {
          navigate( '/user/login', {replace: true} )
        }
      } ).finally( () => {
      setIsLoadedMetric( true );
    } )
  }

  useEffect( () => {
    if ( chosenMetrics.length > metricLimit || CSMetricsAttributes.length > metricLimit) {
      setChosenMetrics( [] );
      setCSMetricsAttributes([]);
    }
  }, [ ] )

  useMemo( () => {
    if ( !metricList.length ) {
      getMetricList();
    }
  }, [] )

  return <div className="p-4">
    <h2>Choose Metrics</h2>
    <div className="d-flex justify-content-between flex-wrap">
      { (isLoadedMetric || metricList) && metricCategories.map( ( metricSection, i ) => {
        return !( (compType === 'Time Series' && metricSection === 'Brand' ) || (compType === 'Time Series' && metricSection === 'People'))  && <div key={`category-${i}`} className="col-xxl-4 col-lg-6 py-2">
          <h4 className="py-2">{metricSection}</h4>
          {metricList.map( ( metric, i ) => {
            if ( metricSection === metric.section && !( (metric.slug === 'total_states' ||
              metric.slug === 'glassdoor_rating' ||
              metric.slug === 'indeed_rating' ||
              metric.slug === 'glassdoor_ceo_rating' ||
              metric.slug === 'indeed_ceo_rating'||
              metric.slug === 'tt_followers_per_company'||
              metric.slug === 'fb_followers_per_company'||
              metric.slug === 'employee_satisfaction' ||
              metric.slug === 'ig_followers_per_company'||
              metric.slug === 'valuation') && compType === 'Time Series' || metric.slug === 'employee_satisfaction') ) {
              // console.log(metric.slug)
              return <div className="form-check mb-2 fs-14px" key={`option-${metric.slug}`}>
                <input
                  className="form-check-input"
                  type="checkbox"
                  checked={chosenMetrics.map( comp => +comp ).includes( +metric.id )}
                  disabled={chosenMetrics.length >= metricLimit && !chosenMetrics.map( comp => +comp ).includes( +metric.id )}
                  onChange={handleCheckOption}
                  id={metric.slug}
                  name={metric.name}
                  value={metric.id} />
                <label
                  className="form-check-label"
                  htmlFor={metric.slug}>{metric.name}
                </label>
              </div>
            }
          } )}
        </div>
      } )}
    </div>
  </div>
}

export default NewCompSetStep3;
